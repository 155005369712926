import { useAuth } from '../../store/useAuth';
import { IAuthProps } from '../../typings/auth';
import LoginSubmit from './LoginSubmit';
import { useAuthForm } from './useAuthForm';
import usr from '../../../../img/user.png';
import pwd from '../../../../img/password.png';
import pwdVisible from '../../../../img/passwordVisible.png';
import Form from './Form';
import ErrorTooltip from './ErrorTooltip';

const LoginForm: React.FC<IAuthProps> = ({ onLogin }) => {
  const { formState, handleSubmit, register } = useAuthForm();
  const { closeErrors, loginError, error, isPwdOpen, setPwdOpen } = useAuth();

  return (
    <Form onLogin={onLogin} handleSubmit={handleSubmit}>
      <div className="relative">
        <ErrorTooltip title="Аккаунт с таким логином не найден" open={loginError} isLogin />
        <input
          {...register('username', { required: true })}
          placeholder="Логин"
          className="auth-input"
          onFocus={closeErrors}
        />
        <img src={usr} alt="user-icon" className="input-icon cursor-default" />
      </div>

      <div className="relative">
        <ErrorTooltip title="Не правильный пароль" open={!loginError && error} />
        <input
          {...register('password', { required: true })}
          placeholder="Пароль"
          className="auth-input"
          type={isPwdOpen ? 'text' : 'password'}
          onFocus={closeErrors}
        />

        <img
          src={isPwdOpen ? pwdVisible : pwd}
          alt="password-icon"
          className="input-icon cursor-pointer"
          onClick={() => setPwdOpen(!isPwdOpen)}
        />
      </div>
      <LoginSubmit isValid={formState.isValid} />
    </Form>
  );
};

export default LoginForm;
