import { times } from 'lodash';

/** Компонент пролоадера для таблиц и списков */
const RowPreloader: React.FC<{ count?: number; className?: string }> = ({
  count = 10,
  className = 'my-2',
}) => {
  return (
    <div className={className}>
      {times(count, Number).map((_, i) => (
        <div key={i} className="row-preloader" />
      ))}
    </div>
  );
};

export default RowPreloader;
