import create from 'zustand';

type ILightbox = {
  image: string;
  setImage(image: string): void;
};

export const useLightbox = create<ILightbox>((set) => ({
  image: '',
  setImage: (image) => set(() => ({ image })),
}));
