import axios from 'axios';
import {
  IOnlineHistoryParams,
  IOnlineHistoryResponse,
  IOnlineUsersResponse,
} from '../../typings/admin';

export async function history(params: IOnlineHistoryParams) {
  try {
    const { data } = await axios.get<IOnlineHistoryResponse>(`/api/v1/user/online_history/`, {
      params,
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function users() {
  try {
    const { data } = await axios.get<IOnlineUsersResponse>(`/api/v1/user/online/`);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
