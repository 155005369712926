import { useNavigate } from '@tanstack/react-location';
import { useQuery } from 'react-query';
import { getAllChats, getCurrentUser } from '../../../api/common';
import Table from '../../../shared/Table';
import { filterSupportChats, getChatCompanies } from '../../../utils/chat';
import AdminChatsTemplate from '../templates/AdminChatsTemplate';
import { ADMIN_COMPANIES_CHATS } from '../routes';
import { API } from '../../../api';

const titles = ['Компании', 'Чаты'];

const AdminChatsCompanies: React.FC = () => {
  const navigate = useNavigate();
  const { data: user } = useQuery('user', getCurrentUser);
  const { data: chats, isFetching } = useQuery(
    'support',
    () => getAllChats({ users: user?.id, chat_status: 0, ordering: '-created' }),
    {
      enabled: !!user?.id,
    },
  );
  const { data: companies } = useQuery('companies', () => API.admin.company.all());
  const filteredCompanies = getChatCompanies(filterSupportChats(chats), user?.id);

  const rows = Object.keys(filteredCompanies).map((id) => {
    const companyId = Number(id);
    const foundTitleCompany = companies?.find((company) => company.id === companyId);
    return [
      <div
        className="cursor-pointer"
        onClick={() => navigate({ to: ADMIN_COMPANIES_CHATS + '/' + companyId })}
      >
        {foundTitleCompany?.title}
      </div>,
      <>{filteredCompanies[companyId].length}</>,
    ];
  });

  return (
    <AdminChatsTemplate>
      <Table titles={titles} rows={rows} isLoading={!chats && isFetching} />
    </AdminChatsTemplate>
  );
};

export default AdminChatsCompanies;
