import Tooltip from '@mui/material/Tooltip';
import type { IPassings } from '../../../typings/curator';
import { renderDate } from '../../../utils/time';

// 0 Тест пройден успешно
// 1 На проверке
// 2 Превышено допустимое время прохождения
// 3 Превышено допустимое кол-во попыток
// 4 Процент прохождения не набран
// 5 тест закончен, но есть неотвеченные вопросы

interface IProps {
  finalPassings: IPassings[];
  coursesCount?: number;
}

const Previous: React.FC<IProps> = (props) => {
  return (
    <div className="text-center text-sm">
      <span>Неудачные попытки сдачи:</span>
      {props.finalPassings.reverse().map((el, index) => (
        <span className="block" key={index}>
          {renderDate(new Date(el.created).toString())}
        </span>
      ))}
    </div>
  );
};

const CuratorFinalResults: React.FC<IProps> = (props) => {
  const getLastPassing = () => {
    if (Array.isArray(props.finalPassings) && !!props.finalPassings.length) {
      return props.finalPassings[0];
    } else {
      return null;
    }
  };

  const render = (passing: IPassings) => {
    const id = new Date(passing.created).getTime();
    const whiteSpace = (props.coursesCount || 0) > 1 ? 'nowrap' : 'normal';

    switch (passing.success_passed) {
      case 0: {
        return (
          <Tooltip
            title={<div className="text-sm">Сдан с {props.finalPassings.length}-й попытки</div>}
            arrow
          >
            <div id={`_${id}_`}>
              <b
                style={{
                  color: 'green',
                  whiteSpace,
                }}
              >
                Успешно сдан <span>({passing.response_rate})</span>
              </b>
            </div>
          </Tooltip>
        );
      }
      case 1: {
        return (
          <Tooltip title={<div className="text-sm">Проверяется преподавателем</div>} arrow>
            <div id={`_${id}_`}>
              <span style={{ color: 'orange', whiteSpace }}>На проверке</span>
            </div>
          </Tooltip>
        );
      }
      case 4: {
        return (
          <Tooltip title={<Previous finalPassings={props.finalPassings} />} arrow>
            <div style={{ color: 'red', whiteSpace }} id={`failed_passing_${id}`}>
              Не зачтено <span>({passing.response_rate})</span>
            </div>
          </Tooltip>
        );
      }

      default: {
        return <div>-</div>;
      }
    }
  };

  const lastPassing = getLastPassing();

  return lastPassing ? (
    <div className="flex justify-center">{render(lastPassing)}</div>
  ) : (
    <div style={{ color: 'grey' }}>Не сдавал</div>
  );
};

export default CuratorFinalResults;
