import axios from 'axios';
import { IAuthParams, IAuthResponce } from '../../typings/common';

/** функция возвращает временный и постоянный токены доступа  */
export async function getToken(params: IAuthParams) {
  try {
    const { data } = await axios.post<IAuthResponce>('/api/v1/token/', params);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
