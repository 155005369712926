import Spinner from '../../../../shared/Spinner';
import arrow from '../../../../img/arrow-auth.png';
import { ISubmitButtonProps } from '../../typings/auth';

const SubmitButton: React.FC<ISubmitButtonProps> = (props) => {
  return (
    <button
      type={props.isVisible ? 'submit' : 'button'}
      className={`relative mt-6 disabled:cursor-default cursor-pointer text-[19px] w-full h-[50px] text-white rounded transition-all duration-300 ${
        props.isIntelleka
          ? 'disabled:bg-active_disabled_i hover:bg-active_i_hover bg-active_i'
          : 'disabled:bg-active_disabled_p hover:bg-active_p_hover bg-active_p'
      } ${props.isVisible ? 'opacity-100 z-10' : 'opacity-0 z-0'}`}
      disabled={!props.isValid}
    >
      Войти
      {props.loading ? (
        <Spinner
          className={`${
            props.isIntelleka ? 'fill-active_i' : 'fill-active_p'
          } w-[16px] mr-0 absolute right-4 top-[17px]`}
        />
      ) : (
        <img src={arrow} alt="arrow" className="w-[7px] absolute right-5 top-[18px]" />
      )}
    </button>
  );
};

export default SubmitButton;
