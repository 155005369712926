import { useUsersCheckParams } from '../store/useUsers';
import AdminRenderUsers from '../containers/AdminRenderUsers';

const AdminUsersCheck: React.FC = () => {
  const params = useUsersCheckParams();

  return <AdminRenderUsers {...params} />;
};

export default AdminUsersCheck;
