/**
 * @module passing
 * @category общие утилиты
 * @description утилиты по работе с прохождениями материалов и тестов
 */

import { ITestPassing } from '../typings/client';
import { russianEndings } from './string';
import { stringToSeconds } from './time';

/**
 * Функция парсит результат прохождения тестовой попытки в читаемую строку,
 * для рендера в модальном окне результатов
 * @param rateParam результат прохождения
 * @returns строка в формате "Зачтено ХХ вопросов из ХХ"
 */
export const parseResponseRate = (rateParam?: number | string) => {
  if (!rateParam) return null;
  const rate = String(rateParam);
  const arr = rate.split(':');
  if (arr.length === 2) {
    const correct = arr[0],
      all = arr[1];
    return `Зачтено ${correct} ${russianEndings(Number(correct), [
      'вопрос',
      'вопроса',
      'вопросов',
    ])} из ${all}`;
  } else {
    return null;
  }
};

/**
 * Функция парсит результат прохождения теста пользователем в читаемую строку,
 * для рендера в статистике пользователя
 * @param rateParam результат прохождения
 * @returns строка в формате "ХХ правильно из ХХ"
 */
export const parseResponseRateAdmin = (rate?: string) => {
  if (!rate) return null;
  const arr = rate.split(':');
  if (arr.length === 2) {
    const correct = arr[0],
      all = arr[1];
    return `${correct} правильно из ${all}`;
  } else {
    return null;
  }
};

/**
 * Функция проверяет, закрыта ли последняя попытка в интерфейсе пользователя
 * @param passings массив всех попыток
 * @returns возвращает false, если последняя попытка не закрыта или true, если закрыта или отсутствует
 */
export const isLastPassingClosed = (passings: ITestPassing[]) => {
  if (!passings.length) return true;
  return !!passings[0].finish_time;
};

/**
 * Функция возвращает последнюю попытку из списка, который приходит с сервера
 * @param passings список попыток
 * @returns последняя попытка
 */
export const getLastPassing = (passings: ITestPassing[]) => {
  if (passings.length) {
    return passings[0];
  } else {
    return undefined;
  }
};

/**
 *
 * @param passing попытка
 * @param testTravelTime общее время прохождения теста
 * @returns возвращает true, если есть время закончить попытку или false если нету
 */
export const hasTimeToPass = (passing: ITestPassing, testTravelTime?: string) => {
  const lastPassingStartTime = Math.round(new Date(passing.start_time).getTime() / 1000);
  const dateNow = Math.round(Date.now() / 1000);
  const timePassedSinceLastPassingStarted = dateNow - lastPassingStartTime;
  const travelTime = stringToSeconds(testTravelTime);
  return timePassedSinceLastPassingStarted < travelTime;
};

/**
 * Функция определяет оставшееся время до конца теста
 * @param start_time время начала теста
 * @param travel_time время окончания теста
 * @returns оставшееся время до конца теста
 */
export const getTimePassingLeft = (start_time: string, travel_time: string) => {
  const lastPassingStartTime = Math.round(new Date(start_time).getTime() / 1000);
  const dateNow = Math.round(Date.now() / 1000);
  const timePassedSinceLastPassingStarted = dateNow - lastPassingStartTime;
  const travelTime = stringToSeconds(travel_time);
  return travelTime - timePassedSinceLastPassingStarted;
};
