import { Link, useMatch } from '@tanstack/react-location';
import { useQuery } from 'react-query';
import { API } from '../../../api';
import { hasLocationMatches } from '../../../utils/link';
import { getUserActivityFormatted } from '../../../utils/statistic';
import { generateUserTestPassingLink, generateUserLink } from '../routes';
import AdminUserTemplate from './AdminUserTemplate';
import { TiInfoOutline } from 'react-icons/ti';
import Tooltip from '@mui/material/Tooltip';

const AdminUserStatisticTemplate: React.FC = ({ children }) => {
  const { params } = useMatch();
  const { id } = params;

  const { data: userActivity } = useQuery(['activity', 'user', id], () =>
    API.admin.activity.visitings({
      user: id,
    }),
  );

  const { data: log_errors } = useQuery(
    ['user-log-errors', id],
    () => API.common.getUserLogErrors({ user: Number(id) }),
    { enabled: !!id },
  );

  const { data } = useQuery(['user', id], () => API.admin.user.byId(id), { enabled: !!id });
  const courseId = !!data?.courses?.length ? data.courses[0].id : undefined;

  const activeStyles = { color: '#000', borderBottom: 'none' };

  const isCoursesTab = hasLocationMatches('courses');

  return (
    <AdminUserTemplate>
      <div className="border border-gray-medium pt-0 rounded">
        <div className="flex text-[1rem] w-full text-gray-400">
          <Link
            className="pt-1 pb-7 border-b border-gray-medium w-full pl-3 hover:text-black-default transition-all"
            to={generateUserLink(id, '/visitings')}
            getActiveProps={() => ({ style: activeStyles })}
          >
            Общее время в системе <b>{getUserActivityFormatted(userActivity)}</b>
          </Link>
          <Link
            className="pt-1 pb-7 border-l border-r border-b border-gray-medium w-full pl-3 hover:text-black-default transition-all flex items-center"
            to={generateUserLink(id, '/devices')}
            getActiveProps={() => ({ style: activeStyles })}
          >
            <div>Устройства</div>
            {!!log_errors?.length && (
              <Tooltip
                arrow
                title="У пользователя были проблемы с подключением к сети, в таблице отображается не вся статистика"
              >
                <div>
                  <TiInfoOutline className="text-yellow-400 ml-1" />
                </div>
              </Tooltip>
            )}
          </Link>
          {courseId && (
            <Link
              style={{ borderBottom: isCoursesTab ? 'none' : '1px solid #000' }}
              className="pt-1 pb-7 border-b border-gray-medium w-full pl-3 hover:text-black-default transition-all"
              to={generateUserTestPassingLink(id, courseId)}
            >
              Курсы
            </Link>
          )}
        </div>
        <div className="p-3">{children}</div>
      </div>
    </AdminUserTemplate>
  );
};

export default AdminUserStatisticTemplate;
