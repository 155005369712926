import { useMutation } from 'react-query';
import { API } from '../../../api';
import { queryClient } from '../../../hooks/queryClient';
import { IAuthPassword, IUser, TUpdateUser } from '../../../typings/admin';
import { TPagination, useCheck, useNotifications } from '../../../ui/admin/store/useUsers';
import { getKey } from '.';
import { AdminUsersStore } from './typings';
import { IAuthParams, IMutateNotification } from '../../../typings/common';

export function useChangeAdminUsers(params: TPagination) {
  const key = getKey(params);
  const users = queryClient.getQueryData<AdminUsersStore>(key);
  const { setChangedStatus, setConfirmVisible, setFailedChangedStatus } = useNotifications();
  const { setChecked } = useCheck();

  return useMutation<IUser, unknown, number>(
    (id) => API.admin.user.update({ id, is_active: !users?.usersById[id]?.is_active }),
    {
      onSuccess: (user) => {
        /** вывод нотификации */
        setChangedStatus(user.username);
        /** обновление кеша пользователей */
        queryClient.refetchQueries(key);
        /** сброс кеша отмеченных пользователей */
        setChecked([]);
        /** закрытия окна подтверджения */
        setConfirmVisible(undefined);
      },
      onError: (_, user) => users && setFailedChangedStatus(users.usersById[user].username),
    },
  );
}

export function useChangeSingleUser(onMutate: (data: IMutateNotification) => void) {
  return useMutation<IUser, unknown, TUpdateUser>((user) => API.admin.user.update(user), {
    onSuccess: () => onMutate({ appearance: 'success' }),
    onError: () => onMutate({ appearance: 'error' }),
  });
}

export function useChangeUserPassword(onMutate: (data: IMutateNotification) => void) {
  return useMutation<IAuthParams, unknown, IAuthPassword>(
    (user) => API.admin.user.updatePassword(user),
    {
      onSuccess: () => onMutate({ appearance: 'success' }),
      onError: () => onMutate({ appearance: 'error' }),
    },
  );
}
