/**
 * @module pagination
 * @category общие утилиты
 * @description утилиты по работе с компонентом пагинации
 */

/**
 * Функция вычисляет количество страниц по заданным параметрам
 * @param countElements количество элементов
 * @param pageSize размер страницы
 * @returns количество страниц из предложенных элементов
 */
export function getCountPages(countElements: number, pageSize?: number) {
  if (!pageSize) return 0;
  if (countElements <= pageSize) {
    return 1;
  }
  const restUsers = countElements % pageSize;

  if (Number.isNaN(restUsers)) {
    return 1;
  }

  return restUsers ? (countElements - restUsers) / pageSize + 1 : countElements / pageSize;
}

/**
 * Функция возвращает массив значений для селекта в пагинации
 * @param countUsers количество элементов всего
 * @param sizePage количество элементов на странице
 * @returns массив значений для селекта в пагинации
 */
export const getPagination = (countUsers: number, sizePage?: number) => {
  const all = { label: 'Всех', value: countUsers };

  const pagination = [
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '500', value: 500 },
    { label: '1000', value: 1000 },
  ];

  if (sizePage) {
    const foundValue = pagination.find((el) => el.label.includes(String(sizePage)));

    if (!foundValue) {
      pagination.unshift({ label: String(sizePage), value: Number(sizePage) });
    }
  }

  return pagination.concat(all);
};
