import { useEffect, useState } from 'react';

import LightBox from './LightBox';
import image_not_found from '../img/image_not_found.png';

/**
 * Компонент рендера картинки для теста
 */
const Media: React.FC<{
  m_file: string;
}> = ({ m_file }) => {
  const [isOpen, setOpen] = useState(false);
  const [image, setImage] = useState('');

  useEffect(() => {
    m_file && setImage(m_file);
  }, [m_file]);

  return m_file ? (
    <div className={`min-h-[150px] mt-5`}>
      <div className="relative z-30" onClick={() => setOpen(true)}>
        <img src={image} alt="media file" onError={() => setImage(image_not_found)} />
      </div>

      {isOpen && <LightBox mainSrc={image} onCloseRequest={() => setOpen(false)} />}
    </div>
  ) : null;
};

export default Media;
