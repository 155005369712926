import ClientRedirects from './pages/ClientRedirects';
import ClientInfo from './pages/ClientInfo';
import ClientMaterials from './pages/ClientMaterials';
import ClientWebinars from './pages/ClientWebinars';
import ClientTests from './pages/ClientTests';
import ClientTeacher from './pages/ClientTeacher';
import ClientGroup from './pages/ClientGroup';
import ClientSupport from './pages/ClientSupport';
import Page404 from '../common/pages/Page404';
import { TCustomRoute } from '../admin/routes';

export const ROOT = '/client',
  REDIRECTS = '/',
  INFO = '/info',
  MATERIALS = '/materials',
  WEBINARS = '/webinars',
  TESTS = '/tests',
  TEACHER = '/teacher',
  GROUP = '/group',
  SUPPORT = '/support';

const routes: TCustomRoute[] = [
  {
    path: ROOT,
    description: 'Интерфейс ученика',
    children: [
      { path: REDIRECTS, element: <ClientRedirects />, description: 'Страница редиректов' },
      { path: INFO, element: <ClientInfo />, description: 'Страница описания курса' },
      { path: MATERIALS, element: <ClientMaterials />, description: 'Страница материлов' },
      { path: WEBINARS, element: <ClientWebinars />, description: 'Страница вебинаров' },
      { path: TESTS, element: <ClientTests />, description: 'Страница тестов' },
      { path: TEACHER, element: <ClientTeacher />, description: 'Страница чата с преподавателем' },
      { path: GROUP, element: <ClientGroup />, description: 'Страница чата с группой' },
      {
        path: SUPPORT,
        element: <ClientSupport />,
        description: 'Страница чата со службой поддержки',
      },
      { path: '*', element: <Page404 />, description: 'Страница 404' },
    ],
  },
];

export default routes;
