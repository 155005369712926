import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';
import { IOnlineHistoryResponse } from '../typings/admin';

const SResponsiveContainer = styled(ResponsiveContainer)<{ loading: boolean }>`
  opacity: ${(props) => (props.loading ? '.4' : 1)};
`;

const Chart: React.FC<{
  history?: IOnlineHistoryResponse;
  isFetching?: boolean;
  width?: number;
  height?: number;
}> = ({ width = 227, height = 100, isFetching = false, ...props }) => {
  return (
    <div className="relative text-xs">
      <SResponsiveContainer width={width} height={height} loading={isFetching}>
        <LineChart
          data={props.history?.map((el) => ({ name: el.date, users: el.active_clients_count }))}
          width={300}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="users" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </SResponsiveContainer>
    </div>
  );
};

export default Chart;
