import { Link } from '@tanstack/react-location';
import AdminTemplate from '../templates/AdminTemplate';
import { ADMIN_SETTINGS_AUTH, ADMIN_SETTINGS_STATISTIC, ADMIN_SETTINGS_ROUTEMAP } from '../routes';

export const SETTINGS_TITLES = [
  'Общая статистика',
  'Выбор пользователей для быстрой авторизации',
  'Карта приложения',
];

const AdminSettings: React.FC = () => {
  return (
    <AdminTemplate>
      <h1 className="text-[32px] text-center mb-4">Настройки</h1>
      <div className="flex flex-col w-full justify-center max-w-[1100px] m-auto">
        <Link className="border p-3 hover:bg-gray-100 transition-all" to={ADMIN_SETTINGS_STATISTIC}>
          {SETTINGS_TITLES[0]}
        </Link>
        <Link className="border p-3 hover:bg-gray-100 transition-all" to={ADMIN_SETTINGS_AUTH}>
          {SETTINGS_TITLES[1]}
        </Link>
        <Link className="border p-3 hover:bg-gray-100 transition-all" to={ADMIN_SETTINGS_ROUTEMAP}>
          {SETTINGS_TITLES[2]}
        </Link>
      </div>
    </AdminTemplate>
  );
};

export default AdminSettings;
