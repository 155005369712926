import axios from 'axios';
import {
  IMateriaPassingsParams,
  IMaterialPassingResponse,
  ICreateMaterialPassing,
  IUpdateMaterialPassing,
} from '../../typings/client';
import { toFormData } from '../../utils/api';

export const CLIENT_MATERIALS_PASSING_URL = '/api/v1/client/courses/material_passing/';

export async function getMaterialPassings(params?: IMateriaPassingsParams) {
  try {
    const { data } = await axios.get<IMaterialPassingResponse[]>(CLIENT_MATERIALS_PASSING_URL, {
      params,
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createMaterialPassing(model: ICreateMaterialPassing) {
  try {
    const { data } = await axios.post<IMaterialPassingResponse>(
      CLIENT_MATERIALS_PASSING_URL,
      toFormData(model),
    );

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updateMaterialPassing({ id, ...model }: IUpdateMaterialPassing) {
  try {
    const { data } = await axios.patch<IMaterialPassingResponse>(
      `${CLIENT_MATERIALS_PASSING_URL}${id}/`,
      model,
    );

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
