import { keyBy } from 'lodash';
import { useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { IBookmark } from '../../../typings/client';
import { getCurrentUser } from '../../../api/common';
import { queryClient } from '../../../hooks/queryClient';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import ArrowLeft from '../../../shared/ArrowLeft';
import BookmarkForm from '../../../shared/BookmarkForm';
import Bookmarks from '../../../shared/Bookmarks';
import { Modal } from '@mui/material';
import { checkIsIntelleka } from '../../../utils/link';
import { useCourseMaterials } from '../store/useCourseMaterials';
import { useMaterial } from '../store/useMaterial';
import { Tooltip } from '@mui/material';
import { API } from '../../../api';
import { useCourse } from '../store/useCourse';
import { BsArrowBarLeft } from 'react-icons/bs';
import { useWindowSize } from 'react-use';

const ClientBookmarks: React.FC = () => {
  const ref = useRef(null);
  const { id, setId, page, setTask, toggleSidebar, pages } = useMaterial();
  const { course } = useCourse();
  const { data: user } = useQuery('user', getCurrentUser);
  const { materials } = useCourseMaterials();
  const [open, setOpen] = useState(false);
  const [adding, setAdding] = useState(false);
  const [editing, setEditing] = useState(0);
  const [hovered, setHovered] = useState(false);
  const { data } = useQuery('bookmarks', () => API.client.bookmark.getAllBookmarks());
  const { mutate: removeBookmark, isLoading: deleting } = useMutation<unknown, unknown, number>(
    API.client.bookmark.deleteBookmark,
    {
      onSuccess: (_, id) => {
        queryClient.setQueryData('bookmarks', () => {
          const oldData = queryClient.getQueryData<IBookmark[]>('bookmarks');
          if (oldData) {
            return oldData.filter((b) => b.id !== id);
          }
        });
      },
    },
  );
  const { mutate: addBookmark, isLoading: creating } = useMutation<IBookmark, unknown, IBookmark>(
    (data) => API.client.bookmark.createBookmark({ ...data, material: id, page, user: user?.id }),
    {
      onSuccess: (res) => {
        queryClient.setQueryData('bookmarks', () => {
          const oldData = queryClient.getQueryData<IBookmark[]>('bookmarks');
          setAdding(false);
          if (oldData) return [res, ...oldData];
        });
      },
    },
  );

  const { mutate: editBookmark, isLoading: updating } = useMutation<IBookmark, unknown, IBookmark>(
    (data) => {
      return API.client.bookmark.changeBookmark({
        ...data,
        material: data.id,
        page: data.page,
        user: user?.id,
        id: editing,
      });
    },
    {
      onSuccess: (res) => {
        queryClient.setQueryData('bookmarks', () => {
          const oldData = queryClient.getQueryData<IBookmark[]>('bookmarks');
          setEditing(0);
          if (oldData) return oldData.map((el) => (el.id === res.id ? res : el));
        });
      },
    },
  );

  const bookMarksById = keyBy(data, 'id');
  const currentMaterialTitle = id ? materials[id]?.title : '';

  useOnClickOutside(ref, () => setOpen(false));

  const isIntelleka = checkIsIntelleka();
  const { width: windowWidth } = useWindowSize();

  return (
    <div className="flex p-5 pr-[10px] items-center border-b border-gray-dark_ultra_light_opacity justify-between">
      <div className="flex items-center">
        <ArrowLeft
          className={`${
            hovered
              ? isIntelleka
                ? 'before:bg-active_i after:bg-active_i'
                : 'before:bg-active_p after:bg-active_p'
              : ''
          } mr-4`}
        />
        <div
          onClick={() => setId()}
          onMouseMove={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          className={`cursor-pointer ${
            hovered ? (isIntelleka ? 'text-active_i' : 'text-active_p') : ''
          } w-fit whitespace-nowrap text-[18px] underline leading-none transition-all duration-300`}
        >
          Вернуться к программе курса
        </div>
      </div>

      <div className="flex items-center">
        <Tooltip
          title={<div className="text-sm text-center">Ваши закладки</div>}
          arrow
          placement="top"
          onOpen={() => setOpen(true)}
        >
          <svg
            className={`${
              isIntelleka ? 'bookmark-icons-i' : 'bookmark-icons'
            } mr-[10px] focus:outline-none`}
            viewBox="0 0 24 24"
            fill="#000000"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M17 3H7c-1.1 0-2 .9-2 2v16l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z" />
          </svg>
        </Tooltip>

        <Tooltip
          title={<div className="text-sm text-center">Добавить закладку</div>}
          arrow
          placement="top"
        >
          <svg
            className={`${isIntelleka ? 'bookmark-icons-i' : 'bookmark-icons'} focus:outline-none`}
            viewBox="-2 -2 28 28"
            onClick={() => setAdding(true)}
          >
            <path d="M18 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 4h2v5l-1-.75L9 9V4zm9 16H6V4h1v9l3-2.25L13 13V4h5v16z"></path>
          </svg>
        </Tooltip>
        {windowWidth < 992 && (
          <BsArrowBarLeft className="ml-2" onClick={() => toggleSidebar(false)} />
        )}
      </div>

      {open && (
        <Bookmarks
          isIntelleka={isIntelleka}
          bookmarks={data?.filter((el) => Number(el.course) === Number(course))}
          refObject={ref}
          setEditing={setEditing}
          removeBookmark={removeBookmark}
          deleting={deleting || updating}
          onClick={(id: number, page: number) => {
            setTask({ type: 'goTo', material: id, page });
          }}
        />
      )}

      <Modal open={adding || !!editing} className="m-auto max-w-[500px] mt-[1.75rem]">
        <BookmarkForm
          isIntelleka={isIntelleka}
          onClose={() => {
            setAdding(false);
            setEditing(0);
          }}
          page={adding ? page : bookMarksById[editing]?.page}
          material={adding ? currentMaterialTitle : bookMarksById[editing]?.title}
          loading={creating || updating}
          submit={adding ? addBookmark : editBookmark}
          edit={!adding}
          pagesCount={pages.length}
        />
      </Modal>
    </div>
  );
};

export default ClientBookmarks;
