import AdminTemplate from '../templates/AdminTemplate';
import AdminAddUser from './AdminAddUser';
import { getOnCheckUsers } from '../../../utils/array';
import Pagination from '../../../shared/Pagination';
import { getPagination } from '../../../utils/pagination';
import { IParams, useCheck, useNotifications } from '../store/useUsers';

import ConfirmModal from '../../../shared/ConfirmModal';
import { russianEndings } from '../../../utils/string';
import { checkIsIntelleka } from '../../../utils/link';
import { hasLocationMatches } from '../../../utils/link';
import AdminUsersErrors from './AdminUsersErrors';
import AdminUsersSuccess from './AdminUsersSuccess';
import UsersCount from '../../../shared/UsersCount';
import UsersActions from '../../../shared/UsersActions';
import SearchForm from '../../../shared/SearchForm';
import FilterIndicator from '../../../shared/FilterIndicator';
import {
  useChangeAdminUsers,
  useDeleteAdminUsers,
  useGetAdminUsers,
  useNotificationsReset,
  useSetOrderIndexes,
} from '../../../cache/admin/users';
import AdminUsersTable from './AdminUsersTable';
import { useEffect, useState } from 'react';

const AdminRenderUsers: React.FC<IParams> = ({ params, updateParam, reset }) => {
  const isOnCheckPage = hasLocationMatches('checking');
  const isExamPage = hasLocationMatches('exam');

  /** сброс параметра поиска при размонтировании компонента */
  useEffect(() => {
    return () => updateParam('search', '');
  }, []);

  const { data: storeData, isLoading } = useGetAdminUsers(params, isExamPage);
  const data = storeData?.data;

  const [countData, setCountData] = useState(3000);

  useEffect(() => {
    storeData?.data.count && setCountData(storeData.data.count);
  }, [storeData]);

  useNotificationsReset();
  useSetOrderIndexes(params);

  const { checked } = useCheck();

  const { setConfirmVisible, confirmVisible } = useNotifications();
  const { mutate: deleteUserRequest, isLoading: deleting } = useDeleteAdminUsers(params);
  const { mutate: updateUserRequest, isLoading: updating } = useChangeAdminUsers(params);

  const onCheckUsers = isOnCheckPage ? getOnCheckUsers(data?.results) : null;
  const paginationValues = getPagination(data?.count || 0, params.page_size);
  const isIntelleka = checkIsIntelleka();

  return (
    <AdminTemplate>
      <div className="flex justify-between flex-col xl:flex-row">
        <div className="flex items-center mb-5 flex-col md:flex-row w-full max-w-[540px]">
          <div className="md:mr-4 mb-3 w-full md:w-fit">
            <UsersCount
              loading={isLoading}
              count={isOnCheckPage ? onCheckUsers?.length : data?.results.length}
              isIntelleka={isIntelleka}
            />
            <AdminAddUser />
          </div>

          <SearchForm
            isIntelleka={isIntelleka}
            loading={isLoading}
            submit={(data) => {
              reset();
              updateParam('search', data.search);
            }}
          />
        </div>

        {!isOnCheckPage && (
          <div className="w-full max-w-[560px] mb-3 xl:mb-0 relative">
            <div
              className={`${
                isLoading ? 'block' : 'hidden'
              } opacity-50 bg-white z-50 cursor-default w-full h-full absolute`}
            />
            <Pagination
              pagesCountOptions={paginationValues}
              activePage={params.page}
              pageSize={params.page_size}
              countElements={countData}
              onClickNext={() => data?.next && updateParam('page', params.page + 1)}
              onClickPrev={() => data?.previous && updateParam('page', params.page - 1)}
              isIntelleka={isIntelleka}
              onChangeCurrentPage={(page) => updateParam('page', page)}
              onChangePageSize={(pageSize) => {
                updateParam('page', 1);
                updateParam('page_size', pageSize);
              }}
            />
          </div>
        )}

        {!!checked.length && (
          <UsersActions
            onClick={(type) => setConfirmVisible(type)}
            isIntelleka={isIntelleka}
            className="flex justify-end mb-2 items-center"
          />
        )}

        <ConfirmModal
          isIntelleka={isIntelleka}
          confirm={() =>
            checked.forEach((u) =>
              confirmVisible === 'delete' ? deleteUserRequest(u) : updateUserRequest(u),
            )
          }
          isOpen={!!confirmVisible}
          hide={() => setConfirmVisible(undefined)}
          loading={deleting || updating}
        >
          {`${confirmVisible === 'delete' ? 'Удалить' : 'Изменить статус'} ${
            checked.length
          } ${russianEndings(checked.length, ['пользователя', 'пользователей', 'пользователей'])}?`}
          <AdminUsersErrors />
        </ConfirmModal>
      </div>

      {!!params.company && (
        <FilterIndicator onClick={reset} isIntelleka={isIntelleka} align="end">
          фильтр по компаниям
        </FilterIndicator>
      )}

      <AdminUsersTable
        data={data}
        onCheckUsers={onCheckUsers}
        isOnCheckPage={isOnCheckPage}
        isLoading={isLoading}
        onClickCompany={(company) => updateParam('company', company)}
      />

      <AdminUsersSuccess />
    </AdminTemplate>
  );
};

export default AdminRenderUsers;
