import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { IMassUsers, IUser, IUsersRes, TCreateUser } from '../../../typings/admin';
import { queryClient } from '../../../hooks/queryClient';
import { Modal } from '@mui/material';
import RadioButton from '../../../shared/RadioButton';
import upload from '../../../img/upload.png';
import RSelect from '../../../shared/ReactSelect';
import DatePicker from '../../../shared/DatePicker';
import { convertLocalTimeToFormat, in30Days } from '../../../utils/time';
import { checkIsIntelleka } from '../../../utils/link';
import { getError, getErrorMessage } from '../../../utils/api';
import { AxiosError } from 'axios';
import { Alert } from '@mui/material';
import { API } from '../../../api';
import { ThemeButton } from '../../../shared/Button';
import ThemeInput from '../../../shared/ThemeInput';

const AdminAddUser: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [isFile, setIsFile] = useState(true);
  const { register, handleSubmit, control, setValue, reset } = useForm<IUser & IMassUsers>();
  const [fileName, setFileName] = useState('');
  const [binary, setBinary] = useState<File>();
  const [isIntelleka, setIsIntelleka] = useState(false);
  const { data: courses, isLoading: loadingCourses } = useQuery('courses', () =>
    API.admin.course.all(),
  );

  useEffect(() => {
    const now = new Date();
    if (open) {
      setValue('start_course', convertLocalTimeToFormat(now, 'YYYY-MM-DD'));
      setValue('end_course', in30Days);
    }
  }, [open, setValue]);

  const resetForm = () => {
    setIsIntelleka(false);
    setIsFile(true);
    reset();
    setFileName('');
    setBinary(undefined);
    setOpen(false);
  };

  const addSuccess = (message: string) => {
    setSuccess(message);
    setTimeout(() => setSuccess(''), 3000);
  };

  const { mutate: addUser, isLoading: singleAdding } = useMutation<
    { message: string },
    unknown,
    TCreateUser
  >((data) => API.admin.user.createUser(data), {
    onSuccess: (_, user) => {
      resetForm();
      queryClient.setQueryData('users', () => {
        const oldData = queryClient.getQueryData<IUsersRes>('users');
        if (oldData) {
          return { ...oldData, results: [user, ...oldData.results] };
        }
        queryClient.refetchQueries('users');
        queryClient.refetchQueries('user-settings');
      });
      addSuccess('пользователь ' + user.username + ' успешно создан');
    },
    onError: (e) =>
      isFile ? setError(getErrorMessage(e as AxiosError)) : setError(getError(e as AxiosError)),
  });

  const { mutate: addMassUser, isLoading: massAdding } = useMutation<
    { message: string },
    unknown,
    IMassUsers
  >((data) => API.admin.user.createMass(data), {
    onSuccess: (res, user) => {
      resetForm();
      queryClient.setQueryData('users', () => {
        const oldData = queryClient.getQueryData<IUsersRes>('users');
        if (oldData) {
          return { ...oldData, results: [user, ...oldData.results] };
        }
        queryClient.refetchQueries('users');
        queryClient.refetchQueries('user-settings');
      });
      addSuccess(res.message);
    },
    onError: (e) => {
      setError(getErrorMessage(e as AxiosError));
    },
  });

  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files?.length) {
      setFileName(files[0].name);
      setBinary(files[0]);
    }
  };

  const submit = (data: IUser & IMassUsers) => {
    if (isFile) {
      addMassUser({
        file_users: binary,
        course_id: data.course_id,
        design: isIntelleka ? 1 : 0,
      });
    } else {
      addUser({ ...data, design: isIntelleka ? 1 : 0 });
    }
  };

  const coursesList = courses?.map((c) => ({ label: c.title, value: c.id })) || [];

  const isIntellekaTheme = checkIsIntelleka();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const isIntellekaStyle = checkIsIntelleka();

  return (
    <>
      <button className="badge text-[15px] font-bold bg-gray-badge" onClick={() => setOpen(true)}>
        Добавить пользователя
      </button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        className="m-auto max-w-screen-modal_md md:mt-7"
        disableAutoFocus
      >
        <div className="rounded bg-white p-4">
          <div className="flex mb-8">
            <FormType
              label="Загрузить файл (формат xlsx)"
              checked={isFile}
              onClick={() => setIsFile(true)}
            />
            <FormType label="Отправить текст" checked={!isFile} onClick={() => setIsFile(false)} />
          </div>

          <form onSubmit={handleSubmit(submit)} className="flex flex-col">
            {/* file */}
            <label className={`${isFile ? 'block' : 'hidden'}`}>
              <div className="mb-1">Добавить файл</div>
              <div
                className={`cursor-pointer hover:opacity-90 flex mb-5 ${
                  fileName ? 'opacity-100' : 'opacity-60'
                }`}
              >
                <input
                  type="file"
                  className="hidden"
                  accept={['xlsx', 'csv'].map((x) => '.' + x).join(',')}
                  {...register('file_users')}
                  onChange={onChangeFile}
                />
                <img src={upload} alt="upload" width={25} height={25} className={`mr-2`} />
                {fileName || 'файл не выбран'}
              </div>
            </label>
            {/* text */}
            <div className={`${!isFile ? 'block' : 'hidden'}`}>
              <label className="mb-5 block">
                <div className="mb-1">ФИО</div>
                <Controller
                  control={control}
                  render={({ field }) => {
                    return (
                      <ThemeInput {...field} className="w-full" isIntelleka={isIntellekaTheme} />
                    );
                  }}
                  name="first_name"
                />
              </label>
              <div className="flex justify-between">
                <label className="mb-5 w-[calc(50%-15px)]">
                  <div className="mb-1">Логин</div>
                  <Controller
                    control={control}
                    render={({ field }) => {
                      return (
                        <ThemeInput {...field} className="w-full" isIntelleka={isIntellekaTheme} />
                      );
                    }}
                    name="username"
                  />
                </label>
                <label className="mb-5 w-[calc(50%-15px)]">
                  <div className="mb-1">Пароль</div>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <ThemeInput {...field} className="w-full" isIntelleka={isIntellekaTheme} />
                    )}
                    name="password"
                  />
                </label>
              </div>
              <label className="mb-5 block">
                <div className="mb-1">Название компании</div>
                <Controller
                  control={control}
                  rules={{ required: false }}
                  render={({ field, fieldState: { invalid } }) => (
                    <>
                      {invalid && (
                        <div className="text-red-500">Это поле обязательно для заполнения</div>
                      )}
                      <ThemeInput {...field} className="w-full" isIntelleka={isIntellekaTheme} />
                    </>
                  )}
                  name="company_name"
                />
              </label>
              <div className="flex justify-between">
                <label className="mb-5 w-[calc(50%-15px)]">
                  <div className="mb-1">Дата начала обучения</div>
                  <Controller
                    control={control}
                    render={({ field: { name, value } }) => {
                      return (
                        <DatePicker
                          onChange={(value) =>
                            value && setValue(name, convertLocalTimeToFormat(value, 'YYYY-MM-DD'))
                          }
                          value={value}
                          field={name}
                          dateFormat="dd.MM.yyyy"
                          isIntelleka={isIntellekaStyle}
                        />
                      );
                    }}
                    name="start_course"
                  />
                </label>
                <label className="mb-5 w-[calc(50%-15px)]">
                  <div className="mb-1">Дата окончания обучения</div>
                  <Controller
                    control={control}
                    render={({ field: { name, value } }) => {
                      return (
                        <DatePicker
                          onChange={(value) =>
                            value && setValue(name, convertLocalTimeToFormat(value, 'YYYY-MM-DD'))
                          }
                          value={value}
                          field={name}
                          dateFormat="dd.MM.yyyy"
                          isIntelleka={isIntellekaStyle}
                        />
                      );
                    }}
                    name="end_course"
                  />
                </label>
              </div>
            </div>
            {/* common */}
            <label className="mb-4">
              <div className="mb-1">Курсы</div>
              <Controller
                control={control}
                rules={{ required: true }}
                render={({ field: { value, name }, fieldState: { invalid } }) => {
                  return (
                    <>
                      {invalid && (
                        <div className="text-red-500">Это поле обязательно для заполнения</div>
                      )}
                      <RSelect
                        value={coursesList?.find((c) => c.value === value)}
                        name={name}
                        options={coursesList}
                        onChange={(data: any) => data && setValue(name, data?.value)}
                        isDisabled={loadingCourses}
                        placeholder={loadingCourses ? 'Загрузка...' : 'Выберите курс'}
                        isIntelleka={isIntellekaStyle}
                      />
                    </>
                  );
                }}
                name="course_id"
              />
            </label>
            <div className="flex">
              <FormType
                label="ПромРесурс"
                checked={!isIntelleka}
                onClick={() => setIsIntelleka(false)}
              />
              <FormType
                label="Интеллека"
                checked={isIntelleka}
                onClick={() => setIsIntelleka(true)}
              />
            </div>
            <div className="mt-5">
              <ThemeButton
                type="button"
                className="mr-2"
                onClick={() => setOpen(false)}
                isIntelleka={isIntellekaTheme}
                isSecondary
              >
                Отменить
              </ThemeButton>
              <ThemeButton
                type="submit"
                disabled={massAdding || singleAdding}
                isIntelleka={isIntellekaTheme}
              >
                {massAdding || singleAdding ? 'Сохранение...' : 'Сохранить'}
              </ThemeButton>
            </div>
          </form>
          {!!error && (
            <Alert color="error" className="notification" onClose={() => setError('')}>
              {error}
            </Alert>
          )}
        </div>
      </Modal>
      {!!success && (
        <Alert color="success" className="notification" onClose={() => setSuccess('')}>
          {success}
        </Alert>
      )}
    </>
  );
};

export default AdminAddUser;

const FormType: React.FC<{ onClick(): void; checked: boolean; label: string }> = (props) => {
  const isIntelleka = checkIsIntelleka();

  return (
    <label
      className={`flex mr-4 cursor-pointer ${
        isIntelleka ? 'hover:text-active_i' : 'hover:text-active_p'
      } transition-all`}
      onClick={props.onClick}
    >
      <RadioButton checked={props.checked} isIntelleka={isIntelleka} />
      <span>{props.label}</span>
    </label>
  );
};
