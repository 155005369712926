import admin from '../../../img/admin.jpg';
import curator from '../../../img/curator.png';
import moderator from '../../../img/moderator.png';
import user from '../../../img/user.png';
import { coockiesCacheDays, isCurrentBuildProd } from '../../../constants';
import { useMutation } from 'react-query';
import { getToken } from '../../../api/common';
import { IAuthParams, IQuiclkLogin } from '../../../typings/common';
import Cookies from 'js-cookie';
import { getError, loginRedirects } from '../../../utils/api';
import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useQuery } from 'react-query';
import { checkIsIntelleka } from '../../../utils/link';
import styled from 'styled-components';
import { API } from '../../../api';
import OneClickSetting from '../../../shared/OneClickSetting';
import { queryClient } from '../../../hooks/queryClient';
import { Tooltip } from '@mui/material';
import { AxiosError } from 'axios';
import { isJson } from '../../../utils/string';
import { BASE_CACHE_OPTIONS } from '../../../cache/common';

export const Title = styled.h1`
  font-size: 2rem;
  text-align: center;
  display: block;
  margin: 30px 0;
`;

export const QuickLogin = styled.div<{ loading?: boolean; isAuthPage?: boolean }>`
  position: absolute;
  right: 20px;
  top: 20px;
  margin-top: -5px;
  width: auto;
  display: flex;
  justify-content: space-between;
  img {
    height: 25px;
    opacity: 0.5;
    width: 25px;
    transition: ease-in-out 0.3s;
    cursor: pointer;
    &:hover {
      opacity: ${(props) => (props.loading ? 0.3 : 1)};
    }
  }
  @media screen and (max-width: 1300px) {
    display: ${(props) => (props.isAuthPage ? 'flex' : 'none')};
  }
`;

export const Preloader = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  padding: 20px;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .inner {
    background: rgba(0, 0, 0, 0.5);
    padding: 60px 70px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  }
  b {
    display: block;
    margin-top: 10px;
    color: #e63f1d;
    font-weight: 300;
    font-size: 25px;
    text-shadow: 0 0 2px rgba(255, 0, 0, 0.4);
  }
  span {
    display: block;
    margin: auto;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    border-top: 5px solid #f1f1f1;
    border-right: 4px double #ff6041;
    border-left: 4px double #ff6041;
    border-bottom: 5px solid #f1f1f1;
    animation: libot 1.4s linear infinite;
    &.center {
      margin: auto;
    }
  }

  @keyframes libot {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const AuthPreloader: React.FC<{ loading: boolean }> = (props) => {
  if (!props.loading) return null;
  return (
    <Preloader>
      <div className="inner">
        <span />
        <b>Авторизация...</b>
      </div>
    </Preloader>
  );
};

const key = 'quick_login';

export const QuickLoginAccess: React.FC = () => {
  const { addToast } = useToasts();

  const { data: admins, isLoading: aLoading } = useQuery('admins', () =>
    API.admin.user.all({ user_status: 0 }),
  );
  const { data: settings } = useQuery(key, API.common.getQuickLogin, {
    refetchOnWindowFocus: false,
  });

  const [statusUpdating, setStatusUpdating] = useState<
    'admin' | 'client' | 'curator' | 'moderator' | ''
  >('');

  const {
    mutate: update,
    isLoading: updating,
    isSuccess,
    isError,
  } = useMutation<'success', unknown, IQuiclkLogin>(
    async (data) => API.common.changeQuickLogin(data),
    {
      onSuccess: (_, newData) => {
        queryClient.setQueryData(key, () => {
          return newData;
        });
        setStatusUpdating('');
      },
      onError: () => setStatusUpdating(''),
    },
  );

  useEffect(() => {
    if (isSuccess || isError) {
      addToast(
        `Данные быстрой авторизации ${isSuccess ? 'успешно обновлены' : 'обновить не удалось'}`,
        { appearance: isSuccess ? 'success' : 'error' },
      );
    }
  }, [isSuccess, isError, addToast]);

  const { data: moderators, isLoading: mLoading } = useQuery('moderators', () =>
    API.admin.user.all({ user_status: 1, ordering: '-id' }),
  );
  const { data: curators, isLoading: cLoading } = useQuery('curators', () =>
    API.admin.user.all({ user_status: 3, ordering: '-id' }),
  );
  const { data: users, isLoading: uLoading } = useQuery('users', () =>
    API.admin.user.all({ ordering: '-id', page_size: 50, user_status: 2 }),
  );

  const isIntelleka = checkIsIntelleka();

  const getPassword = (username: string, isClient: boolean) => {
    const list = isClient ? users : curators;
    return list?.results.find((u) => u.username === username)?.old_password;
  };

  return !isCurrentBuildProd ? (
    <div className="w-full max-w-[1200px] m-auto">
      <OneClickSetting
        label="Админ"
        userName={settings?.admin_login}
        password={settings?.admin_password}
        list={admins?.results}
        onChose={(checked, username) => {
          const admin_login = checked ? '' : username;
          update({ ...settings, admin_login });
          setStatusUpdating('admin');
        }}
        onChangeInput={(admin_password) => {
          update({ ...settings, admin_password });
          setStatusUpdating('admin');
        }}
        loading={aLoading}
        updating={updating && statusUpdating === 'admin'}
        isIntelleka={isIntelleka}
      />
      <OneClickSetting
        label="Модератор"
        userName={settings?.moderator_login}
        password={settings?.moderator_password}
        list={moderators?.results}
        onChose={(checked, username) => {
          const moderator_login = checked ? '' : username;
          update({ ...settings, moderator_login });
          setStatusUpdating('moderator');
        }}
        onChangeInput={(moderator_password) => {
          update({ ...settings, moderator_password });
          setStatusUpdating('moderator');
        }}
        loading={mLoading}
        updating={updating && statusUpdating === 'moderator'}
        isIntelleka={isIntelleka}
      />
      <OneClickSetting
        label="Куратор"
        userName={settings?.curator_login}
        password={settings?.curator_password}
        list={curators?.results}
        onChose={(checked, username) => {
          const curator_login = checked ? '' : username;
          update({ ...settings, curator_login, curator_password: getPassword(username, false) });
          setStatusUpdating('curator');
        }}
        onChangeInput={(curator_password) => {
          update({ ...settings, curator_password });
          setStatusUpdating('curator');
        }}
        loading={cLoading}
        updating={updating && statusUpdating === 'curator'}
        isIntelleka={isIntelleka}
      />
      <OneClickSetting
        label="Пользователь"
        userName={settings?.client_login}
        password={settings?.client_password}
        list={users?.results}
        onChose={(checked, username) => {
          const client_login = checked ? '' : username;
          update({
            ...settings,
            client_login,
            client_password: getPassword(username, true),
          });
          setStatusUpdating('client');
        }}
        onChangeInput={(client_password) => {
          update({ ...settings, client_password });
          setStatusUpdating('client');
        }}
        loading={uLoading}
        updating={updating && statusUpdating === 'client'}
        isIntelleka={isIntelleka}
      />
    </div>
  ) : (
    <div style={{ padding: 20 }}>
      <div>Раздел доступен только на тестовом сервере</div>
    </div>
  );
};

const OneClickItem: React.FC<{
  isVisible: boolean;
  id: string;
  image: string;
  text: string;
  loaded: boolean;
  notLoaded: boolean;
  error: string;
  interfaceLoading: string | null;
  login: () => void;
}> = (props) => {
  const { addToast } = useToasts();
  const parsedError = isJson(props.error) && JSON.parse(props.error);
  const errorText = parsedError && parsedError.non_field_errors?.[0];

  useEffect(() => {
    props.loaded && loginRedirects((to) => window.location.replace(to), Cookies.get('access'));

    if (props.notLoaded && props.id === props.interfaceLoading) {
      addToast(errorText || `Не удалось войти, проверьте настройки авторизации`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, props.loaded, props.notLoaded, props.interfaceLoading, props.id]);

  if (!props.isVisible) return null;

  return (
    <>
      <Tooltip title={<div className="text-sm">{props.text}</div>} arrow>
        <img id={props.id} src={props.image} onClick={props.login} alt={props.text} />
      </Tooltip>
    </>
  );
};

const OneClickLogin: React.FC<{ userStatus: number }> = (props) => {
  const {
    mutate: loginUser,
    isLoading: loading,
    isSuccess: loaded,
    isError: notLoaded,
    error: errorRes,
  } = useMutation<{ access: string }, unknown, IAuthParams>((data) => getToken(data), {
    onSuccess: async ({ access }) => Cookies.set('access', access, { expires: coockiesCacheDays }),
  });
  const error = getError(errorRes as AxiosError);

  const [interfaceLoading, setInterfaceLoading] = useState<
    'admin' | 'moderator' | 'user' | 'curator' | null
  >(null);

  const { data: settings } = useQuery(key, API.common.getQuickLogin, BASE_CACHE_OPTIONS);

  if (isCurrentBuildProd) return null;

  const handleLogin = (username: string | null, password: string | null) => {
    if (!username || !password) return;
    loginUser({ username, password });
  };

  return (
    <QuickLogin
      loading={loading ? loading : undefined}
      isAuthPage={window.location.pathname === '/'}
    >
      <AuthPreloader loading={loading} />
      <OneClickItem
        isVisible={!!settings?.admin_login && !!settings?.admin_password && props.userStatus !== 0}
        id="admin"
        image={admin}
        text={settings?.admin_login || ''}
        loaded={loaded}
        notLoaded={notLoaded}
        interfaceLoading={interfaceLoading}
        error={error}
        login={() => {
          if (settings?.admin_login && settings?.admin_password) {
            handleLogin(settings.admin_login, settings?.admin_password);
            setInterfaceLoading('admin');
          }
        }}
      />
      <OneClickItem
        isVisible={
          !!settings?.moderator_login && !!settings?.moderator_password && props.userStatus !== 1
        }
        id="moderator"
        image={moderator}
        text={settings?.moderator_login || ''}
        loaded={loaded}
        notLoaded={notLoaded}
        error={error}
        interfaceLoading={interfaceLoading}
        login={() => {
          if (settings?.moderator_login && settings?.moderator_password) {
            handleLogin(settings.moderator_login, settings.moderator_password);
            setInterfaceLoading('moderator');
          }
        }}
      />
      <OneClickItem
        isVisible={
          !!settings?.client_login && !!settings?.client_password && props.userStatus !== 2
        }
        id="user"
        image={user}
        text={settings?.client_login || ''}
        loaded={loaded}
        notLoaded={notLoaded}
        error={error}
        interfaceLoading={interfaceLoading}
        login={() => {
          if (settings?.client_login && settings?.client_password) {
            handleLogin(settings.client_login, settings.client_password);
            setInterfaceLoading('user');
          }
        }}
      />
      <OneClickItem
        isVisible={
          !!settings?.curator_login && !!settings?.curator_password && props.userStatus !== 3
        }
        id="curator"
        image={curator}
        text={settings?.curator_login || ''}
        loaded={loaded}
        notLoaded={notLoaded}
        error={error}
        interfaceLoading={interfaceLoading}
        login={() => {
          if (settings?.curator_login && settings?.curator_password) {
            handleLogin(settings?.curator_login, settings.curator_password);
            setInterfaceLoading('curator');
          }
        }}
      />
    </QuickLogin>
  );
};

export default OneClickLogin;
