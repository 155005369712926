import { theme_color, theme_color_i } from '../constants';

/** Компонент стилизованной радиокнопки и чекбокса */
const RadioButton: React.FC<{
  checked?: boolean;
  color?: string;
  isCheckBox?: boolean;
  isIntelleka?: boolean;
}> = ({ checked = false, isCheckBox = false, isIntelleka = false, color }) => {
  const defaultColor = isIntelleka ? theme_color_i : theme_color;

  return isCheckBox ? (
    <div className="flex items-center">
      <div
        style={{
          backgroundColor: checked ? defaultColor : '#fff',
          border: `1px solid ${color ? color : defaultColor}`,
        }}
        className="rounded w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 transition-all duration-300"
      >
        {checked && (
          <svg
            className={`fill-current w-3 h-3 ${
              isIntelleka ? 'text-active_i' : 'text-active_p'
            } pointer-events-none`}
            version="1.1"
            viewBox="0 0 17 12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="none" fillRule="evenodd">
              <g transform="translate(-9 -11)" fill="#fff" fillRule="nonzero">
                <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
              </g>
            </g>
          </svg>
        )}
      </div>
    </div>
  ) : (
    <div
      className="h-6 w-6 min-w-[24px] mr-2 rounded-full relative"
      style={{ border: `1px solid ${defaultColor}` }}
    >
      {checked && (
        <div
          className="absolute left-[50%] top-[50%] w-3 h-3 rounded-full"
          style={{ transform: 'translate(-50%, -50%)', backgroundColor: defaultColor }}
        />
      )}
    </div>
  );
};

export default RadioButton;

export const RadioBordered: React.FC<{ checked: boolean; isIntelleka?: boolean }> = ({
  isIntelleka = false,
  ...props
}) => {
  return (
    <div
      className={`w-4 h-4 mr-2 rounded-full border ${
        !props.checked
          ? 'border-gray-400'
          : `${isIntelleka ? 'border-active_i ' : 'border-active_p '} border-[5px]`
      }`}
    />
  );
};
