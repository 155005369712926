import CuratorTemplate from '../templates/CuratorTemplate';

import { useQuery } from 'react-query';
import { getCurrentUser } from '../../../api/common';
import { createWebsocketUrl, createUrlChat } from '../../../utils/link';
import Chat from '../../common/containers/ChatContainer';
import { CURATOR_CACHE_OPTIONS } from '../../../cache/curator';

const CuratorSupport: React.FC = () => {
  const { data: user } = useQuery('user', getCurrentUser, CURATOR_CACHE_OPTIONS);
  const urlChat = user && createUrlChat('support_chat', user.id);
  const wsUrl = urlChat && createWebsocketUrl(urlChat);

  return (
    <CuratorTemplate>{user && wsUrl && <Chat userId={user.id} wsUrl={wsUrl} />}</CuratorTemplate>
  );
};

export default CuratorSupport;
