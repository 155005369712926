import { renderDate, renderShortDate } from '../utils/time';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 13,
  borderRadius: 2,
  backgroundColor: '#e9ecef',
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: '#ffc107',
  },
}));

const RenderDate: React.FC<{ date: string; isShort: boolean }> = ({ date, isShort }) => {
  return (
    <div
      className={`w-[65px] text-[12px] ${isShort ? 'leading-[2.07]' : 'leading-[1.5]'} ${
        !date ? 'transparent' : '#000'
      }`}
    >
      {isShort ? renderShortDate(date) : renderDate(date)}
    </div>
  );
};

const CourseProgress: React.FC<{
  courseId: number;
  passed: number;
  isShort: boolean;
  startDate: string;
  endDate: string;
}> = (props) => {
  return (
    <div key={props.courseId} className="flex items-center flex-row">
      <RenderDate date={props.startDate} isShort={props.isShort} />
      <BorderLinearProgress
        variant="determinate"
        value={props.passed}
        className="grow w-full relative mx-1"
        color="warning"
      />
      <RenderDate date={props.endDate} isShort={props.isShort} />
    </div>
  );
};

export default CourseProgress;
