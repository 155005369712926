import { Dictionary, keyBy } from 'lodash';
import moment from 'moment';
import { useQuery } from 'react-query';
import { IUser } from '../../../typings/admin';
import { getUserCourseSettings } from '../../../api/common';
import { ISettingData } from '../../../typings/common';

const AdminUserSettings: React.FC<{
  u: IUser;
  start?: boolean;
}> = ({ u, start }) => {
  const { data } = useQuery('user-settings', () => getUserCourseSettings());
  const currentUserCourses = data?.filter((setting) => Number(setting.user) === u.id);
  const userSettingsByCourseId: Partial<Dictionary<ISettingData>> = keyBy(
    currentUserCourses,
    'course',
  );

  return (
    <>
      {u.courses
        ?.sort((a, b) => a.id - b.id)
        .map((course) => {
          const date = start
            ? userSettingsByCourseId[course.id]?.start_course
            : userSettingsByCourseId[course.id]?.end_course;
          return (
            <div key={course.id}>
              {date ? <div>{moment(date).format('DD.MM.YYYY')}</div> : <div>-</div>}
            </div>
          );
        })}
    </>
  );
};

export default AdminUserSettings;
