import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaEdit, FaTimes } from 'react-icons/fa';
import { useMutation } from 'react-query';
import { ICompany, TUpdateCompany } from '../../../typings/admin';
import { queryClient } from '../../../hooks/queryClient';
import Modal from '../../../shared/Modal';
import { checkIsIntelleka } from '../../../utils/link';
import { key } from '../pages/AdminCompanies';
import { API } from '../../../api';
import { ThemeButton } from '../../../shared/Button';
import ThemeInput from '../../../shared/ThemeInput';

const AdminEditCompanies: React.FC<{ company: ICompany }> = ({ company }) => {
  const [open, toggle] = useState(false);

  const isIntelleka = checkIsIntelleka();

  const { mutate: editNameCompany, isLoading: updating } = useMutation<
    ICompany,
    unknown,
    TUpdateCompany
  >(
    (data) => {
      return API.admin.company.update(data);
    },
    {
      onSuccess: (res) => {
        toggle(false);
        queryClient.setQueryData(key, () => {
          const oldData = queryClient.getQueryData<ICompany[]>(key);
          if (oldData) {
            return oldData.map((c) =>
              c.id === res.id
                ? { ...res, all_users: c.all_users, active_users: c.active_users }
                : c,
            );
          }
        });
      },
    },
  );

  const { mutate: deleteCompany, isLoading: deleting } = useMutation<unknown, unknown, number>(
    (data) => {
      return API.admin.company.remove(data);
    },
    {
      onSuccess: (_, req) => {
        queryClient.setQueryData(key, () => {
          toggle(false);
          const oldData = queryClient.getQueryData<ICompany[]>(key);
          if (oldData) {
            return oldData.filter((c) => c.id !== req);
          }
        });
      },
    },
  );

  const { handleSubmit, setValue, control } = useForm<ICompany>();

  useEffect(() => {
    if (company) {
      setValue('title', company.title);
      setValue('id', company.id);
    }
  }, [company, setValue]);

  return (
    <div className="flex justify-end lg:justify-center">
      <FaEdit
        className={`cursor-pointer ${
          isIntelleka ? 'hover:text-active_i' : 'hover:text-active_p'
        } transition-all`}
        onClick={() => toggle(true)}
      />
      <Modal
        open={open}
        onClose={() => toggle(false)}
        className="rounded max-w-[500px] mt-[1.75rem] text-left"
      >
        <div>
          <h5 className="text-[1.25rem] p-4 border-b border-gray-default flex justify-between items-center">
            <span>Редактирование компании</span>
            <FaTimes
              onClick={() => toggle(false)}
              className={`text-sm text-gray-500 ${
                isIntelleka ? 'hover:text-active_i' : 'hover:text-active_p'
              } transition-all cursor-pointer`}
            />
          </h5>
        </div>
        <form onSubmit={handleSubmit((data) => editNameCompany(data))} className="p-4">
          <label className="mb-5 block">
            <div className="mb-1">Название</div>
            <Controller
              control={control}
              render={({ field }) => (
                <ThemeInput {...field} className="w-full" isIntelleka={isIntelleka} />
              )}
              name="title"
            />
          </label>
          <div className="flex justify-between">
            <ThemeButton disabled={updating} isIntelleka={isIntelleka}>
              {updating ? 'Сохранение...' : 'Сохранить'}
            </ThemeButton>
            <ThemeButton
              isIntelleka={isIntelleka}
              onClick={() => deleteCompany(company.id)}
              disabled={!!company.all_users || deleting}
            >
              {deleting ? 'Удаление...' : 'Удалить'}
            </ThemeButton>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default AdminEditCompanies;
