import { Link } from '@tanstack/react-location';
import moment from 'moment';
import { useQuery } from 'react-query';
import { getAllChats, getCurrentUser } from '../../../api/common';
import Pill from '../../../shared/Pill';
import Table from '../../../shared/Table';
import { sortChatsByLastMessage } from '../../../utils/array';
import AdminRemoveChat from '../containers/AdminRemoveChat';
import { generateChat } from '../routes';
import { useChats } from '../store/useChats';
import AdminChatsTemplate from '../templates/AdminChatsTemplate';
import { adminChatTitles } from './AdminChatsSupport';

const AdminChatsTeacher: React.FC = () => {
  const key = 'teacher';
  const { data: user } = useQuery('user', getCurrentUser);
  const { data, isFetching } = useQuery(
    key,
    () => getAllChats({ users: user?.id, chat_status: 1, ordering: '-created' }),
    {
      enabled: !!user?.id,
    },
  );

  const { normalizedChats } = useChats();

  sortChatsByLastMessage(data);

  const chats = data
    ?.filter((c) => c.num_messages && c.list_users?.find((user) => user.user_status === 2))
    .map((c) => {
      const unreadMessages = c.id ? normalizedChats[c.id] : 0;

      return [
        <Link to={generateChat(false, c.id)} className="cursor-pointer">
          <span className={`${!!unreadMessages ? 'font-bold' : 'font-normal'}`}>
            {c.list_users?.find((u) => u.id !== user?.id)?.username}
          </span>
          {unreadMessages && <Pill background="#6c757d">{unreadMessages}</Pill>}
        </Link>,
        <span className={`${!!unreadMessages ? 'font-bold' : 'font-normal'}`}>
          {c.num_messages}
        </span>,
        <span className={`${!!unreadMessages ? 'font-bold' : 'font-normal'}`}>
          {c.last_message_date ? moment(c.last_message_date).format('DD.MM.YYYY') : '-'}
        </span>,
        <>{c.id && <AdminRemoveChat id={c.id} refetchKey={key} />}</>,
      ];
    });

  return (
    <AdminChatsTemplate>
      <Table titles={adminChatTitles} rows={chats} isLoading={!data && isFetching} />
    </AdminChatsTemplate>
  );
};

export default AdminChatsTeacher;
