import { useNavigate } from '@tanstack/react-location';
import moment from 'moment';
import { ILastTestPassings } from '../../../typings/admin';
import BadgeButton from '../../../shared/BadgeButton';
import { generateUserTestPassingLink } from '../routes';

interface IProps {
  last_test_passings?: ILastTestPassings;
  userId: number;
  isPluralCourses: boolean;
  courseId: number;
}

const Final: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  const finalsPassings = props.last_test_passings
    ?.filter((passing) => passing.is_final_task)
    .sort((a, b) => new Date(a.finish_time).getTime() - new Date(b.finish_time).getTime())
    .reverse();
  if (!finalsPassings?.length) return <div>-</div>;

  const passing = finalsPassings[0];

  const renderFinalResults = () => {
    if (!passing.finish_time)
      return (
        <div style={{ whiteSpace: props.isPluralCourses ? 'nowrap' : 'normal' }}>
          Попытка не закрыта
        </div>
      );

    const openResultsModal = () => {
      const path = generateUserTestPassingLink(
        props.userId,
        props.courseId,
        passing.task,
        passing.id,
      );
      navigate({ to: path });
    };

    const { response_rate, success_passed } = passing;

    const renderFinalResult = () => {
      switch (success_passed) {
        case 1:
          return (
            <div className="flex justify-center">
              <BadgeButton
                className="cursor-pointer badge bg-gray-badge text-sm w-fit"
                onClick={openResultsModal}
              >
                Проверить
              </BadgeButton>
            </div>
          );
        case 2:
          return (
            <div className="text-active_p">не зачет (превышено допустимое время прохождения)</div>
          );
        case 3:
          return (
            <div className="text-active_p">не зачет (превышено допустимое кол-во попыток)</div>
          );
        case 4:
          return <div className="text-active_p">не зачет ({response_rate})</div>;
        case 5:
          return <div className="text-active_p">не закончен ({response_rate})</div>;
        default:
          return <div className="text-green-600">зачет ({response_rate})</div>;
      }
    };
    return renderFinalResult();
  };

  return <>{renderFinalResults()}</>;
};

const Intermediate: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  const intermediateTestPassings = props.last_test_passings?.filter(
    (passing) => !passing.is_final_task && passing.success_passed === 1,
  );

  const openResultsModal = (task: number, id: number) => {
    const path = generateUserTestPassingLink(props.userId, props.courseId, task, id);
    navigate({ to: path });
  };

  return (
    <div className="flex justify-center">
      {intermediateTestPassings?.map((el) => (
        <BadgeButton
          key={el.id}
          className="cursor-pointer badge bg-gray-badge text-sm w-fit my-1"
          onClick={() => openResultsModal(el.task, el.id)}
        >
          Проверить пр
        </BadgeButton>
      ))}
    </div>
  );
};

export const AdminExamCheck: React.FC<IProps> = (props) => {
  return (
    <>
      <Final {...props} />
      <Intermediate {...props} />
    </>
  );
};

export const AdminExamDate: React.FC<{ testPassings?: ILastTestPassings }> = (props) => {
  if (!props.testPassings?.length) return <div>-</div>;
  const finalPassings = props.testPassings
    .filter((passing) => passing.is_final_task && passing.success_passed === 0)
    .sort((a, b) => new Date(a.finish_time).getTime() - new Date(b.finish_time).getTime())
    .reverse();
  const finalPassing = finalPassings.length ? finalPassings[0] : null;

  return (
    <div>
      {finalPassing ? moment(finalPassing.finish_time).format('DD.MM.YYYY') : <span>-</span>}
    </div>
  );
};
