import { ICourse, IMaterial } from '../../../typings/admin';
import Table from '../../../shared/Table';
import { convertTimestampToDate } from '../../../utils/time';
import AdminTemplate from '../templates/AdminTemplate';
import { Link } from '@tanstack/react-location';
import { generateCourseLink } from '../routes';
import AdminAddCourse from '../containers/AdminAddCourse';
import { useCallback, useEffect, useMemo, useState } from 'react';
import AdminEditCourse from '../containers/AdminEditCourse';
import { alphaBetSort } from '../../../utils/array';
import { keyBy } from 'lodash';
import AdminCourseStatistic from './AdminCourseStatistic';
import { FaEdit } from 'react-icons/fa';
import RSelect from '../../../shared/ReactSelect';
import { checkIsIntelleka } from '../../../utils/link';
import { useCoursesSearch } from '../store/useCourses';
import SearchForm from '../../../shared/SearchForm';
import BadgeButton from '../../../shared/BadgeButton';
import RowPreloader from '../../../shared/RowPreloader';
import ThemeInput from '../../../shared/ThemeInput';
import { useGetCourses } from '../../../cache/admin/course';
import { useGetTags } from '../../../cache/admin/tag';
import { useMaterialsSearch } from '../../../cache/admin/material';

type TOrdering = 'TITLE' | 'DATE' | 'TAG' | 'AUTHOR';

type IMaterialsByCourseId = Record<string, string[]>;

export const ORDER_TYPES: Record<string, TOrdering> = {
  TITLE: 'TITLE',
  DATE: 'DATE',
  TAG: 'TAG',
  AUTHOR: 'AUTHOR',
};

export const SearchBlock: React.FC<{
  search: string;
  isSearching: boolean;
  materialsByCourseId: IMaterialsByCourseId;
  materialsSearch?: IMaterial[];
  coursesById: Record<string, ICourse>;
}> = ({ search, isSearching, materialsByCourseId, materialsSearch, coursesById }) => {
  if (!search) return null;
  return (
    <>
      {!materialsSearch?.length && !isSearching && (
        <div className="text-center italic text-gray-500 mt-3">Курсы не найдены</div>
      )}
      {!isSearching &&
        Object.keys(materialsByCourseId).map((courseId) => {
          const materials = materialsByCourseId[courseId];

          return (
            <div key={courseId} className="my-2">
              <div>
                <Link className="" to={generateCourseLink(courseId, '/content')}>
                  <b className="uppercase">{coursesById[courseId].title}</b>
                </Link>
                <i className="w-fit text-gray-400 block py-1 rounded-sm transition-all">
                  <ul className="pl-5">
                    {materials.map((material: string) => (
                      <li className="list-disc">
                        <Highlighted text={material} highlight={search} />
                      </li>
                    ))}
                  </ul>
                </i>
              </div>
            </div>
          );
        })}
    </>
  );
};

const AdminCourses: React.FC = () => {
  const [edit, setEdit] = useState<ICourse>();
  const { data, isLoading } = useGetCourses();
  const { data: tags } = useGetTags();
  const [orderBy, setorderBy] = useState<TOrdering>(ORDER_TYPES.DATE);
  const [isReverse, setIsReverse] = useState(true);
  const [searchFilter, setSearchFilter] = useState('');
  const { search, setSearch } = useCoursesSearch();
  const coursesById = keyBy(data, 'id');

  const { data: materialsSearch, isLoading: isSearching } = useMaterialsSearch(search);

  /** добавление элемента first_tag для сортировки по первому тегу */
  const courses = useMemo(() => {
    const serverCourses = data || [];
    const tagsData = tags || [];
    return serverCourses.map((course) => {
      if (Number(course.tag?.length) > 0) {
        const tagObj = tagsData.find((tag) => tag.id === course.tag?.[0]);
        return {
          ...course,
          first_tag: tagObj ? tagObj.tag : '',
        };
      } else {
        return {
          ...course,
          first_tag: 'я',
        };
      }
    });
  }, [data, tags]);

  const tagsById = keyBy(tags, 'id');

  const setOrdering = (orderName: TOrdering) => {
    const prevOrder = orderBy;
    const newOrder = orderName;
    if (prevOrder === newOrder && !isReverse) {
      setIsReverse(true);
    }
    if (prevOrder === newOrder && isReverse) {
      setIsReverse(false);
    }
    if (prevOrder !== newOrder && isReverse) {
      setIsReverse(false);
    }
    setorderBy(orderName);
  };

  const sortedCourses = useMemo(() => {
    if (!courses) return [];
    switch (orderBy) {
      case ORDER_TYPES.TAG:
        return isReverse
          ? alphaBetSort(courses, 'first_tag').reverse()
          : alphaBetSort(courses, 'first_tag');
      case ORDER_TYPES.TITLE:
        return isReverse
          ? alphaBetSort(courses, 'title').reverse()
          : alphaBetSort(courses, 'title');
      case ORDER_TYPES.DATE:
        return isReverse
          ? alphaBetSort(courses, 'created').reverse()
          : alphaBetSort(courses, 'created');
      case ORDER_TYPES.AUTHOR:
        return isReverse
          ? alphaBetSort(courses, 'author_name')
          : alphaBetSort(courses, 'author_name').reverse();
      default:
        return courses;
    }
  }, [courses, orderBy, isReverse]);

  const titles = [
    <div className="title-sort" onClick={() => setOrdering(ORDER_TYPES.TITLE)}>
      Названия
    </div>,
    <div className="title-sort" onClick={() => setOrdering(ORDER_TYPES.DATE)}>
      Дата создания
    </div>,
    'З/В/П',
    <div className="title-sort" onClick={() => setOrdering(ORDER_TYPES.TAG)}>
      Теги
    </div>,
    <div className="title-sort" onClick={() => setOrdering(ORDER_TYPES.AUTHOR)}>
      Автор
    </div>,
    'Действия',
  ];

  const [tagsFilter, setTagsFilter] = useState<
    {
      label: string;
      value: number;
    }[]
  >();

  const filterCoursesByTag = useCallback(
    (courses: ICourse[]) => {
      if (Array.isArray(tagsFilter)) {
        if (!tagsFilter.length) return courses;

        const tagsFilterValues = tagsFilter.map((tag) => tag.value);

        const filteredTags = courses.filter((course) => {
          const foundTag = tagsFilterValues.every((value) => course.tag?.includes(value));

          return Boolean(foundTag);
        });

        return filteredTags;
      }

      return courses;
    },
    [tagsFilter],
  );

  const filteredCourses = tagsFilter ? filterCoursesByTag(sortedCourses) : sortedCourses;

  const isIntelleka = checkIsIntelleka();

  const rows = filteredCourses
    .filter((course) => course.title.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0)
    ?.map((c) => [
      <Link to={generateCourseLink(c.id, '/content')}>{c.title}</Link>,
      convertTimestampToDate(c.created, 'DD.MM.YYYY'),
      <AdminCourseStatistic course={c} />,
      <div className="flex flex-wrap max-w-[100%] justify-center">
        {c.tag.map((t) => (
          <BadgeButton
            key={t}
            className="badge bg-gray-badge mx-[2px] my-[2px] text-sm cursor-default"
          >
            {tagsById[t]?.tag}
          </BadgeButton>
        ))}
      </div>,
      c.author_name,
      <button onClick={() => setEdit(c)}>
        <FaEdit className={`${isIntelleka ? 'hover:text-active_i' : 'hover:text-active_p'}`} />
      </button>,
    ]);

  const [materialsByCourseId, setMaterialsByCourseId] = useState<IMaterialsByCourseId>({});

  useEffect(() => {
    if (materialsSearch) {
      const res = materialsSearch.reduce((prev, cur) => {
        return {
          ...prev,
          [cur.course]: prev[cur.course] ? [...prev[cur.course], cur.title] : [cur.title],
        };
      }, {} as any);
      setMaterialsByCourseId(res);
    }
  }, [materialsSearch]);

  return (
    <AdminTemplate>
      <div className="flex flex-col md:flex-row md:items-center mb-5">
        <AdminAddCourse />
        <ThemeInput
          type="search"
          isIntelleka={isIntelleka}
          className="w-full md:w-[400px] my-2 md:my-0 md:mx-3"
          value={searchFilter}
          disabled={!!search}
          onChange={(e) => setSearchFilter(e.target.value)}
          placeholder="Фильтр по названию"
        />
        <SearchForm
          className="md:w-[400px] flex"
          submit={({ search }) => setSearch(search)}
          loading={isSearching}
          isIntelleka={isIntelleka}
          classNameInput="w-full"
          placeholder="Поиск по материалам"
        />
      </div>
      <AdminEditCourse onClose={() => setEdit(undefined)} edit={edit} />
      <div className="bg-[#e8e8e8] p-2">
        <RSelect
          className="max-w-[262px]"
          options={tags?.map((t) => ({ value: t.id, label: t.tag }))}
          value={tagsFilter}
          onChange={(data) => Array.isArray(data) && setTagsFilter(data)}
          placeholder="Тэги"
          isMulti
          isDisabled={!!search}
          isIntelleka={isIntelleka}
        />
      </div>
      {isSearching && <RowPreloader count={7} />}
      <SearchBlock
        coursesById={coursesById}
        isSearching={isSearching}
        materialsByCourseId={materialsByCourseId}
        materialsSearch={materialsSearch}
        search={search}
      />
      {!search && <Table titles={titles} rows={rows} isLoading={isLoading} />}
    </AdminTemplate>
  );
};

export default AdminCourses;

const Highlighted = ({ text = '', highlight = '' }) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = text.split(regex);

  return (
    <div>
      {parts.filter(String).map((part, i) => {
        return regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>;
      })}
    </div>
  );
};
