import { FaTimes } from 'react-icons/fa';
import { checkIsIntelleka } from '../../../utils/link';
import { getTaskTopic } from '../../../utils/material';
import { formattedTime, secondsToTime } from '../../../utils/time';
import { useMaterial } from '../store/useMaterial';
import { useTest } from '../store/useTest';
import { useTests } from '../store/useTests';

const ClientTestHeader: React.FC<{
  title?: string;
  travel_time?: string;
  materialId?: number;
}> = (props) => {
  const { ui, task, getCurrentAttemptQuestions, getQuestionNumber, handleClose } = useTest();
  const { tasksDuration } = useTests();
  const { materials } = useMaterial();
  const taskTopic = getTaskTopic(props.materialId, materials);

  const timeLeft = task ? tasksDuration[task.id] : 0;
  const isIntelleka = checkIsIntelleka();

  return (
    <div
      className={`${
        isIntelleka ? 'gradient_i' : 'bg-black-default'
      }  text-white pt-4 pb-2 px-5 md:px-12 rounded-t`}
    >
      <div
        className={`${isIntelleka ? '#fff' : 'text-active_p'} flex justify-between items-center`}
      >
        <div
          className={`underline cursor-pointer ${
            isIntelleka ? 'hover:text-blue-400' : 'hover:text-active_p_hover'
          }`}
          onClick={handleClose}
        >
          К материалам курса
        </div>
        <FaTimes
          className={`cursor-pointer ${
            isIntelleka ? 'hover:text-blue-400' : 'hover:text-active_p_hover'
          } -mr-1`}
          onClick={handleClose}
        />
      </div>
      <div className="min-h-[130px] flex items-center justify-center text-center">
        <div className="flex flex-col">
          <span className="text-[25px]">{props.title}</span>
          <span className="text-[19px] font-['light']">{taskTopic && `Тема: ${taskTopic}`}</span>
        </div>
      </div>
      <div className="flex justify-between flex-col sm:flex-row text-center sm:text-left">
        <div>
          <span className="mr-2">Время на тестирование:</span>
          <span className="text-yellow-default">
            {ui === 'question' || ui === 'loading'
              ? timeLeft
                ? secondsToTime(timeLeft)
                : '00:00:00'
              : formattedTime(props.travel_time)}
          </span>
        </div>
        {ui === 'question' ? (
          <div>
            <span className="mr-2">Вопрос:</span>
            <span className="text-yellow-default">
              {getQuestionNumber()} из {getCurrentAttemptQuestions().length}
            </span>
          </div>
        ) : (
          <div>
            <span className="mr-2">Вопросов:</span>
            <span className="text-yellow-default">{getCurrentAttemptQuestions().length}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientTestHeader;
