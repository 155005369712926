import { useLightbox } from '../ui/common/store/useLightbox';

/** Компонент рендера изображения в сообщениях */
const MessageImage: React.FC<{ image: string }> = (props) => {
  const { setImage } = useLightbox();

  return (
    <img
      src={props.image}
      alt="media"
      className="w-full max-w-[220px] rounded"
      onClick={() => setImage(props.image)}
    />
  );
};

export default MessageImage;
