import { IMaterial } from '../../../typings/client';
import { getStructuredMaterials } from '../../../utils/material';
import ClientMaterialList from './ClientMaterialList';
import ClientBookmarks from './ClientBookmarks';
import { useMaterial } from '../store/useMaterial';

const ClientMaterialSidebar: React.FC<{ materials?: IMaterial[]; windowWidth: number }> = (
  props,
) => {
  const { setId, isSidebarVisible } = useMaterial();
  const structured = props.materials && getStructuredMaterials(props.materials);

  return (
    <div
      className={`${
        isSidebarVisible ? (props.windowWidth < 992 ? 'w-full' : 'w-[360px]') : 'w-0'
      } border-r-black border-r relative transition-all duration-500`}
    >
      <div
        className={`${
          isSidebarVisible ? 'opacity-100 delay-500 duration-100' : 'opacity-0 duration-400'
        } transition-all`}
      >
        <ClientBookmarks />
        <ClientMaterialList materials={structured} open={setId} sidebar />
      </div>
    </div>
  );
};

export default ClientMaterialSidebar;
