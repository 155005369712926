import { ROOT, INFO, MATERIALS, WEBINARS, TESTS, SUPPORT, TEACHER } from '../routes';
import SvgList from '../../../react-svg/SvgList';
import SvgBook from '../../../react-svg/SvgBook';
import SvgInfo from '../../../react-svg/SvgInfo';
import SvgWebinars from '../../../react-svg/SvgWebinars';
import SvgChat from '../../../react-svg/SvgChat';
import SvgSpeech from '../../../react-svg/SvgSpeech';
import { SidebarLink } from '../../../shared/SidebarLink';
import { useTest } from '../store/useTest';
import { getUnreadMessages } from '../../../api/common';
import { IUnreadChatTypesNormalized } from '../../../typings/common';
import { useQuery } from 'react-query';
import Pill from '../../../shared/Pill';
import { useNotificationsStore } from '../../common/store/useNotifications';
import { useEffect } from 'react';
import { checkIsIntelleka } from '../../../utils/link';

const ClientSidebarMenu: React.FC<{ hasDescription: boolean; hasTasks: boolean }> = ({
  hasDescription,
  hasTasks,
}) => {
  const { user } = useTest();

  const { notifications } = useNotificationsStore();
  const { data: unreadMessages, refetch } = useQuery('unread', getUnreadMessages);

  useEffect(() => {
    notifications.length > 0 && refetch();
  }, [notifications.length, refetch]);

  const normalizedChatTypes = unreadMessages?.chats.reduce(
    (acc, curr) => ({ ...acc, [curr.chat_type]: curr.unread_messages }),
    {} as IUnreadChatTypesNormalized,
  );

  const supportUnreadMessages = Number(normalizedChatTypes?.[0] || 0);
  const teacherUnreadMessages = Number(normalizedChatTypes?.[1] || 0);

  const isIntelleka = checkIsIntelleka();

  return (
    <>
      {hasDescription && (
        <SidebarLink link={ROOT + INFO} icon={<SvgInfo />} isIntelleka={isIntelleka}>
          Информация о курсе
        </SidebarLink>
      )}
      <SidebarLink link={ROOT + MATERIALS} icon={<SvgBook />} isIntelleka={isIntelleka}>
        Материалы курса
      </SidebarLink>
      {user?.webinars && (
        <SidebarLink link={ROOT + WEBINARS} icon={<SvgWebinars />} isIntelleka={isIntelleka}>
          Вебинары
        </SidebarLink>
      )}
      {hasTasks && (
        <SidebarLink link={ROOT + TESTS} icon={<SvgList />} isIntelleka={isIntelleka}>
          Экзамены
        </SidebarLink>
      )}
      {!!user?.teacher_chat && (
        <SidebarLink link={ROOT + TEACHER} icon={<SvgSpeech />} isIntelleka={isIntelleka}>
          Чат с преподавателем {!!teacherUnreadMessages && <Pill>{teacherUnreadMessages}</Pill>}
        </SidebarLink>
      )}
      {!!user?.tech_chat && (
        <SidebarLink link={ROOT + SUPPORT} icon={<SvgChat />} isIntelleka={isIntelleka}>
          Служба поддержки {!!supportUnreadMessages && <Pill>{supportUnreadMessages}</Pill>}
        </SidebarLink>
      )}
    </>
  );
};

export default ClientSidebarMenu;
