export interface IBurgerProps {
  onClick: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  open?: boolean;
  className?: string;
}

/**
 * Иконка бургера в состоянии закрытия и открытия, меняющемся при клике
 * @testcases:
 * - при клике срабатывает колбек
 */
const Burger: React.FC<IBurgerProps> = ({ onClick, className = '', open = false }) => {
  return (
    <div onClick={onClick} className={className} data-testid="burger-icon">
      <div className="bg-transparent flex items-center justify-center h-[25px] w-[28px]">
        <span
          className={`
          h-[2px] w-[calc(100%-4px)] relative transition-all after:content-['']
          after:absolute after:w-full after:h-[2px] after:bg-[gray]
          after:transition-all before:content-[''] before:absolute before:w-full
          before:h-[2px] before:bg-[gray] before:transition-all ${
            open
              ? 'after:-rotate-45 bg-transparent after:bottom-0 before:top-0 before:rotate-45'
              : 'bg-[gray] after:rotate-0 after:bottom-[-7px] before:top-[-7px] before:rotate-0'
          }`}
        />
      </div>
    </div>
  );
};

export default Burger;
