import { MdAttachFile } from 'react-icons/md';
import { BiLinkExternal } from 'react-icons/bi';

/** Компонент рендера ссылки в сообщениях */
const MessageLink: React.FC<{ link: string; isAuthorMessage?: boolean }> = ({
  isAuthorMessage = false,
  ...props
}) => {
  const isFile = ~props.link.indexOf('firebasestorage');
  const info = props.link.split('|');
  const hasFileName = info.length >= 2;

  return (
    <a
      href={isFile ? info[0] : props.link}
      target="_blank"
      className={`${
        isAuthorMessage
          ? 'bg-active_p text-white hover:text-white hover:after:border-white'
          : 'bg-gray-message text-black-message hover:text-black-message hover:after:bg-[#212529]]'
      } max-w-md p-2 relative after:content-[''] after:absolute after:block after:w-full after:h-full after:right-0 after:left-0 after:top-0 after:rounded after:bottom-0 after:m-auto after:bg-transparent after:transition-all hover:after:bg-transparent hover:after:border hover:after:w-[calc(100%-10px)] hover:after:h-[calc(100%-10px)] rounded block overflow-hidden`}
      rel="noreferrer"
    >
      {isFile ? (
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <MdAttachFile />
            <div className="mr-1">{(hasFileName && info[2]) || 'Вложение'}</div>
          </div>
          <BiLinkExternal />
        </div>
      ) : (
        props.link
      )}
    </a>
  );
};

export default MessageLink;
