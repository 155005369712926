import { FaUserCog, FaUserTimes } from 'react-icons/fa';

/** Компонент кнопок операций с пользователями */
const UsersActions: React.FC<{
  onClick?: (type: 'update' | 'delete') => void;
  isIntelleka?: boolean;
  className?: string;
}> = ({ onClick = (type) => console.log(type), className = '', ...props }) => {
  return (
    <div className={className}>
      <FaUserCog
        className={`mr-4 cursor-pointer ${
          props.isIntelleka ? 'hover:text-active_i' : 'hover:text-active_p'
        }`}
        onClick={() => onClick('update')}
      />
      <FaUserTimes
        className={`${
          props.isIntelleka ? 'hover:text-active_i' : 'hover:text-active_p'
        } cursor-pointer`}
        onClick={() => onClick('delete')}
      />
    </div>
  );
};

export default UsersActions;
