import { Link } from '@tanstack/react-location';

/** Компонент футера */
const Footer: React.FC<{ userStatus?: number; isIntelleka?: boolean }> = ({
  userStatus,
  isIntelleka = false,
}) => {
  return (
    <div className={`${userStatus === 2 ? '' : 'mt-[-135px] h-[135px]'}`}>
      <div
        className={`text-center ${
          userStatus === 3
            ? // curator classes
              'bg-gray-curator_footer py-14 px-2'
            : userStatus === 2
            ? // user classes
              `${isIntelleka ? 'gradient_i' : 'gradient_p'} text-white lg:-mt-7 pt-9 lg:pt-16 pb-8`
            : // admin classes
              `${isIntelleka ? 'gradient_i' : 'gradient_p'} text-white py-14 px-2`
        }`}
      >
        © 2022
        <Link
          to="/"
          className={`${
            userStatus === 3
              ? // curator classes
                isIntelleka
                ? 'text-active_i'
                : 'text-active_p'
              : userStatus === 2
              ? // user classes
                'text-[#fecf33]'
              : // admin classes
                'text-[#fecf33]'
          } hover:opacity-80 ml-1`}
        >
          {isIntelleka ? '«Интеллека»' : '«ПромРесурс»'}
        </Link>
        . Любое использование материалов сайта без разрешения владельцев запрещено авторскими
        правами.
      </div>
    </div>
  );
};

export default Footer;
