import React from 'react';
import { useQuery } from 'react-query';
import { API } from '../../../api';
import PassingStatus from '../../../shared/PassingStatus';
import { checkIsIntelleka } from '../../../utils/link';

const Dot: React.FC<{ layer: number; isActive: boolean }> = ({ layer, isActive }) => {
  const isIntelleka = checkIsIntelleka();

  return (
    <div
      style={{
        fontSize: layer > 1 ? '32px' : '55px',
        marginTop: layer > 1 ? '-10px' : '-16px',
        marginLeft: -1,
      }}
      className={`${
        isActive ? (isIntelleka ? 'text-active_i' : 'text-active_p') : ''
      } opacity-40 relative leading-[0] w-[17px] text-center z-20`}
    >
      .
    </div>
  );
};

const Line: React.FC = () => (
  <div className="w-[50%] border-r border-dashed border-[#ccc] z-10 bg-white" />
);

const ClientPassingIndicator: React.FC<{
  layer: number;
  hasLine?: boolean;
  material?: number;
  task?: number;
  sidebar?: boolean;
  hasChildren?: boolean;
  titleRef: React.RefObject<HTMLDivElement>;
  isActive: boolean;
}> = ({ material, layer, task, sidebar, hasChildren, titleRef, hasLine = false, isActive }) => {
  const { data: passings } = useQuery(
    'all-passings',
    () => API.client.material_passing.getMaterialPassings({ material }),
    { enabled: !!material, staleTime: 99999999 },
  );

  const { data: test_passings } = useQuery(
    ['test-passings', task],
    () => API.client.test_passing.getAllTestPassings({ task }),
    { enabled: !!task, staleTime: 99999999 },
  );

  const testPassingStatus = test_passings?.find((p) => p.task === task)?.success_passed;

  /** все прохождения материала, сортированные по статусам от большего к меньшим */
  const currentMaterialPassings = passings
    ?.filter((p) => p.material === material)
    ?.sort((a, b) => a.status - b.status)
    .reverse();

  const passingStatus = material
    ? currentMaterialPassings?.length && currentMaterialPassings[0].status
    : !!test_passings?.length
    ? testPassingStatus === 0
      ? 2
      : 1
    : 0;

  const getStyle = () => {
    switch (layer) {
      case 1:
        return {
          width: 18,
          minWidth: 18,
          marginLeft: -24,
        };
      case 2:
        return {
          width: 14,
          minWidth: 14,
          marginLeft: sidebar ? -43 : -55,
        };
      default:
        return {
          width: 14,
          minWidth: 14,
          marginLeft: sidebar ? -46 : -70,
        };
    }
  };

  const isIntelleka = checkIsIntelleka();

  return (
    <div
      className="absolute mt-[2px] grid grid-rows-auto_1fr h-full"
      style={{ ...getStyle(), top: sidebar ? 8 : 0, left: sidebar ? 7 : 0 }}
    >
      {sidebar ? (
        <>
          <Dot layer={layer} isActive={isActive} />
          {layer > 0 && hasChildren && (
            <>
              <div className="flex absolute">
                <div
                  style={{ height: titleRef?.current?.clientHeight }}
                  className="z-10 bg-white absolute w-[7px] border border-dashed border-[#ccc] rounded top-[3px] left-[7px] border-t-0 border-r-0 rounded-tl-none rounded-br-none"
                />
                <div
                  style={{
                    height: (titleRef?.current?.clientHeight || 0) / 2,
                    top: (titleRef?.current?.clientHeight || 0) + 2,
                    left: 14,
                  }}
                  className="z-20 bg-white w-[7px] border border-dashed border-[#ccc] rounded absolute rounded-tl-none rounded-br-none border-l-0 border-b-0"
                />
              </div>
            </>
          )}
          {layer > 1 && (
            <div
              className={`absolute top-[-1px] right-2 ${
                isActive ? (isIntelleka ? 'text-active_i' : 'text-active_p') : ''
              }`}
            >
              <div className="w-4 left-[-1px] top-[-9px] absolute z-20 leading-none opacity-40">
                ---
              </div>
              <div className="w-4 text-right z-30 left-[2px] top-0 absolute leading-3 mt-[-15px] text-[32px] opacity-40">
                .
              </div>
            </div>
          )}
          {hasLine && layer > 1 && !hasChildren && <Line />}
        </>
      ) : (
        <>
          <PassingStatus status={passingStatus} small={layer > 1} />
          {hasLine && <Line />}
        </>
      )}
    </div>
  );
};

export default ClientPassingIndicator;
