import { useEffect } from 'react';
import { FaRegCommentDots, FaRegComments, FaRegEnvelope, FaUserTie } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { getAllChats, getCurrentUser, getUnreadMessages } from '../../../api/common';
import Pill from '../../../shared/Pill';
import { SidebarLink } from '../../../shared/SidebarLink';
import { checkIsIntelleka } from '../../../utils/link';
import { useNotificationsStore } from '../../common/store/useNotifications';

import {
  ADMIN_SUPPORT_CHATS,
  ADMIN_COMPANIES_CHATS,
  ADMIN_MASS_MAIL,
  ADMIN_TEACHER_CHATS,
  ADMIN_CURATOR_CHATS,
} from '../routes';
import { useChats } from '../store/useChats';

const AdminChatsMenu: React.FC = () => {
  const { data: user } = useQuery('user', getCurrentUser);

  const { data: clientSupportChats } = useQuery(
    'support',
    () => getAllChats({ users: user?.id, chat_status: 0, ordering: '-created' }),
    {
      enabled: !!user?.id,
    },
  );

  const { data: teacherSupportChats } = useQuery(
    'teacher',
    () => getAllChats({ users: user?.id, chat_status: 1, ordering: '-created' }),
    {
      enabled: !!user?.id,
    },
  );

  const { setNormalizedChats, unreadClient, unreadCurator, unreadTeacher } = useChats();
  const { notifications } = useNotificationsStore();

  const { refetch } = useQuery('unread', getUnreadMessages, {
    enabled: !!clientSupportChats && !!teacherSupportChats,
    onSuccess: (res) =>
      clientSupportChats &&
      teacherSupportChats &&
      setNormalizedChats(res, clientSupportChats, teacherSupportChats),
  });

  useEffect(() => {
    notifications.length > 0 && refetch();
  }, [notifications, refetch]);

  const isIntelleka = checkIsIntelleka();
  const isAdmin = user?.user_status === 0;

  return (
    <div className="flex flex-col overflow-hidden">
      <SidebarLink link={ADMIN_SUPPORT_CHATS} icon={<FaRegComments />} isIntelleka={isIntelleka}>
        <div className="flex items-center">
          <div>Служба поддержки</div>
          <div>{!!unreadClient && <Pill>{unreadClient}</Pill>}</div>
        </div>
      </SidebarLink>
      <SidebarLink
        link={ADMIN_COMPANIES_CHATS}
        icon={<FaRegCommentDots />}
        isIntelleka={isIntelleka}
      >
        Список чатов компаний
      </SidebarLink>

      {isAdmin && (
        <SidebarLink link={ADMIN_MASS_MAIL} icon={<FaRegEnvelope />} isIntelleka={isIntelleka}>
          Массовая рассылка сообщений
        </SidebarLink>
      )}

      <SidebarLink link={ADMIN_TEACHER_CHATS} icon={<FaUserTie />} isIntelleka={isIntelleka}>
        <div className="flex items-center">
          <div>Преподаватель</div>
          <div>{!!unreadTeacher && <Pill>{unreadTeacher}</Pill>}</div>
        </div>
      </SidebarLink>
      {isAdmin && (
        <SidebarLink link={ADMIN_CURATOR_CHATS} icon={<FaUserTie />} isIntelleka={isIntelleka}>
          <div className="flex items-center">
            <div>Чаты с кураторами</div>
            <div>{!!unreadCurator && <Pill>{unreadCurator}</Pill>}</div>
          </div>
        </SidebarLink>
      )}
    </div>
  );
};

export default AdminChatsMenu;
