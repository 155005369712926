import { useMutation } from 'react-query';
import { API } from '../../../api';
import { queryClient } from '../../../hooks/queryClient';
import { IQuestion, TUpdateQuestion } from '../../../typings/admin';
import { getQuestionsKey } from './';

export const useUpdateQuestion = (
  testId: string,
  questionId: number,
  toggle: (d: boolean) => void,
) => {
  return useMutation<IQuestion, unknown, TUpdateQuestion>(
    (data) =>
      API.admin.question.change({
        ...data,
        tasks: [Number(testId)],
        id: questionId,
      }),
    {
      onSuccess: () => {
        toggle(false);
        queryClient.refetchQueries(getQuestionsKey(testId));
      },
    },
  );
};
