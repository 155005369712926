/** Компонент индикатора новых сообщений */
const Pill: React.FC<{
  /** цвет */
  background?: string;
}> = ({ children, background = '#dc3545' }) => {
  return (
    <span
      style={{ background }}
      className="text-white ml-1 text-[13px] font-bold rounded-full py-[.25em] px-[.6em]"
    >
      {children}
    </span>
  );
};

export default Pill;
