/**
 * @module number
 * @category общие утилиты
 * @description утилиты по работе с числами
 */

/**
 * функция генерирует случайное число от нуля до указанного в параметре
 * @param max максимальное значение
 * @returns возвращает случайное число
 */
export const getRandomInt = (max: number) => Math.floor(Math.random() * max);

/**
 * функция высчитывает число из процента
 * @param num число
 * @param percent процент
 * @returns возвращает число из процента
 */
export const countByPercent = (num: number, percent: number) => (num * percent) / 100;
