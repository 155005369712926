import ClientTemplate from '../templates/ClientTemplate';

const ClientWebinars: React.FC = () => {
  return (
    <ClientTemplate>
      <div className="p-7"></div>
    </ClientTemplate>
  );
};

export default ClientWebinars;
