import { useState } from 'react';
import { TCreateUser } from '../../../typings/admin';
import BadgeButton from '../../../shared/BadgeButton';
import Modal from '../../../shared/Modal';
import AdminMasterForm from './AdminMasterForm';
import { useAddCurator } from '../../../cache/admin/users/curators';
import { getError } from '../../../utils/api';

const AdminAddCurator: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');

  const { mutate: addUser, isLoading: adding } = useAddCurator(
    () => setOpen(false),
    (error) => {
      setError(getError(error));
      setTimeout(() => setError(''), 3000);
    },
  );

  const submit = (data: TCreateUser) => addUser(data);

  return (
    <div>
      <BadgeButton onClick={() => setOpen(true)}>Добавить куратора</BadgeButton>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        className="rounded max-w-screen-modal_md md:mt-7 p-4"
      >
        <AdminMasterForm
          submit={submit}
          loading={adding}
          onClose={() => setOpen(false)}
          open={open}
          curator
          error={error}
          setError={setError}
        />
      </Modal>
    </div>
  );
};

export default AdminAddCurator;
