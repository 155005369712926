import ClientTaskTemplate from '../ui/client/templates/ClientTaskTemplate';
import { parseResponseRate } from '../utils/passing';
import { russianEndings } from '../utils/string';
import { toHoursAndMinutes } from '../utils/time';
import { ThemeButton } from './Button';

const TrialResults: React.FC<{ rate: string | null }> = (props) =>
  props.rate ? <div className="text-center">{props.rate}</div> : null;

const RateResults: React.FC<{
  notPassed: boolean;
  resultQuesions: number;
  response_rate: string;
}> = (props) => (
  <>
    <div className="text-center text-[29px]">Тест{` ${props.notPassed ? 'не ' : ''}`}сдан!</div>
    <div className={`flex justify-center ${props.notPassed ? 'text-active_p' : 'text-green-700'}`}>
      Вы набрали {props.response_rate} (<div className="mr-1">{props.resultQuesions}</div>
      {russianEndings(props.resultQuesions, ['вопрос', 'вопроса', 'вопросов'])})
    </div>
  </>
);

const Motivation: React.FC<{
  passing?: number;
  retake_seconds?: string;
  successQuesions: number;
}> = (props) => (
  <div className="mt-4">
    <div className="mb-2">
      Для успешной сдачи необходимо ответить правильно на
      <strong className="pl-2">
        {props.passing}% (<span className="pr-1">{props.successQuesions}</span>
        {russianEndings(props.successQuesions, ['вопрос', 'вопроса', 'вопросов'])})
      </strong>
      .
    </div>
    <div>
      Не расстраивайтесь, следующая попытка станет доступна всего через
      {props.retake_seconds && (
        <strong className="pl-2">{toHoursAndMinutes(Number(props.retake_seconds))}</strong>
      )}
      <span>,</span> а пока у вас будет время повторить материалы курса.
    </div>
  </div>
);

const HasRate: React.FC<{
  isTrial: boolean;
  notPassed: boolean;
  responseRate: string;
  successQuesions: number;
  resultQuesions: number;
  retakeSeconds?: string;
  rate?: number;
}> = (props) => (
  <>
    <div>
      {props.isTrial ? (
        <TrialResults rate={parseResponseRate(props.responseRate)} />
      ) : (
        <RateResults
          notPassed={props.notPassed}
          response_rate={props.responseRate}
          resultQuesions={props.resultQuesions}
        />
      )}
    </div>

    {props.notPassed && !props.isTrial && props.retakeSeconds && (
      <Motivation
        passing={props.rate}
        retake_seconds={props.retakeSeconds}
        successQuesions={props.successQuesions}
      />
    )}
  </>
);

const Checking: React.FC = () => (
  <>
    <div className="text-center">
      <div className="text-orange-400">Тест находится на проверке.</div>
      <div className="mt-2">
        Результат теста станет известным после проверки ответов преподавателем.
      </div>
    </div>
  </>
);

const TestResult: React.FC<{
  notPassed: boolean;
  onClose(): void;
  isIntelleka: boolean;
  isChecking: boolean;
  isHidden: boolean;
  isTrial: boolean;
  /** Итоговая оценка (%) */
  responseRate: string;
  retakeSeconds?: string;
  /** Минимальная оценка необходимая для сдачи теста (%) */
  rate?: number;
  questionsCount: number;
}> = (props) => {
  const resultQuesions = Math.round(
    (props.questionsCount / 100) * Number(props.responseRate?.replaceAll('%', '')),
  );
  const successQuesions = Math.ceil((props.questionsCount / 100) * (props.rate || 0));

  return (
    <ClientTaskTemplate>
      {props.isHidden ? (
        <Checking />
      ) : props.isChecking ? (
        <Checking />
      ) : (
        <HasRate
          isTrial={props.isTrial}
          notPassed={props.notPassed}
          responseRate={props.responseRate}
          successQuesions={successQuesions}
          resultQuesions={resultQuesions}
          retakeSeconds={props.retakeSeconds}
          rate={props.rate}
        />
      )}
      <div className="w-full text-center mt-5">
        <ThemeButton
          onClick={props.onClose}
          isIntelleka={props.isIntelleka}
          className="mt-6 pt-3 pb-3 pl-5 pr-5"
          isSecondary={props.notPassed}
        >
          <div className="py-3 px-8">
            {props.notPassed ? 'Вернуться к обучению' : 'Продолжить обучение'}
          </div>
        </ThemeButton>
      </div>
    </ClientTaskTemplate>
  );
};

export default TestResult;
