import { IBookmark } from '../typings/client';
import { FaEdit, FaTimes } from 'react-icons/fa';
import Tooltip from './Tooltip';

/**
 * Компонент рендера закладок
 * @testcases:
 * - видимость первого элемента
 */
const Bookmarks: React.FC<{
  bookmarks?: IBookmark[];
  refObject?: React.RefObject<HTMLDivElement>;
  setEditing: (id: number) => void;
  removeBookmark: (id: number) => void;
  deleting: boolean;
  isIntelleka?: boolean;
  onClick(id: number, page: number): void;
}> = ({
  bookmarks,
  setEditing,
  removeBookmark,
  deleting,
  isIntelleka = false,
  refObject = null,
  onClick,
}) => {
  if (!bookmarks) return null;

  return (
    <div
      ref={refObject}
      className="absolute bg-white w-full max-w-xs left-0 right-0 m-auto shadow-lg rounded p-3 z-40 top-24"
    >
      {bookmarks?.map((b, i, self) => {
        const first = i === 0,
          last = i === self.length - 1;
        return (
          <div
            className={`${deleting ? 'opacity-40' : ''} flex justify-between items-center ${
              !last ? 'border-b border-gray-dark_ultra_light_opacity ' : ''
            } ${first ? 'pb-2' : last ? 'pb-0' : 'py-2'}`}
            key={b.id}
          >
            <div
              className={`pr-1 hover ${
                isIntelleka ? 'hover:text-active_i' : 'hover:text-active_p'
              }`}
              onClick={() => onClick(b.material, b.page)}
            >
              {b.title}, стр. {b.page}
            </div>
            <div className="flex items-center justify-between w-9 min-w-[36px]">
              <Tooltip text="Редактировать имя закладки">
                <FaEdit
                  className={`hover ${isIntelleka ? 'hover:text-active_i' : 'hover:text-active_p'}`}
                  onClick={() => setEditing(b.id)}
                />
              </Tooltip>
              <Tooltip text="Удалить закладку">
                <FaTimes
                  className={`hover ${isIntelleka ? 'hover:text-active_i' : 'hover:text-active_p'}`}
                  onClick={() => removeBookmark(b.id)}
                />
              </Tooltip>
            </div>
          </div>
        );
      })}
      {!bookmarks.length && <div className="text-gray-400">Нет закладок</div>}
    </div>
  );
};

export default Bookmarks;
