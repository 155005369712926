import AdminCourses from './pages/AdminCourses';
import AdminCourseInfo from './pages/AdminCourseInfo';
import AdminUsers from './pages/AdminUsers';
import AdminChatsSupport from './pages/AdminChatsSupport';
import AdminCourseContent from './pages/AdminCourseContent';
import AdminCourseMaterials from './pages/AdminCourseMaterials';
import AdminCourseTests from './pages/AdminCourseTests';
import AdminCompanies from './pages/AdminCompanies';
import AdminModerators from './pages/AdminModerators';
import AdminCurators from './pages/AdminCurators';
import AdminUserProfile from './pages/AdminUserProfile';
import AdminUserVisitings from './pages/AdminUserVisitings';
import AdminUserDevices from './pages/AdminUserDevices';
import AdminUserCourseProgress from './pages/AdminUserCourseProgress';
import AdminUserCourseDetails from './pages/AdminUserCourseDetails';
import AdminUserCourseTest from './pages/AdminUserCourseTest';
import AdminUserCourseTestAttempt from './pages/AdminUserCourseTestAttempt';
import AdminChatsCompanies from './pages/AdminChatsCompanies';
import AdminMassMail from './pages/AdminMassMail';
import AdminChatsTeacher from './pages/AdminChatsTeacher';
import AdminChatsCurator from './pages/AdminChatsCurator';
import AdminSettings from './pages/AdminSettings';
import AdminSettingsStatistic from './pages/AdminSettingsStatistic';
import AdminSettingsAuth from './pages/AdminSettingsAuth';
import AdminTestQuestions from './pages/AdminTestQuestions';
import AdminCompanyChats from './pages/AdminCompanyChats';
import AdminUsersCheck from './pages/AdminUsersCheck';
import AdminUsersExam from './pages/AdminUsersExam';
import Page404 from '../common/pages/Page404';
import AdminChat from './pages/AdminChat';
import AdminAddTestToMaterial from './pages/AdminAddTestToMaterial';
import AdminRouteMap from './pages/AdminRouteMap';
import { ReactElement } from 'react';

export const ROOT = '/admin',
  COURSES = '/',
  COURSE_CHUNK = '/courses',
  COURSE = `${COURSE_CHUNK}/:id`,
  COURSE_INFO = '/',
  COURSE_CONTENT = '/content',
  COURSE_MATERIALS = '/materials',
  COURSE_TESTS = '/tests',
  TEST_MATERIAL_CHUNK = '/add-material',
  TEST_MATERIAL = '/:materialId',
  COURSE_TEST_QUESTIONS = '/:testId',
  COMPANIES = '/companies',
  CURATORS = '/curators',
  MODERATORS = '/moderators',
  USERS = '/users',
  USERS_CHECKING = '/checking',
  USERS_EXAM = '/exam',
  USER = '/:id',
  USER_PROFILE = '/',
  USER_VISITINGS = '/visitings',
  USER_DEVICES = '/devices',
  USER_COURSE_CHUNK = '/courses',
  USER_COURSE = `${USER_COURSE_CHUNK}/:courseId`,
  USER_COURSE_DETAILS = '/details',
  USER_COURSE_TEST = '/:testId',
  USER_COURSE_TEST_ATTEMPT = '/:attemptId',
  MESSAGES = '/messages',
  TEACHER_CHAT_CHUNK = '/teacher_chat',
  SUPPORT_CHAT_CHUNK = '/support_chat',
  TEACHER_CHAT = `${TEACHER_CHAT_CHUNK}/:id`,
  SUPPORT_CHAT = `${SUPPORT_CHAT_CHUNK}/:id`,
  SUPPORT_CHATS = '/support',
  COMPANIES_CHATS = '/companies',
  COMPANY_CHATS = '/:companyId',
  MASS_MAIL = '/mass-mail',
  TEACHER_CHATS = '/teacher',
  CURATOR_CHATS = '/curator',
  SETTINGS = '/settings',
  SETTINGS_STATISTIC = '/statistic',
  SETTINGS_AUTH = '/auth',
  SETTINGS_ROUTE_MAP = '/routes';

/** Страница администратора */
export const ADMIN_ROOT = ROOT,
  /** Страница курсов */
  ADMIN_COURSES = ROOT + COURSES,
  /** Страница описания курса */
  ADMIN_COURSE_INFO = ROOT + COURSE + COURSE_INFO,
  /** Страница содержания курса */
  ADMIN_COURSE_CONTENT = ROOT + COURSE + COURSE_CONTENT,
  /** Страница материалов курса */
  ADMIN_COURSE_MATERIAL = ROOT + COURSE + COURSE_MATERIALS,
  /** Страница тестов курса */
  ADMIN_COURSE_TESTS = ROOT + COURSE + COURSE_TESTS,
  /** Страница формы добавления теста к материалу курса */
  ADMIN_ADD_TEST_TO_COURSE_MATERIAL = ROOT + COURSE + TEST_MATERIAL_CHUNK + TEST_MATERIAL,
  /** Страница вопросов теста */
  ADMIN_COURSE_TEST_QUESTIONS = ROOT + COURSE + COURSE_TESTS + COURSE_TEST_QUESTIONS,
  /** Страница компаний */
  ADMIN_COMPANIES = ROOT + COMPANIES,
  /** Страница кураторов */
  ADMIN_CURATORS = ROOT + CURATORS,
  /** Страница модераторов */
  ADMIN_MODERATORS = ROOT + MODERATORS,
  /** Страница пользователей */
  ADMIN_USERS = ROOT + USERS,
  /** Страница результатов экзаменов пользователей */
  ADMIN_USERS_CHECKING = ROOT + USERS + USERS_CHECKING,
  /** Страница проверки экзаменов пользователей */
  ADMIN_USERS_EXAM = ROOT + USERS + USERS_EXAM,
  /** Страница пользователя */
  ADMIN_USER_PROFILE = ROOT + USERS + USER + USER_PROFILE,
  /** Страница посещений пользователя */
  ADMIN_USER_VISITINGS = ROOT + USERS + USER + USER_VISITINGS,
  /** Страница устройств пользователя */
  ADMIN_USER_DEVICES = ROOT + USERS + USER + USER_DEVICES,
  /** Страница курсов пользователя */
  ADMIN_USER_COURSES = ROOT + USERS + USER + USER_COURSE,
  /** Страница настроек курса пользователя */
  ADMIN_USER_COURSE_SETTINGS = ROOT + USERS + USER + USER_COURSE,
  /** Страница подробной статистики курса пользователя */
  ADMIN_USER_COURSE_DETAILS = ROOT + USERS + USER + USER_COURSE + USER_COURSE_DETAILS,
  /** Страница попыток теста в курсе пользователя */
  ADMIN_USER_COURSE_TEST = ROOT + USERS + USER + USER_COURSE + USER_COURSE_TEST,
  /** Страница результатов попытки теста в курсе пользователя */
  ADMIN_USER_COURSE_TEST_ATTEMPT =
    ROOT + USERS + USER + USER_COURSE + USER_COURSE_TEST + USER_COURSE_TEST_ATTEMPT,
  /** Страница чатов поддержки */
  ADMIN_SUPPORT_CHATS = ROOT + MESSAGES + SUPPORT_CHATS,
  /** Страница чатов компаний */
  ADMIN_COMPANIES_CHATS = ROOT + MESSAGES + COMPANIES_CHATS,
  /** Страница чатов компаний */
  ADMIN_CHAT_COMPANIES = ROOT + MESSAGES + COMPANIES_CHATS + COMPANY_CHATS,
  /** Страница рассылки */
  ADMIN_MASS_MAIL = ROOT + MESSAGES + MASS_MAIL,
  /** Страница чатов от имени преподавателя */
  ADMIN_TEACHER_CHATS = ROOT + MESSAGES + TEACHER_CHATS,
  /** Страница чатов с кураторами */
  ADMIN_CURATOR_CHATS = ROOT + MESSAGES + CURATOR_CHATS,
  /** Страница конкретного чата поддержки */
  ADMIN_TEACHER_CHAT = ROOT + MESSAGES + TEACHER_CHAT,
  /** Страница конкретного чата от имени преподавателя */
  ADMIN_SUPPORT_CHAT = ROOT + MESSAGES + SUPPORT_CHAT,
  /** Общая страница настроек */
  ADMIN_SETTINGS = ROOT + SETTINGS,
  /** Страница настроек сроков обучения пользователей */
  ADMIN_SETTINGS_STATISTIC = ROOT + SETTINGS + SETTINGS_STATISTIC,
  /** Страница настроек быстрой авторизации */
  ADMIN_SETTINGS_AUTH = ROOT + SETTINGS + SETTINGS_AUTH,
  /** Страница карты приложения **/
  ADMIN_SETTINGS_ROUTEMAP = ROOT + SETTINGS + SETTINGS_ROUTE_MAP;

export type TCustomRoute = {
  path: string;
  element?: ReactElement;
  description?: string;
  children?: TCustomRoute[];
};

const routes: TCustomRoute[] = [
  {
    path: ROOT,
    description: 'Интерфейс администратора',
    children: [
      { path: COURSES, element: <AdminCourses />, description: 'Страница курсов' },
      {
        path: COURSE,
        description: 'Страница курса',
        children: [
          {
            path: COURSE_INFO,
            element: <AdminCourseInfo />,
            description: 'Информация курса',
          },
          {
            path: COURSE_CONTENT,
            element: <AdminCourseContent />,
            description: 'Содержание курса',
          },
          {
            path: COURSE_MATERIALS,
            element: <AdminCourseMaterials />,
            description: 'Материалы курса',
          },
          {
            path: TEST_MATERIAL_CHUNK + TEST_MATERIAL,
            element: <AdminAddTestToMaterial />,
            description: 'Добавление теста к материалу',
          },
          {
            path: COURSE_TESTS,
            description: 'Тесты курса',
            children: [
              {
                path: '/',
                element: <AdminCourseTests />,
                description: 'Список тестов',
              },
              {
                path: COURSE_TEST_QUESTIONS,
                element: <AdminTestQuestions />,
                description: 'Страница вопросов к тесту курса',
              },
            ],
          },
          { path: '*', element: <Page404 />, description: 'Страница 404' },
        ],
      },
      { path: COMPANIES, element: <AdminCompanies />, description: 'Страница компаний' },
      {
        path: USERS,
        description: 'Страница пользователей',
        children: [
          { path: '/', element: <AdminUsers />, description: 'Пользователи' },
          { path: USERS_CHECKING, element: <AdminUsersCheck />, description: 'Проверка' },
          { path: USERS_EXAM, element: <AdminUsersExam />, description: 'Экзамен' },
          {
            path: USER,
            description: 'Страница пользователя',
            children: [
              {
                path: USER_PROFILE,
                element: <AdminUserProfile />,
                description: 'Профиль пользователя',
              },
              {
                path: USER_VISITINGS,
                element: <AdminUserVisitings />,
                description: 'Общее время в системе',
              },
              {
                path: USER_DEVICES,
                element: <AdminUserDevices />,
                description: 'Устройства',
              },
              {
                path: USER_COURSE,
                description: 'Курсы',
                children: [
                  {
                    path: '/',
                    element: <AdminUserCourseProgress />,
                    description: 'Прогресс',
                  },
                  {
                    path: USER_COURSE_DETAILS,
                    element: <AdminUserCourseDetails />,
                    description: 'Подробные результаты',
                  },
                  {
                    path: USER_COURSE_TEST,
                    description: 'Тесты',
                    children: [
                      { path: '/', element: <AdminUserCourseTest />, description: 'Тест' },
                      {
                        path: USER_COURSE_TEST_ATTEMPT,
                        element: <AdminUserCourseTestAttempt />,
                        description: 'Проверка теста',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          { path: '*', element: <Page404 />, description: 'Страница 404' },
        ],
      },
      { path: MODERATORS, element: <AdminModerators />, description: 'Страница модераторов' },
      { path: CURATORS, element: <AdminCurators />, description: 'Страница кураторов' },
      {
        path: MESSAGES,
        description: 'Страница сообщений',
        children: [
          { path: '/', element: <AdminChatsSupport />, description: 'Чаты службы поддержки' },
          {
            path: TEACHER_CHAT,
            element: <AdminChat />,
            description: 'Чат от имени преподавателя',
          },
          { path: SUPPORT_CHAT, element: <AdminChat />, description: 'Чат службы поддержки' },
          {
            path: SUPPORT_CHATS,
            element: <AdminChatsSupport />,
            description: 'Чаты службы поддержки',
          },
          {
            path: COMPANIES_CHATS,
            description: 'Чаты компаний',
            children: [
              { path: '/', element: <AdminChatsCompanies />, description: 'Список чатов' },
              {
                path: COMPANY_CHATS,
                element: <AdminCompanyChats />,
                description: 'Страница чата компании',
              },
            ],
          },
          {
            path: MASS_MAIL,
            element: <AdminMassMail />,
            description: 'Массовая рассылка сообщений',
          },
          {
            path: TEACHER_CHATS,
            element: <AdminChatsTeacher />,
            description: 'Чаты от имени преподавателя',
          },
          { path: CURATOR_CHATS, element: <AdminChatsCurator />, description: 'Чаты с кураторами' },
          { path: '*', element: <Page404 />, description: 'Страница 404' },
        ],
      },
      {
        path: SETTINGS,
        description: 'Страница настроек пользователя',
        children: [
          { path: '/', element: <AdminSettings />, description: 'Список настроек' },
          {
            path: SETTINGS_STATISTIC,
            element: <AdminSettingsStatistic />,
            description: 'Общая статистика',
          },
          {
            path: SETTINGS_AUTH,
            element: <AdminSettingsAuth />,
            description: 'Выбор пользователей для быстрой авторизации',
          },
          { path: SETTINGS_ROUTE_MAP, element: <AdminRouteMap />, description: 'Карта приложения' },
          { path: '*', element: <Page404 />, description: 'Страница 404' },
        ],
      },
      { path: '*', element: <Page404 />, description: 'Страница 404' },
    ],
  },
];

export default routes;

type TId = number | string | undefined;

/**
 * Функция генерирует ссылку на страницу курса в админке
 * @param courseId айди курса
 * @param destination подстраница курса
 * @returns ссылка на страницу курса
 */
export const generateCourseLink = (
  courseId: TId = '',
  destination:
    | typeof COURSE_INFO
    | typeof COURSE_CONTENT
    | typeof COURSE_MATERIALS
    | typeof COURSE_TESTS,
) => ROOT + COURSE_CHUNK + '/' + courseId + destination;

/**
 * Функция генерирует ссылку на страницу вопросов к тесту
 * @param courseId айди курса
 * @param testId айди теста
 * @returns
 */
export const generateCourseTestLink = (courseId: TId = '', testId: TId = '') =>
  ROOT + COURSE_CHUNK + '/' + courseId + COURSE_TESTS + '/' + testId;

/**
 * Функция генерирует ссылку на форму добавления материала к тесту
 * @param courseId айди курса
 * @param materialId айди материала
 * @returns ссылка на форму добавления материала к тесту
 */
export const generateAddMaterialToTestLink = (courseId: TId = '', materialId: TId = '') =>
  ROOT + COURSE_CHUNK + '/' + courseId + TEST_MATERIAL_CHUNK + '/' + materialId;

/**
 * Функция генерирует ссылку на страницу пользователя в админке
 * @param userId айди пользователя
 * @param destination подстраница пользователя
 * @returns ссылка на страницу пользователя
 */
export const generateUserLink = (
  userId: TId = '',
  destination: typeof USER_PROFILE | typeof USER_DEVICES | typeof USER_VISITINGS,
) => ROOT + USERS + '/' + userId + destination;

/**
 * Функция генерирует ссылку на страницу результатов попытки сдачи теста в статистике пользователя
 * @param userId айди пользователя
 * @param courseId айди курса
 * @param taskId айди теста
 * @param attemptId айди попытки сдачи теста
 * @returns ссылка на страницу курса в статистике пользователя
 */
export const generateUserTestPassingLink = (
  userId: TId = '',
  courseId: TId = '',
  taskId: TId = '',
  attemptId: TId = '',
) => {
  if (userId && courseId && taskId && attemptId)
    return (
      ROOT +
      USERS +
      '/' +
      userId +
      USER_COURSE_CHUNK +
      '/' +
      courseId +
      '/' +
      taskId +
      '/' +
      attemptId
    );

  if (userId && courseId && taskId)
    return ROOT + USERS + '/' + userId + USER_COURSE_CHUNK + '/' + courseId + '/' + taskId;

  if (userId && courseId) return ROOT + USERS + '/' + userId + USER_COURSE_CHUNK + '/' + courseId;
  if (userId) return ROOT + USERS + '/' + userId;
};

/**
 * функция генерирует ссылку на подробные результаты курса в статистике пользователя
 * @param userId айди пользователя
 * @param courseId айди курса
 * @returns ссылка
 */
export const generateUserCourseDetailsLink = (userId: TId, courseId: TId) =>
  ROOT + USERS + '/' + userId + USER_COURSE_CHUNK + '/' + courseId + '/' + USER_COURSE_DETAILS;

/**
 * Функция генерирует ссылку на чат
 * @param chatId айди чата
 * @returns ссылка на чат
 */
export const generateChat = (isSupport: boolean, chatId?: number) => {
  if (!chatId) return '/';
  const chatType = isSupport ? SUPPORT_CHAT_CHUNK : TEACHER_CHAT_CHUNK;
  return ROOT + MESSAGES + chatType + `/${chatId}`;
};
