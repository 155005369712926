import { useCallback, useEffect, useRef } from 'react';

import { IStructured } from '../../../utils/material';
import ClientMaterialItem from './ClientMaterialItem';
import { Scrollbars } from 'react-custom-scrollbars';
import { useMaterial } from '../store/useMaterial';

const Wrapper: React.FC<{ sidebar?: boolean }> = ({ children, sidebar }) => {
  const { id } = useMaterial();
  const scrollBarRef = useRef<Scrollbars>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const scrollToActive = useCallback(() => {
    const activeElement = containerRef.current?.querySelector(`#material-${id}`);

    if (!activeElement) return;

    const scrollTop = scrollBarRef.current?.scrollTop;

    if (scrollTop) {
      scrollTop(0);
      scrollTop(activeElement.getBoundingClientRect().top - 130);
    }
  }, [id]);

  useEffect(() => {
    scrollToActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return sidebar ? (
    <Scrollbars ref={scrollBarRef} style={{ height: window.innerHeight - 77 }}>
      <div className="pl-5" ref={containerRef}>
        {children}
      </div>
    </Scrollbars>
  ) : (
    <div>{children}</div>
  );
};

const ClientMaterialList: React.FC<{
  materials?: IStructured[];
  open(id: number): void;
  sidebar?: boolean;
}> = (props) => {
  return (
    <Wrapper sidebar={props.sidebar}>
      <ul className={`${props.sidebar ? 'mr-5' : 'mx-0'}`}>
        {props.materials?.map((m, i, self) => (
          <ClientMaterialItem
            sidebar={props.sidebar}
            key={m.id}
            item={m}
            open={(id) => props.open(id)}
            index={i + 1}
            isLast={i === self.length - 1}
            layer={0}
          />
        ))}
      </ul>
    </Wrapper>
  );
};

export default ClientMaterialList;
