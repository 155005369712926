import { useQuery } from 'react-query';
import { getCurrentUser } from '../../../api/common';
import ClientTemplate from '../templates/ClientTemplate';
import Chat from '../../common/containers/ChatContainer';
import { createWebsocketUrl, createUrlChat } from '../../../utils/link';

const ClientSupport: React.FC = () => {
  const { data: user } = useQuery('user', getCurrentUser);
  const urlChat = user && createUrlChat('support_chat', user.id);
  const wsUrl = urlChat && createWebsocketUrl(urlChat);

  return (
    <ClientTemplate>
      <div>{user && wsUrl && <Chat userId={user?.id} wsUrl={wsUrl} />}</div>
    </ClientTemplate>
  );
};

export default ClientSupport;
