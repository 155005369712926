import { useCallback, useEffect, useRef, useState } from 'react';

/** Компонент загрузки файлов при помощи манипуляции мыши или сенсорного экрана */
const DragAndDrop: React.FC<{
  /** обработчик загружаемых файлов */
  handleDrop(files: FileList): void;
}> = (props) => {
  const [drag, setDrag] = useState(false);
  const dropRef = useRef<HTMLLabelElement>(null);

  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(true);
  };

  const handleDragOut = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
  };

  const handleDrop = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDrag(false);
      if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
        props.handleDrop(e.dataTransfer.files);
        e.dataTransfer.clearData();
      }
    },
    [props],
  );

  useEffect(() => {
    const div = dropRef.current;

    div?.addEventListener('dragenter', handleDragIn);
    div?.addEventListener('dragleave', handleDragOut);
    div?.addEventListener('dragover', handleDrag);
    div?.addEventListener('drop', handleDrop);

    return () => {
      div?.removeEventListener('dragenter', handleDragIn);
      div?.removeEventListener('dragleave', handleDragOut);
      div?.removeEventListener('dragover', handleDrag);
      div?.removeEventListener('drop', handleDrop);
    };
  }, [handleDrop]);

  return (
    <div className="relative h-[232px] border border-dashed border-gray-pdf_header rounded w-full z-20 flex flex-col items-center">
      <label
        htmlFor="file_answer"
        className="cursor-pointer absolute w-full h-full bg-white"
        style={{ background: drag ? 'rgba(0,0,0,.1)' : 'transparent' }}
        ref={dropRef}
      />
      {props.children}
    </div>
  );
};

export default DragAndDrop;
