/**
 * @module chat
 * @category общие утилиты
 * @description утилиты по работе с чатами
 */

import { IChat } from '../typings/common';

/**
 * Функция сортирует чаты по компаниям
 * @param chats массив чатов
 * @param userId айди пользовател
 * @returns объект формата: {[companyId]: [chatId-1, chatId-2, chatId-3]}
 */
export const getChatCompanies = (chats?: IChat[], userId?: number) => {
  if (!chats || !userId) return {};
  const companiesChats: Record<string, number[]> = {};

  chats.forEach((chat) => {
    const company = chat.list_users?.find((user) => user.id !== userId);
    const companyId = company?.company;

    if (companyId) {
      if (companiesChats[companyId]) {
        companiesChats[companyId].push(Number(chat.id));
      } else {
        companiesChats[companyId] = [Number(chat.id)];
      }
    }
  });

  return companiesChats;
};

/**
 * Функция фильтрует массив чатов по наличию сообщений а также пользователей со статусом 2
 * @param chats массив чатов
 * @returns отфильтрованный массив чатов
 */
export const filterSupportChats = (chats?: IChat[]) =>
  chats?.filter((c) => c.num_messages && c.list_users?.find((user) => user.user_status === 2));
