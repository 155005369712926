import create from 'zustand';
import { IUserStatus } from '../../../typings/common';
import { TUsersFilter } from '../../../utils/array';

type TParamLabel = 'page' | 'page_size' | 'search' | 'company' | 'user_status' | 'ordering';

export type TPagination = {
  page: number;
  page_size: number;
  search: string;
  user_status: IUserStatus;
  company?: number;
  ordering?: string;
};

export interface IParams {
  params: TPagination;
  updateParam(paramLabel: TParamLabel, paramValue: number | string): void;
  reset(): void;
}

type TConfirmVisible = 'update' | 'delete' | undefined;

export interface INotifications {
  successRemovedUsers: string[];
  errorRemovedUsers: string[];
  successStatusUsers: string[];
  errorStatusUsers: string[];
  confirmVisible?: TConfirmVisible;

  setRemovedUser(user: string): void;
  setChangedStatus(user: string): void;
  setFailedRemoveUser(user: string): void;
  setFailedChangedStatus(user: string): void;
  setConfirmVisible(visible: TConfirmVisible): void;

  clearNotifications(): void;
}

export const useNotifications = create<INotifications>((set, get) => ({
  successRemovedUsers: [],
  errorRemovedUsers: [],
  successStatusUsers: [],
  errorStatusUsers: [],

  setRemovedUser: (user) => set({ successRemovedUsers: [...get().successRemovedUsers, user] }),
  setChangedStatus: (user) => set({ successStatusUsers: [...get().successStatusUsers, user] }),
  setFailedRemoveUser: (user) => set({ errorRemovedUsers: [...get().errorRemovedUsers, user] }),
  setFailedChangedStatus: (user) => set({ errorStatusUsers: [...get().errorStatusUsers, user] }),
  setConfirmVisible: (confirmVisible) => set({ confirmVisible }),

  clearNotifications: () =>
    set({
      successRemovedUsers: [],
      errorRemovedUsers: [],
      successStatusUsers: [],
      errorStatusUsers: [],
    }),
}));

const baseParams = { page: 1, page_size: 50, search: '', company: undefined, user_status: 2 };

const usersCheckDefaultParams = {
  ...baseParams,
  ordering: '-on_check,-course_count',
  page_size: 40,
};

const usersExamDefaultParams = {
  ...baseParams,
  ordering: '-last_passing_dt',
};

const usersDefaultParams = {
  ...baseParams,
  ordering: '-id',
};

const usersMassMailDefaultParams = {
  ...baseParams,
  ordering: '-id',
  page_size: 10,
};

export const useMassMailParams = create<IParams>((set) => ({
  params: usersMassMailDefaultParams,
  reset: () => set(() => ({ params: usersMassMailDefaultParams })),
  updateParam: (paramLabel, paramValue) => {
    return set((state) => {
      return {
        params: {
          ...state.params,
          [paramLabel]: paramValue,
        },
      };
    });
  },
}));

export const useUsersParams = create<IParams>((set) => ({
  params: usersDefaultParams,
  reset: () => set(() => ({ params: usersDefaultParams })),
  updateParam: (paramLabel, paramValue) => {
    return set((state) => {
      return {
        params: {
          ...state.params,
          [paramLabel]: paramValue,
        },
      };
    });
  },
}));

export const useUsersCheckParams = create<IParams>((set) => ({
  params: usersCheckDefaultParams,
  reset: () => set(() => ({ params: usersCheckDefaultParams })),
  updateParam: (paramLabel, paramValue) => {
    return set((state) => {
      return {
        params: {
          ...state.params,
          [paramLabel]: paramValue,
        },
      };
    });
  },
}));

export const useUsersExamParams = create<IParams>((set) => ({
  params: usersExamDefaultParams,
  reset: () => set(() => ({ params: usersExamDefaultParams })),
  updateParam: (paramLabel, paramValue) => {
    return set((state) => {
      return {
        params: {
          ...state.params,
          [paramLabel]: paramValue,
        },
      };
    });
  },
}));

export const useCheck = create<{ checked: number[]; setChecked: (checked: number[]) => void }>(
  (set) => ({
    checked: [],
    setChecked: (checked) => set({ checked }),
  }),
);

export const useOrdering = create<{
  isReverse: boolean;
  setIsReverse: (isReverse: boolean) => void;
  orderBy: TUsersFilter;
  setorderBy(orderBy: TUsersFilter): void;
  setOrdering(orderBy: TUsersFilter): void;

  borderIndexes: number[];
  setBorderIndexes(borderIndexes: number[]): void;
}>((set, get) => ({
  isReverse: false,
  setIsReverse: (isReverse) => set({ isReverse }),

  orderBy: 'ID',
  setorderBy: (orderBy) => set({ orderBy }),
  setOrdering: (orderName: TUsersFilter) => {
    const { orderBy, isReverse, setIsReverse, setorderBy } = get();
    const prevOrder = orderBy;
    const newOrder = orderName;
    if (prevOrder === newOrder && !isReverse) {
      setIsReverse(true);
    }
    if (prevOrder === newOrder && isReverse) {
      setIsReverse(false);
    }
    if (prevOrder !== newOrder && isReverse) {
      setIsReverse(false);
    }
    setorderBy(orderName);
  },

  borderIndexes: [],
  setBorderIndexes: (borderIndexes) => set({ borderIndexes }),
}));
