import { FaTimes } from 'react-icons/fa';
import { useMatch, Link } from '@tanstack/react-location';
import { useMutation } from 'react-query';
import Table from '../../../shared/Table';
import AdminCourseTemplate from '../templates/AdminCourseTemplate';
import { generateCourseLink } from '../routes';
import AdminTestForm from './AdminTestForm';
import { useEffect, useState } from 'react';
import { Checkbox } from '@mui/material';
import ConfirmModal from '../../../shared/ConfirmModal';
import { useForm, Controller } from 'react-hook-form';
import AdminEditTest from '../containers/AdminEditTest';
import { checkIsIntelleka } from '../../../utils/link';
import BadgeButton from '../../../shared/BadgeButton';
import { API } from '../../../api';
import {
  useChangeTest,
  useCreateTest,
  useRemoveTest,
  useTestsByCourseId,
} from '../../../cache/admin/task';
import { useCourseTests } from '../store/useTests';

const titles = [
  '№',
  'Варианты заданий',
  'Вопросов',
  'Ответы для зачета (%)',
  'Время прохождения',
  'Действия',
];

const AdminCourseTests: React.FC = () => {
  const match = useMatch();
  const courseId = match.params.id;
  const { openForm, toggleForm } = useCourseTests();
  const [confirmVisible, setConfirmVisible] = useState<number>();

  const { data, isFetching } = useTestsByCourseId(courseId);

  const { mutate: create, isLoading: creating } = useCreateTest(courseId);
  const { mutate: remove, isLoading: removing } = useRemoveTest(courseId);

  const isIntelleka = checkIsIntelleka();

  const tests = data
    ?.sort((a, b) => a.rank - b.rank)
    ?.map((t) => [
      <EditRank rank={t.rank} id={t.id} />,
      <Link to={generateCourseLink(courseId, '/tests') + '/' + t.id}>{t.title}</Link>,
      t.num_questions,
      `${t.passing}%`,
      t.travel_time,
      <div className="flex justify-end lg:justify-center items-center">
        <AdminEditTest test={t} />
        <FaTimes
          onClick={() => setConfirmVisible(t.id)}
          className={`ml-1 -mr-1 cursor-pointer ${
            isIntelleka ? 'hover:text-active_i' : 'hover:text-active_p'
          } transition-all`}
        />
        <ConfirmModal
          isIntelleka={isIntelleka}
          confirm={() => remove(t.id)}
          isOpen={t.id === confirmVisible}
          hide={() => setConfirmVisible(undefined)}
          loading={removing}
        >
          <div className="text-left">Вы действительно хотите удалить тест?</div>
        </ConfirmModal>
        <IsActive isActive={t.is_active} id={t.id} />
      </div>,
    ]);

  return (
    <AdminCourseTemplate>
      <div className="px-4 lg:px-0">
        <div className="mb-1">
          <BadgeButton onClick={() => toggleForm(true)}>Добавить задание</BadgeButton>
        </div>
        <AdminTestForm
          open={openForm}
          onClose={() => toggleForm(false)}
          submit={create}
          loading={creating}
        />
        <Table titles={titles} rows={tests} isLoading={!data && isFetching} />
      </div>
    </AdminCourseTemplate>
  );
};

export default AdminCourseTests;

const EditRank: React.FC<{ rank: number; id: number }> = (props) => {
  const { register, getValues, setValue, handleSubmit } = useForm<{ rank: number; id: number }>();

  const { mutate: changeRank, isLoading, isSuccess, reset } = useMutation(API.admin.test.change);

  useEffect(() => {
    isSuccess && setTimeout(() => reset(), 1000);
  }, [isSuccess, reset]);

  useEffect(() => {
    setValue('rank', props.rank);
    setValue('id', props.id);
  }, [props.rank, props.id, setValue]);

  const submit = () => changeRank({ id: props.id, rank: getValues('rank') });

  return (
    <form className="relative" onSubmit={handleSubmit(submit)}>
      <input
        {...register('rank')}
        disabled={isLoading}
        type="number"
        onBlur={submit}
        min={0}
        className={`${
          isLoading
            ? 'border-orange-400'
            : isSuccess
            ? 'border-green-700 shadow-input_success'
            : 'border-gray-medium'
        } transition-all duraion-300 outline-none max-w-[50px] align-middle text-[16px] py-[14px] px-[4px] max-h-[20px] border bg-[#f1f1f1]`}
      />
    </form>
  );
};

const IsActive: React.FC<{ isActive: boolean; id: number }> = (props) => {
  const { control, setValue, getValues } = useForm<{ is_active: boolean }>();

  useEffect(() => {
    props.isActive !== undefined && setValue('is_active', props.isActive);
  }, [props.isActive, setValue]);

  const { mutate: toggleActive, isLoading } = useChangeTest(() =>
    setValue('is_active', !getValues('is_active')),
  );

  return (
    <Controller
      control={control}
      render={({ field: { name, value } }) => (
        <Checkbox
          className={`${isLoading ? 'opacity-50' : 'opacity-100'}`}
          name={name}
          color="default"
          checked={Boolean(value)}
          onChange={() => toggleActive({ id: props.id, is_active: !value })}
        />
      )}
      name="is_active"
    />
  );
};
