import { useLocation } from '@tanstack/react-location';
import { getStructuredMaterials, IStructured } from '../../../utils/material';
import { ROOT, MATERIALS, TESTS } from '../routes';
import { useMaterial } from '../store/useMaterial';
import { getCurrentSectionMaterials } from '../../../utils/material';
import { useQuery } from 'react-query';
import Tooltip from '@mui/material/Tooltip';
import { russianEndings } from '../../../utils/string';
import { useCourseTasks } from '../store/useCourseTasks';
import { useTests } from '../store/useTests';
import star from '../../../img/star.svg';
import trophy from '../../../img/trophy.svg';
import { API } from '../../../api';
import ProgressbarPreloader from '../../../shared/ProgressbarPreloader';
import { useGetMaterials } from '../../../cache/client/material';
import { useCourse } from '../store/useCourse';

const Section: React.FC<{
  material: IStructured;
  isLastSection?: boolean;
  self?: IStructured[];
}> = ({ material, isLastSection, self }) => {
  const { materials } = useMaterial();
  const { data: passings } = useQuery('all-passings', () =>
    API.client.material_passing.getMaterialPassings(),
  );

  const { tasksByMaterial } = useCourseTasks();
  const { taskStatuses } = useTests();

  const sectionMaterials = getCurrentSectionMaterials(material, materials, self);
  const allPassedMaterials = passings?.filter((p) => p.status === 2).map((p) => p.material) || [];
  const currentSectionPassedMaterials = allPassedMaterials?.filter((m) =>
    sectionMaterials.includes(m),
  );

  const currentSectionTasks = sectionMaterials.map((id) => tasksByMaterial[String(id)]);

  const task = currentSectionTasks.find((t) => sectionMaterials.includes(t?.material));
  const taskStatus = taskStatuses && task ? taskStatuses[task.id] : null;
  const isTestPassed = taskStatus === 4;

  const passedMaterialsCount = currentSectionPassedMaterials
    ? currentSectionPassedMaterials.length > sectionMaterials.length
      ? sectionMaterials.length
      : currentSectionPassedMaterials.length
    : 0;

  const percents = Math.round((passedMaterialsCount / sectionMaterials.length) * 100);

  const isSectionStarted =
    !!currentSectionPassedMaterials && currentSectionPassedMaterials?.length > 0;

  const allMaterialsFinished = sectionMaterials.length === currentSectionPassedMaterials?.length;

  return (
    <div
      className={`flex items-center w-full justify-between ${
        !material.parent ? 'relative progress-section' : 'absolute'
      }`}
    >
      {!material.parent && !material.children && <div className="h-[5px] w-fullbg-gray-section" />}
      {!material.parent && (
        <>
          <div
            className="bg-gray-section h-[5px] absolute right-0"
            style={{ width: `${100 - percents}%` }}
          />
          <div
            className={`progress-block ${
              isSectionStarted
                ? 'bg-transparent'
                : !material.children?.length
                ? 'bg-transparent'
                : 'bg-gray-section'
            }`}
          />
        </>
      )}

      {material.children?.map((m) => (
        <Section key={m.id} material={m} />
      ))}
      {isLastSection && (
        <div
          className={`progress-block ${allMaterialsFinished ? 'bg-[#ff6041]' : 'bg-gray-section'}`}
        />
      )}
      {!material.parent && task && (
        <div className="absolute z-20 -top-6 right-[-8.5px]">
          <Tooltip
            arrow
            title={
              <div className="text-sm text-center">
                <div>{task.is_final ? 'Итоговое' : 'Промежуточное'}</div>
                <div className="whitespace-nowrap">
                  тестирование {!isTestPassed && 'не'} пройдено
                </div>
              </div>
            }
            placement="top"
          >
            <img
              src={task?.is_final ? trophy : star}
              alt="test-trophy"
              className={`w-[13px] h-[13px] ${isTestPassed ? 'grayscale-0' : 'grayscale'}`}
            />
          </Tooltip>
        </div>
      )}
      {!material.parent && (
        <div className="z-20 absolute top-4 justify-end right-[-10px] text-[15px] text-gray-dark">
          <Tooltip
            arrow
            title={
              <div className="whitespace-nowrap text-center text-sm">
                Прочитано {passedMaterialsCount}
                <span className="px-1">
                  {russianEndings(passedMaterialsCount, ['тема', 'темы', 'тем'])}
                </span>
                из {sectionMaterials.length}
              </div>
            }
          >
            <div className="flex">
              <div>{passedMaterialsCount}</div>
              <div>/</div>
              <div>{sectionMaterials.length}</div>
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

const ClientStudingProgress: React.FC = () => {
  const { course } = useCourse();
  const { isFetched, isLoading, data: materials } = useGetMaterials(course);

  const location = useLocation();

  if (location.current.pathname !== ROOT + MATERIALS && location.current.pathname !== ROOT + TESTS)
    return null;

  const structured = getStructuredMaterials(materials || []);

  return (
    <div className="h-[83px] border-b border-b-gray-dark_opacity pt-3 px-6">
      {isLoading || (!isFetched && !isLoading) ? (
        <ProgressbarPreloader />
      ) : isFetched && !materials?.length ? (
        <div className="text-gray-500 pt-4 italic">В этом курсе пока нет материалов</div>
      ) : (
        <div className="relative w-full h-[13px] my-5">
          <div className="bg-gradient-to-r from-yellow-default to-active_p h-full w-full" />
          <div className="flex h-full absolute top-0 w-full">
            {structured?.map((m, i, self) => {
              const isLastSection = i === self.length - 1;
              return <Section key={m.id} material={m} isLastSection={isLastSection} self={self} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientStudingProgress;
