import { useEffect } from 'react';
import { useKey } from 'react-use';

/** Компонент модального окна */
const Modal: React.FC<{
  /** состояние окна */
  open: boolean;
  /** колбек при закрытии */
  onClose?(): void;
  /** класс для стилизации */
  className?: string;
}> = ({ open, onClose = () => null, children, className = '' }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.removeAttribute('style');
    }
  }, [open]);

  useKey('Escape', onClose);

  return open ? (
    <div className="fixed inset-0 z-50 bg-[rgba(0,0,0,.5)] overflow-auto">
      <div className={`w-full bg-white m-auto ${className}`}>{children}</div>
    </div>
  ) : null;
};

export default Modal;
