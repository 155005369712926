const Direct: React.FC<{ filled: boolean; isCurrent: boolean; isLast: boolean }> = (props) => (
  <div className="flex items-center h-[50px] w-[100px]">
    <div
      className={`border-yellow-default rounded-full w-[14px] h-[14px] min-w-[14px] relative border ${
        props.filled ? 'border-solid' : 'bg-white border-dashed'
      } ${
        props.isCurrent ? 'bg-[#ffcd0545]' : props.filled ? 'bg-yellow-default' : 'bg-transparent'
      } after:content-[''] after:absolute after:left-0 after:top-0 after:w-full after:h-full after:rounded-full`}
    />
    {!props.isLast && <div className="w-full border-b border-dashed border-yellow-default" />}
  </div>
);

const Curve: React.FC<{ filled: boolean; isCurrent: boolean; isLast: boolean; index: number }> = (
  props,
) => (
  <div
    className={`flex items-center h-[50px] w-[20px] ${
      props.index % 2 ? 'transform -rotate-45' : 'transform rotate-45'
    }`}
  >
    <div
      className={`border-yellow-default rounded-full w-[14px] h-[14px] min-w-[14px] absolute -ml-3 border ${
        props.filled ? 'border-solid' : 'bg-white border-dashed'
      } ${
        props.isCurrent ? 'bg-[#fff3bb]' : props.filled ? 'bg-yellow-default' : 'bg-white'
      } after:content-[''] after:absolute after:left-0 after:top-0 after:w-full after:h-full after:rounded-full`}
    />
    {!props.isLast && <div className="w-full border-b border-dashed border-yellow-default" />}
  </div>
);

/** Прогрессбар прохождения теста */
export const TestProgress: React.FC<{
  questions: number[];
  answeredQuestions: number[];
  current?: number;
}> = (props) => (
  <div className="flex">
    {props.questions.map((question, index, self) => {
      const filled = props.answeredQuestions.includes(question);
      const isCurrent = props.current === question;
      const isLast = index === self.length - 1;

      return props.questions.length > 40 ? (
        <Curve filled={filled} index={index} isCurrent={isCurrent} isLast={isLast} />
      ) : (
        <Direct filled={filled} isCurrent={isCurrent} isLast={isLast} />
      );
    })}
  </div>
);

export default TestProgress;
