import Select from 'react-select/creatable';
import type { CreatableProps } from 'react-select/creatable';
import type { StylesConfig } from 'react-select';

import {
  theme_color_active_light_p,
  theme_color_active_light_i,
  theme_color,
  theme_color_i,
  theme_shadow_input_i,
  theme_shadow_input_p,
} from '../constants';

const CreatableSelect: React.FC<CreatableProps<any, boolean, any> & { isIntelleka?: boolean }> = ({
  isIntelleka = false,
  ...props
}) => {
  const theme_light = isIntelleka ? theme_color_active_light_i : theme_color_active_light_p;
  const theme = isIntelleka ? theme_color_i : theme_color;
  const theme_shadow = isIntelleka ? theme_shadow_input_i : theme_shadow_input_p;

  const styles: StylesConfig = {
    control: (base, state) => ({
      ...base,
      boxShadow: state.isFocused ? theme_shadow : 'none',
      borderColor: state.isFocused ? theme : '#ced4da',
      '&:hover': { border: `1 px solid ${theme}` },
    }),
    option: (base, state) => ({
      ...base,
      background: state.isFocused ? theme_light : '#fff',
      color: '#000',
      '&:hover': { background: theme_light, cursor: 'pointer' },
    }),
  };

  return <Select {...props} styles={styles} />;
};

export default CreatableSelect;
