import Modal from '@mui/material/Modal';
import { uniq } from 'lodash';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useKey } from 'react-use';
import { ICourse, ITestPassing } from '../../../typings/admin';
import Table from '../../../shared/Table';
import { convertLocalTimeToFormat } from '../../../utils/time';
import { API } from '../../../api';
import { Link } from '@tanstack/react-location';
import { generateUserLink } from '../routes';
import { checkIsIntelleka } from '../../../utils/link';
import { ThemeButton } from '../../../shared/Button';

export const STATISTIC_TYPES = {
  REGISTERED: 'REGISTERED',
  VISITED: 'VISITED',
  COMPLETED: 'COMPLETED',
};

export const UserLink: React.FC<{ id: number }> = ({ children, id }) => {
  return (
    <Link to={generateUserLink(id, '/')} data-testid="user-link">
      {children}
    </Link>
  );
};

const UsersTable: React.FC<{ courseId: number; type: string }> = (props) => {
  const { data: dataUsers, isLoading } = useQuery(['users', props.courseId], () =>
    API.admin.user.all({ courses__id: String(props.courseId) }),
  );
  const { data: courseActivity } = useQuery(['activity', 'course', props.courseId], () =>
    API.admin.activity.visitings({ course_id: props.courseId }),
  );
  const { data: courseSuccessPassings } = useQuery(['success_passings', props.courseId], () =>
    API.admin.test_passing.all({
      success_passed: '0',
      task__material__course: String(props.courseId),
    }),
  );

  const users = dataUsers?.results;
  const usersEntered = uniq(courseActivity?.map((el) => el.user));
  const courseFinalSuccessPassings = courseSuccessPassings?.filter(
    (passing: ITestPassing) => passing.is_final_task,
  );
  const passedUsers = uniq(
    courseFinalSuccessPassings?.map((passing: ITestPassing) => passing.user),
  );

  const getUsers = () => {
    switch (props.type) {
      case STATISTIC_TYPES.VISITED:
        return users?.filter((user) => usersEntered?.includes(user.id || 0));
      case STATISTIC_TYPES.COMPLETED:
        return users?.filter((user) => passedUsers?.includes(user.id || 0));
      default:
        return users;
    }
  };

  const notPassedText = <div className="text-gray-500">Не сдавал</div>;
  const getTestRate = (response_rate: string, success_passed: number) => (
    <div className={`${success_passed === 0 ? 'text-green-600' : 'text-red-600'}`}>
      {response_rate}
    </div>
  );

  const getResponseRate = (
    last_test_passings?: {
      is_final_task: boolean;
      response_rate: string;
      success_passed: number;
    }[],
  ) => {
    if (!last_test_passings || !last_test_passings.length) return notPassedText;
    const finalPassings = last_test_passings.filter((passing) => passing.is_final_task);
    return finalPassings.length
      ? getTestRate(finalPassings[0].response_rate, finalPassings[0].success_passed)
      : notPassedText;
  };

  const titles = ['ФИО', 'Логин', 'Компания', 'Дата регистрации', 'Экзамен'];

  const rows = getUsers()?.map((user) => [
    <UserLink id={user.id}>{user.first_name}</UserLink>,
    <UserLink id={user.id}>{user.username}</UserLink>,
    <>{user.company_title}</>,
    <>{user.date_joined ? convertLocalTimeToFormat(user.date_joined, 'DD.MM.YYYY') : '-'}</>,
    <>{getResponseRate(user.last_test_passings)}</>,
  ]);

  return <Table titles={titles} rows={rows} isLoading={isLoading} />;
};

const Statistic: React.FC<{ value: number; type: string; courseId: number }> = ({
  value,
  type,
  courseId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const renderTitle = () => {
    switch (type) {
      case STATISTIC_TYPES.VISITED:
        return 'Посещающие курс';
      case STATISTIC_TYPES.COMPLETED:
        return 'Завершившие курс';
      default:
        return 'Зарегистрированы на курс';
    }
  };

  useKey('Escape', () => setIsOpen(false));
  const isIntelleka = checkIsIntelleka();

  return (
    <>
      <div
        onClick={() => (!!value ? toggle() : null)}
        className={`${value ? 'text-blue-500 cursor-pointer' : 'text-black-default'}`}
      >
        {value || 0}
      </div>
      <Modal open={isOpen} onClose={toggle}>
        <div className="top-0 sm:top-10 outline-none w-full max-w-[900px] absolute left-0 right-0 bottom-0 m-auto">
          <div className="bg-white rounded-t p-5">{renderTitle()}</div>
          <div>
            {isOpen && (
              <div className="h-auto max-h-[calc(100vh-240px)] bg-white overflow-auto px-5">
                <UsersTable courseId={courseId} type={type} />
              </div>
            )}
          </div>
          <div className="flex justify-end bg-white rounded-b p-5">
            <ThemeButton onClick={toggle} isIntelleka={isIntelleka}>
              Закрыть
            </ThemeButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

const AdminCourseStatistic: React.FC<{ course: ICourse }> = ({ course }) => (
  <div className="flex justify-center">
    <Statistic
      value={course.registered_num}
      type={STATISTIC_TYPES.REGISTERED}
      courseId={course.id}
    />
    /
    <Statistic value={course.visit_num} type={STATISTIC_TYPES.VISITED} courseId={course.id} />/
    <Statistic value={course.completed_num} type={STATISTIC_TYPES.COMPLETED} courseId={course.id} />
  </div>
);

export default AdminCourseStatistic;
