import { useMatch } from '@tanstack/react-location';
import { keyBy } from 'lodash';
import { useQuery } from 'react-query';
import { getStructuredMaterials } from '../../../utils/material';
import AdminUserCourseTemplate from '../templates/AdminUserCourseTemplate';
import AdminMaterialItem from '../containers/AdminMaterialItem';
import RowPreloader from '../../../shared/RowPreloader';
import { API } from '../../../api';

const AdminUserCourseDetails: React.FC = () => {
  const { params } = useMatch();
  const { id, courseId } = params;

  const { data: materials, isLoading: loadingMaterials } = useQuery(
    ['materials', `course-${courseId}`],
    () => API.admin.material.all({ course: courseId, ordering: 'rank' }),
    {
      enabled: !!courseId,
    },
  );

  const { data: passings } = useQuery(
    ['material-passings', `course-${courseId}`],
    () => API.admin.material_passing.all({ material__course: courseId, user: id }),
    {
      enabled: !!courseId,
    },
  );

  const { data: tests } = useQuery(
    ['tests', `course-${courseId}`],
    () => API.admin.test.all({ material__course: courseId }),
    {
      enabled: !!courseId,
    },
  );

  const materialsPassingByMaterialId = keyBy(passings, 'material');
  const testsByMaterialId = keyBy(tests, 'material');

  const structured = materials && getStructuredMaterials(materials);

  return (
    <AdminUserCourseTemplate>
      {loadingMaterials ? (
        <RowPreloader />
      ) : (
        structured?.map((m) => (
          <AdminMaterialItem
            key={m.id}
            item={m}
            materialsPassingByMaterialId={materialsPassingByMaterialId}
            testsByMaterialId={testsByMaterialId}
          />
        ))
      )}
    </AdminUserCourseTemplate>
  );
};

export default AdminUserCourseDetails;
