import { useState } from 'react';
import { useCheckCourses } from '../store/useCheckCourses';
import { FormControlLabel, Checkbox } from '@mui/material';

const CuratorCourses: React.FC<{ courses: { id: number; title: string }[] }> = (props) => {
  const [isFull, setIsFull] = useState(false);
  const { checked, checkCourse } = useCheckCourses();

  const minCoursesLength = 5;

  return (
    <div className="pl-9 mt-4 h-[500px] overflow-auto mb-5">
      {!!props.courses.length && <div className="font-bold mb-2">Курсы</div>}
      {props.courses.map((course, index, self) => {
        const prevCourse = index ? self[index - 1] : null;
        const prevCourseLetter = prevCourse ? prevCourse.title.slice(0, 1) : '';
        const firstLetter = course.title.slice(0, 1);
        return (
          <div key={course.id}>
            {prevCourseLetter !== firstLetter && isFull && (
              <div className="alphabet-letter">{firstLetter.toUpperCase()}</div>
            )}
            <FormControlLabel
              className="flex items-baseline mb-1"
              style={{ alignItems: 'flex-start' }}
              control={
                <Checkbox
                  color="default"
                  style={{ marginTop: -9 }}
                  checked={!checked || checked?.includes(course.id)}
                  onChange={() => checkCourse(course.id)}
                  value={course.id}
                />
              }
              label={course.title}
            />
          </div>
        );
      })}
      {props.courses.length > minCoursesLength && (
        <div
          onClick={() => setIsFull(!isFull)}
          className="cursor-pointer mt-1 px-4 text-blue-500 hover:text-blue-400 transition"
        >
          {isFull ? 'Свернуть' : 'Показать все'}
        </div>
      )}
    </div>
  );
};

export default CuratorCourses;
