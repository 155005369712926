import axios from 'axios';
import {
  IMaterial,
  IMaterialCopy,
  IMaterialsParams,
  TCreateMaterial,
  TUpdateMaterial,
} from '../../typings/admin';
import { toFormData } from '../../utils/api';

export async function all(params?: IMaterialsParams) {
  try {
    const { data } = await axios.get<IMaterial[]>(`/api/v1/courses/material/`, { params });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function update(id: number, model: TUpdateMaterial) {
  try {
    const { data } = await axios.patch<IMaterial>(
      `/api/v1/courses/material/${id}/`,
      toFormData(model),
    );

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updatePosition(id: number, model: TUpdateMaterial) {
  try {
    const { data } = await axios.patch<IMaterial>(`/api/v1/courses/material/${id}/`, model);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function create(model: TCreateMaterial) {
  try {
    const { data } = await axios.post<IMaterial>('/api/v1/courses/material/', toFormData(model));

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function remove(id: number) {
  try {
    const { data } = await axios.delete(`/api/v1/courses/material/${id}/`);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function copy(model: IMaterialCopy) {
  try {
    const { data } = await axios.post<IMaterial>(`/api/v1/courses/material/copy/`, model);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function copyFull(model: IMaterialCopy) {
  try {
    const { data } = await axios.post<IMaterial>('/api/v1/courses/material/copy/full/', model);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function copySingle(model: IMaterialCopy) {
  try {
    const { data } = await axios.post<IMaterial>('/api/v1/courses/material/copy/single/', model);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
