import { useAuth } from '../../store/useAuth';
import { TFormProps } from '../../typings/auth';
import { useAuthRedirects } from './useAuthRedirects';

const Form: React.FC<TFormProps> = ({ onLogin, children, ...props }) => {
  const { submit } = useAuth();
  const { onSuccess } = useAuthRedirects(onLogin);

  return (
    <form
      onSubmit={props.handleSubmit((data) => submit(data, onSuccess))}
      className="flex items-center absolute rounded w-full max-w-[300px] sm:max-w-[418px] sm:w-[418px] h-[410px] border-0 focus:outline-none m-auto left-0 right-0 top-0 bottom-0 bg-white"
    >
      <div className="w-full max-w-[265px] m-auto">
        <h1 className="text-black-default text-[29px] text-center block mb-9">Вход</h1>
        {children}
      </div>
    </form>
  );
};

export default Form;
