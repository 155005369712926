import create from 'zustand';

type TParamLabel = 'page' | 'page_size' | 'search';

export type TPagination = {
  page: number;
  page_size: number;
  search: string;
};

export interface IParams {
  params: TPagination;
  updateParam(paramLabel: TParamLabel, paramValue: number | string): void;
  reset(): void;
}

const defaultParams = {
  page: 1,
  page_size: 50,
  search: '',
};

export const useUsersParams = create<IParams>((set) => ({
  params: defaultParams,
  reset: () => set(() => ({ params: defaultParams })),
  updateParam: (paramLabel, paramValue) => {
    return set((state) => {
      return {
        params: {
          ...state.params,
          [paramLabel]: paramValue,
        },
      };
    });
  },
}));
