import BadgeButton from '../../../shared/BadgeButton';
import AdminQuestionForm from './AdminQuestionForm';

import { useCreateQuestion } from '../../../cache/admin/question';
import { useQuestions } from '../store/useQuestions';
import { useEffect } from 'react';

const AdminAddQuestion: React.FC<{ questionsKey: string[] }> = (props) => {
  const { open, toggle, setError } = useQuestions();
  const { mutate: create, isLoading } = useCreateQuestion(props.questionsKey);

  useEffect(() => {
    open && setError('');
  }, [open, setError]);

  return (
    <>
      <BadgeButton onClick={() => toggle(true)}>Добавить вопрос</BadgeButton>
      <AdminQuestionForm
        open={open}
        onClose={() => toggle(false)}
        submit={create}
        loading={isLoading}
      />
    </>
  );
};

export default AdminAddQuestion;
