/** Срок хранения кеша в интерфейсе авторизации */
export const AUTH_SLATE_TIME = 300000;

/** константа окружения дев режима */
export const isDev = process.env.NODE_ENV === 'development';

/** константа окружения режима прогона тестов */
export const isTest = process.env.NODE_ENV === 'test';

/** константа окружения при использовании мок сервера */
export const isMockServer = process.env.REACT_APP_BACKEND === 'mock';

/**
 * константа рендера количества частиц 404 на странице PageNotFound
 */
export const PARTICLES_NUM = 80;

/**
 * константа, определяющая домены интеллеки
 * ```tsx
 * const intellekaDomains = ['lk.intelleka.ru', 'lms.space-on.space'];
 * ```
 * */
export const intellekaDomains = ['lk.intelleka.ru', '', 'intelleka.promr-group.pp.ua', ''];

/**
 * Домены прода
 */
export const prod_hosts = ['lk.intelleka.ru', 'lk.promrg.ru'];

/**
 * Константа определяет, находиться ли текущая сборка на прод домене
 */
export const isCurrentBuildProd = prod_hosts.includes(window.location.host);

/** фирменные цвета промреcурса */
export const theme_color = '#ff6041';
export const theme_color_active_light_p = '#ff604124';
export const theme_shadow_input_p = '0 0 0 .2rem #ff604140';

/** фирменные цвета интеллеки */
export const theme_color_i = '#035096';
export const theme_color_active_light_i = 'rgba(3, 84, 139, 0.18)';
export const theme_shadow_input_i = '0 0 0 0.2rem rgba(3, 84, 139, 0.4)';

/** количество секунд в сутках */
export const daySeconds = 86400;

/** количество милисекунд в сутках */
export const dayMilliseconds = daySeconds * 1000;

/** Id подсказки закладок в интерфейсе пользователя */
export const YOUR_BOOKMARKS_TOOLTIP = 'your-bookmarks-tooltip';

/** Id подсказки добавления закладки в интерфейсе пользователя */
export const ADD_BOOKMARK_TOOLTIP = 'add-bookmark-tooltip';

/** цвет подсказок */
export const TOOLTIP_BG = 'rgba(97, 97, 97, 0.95)';

/** Id подсказки свертывания бокового меню */
export const TOOGLE_MATERIAL_SIDEBAR = 'toggle-material-sidebar';

/** Id подсказки полноэкранного режима */
export const FULLSCREEN_MATERIAL = 'fullscreen-material';

/** Срок жизни куков в браузере */
export const coockiesCacheDays = 30;
