// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

/**
 * @module dom
 * @category общие утилиты
 * @description утилиты по работе с DOM-элементами
 */

/**
 * функция проверяет видимость дом-элемента на экране
 * @param element дом-элемент
 * @returns значение true, если элемент виден или false если он за пределами экрана
 */
export const isElementVisibleOnScreen = (element: Element) => {
  // Все позиции элемента
  const targetPosition = {
      top: window.pageYOffset + element.getBoundingClientRect().top,
      left: window.pageXOffset + element.getBoundingClientRect().left,
      right: window.pageXOffset + element.getBoundingClientRect().right,
      bottom: window.pageYOffset + element.getBoundingClientRect().bottom,
    },
    // Получаем позиции окна
    windowPosition = {
      top: window.pageYOffset,
      left: window.pageXOffset,
      right: window.pageXOffset + document.documentElement.clientWidth,
      bottom: window.pageYOffset + document.documentElement.clientHeight,
    };

  if (
    targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней части окна, то элемент виден сверху
    targetPosition.top < windowPosition.bottom && // Если позиция верхней части элемента меньше позиции нижней части окна, то элемент виден снизу
    targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
    targetPosition.left < windowPosition.right
  ) {
    // Если позиция левой стороны элемента меньше позиции правой части окна, то элемент виден справа
    // Если элемент полностью видно, то запускаем следующий код
    return element;
  } else {
    // Если элемент не видно, то запускаем этот код
    return null;
  }
};

/**
 * Функция измеряет вертикальную прокрутку элемента от верха страницы
 * и выполняет коллбек в определенной позиции скрола
 * @param elementId айди элемента, для которого измеряется прокрутка
 * @param scrollPosition позиция скрола по вертикали, на которой должен сработать коллбек
 * @param setFixed колбек
 */
export const scrollMeasuring = (
  elementId: string,
  scrollPosition: number,
  setFixed: (isFixed: boolean) => void,
) => {
  const onScroll = () => {
    const element = document.getElementById(elementId);
    if (!element) return;
    const offset = element?.getBoundingClientRect().top;
    if (offset && offset < scrollPosition) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };

  window.addEventListener('scroll', onScroll);

  return () => {
    window.removeEventListener('scroll', onScroll);
  };
};

export const toggleFullScreen = (element: HTMLElement | null) => {
  const isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;

  element.requestFullScreen =
    element.requestFullScreen ||
    element.webkitRequestFullScreen ||
    element.mozRequestFullScreen ||
    (() => false);
  document.cancelFullScreen =
    document.cancelFullScreen ||
    document.webkitCancelFullScreen ||
    document.mozCancelFullScreen ||
    (() => false);

  isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
};
