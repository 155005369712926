import { factory, primaryKey } from '@mswjs/data';
import { CHAT_MOCK, COURSE_MOCK } from '../admin';
import { USER_MOCK } from '../common';

export const database = factory({
  course: {
    id: primaryKey(Date.now),
    title: String,
    description: String,
    teacher: String,
    author: Number,
    author_name: String,
    moderators: Array,
    tag: Array,
    users: Array,
    created: String,
    registered_num: Number,
    visit_num: Number,
    completed_num: Number,
    is_active: Boolean,
  },
  chat: {
    id: primaryKey(Date.now),
    chat_status: Number,
    users: Array,
    course: Number,
    company: Number,
    num_messages: Number,
    list_users: Array,
    last_message_date: String,
  },
  time: { server_time: String, id: primaryKey(Date.now) },
  user: {
    id: primaryKey(Date.now),
    username: String,
    first_name: String,
    last_name: String,
    description: String,
    design: Number,
    webinars: Boolean,
    password: String,
    old_password: String,
    user_status: Number,
    is_active: Boolean,
    company: Number,
    company_title: String,
    curator_company: Array,
    time_limitation: String,
    date_joined: String,
    courses: Array,
    start_course: String,
    last_passing_dt: String,
    end_course: String,
    teacher_chat: Boolean,
    group_chat: Boolean,
    tech_chat: Boolean,
    on_check: Number,
    finish_time_exam: String,
    company_name: String,
    course_id: Number,
    last_test_passings: Array,
  },
});

database.course.create({ ...COURSE_MOCK, id: 1 });

database.user.create({
  ...USER_MOCK,
  id: 1,
  username: 'user-1',
  date_joined: '2022-07-05T10:26:23.110528Z',
});
database.user.create({
  ...USER_MOCK,
  id: 2,
  username: 'user-2',
  date_joined: '2022-07-05T10:26:23.110528Z',
});
database.user.create({
  ...USER_MOCK,
  id: 3,
  username: 'user-3',
  date_joined: '2022-07-05T10:26:23.110528Z',
});

database.chat.create({ ...CHAT_MOCK, id: 1 });
database.chat.create({
  ...CHAT_MOCK,
  id: 2,
  list_users: [{ ...CHAT_MOCK.list_users[0], username: 'user2' }],
  last_message_date: '2022-07-05T10:26:23.110528Z',
});

database.time.create({ server_time: '2022-07-26T20:20:12.551399' });
