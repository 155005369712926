import { times } from 'lodash';
import { PARTICLES_NUM } from '../../../constants';
import Particle from '../../../shared/Particle';
import styled from 'styled-components';
import { useNavigate } from '@tanstack/react-location';
import { checkIsIntelleka } from '../../../utils/link';

const Animation = styled.article`
  @keyframes apparition {
    from {
      opacity: 0;
      transform: translateY(100px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const ContentWrapper = styled(Animation)`
  position: relative;
  width: 600px;
  max-width: 100%;
  margin: 20px;
  background: white;
  padding: 60px 40px;
  text-align: center;
  box-shadow: -10px 10px 67px -12px rgba(0, 0, 0, 0.2);
  opacity: 0;
  animation: apparition 0.8s 1.2s cubic-bezier(0.39, 0.575, 0.28, 0.995) forwards;
`;

export const Text = styled.p`
  font-size: 1.3rem;
  margin-top: 0;
  margin-bottom: 0.6rem;
  letter-spacing: 0.1rem;
`;

export const Button = styled.button`
  display: inline-block;
  margin-top: 2rem;
  padding: 0.5rem 1rem;
  background: transparent;
  font-size: 1rem;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
`;

const Page404: React.FC = () => {
  const navigate = useNavigate();
  const isIntelleka = checkIsIntelleka();

  return (
    <div
      className={`relative flex items-center justify-center h-[calc(100vh-188px)] bg-white overflow-hidden ${
        isIntelleka ? 'text-active_i' : 'text-active_p'
      }`}
    >
      {times(PARTICLES_NUM).map((_, index) => (
        <Particle key={index}>{index < 45 ? 4 : 0}</Particle>
      ))}
      <ContentWrapper>
        <h1 className="text-[40px]">404</h1>
        <Text>Страница не найдена.</Text>
        <Button
          className={`border ${
            isIntelleka ? 'border-active_i hover:bg-active_i' : 'border-active_p hover:bg-active_p'
          } hover:text-white transition-all duration-300`}
          onClick={() => navigate({ to: '/' })}
        >
          Вернуться на главную
        </Button>
      </ContentWrapper>
    </div>
  );
};

export default Page404;
