/**
 * @module object
 * @category общие утилиты
 * @description утилиты по работе с объектами
 */

/**
 * Функция очищает объект от полей со значением (null, undefined)
 * @param obj объект
 * @returns объект, очищенный от полей со значенийм null и undefined
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cleanObject = (obj?: { [x: string]: any }): any => {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
      delete obj[propName];
    }
  }
  return obj;
};

/**
 * Функция проверяет параметры на предмет значений null и undefined и очищает от них
 * @param params объект параметров
 * @returns Возвращает параметры без null и undefined
 * или false, если параметров со значениями нету
 */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
export function existParams(params?: { [key: string]: any }) {
  if (!params) {
    return false;
  }

  const filteredParams = Object.fromEntries(Object.entries(params).filter(([_, v]) => Boolean(v)));

  if (Object.keys(filteredParams).length === 0) {
    return false;
  }

  return filteredParams;
}

/**
 * Функция считает количество полей в объекте
 * @param obj объект
 * @returns возвращает кол-во полей в объекте
 */
/* eslint-disable @typescript-eslint/ban-types */
export const getObjectSize = (obj: Object) => {
  let size = 0,
    key;
  for (key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) size++;
  }
  return size;
};

/**
 * Функция преобразует объект в массив
 * @param object объект
 * @returns массив, преобразованный из объекта
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const objectToArray = (object: Record<string, any>) => {
  return object ? Object.keys(object).map((item) => object[item]) : [];
};

/**
 * Функция возвращает значение из объекта по ключу
 * @param object объект
 * @param key ключ
 * @returns значение из объекта по ключу
 */
export const getValueByKey = (object: Record<string, any>, key: string) => {
  const title = Object.keys(object).find((k) => k.localeCompare(key) === 0);
  return title ? object[title] : 0;
};
