import React from 'react';
import { times } from 'lodash';

type TElement = string | number | React.ReactElement;

/** Компонент таблицы */
const Table: React.FC<{
  titles: TElement[];
  rows?: TElement[][];
  isLoading?: boolean;
  borderIndexes?: number[];
}> = (props) => {
  if (!props.rows?.length && !props.isLoading)
    return <div className="text-gray-500 italic pt-4 text-center">Нет данных</div>;
  return (
    <div className="block w-full overflow-hidden">
      <table className="w-full text-center">
        <thead>
          <tr>
            {props.titles.map((t, i) => (
              <th key={i} className={`p-3 max-w-[140px] ${i === 0 ? 'text-left' : 'text-center'}`}>
                {t}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.isLoading
            ? times(10, Number).map((_, i) => (
                <tr key={i} className="row-preloader">
                  {props.titles.map((_, i) => (
                    <td key={i} />
                  ))}
                </tr>
              ))
            : props.rows?.map((el, i) => {
                return (
                  <tr
                    key={i}
                    className={`${i % 2 === 0 ? 'bg-gray-table_odd' : 'bg-white'} ${
                      props.borderIndexes?.includes(i) ? 'border-b-gray-500 border-b-[3px]' : ''
                    } ${
                      !!props.borderIndexes?.length && i === 0
                        ? 'border-t-gray-500 border-t-[3px]'
                        : ''
                    }`}
                  >
                    {el.map((el, i) => (
                      <td
                        data-label={
                          typeof props.titles[i] === 'object'
                            ? // @ts-ignore
                              props.titles[i].props?.children
                            : props.titles[i]
                        }
                        key={i}
                        className={`flex justify-between md:table-cell p-3 max-w-[140px] ${
                          i === 0 ? 'text-left' : 'text-center'
                        }`}
                      >
                        {el}
                      </td>
                    ))}
                  </tr>
                );
              })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
