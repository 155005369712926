import create from 'zustand';

type ICheckedCourses = {
  checked: number[];
  checkCourse(courseId: number): void;
  reset(): void;
  checkAllCourses(checked: number[]): void;
};

export const useCheckCourses = create<ICheckedCourses>((set) => ({
  checked: [],
  reset: () => set(() => ({ checked: [] })),
  checkCourse: (courseId) => {
    return set((state) => ({
      checked: state.checked.includes(courseId)
        ? state.checked.filter((id) => courseId !== id)
        : [...state.checked, courseId],
    }));
  },
  checkAllCourses: (checked) => set({ checked }),
}));
