import axios from 'axios';
import {
  IOutOfTimeTestPassing,
  ITestPassing,
  ITestPassingParams,
  TUpdateTestPassing,
} from '../../typings/admin';

export async function all(params?: ITestPassingParams) {
  try {
    const { data } = await axios.get<ITestPassing[]>(`/api/v1/courses/test_passing/`, { params });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function change({ id, ...model }: TUpdateTestPassing) {
  try {
    const { data } = await axios.patch<IOutOfTimeTestPassing>(
      `/api/v1/courses/test_passing/${id}/`,
      model,
    );

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
