import { Alert } from '@mui/material';
import { useNotifications } from '../store/useUsers';

const AdminUsersErrors: React.FC = () => {
  const { errorRemovedUsers, errorStatusUsers, clearNotifications } = useNotifications();
  return (
    <>
      {!!errorRemovedUsers.length && (
        <Alert color="error" className="notification" onClose={() => clearNotifications()}>
          Не удалось удалить пользователей <b>{errorRemovedUsers.join(', ')}</b>, повторите попытку
          позже
        </Alert>
      )}
      {!!errorStatusUsers.length && (
        <Alert color="error" className="notification" onClose={() => clearNotifications()}>
          Не удалось обновить пользователей <b>{errorStatusUsers.join(', ')}</b>, повторите попытку
          позже
        </Alert>
      )}
    </>
  );
};

export default AdminUsersErrors;
