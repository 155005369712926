const SvgInfo: React.FC = () => (
  <svg fill="currentColor" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 64.002 64.002">
    <g>
      <g>
        <path
          d="M32.001,0.001c-8.548,0-16.583,3.33-22.627,9.375C3.329,15.418,0,23.454,0,32.001s3.329,16.583,9.374,22.626
			c6.044,6.045,14.079,9.374,22.627,9.374c8.547,0,16.583-3.33,22.627-9.375c6.045-6.043,9.374-14.079,9.374-22.626
			s-3.329-16.583-9.374-22.626C48.584,3.33,40.548,0.001,32.001,0.001z M53.214,53.213c-5.666,5.667-13.2,8.788-21.213,8.788
			c-8.014,0-15.547-3.121-21.213-8.789C5.121,47.547,2,40.014,2,32.001s3.121-15.546,8.788-21.212
			c5.666-5.667,13.199-8.788,21.213-8.788c8.013,0,15.547,3.122,21.213,8.789c5.667,5.665,8.788,13.198,8.788,21.211
			S58.881,47.547,53.214,53.213z"
        />
        <rect x="31.001" y="25.001" width="2" height="22" />
        <rect x="31.001" y="17.001" width="2" height="4" />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default SvgInfo;
