import { useCookie } from 'react-use';
import { coockiesCacheDays } from './constants';
import { useChangeDocumentTitle } from './hooks/useChangeDocumentTitle';
import LoggedInOnly from './ui/common/containers/LoggedInOnly';
import AppRoutes from './ui/common/containers/AppRoutes';

const App: React.FC = () => {
  const [access, updateCookie] = useCookie('access');

  useChangeDocumentTitle();

  return (
    <>
      {access && <LoggedInOnly access={access} />}
      <AppRoutes
        access={access}
        updateCookie={(token: string) => updateCookie(token, { expires: coockiesCacheDays })}
      />
    </>
  );
};

export default App;
