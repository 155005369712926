import axios from 'axios';
import {
  IQuestion,
  IQuestionFile,
  IQuestionParams,
  TCreateQuestion,
  TUpdateQuestion,
} from '../../typings/admin';
import { toFormData } from '../../utils/api';

export async function all(params?: IQuestionParams) {
  try {
    const { data } = await axios.get<IQuestion[]>(`/api/v1/courses/question/`, { params });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function byId(id: string) {
  try {
    const { data } = await axios.get<IQuestion>(`/api/v1/courses/question/${id}/`);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function create(model: TCreateQuestion) {
  try {
    const { data } = await axios.post<IQuestion>('/api/v1/courses/question/', toFormData(model));

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createFromFile(model: IQuestionFile) {
  try {
    const { data } = await axios.post<IQuestionFile>(
      '/api/v1/courses/questions_from_file/',
      toFormData(model),
    );

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function update({ id, ...model }: TUpdateQuestion) {
  try {
    const { data } = await axios.put<IQuestion>(`/api/v1/courses/question/${id}/`, model);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function change({ id, ...model }: TUpdateQuestion) {
  try {
    const { data } = await axios.patch<IQuestion>(
      `/api/v1/courses/question/${id}/`,
      toFormData(model),
    );

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function remove(id: number) {
  try {
    const { data } = await axios.delete(`/api/v1/courses/question/${id}/`);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
