import { FaSearch } from 'react-icons/fa';
import Spinner from './Spinner';

/** Компонент кнопки поиска */
const SearchButton: React.FC<{
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  isIntelleka?: boolean;
}> = ({ loading = false, type = 'button', isIntelleka = false }) => (
  <button
    type={type}
    className={`${
      isIntelleka ? 'bg-active_i' : 'bg-active_p'
    } text-white w-full h-full min-h-[38px] max-w-[40px] min-w-[40px] rounded-tr-[4px] rounded-br-[4px] -ml-1 flex justify-center items-center`}
    disabled={loading}
  >
    {loading ? (
      <Spinner className={`${isIntelleka ? 'fill-active_i' : 'fill-active_p'} w-[16px] h-[17px]`} />
    ) : (
      <FaSearch />
    )}
  </button>
);

export default SearchButton;
