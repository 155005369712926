import { Alert } from '@mui/material';
import { useMatch } from '@tanstack/react-location';
import { useGetCourseById } from '../../../cache/admin/course';
import Spinner from '../../../shared/Spinner';
import { checkIsIntelleka } from '../../../utils/link';
import AsideTemplate from '../../common/templates/AsideTemplate';
import AdminCourseMenu from '../containers/AdminCourseMenu';
import AdminTemplate from './AdminTemplate';

const AdminCourseTemplate: React.FC<{
  isLoading?: boolean;
  isEmpty?: boolean;
  emptyContent?: string;
  materialsCount?: number;
}> = ({ children, isLoading = false, isEmpty = false, emptyContent = 'Нет данных', ...props }) => {
  const { params } = useMatch();
  const { data: course, isError } = useGetCourseById(params.id);
  const isIntelleka = checkIsIntelleka();

  return (
    <AdminTemplate>
      <h1 className="text-center text-[25px]">{course?.title}</h1>
      <AsideTemplate
        btnClassName="mb-3 mt-2"
        sidebar={<AdminCourseMenu materialsCount={props.materialsCount || 0} />}
        className="flex"
        isError={isError}
      >
        <div className="w-full p-0 lg:p-5">
          {isError ? (
            <Alert color="error">Не удалось загрузить данные курса, возможно он был удален.</Alert>
          ) : (
            <>
              {isLoading ? (
                <Spinner
                  className={`${isIntelleka ? 'fill-active_i' : 'fill-active_p'} w-12 mt-12 m-auto`}
                />
              ) : isEmpty ? (
                <div className="text-gray-400 italic">{emptyContent}</div>
              ) : (
                children
              )}
            </>
          )}
        </div>
      </AsideTemplate>
    </AdminTemplate>
  );
};

export default AdminCourseTemplate;
