import { Link, useMatch } from '@tanstack/react-location';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { API } from '../../../api';
import { getStructuredMaterials, IStructured } from '../../../utils/material';
import { generateCourseTestLink } from '../routes';
import AdminCourseTemplate from '../templates/AdminCourseTemplate';

export const MaterialItem: React.FC<{ item: IStructured }> = ({ item }) => {
  const match = useMatch();
  const courseId = match.params.id;

  const { data: tasks } = useQuery(
    ['tasks', `course-${courseId}`],
    () => API.admin.test.all({ material__course: courseId }),
    {
      enabled: !!courseId,
    },
  );

  const materialTasks = tasks?.filter((el) => el.material === item.id);

  return (
    <li className="flex flex-col justify-start transition-all w-fit">
      <div>{item.title}</div>
      {materialTasks?.map((test) => {
        return (
          <div key={test.id}>
            <Link to={generateCourseTestLink(courseId, test.id)}>
              <b className="text-[#0093dd] hover:text-[#0093ddbd] transition-all">{test.title}</b>
            </Link>
          </div>
        );
      })}
      <div>
        {item?.children && (
          <ol className="pl-6">
            {item.children?.map((c) => (
              <MaterialItem key={c.id} item={c} />
            ))}
          </ol>
        )}
      </div>
    </li>
  );
};

const AdminCourseContent: React.FC = () => {
  const match = useMatch();
  const courseId = match.params.id;
  const {
    data: materials,
    isFetching,
    isFetched,
  } = useQuery(
    ['materials', `course-${courseId}`],
    () => API.admin.material.all({ course: courseId, ordering: 'rank' }),
    {
      enabled: !!courseId,
    },
  );

  const structured = materials && getStructuredMaterials(materials);

  return (
    <AdminCourseTemplate
      isLoading={!materials && isFetching}
      isEmpty={isFetched && !materials?.length}
      emptyContent="Нет материалов"
    >
      <div className="px-4 lg:px-0">
        <Styled>
          <ol className="list-decimal">
            {structured?.map((m) => (
              <MaterialItem key={m.id} item={m} />
            ))}
          </ol>
        </Styled>
      </div>
    </AdminCourseTemplate>
  );
};

export default AdminCourseContent;

const Styled = styled.div`
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }

  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
    &:hover {
      color: #000;
    }
  }

  ol > li::before {
    content: counters(item, '.') '. ';
    display: table-cell;
    padding-right: 0.6em;
  }

  li ol > li {
    margin: 0;
  }

  li ol > li::before {
    content: counters(item, '.') ' ';
  }
`;
