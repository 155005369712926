import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';

const config = {
  apiKey: 'AIzaSyBtGFUz0HBF_PTU6TEWgsv1TBQOHpk0wfg',
  authDomain: 'promr-group.firebaseapp.com',
  databaseURL: 'https://promr-group.firebaseio.com',
  projectId: 'promr-group',
  storageBucket: 'promr-group.appspot.com',
  messagingSenderId: '745748191627',
  appId: '1:745748191627:web:058702b29cca524584778e',
  measurementId: 'G-HL0QW0NG2E',
};

const firebaseApp = initializeApp(config);

export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);
export const database = getDatabase(firebaseApp);
