import create from 'zustand';

export const useCoursesSearch = create<{
  search: string;
  setSearch: (checked: string) => void;
}>((set) => ({
  search: '',
  setSearch: (search) => set({ search }),
}));

export const useCoursesErrors = create<{
  error?: string;
  setError(error: string): void;
}>((set) => ({
  setError: (error) => {
    set({ error });
    setTimeout(() => set({ error: '' }), 5000);
  },
}));

export const useCoursesForm = create<{
  open: boolean;
  toggle(open: boolean): void;
}>((set) => ({
  open: false,
  toggle: (open) => set({ open }),
}));
