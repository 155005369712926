import { useTest } from '../store/useTest';
import Modal from '../../../shared/Modal';
import ConfirmModal from '../../../shared/ConfirmModal';
import { useNavigate } from '@tanstack/react-location';
import { MATERIALS, ROOT } from '../routes';
import ClientTestHeader from './ClientTestHeader';
import ClientTestUiStart from './ClientTestUiStart';
import { useEffect } from 'react';
import ClientTestUiResults from './ClientTestUiResults';
import ClientTestUiQuestion from './ClientTestUiQuestion';
import ClientTestUiError from './ClientTestUiError';
import ClientTestLoading from './ClientTestLoading';
import { checkIsIntelleka } from '../../../utils/link';

const ClientTestView: React.FC = () => {
  const navigate = useNavigate();

  const { ui, task, onOpen, onClose, isConfirmVisible, hideConfirmWindow, handleClose } = useTest();

  const confirm = () => {
    onClose();
    navigate({ to: ROOT + MATERIALS });
  };

  useEffect(() => {
    task ? onOpen(task.id) : onClose();
  }, [onOpen, onClose, task]);

  const renderUi = () => {
    switch (ui) {
      case 'loading':
        return <ClientTestLoading />;
      case 'start':
        return <ClientTestUiStart />;
      case 'results':
        return <ClientTestUiResults />;
      case 'error':
        return <ClientTestUiError />;
      default:
        return <ClientTestUiQuestion />;
    }
  };

  const isIntelleka = checkIsIntelleka();

  return (
    <Modal open={!!task} className="rounded max-w-[800px] mt-[25px]" onClose={handleClose}>
      <ClientTestHeader
        title={task?.title}
        materialId={task?.material}
        travel_time={task?.travel_time}
      />
      <ConfirmModal
        confirm={confirm}
        isOpen={isConfirmVisible}
        hide={hideConfirmWindow}
        isIntelleka={isIntelleka}
      >
        Вы можете вернуться к материалам курса, но время отведенное на тестирование будет идти.
      </ConfirmModal>
      {renderUi()}
    </Modal>
  );
};

export default ClientTestView;
