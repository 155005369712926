import { AiFillCheckCircle } from 'react-icons/ai';

/** Компонент рендера индикатора статуса прохождения */
const PassingStatus: React.FC<{ status?: 0 | 1 | 2; className?: string; small?: boolean }> = ({
  status,
  className = '',
  small,
}) => {
  if (status === 2)
    return (
      <AiFillCheckCircle
        className={`${
          small ? 'scale-90' : 'scale-110'
        } rounded-full bg-white text-yellow-400 h-[17px] w-[17px] ${className}`}
      />
    );

  return (
    <div
      className={`${small ? 'scale-75' : ''} h-[17px] w-[17px] rounded-full border border-dashed ${
        !status ? 'border-[rgb(204,204,204)]' : 'border-yellow-400'
      } ${className}`}
    />
  );
};

export default PassingStatus;
