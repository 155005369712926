import { useMatch } from '@tanstack/react-location';
import { keyBy } from 'lodash';
import moment from 'moment';
import { useQuery } from 'react-query';
import { API } from '../../../api';
import DeviceTimeItem from '../../../shared/DeviceTimeItem';
import Table from '../../../shared/Table';
import { filterUniqDaysActivity } from '../../../utils/statistic';
import AdminUserStatisticTemplate from '../templates/AdminUserStatisticTemplate';

const titles = ['#', 'Время активности', 'Браузер', 'OC', 'Курс'];

const AdminUserDevices: React.FC = () => {
  const { params } = useMatch();
  const { id } = params;
  const { data, isFetching } = useQuery(['devices', id], () =>
    API.admin.activity.devices({ user: Number(id), ordering: 'id' }),
  );
  const { data: courses } = useQuery('courses', () => API.admin.course.all());

  const coursesObject = courses ? keyBy(courses, 'id') : {};

  const filteredActivity = filterUniqDaysActivity(data);

  const rows = filteredActivity?.map((a, i) => {
    const currentDay = moment(a?.payload.time).format('DD.MM.YYYY');
    return [
      i + 1,
      <DeviceTimeItem
        currentDay={currentDay}
        devices={data || []}
        className="justify-end lg:justify-center "
      />,
      <>
        {a?.payload.browser?.name} ({a?.payload.browser?.version})
      </>,
      <>
        {a?.payload.os?.name} ({a?.payload.os?.version})
      </>,
      <>{coursesObject[a?.course_id || 0]?.title}</>,
    ];
  });

  return (
    <AdminUserStatisticTemplate>
      <Table titles={titles} rows={rows} isLoading={!data && isFetching} />
    </AdminUserStatisticTemplate>
  );
};

export default AdminUserDevices;
