import { Tooltip } from '@mui/material';
import { forwardRef } from 'react';
import { useSuccessTestPassingsByCourse } from '../../../cache/client/passing';
import { useGetTasks } from '../../../cache/client/task';
import { ThemeButton } from '../../../shared/Button';
import TaskHint from '../../../shared/TaskHint';
import { ITask } from '../../../typings/client';
import { onlyUnique } from '../../../utils/array';
import { checkIsIntelleka } from '../../../utils/link';
import { useCourse } from '../store/useCourse';
import ClientTestButton from './ClientTestButton';

const NotAvaliable = forwardRef<
  HTMLDivElement,
  {
    isMaterialPage?: boolean;
    task: ITask;
    isIntelleka: boolean;
    tooltipText: string;
  }
>((props, ref) => (
  <div>
    <div className="font-bold">{props.isMaterialPage && props.task.title}</div>
    <Tooltip
      title={<div className="text-sm">{props.tooltipText}</div>}
      arrow
      placement="top"
      ref={ref}
    >
      <ThemeButton isIntelleka={props.isIntelleka} className="opacity-60 cursor-default">
        Пройти тестирование
      </ThemeButton>
    </Tooltip>
    <TaskHint prepared_text={props.task?.prepared_text ?? ''} />
  </div>
));

const ClientCheckIsFinalTask: React.FC<{
  task: ITask;
  setTask(): void;
  sidebar?: boolean;
  noTitle?: boolean;
  isMaterialPage?: boolean;
}> = (props) => {
  const isIntelleka = checkIsIntelleka();
  const { course } = useCourse();
  const { data: allTasksOfCurrentCourse } = useGetTasks(course);

  const { data: passings } = useSuccessTestPassingsByCourse(course);

  const intermediateTasks = allTasksOfCurrentCourse?.filter((t) => !t.is_final);

  const necessarilyTasks = intermediateTasks
    ?.filter((t) => t.is_necessarily)
    .sort((a, b) => a.rank - b.rank);

  const allPrevNecessarilyTasks = necessarilyTasks?.filter((t) => t.rank < props.task.rank);

  /** айдишники успешно сданных промежуточных тестов */
  const successPassingIntermediateTaskIds = passings
    ?.filter((el) => el.success_passed === 0 && !el.is_final_task)
    .map((el) => el.task)
    /** исключить повторяющиеся */
    .filter(onlyUnique);

  const isNecessarilyNotPassed = allPrevNecessarilyTasks
    ?.map((t) => successPassingIntermediateTaskIds?.includes(t.id))
    .includes(false);
  const successPassingsIntermediateTasksCount = successPassingIntermediateTaskIds?.length || 0;
  const intermediateTasksCount = intermediateTasks?.length || 0;

  const isFinalAvailable = successPassingsIntermediateTasksCount >= intermediateTasksCount;

  return props.task.is_final ? (
    isFinalAvailable ? (
      <ClientTestButton {...props} />
    ) : (
      <NotAvaliable
        isIntelleka={isIntelleka}
        task={props.task}
        isMaterialPage={props.isMaterialPage}
        tooltipText="Итоговый экзамен доступен только после сдачи всех промежуточных тестов"
      />
    )
  ) : isNecessarilyNotPassed ? (
    <NotAvaliable
      isIntelleka={isIntelleka}
      task={props.task}
      isMaterialPage={props.isMaterialPage}
      tooltipText={`Этот тест будет доступен только после сдачи предыдущих обязательных тестов`}
    />
  ) : (
    <ClientTestButton {...props} />
  );
};

export default ClientCheckIsFinalTask;
