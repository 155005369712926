import { useAuth } from '../../store/useAuth';
import { checkIsIntelleka } from '../../../../utils/link';
import AuthError from '../../../../shared/AuthError';
import Recaptcha from './Recaptcha';
import { ISubmitProps } from '../../typings/auth';

const LoginSubmit: React.FC<ISubmitProps> = (props) => {
  const { error, closeErrors } = useAuth();
  const isIntelleka = checkIsIntelleka();

  return (
    <>
      <Recaptcha {...props} />
      {error && (
        <AuthError
          onClose={closeErrors}
          isIntelleka={isIntelleka}
          className="absolute z-50 left-0 right-0 xl:right-[-718px] top-0 bottom-0 m-auto"
        />
      )}
    </>
  );
};

export default LoginSubmit;
