import create from 'zustand';
import type { TreeItem, NodeData } from 'react-sortable-tree';
import { getFlatDataFromTree } from 'react-sortable-tree';
import { API } from '../../../api';

type ITree = TreeItem[];
type IMaterial = { id: number; rank: number; parent: number | null };

type ISortMaterials = {
  loading: boolean;
  changed: number[];
  tree: ITree;
  checked: number[];
  isButtonsFixed: boolean;
  addMaterialOpen: boolean;
  copyMaterialOpen: boolean;
  massAddingMaterialOpen: boolean;
  editId?: number;
  error?: string;
  removeId?: number;
  allowCollapse?: string | null;

  setTree(t: ITree): void;
  onMoveNode(data: NodeData): void;
  getTreeArray(): IMaterial[];
  onSave(): void;
  toggleChecked(checked: number): void;
  clearChecked(): void;
  setButtonsFixed(isButtonsFixed: boolean): void;
  setAddMaterialOpen(open: boolean): void;
  setCopyMaterialOpen(open: boolean): void;
  setMassAddingMaterialOpen(open: boolean): void;
  toggleId(editId?: number): void;
  setError(error?: string): void;
  setRemoveId(id?: number): void;
  setAllowCollapse(): void;
  massSuccess: string;
  massError: string;
  clearMassNotification(): void;
};

export const useSortMaterials = create<ISortMaterials>((set, get) => ({
  loading: false,
  tree: [],
  changed: [],
  checked: [],
  isButtonsFixed: false,
  addMaterialOpen: false,
  copyMaterialOpen: false,
  massAddingMaterialOpen: false,
  allowCollapse: localStorage.getItem('allowCollapse'),

  setTree: (tree) => set(() => ({ tree })),

  onMoveNode: (data) => {
    const id = data?.node?.id;
    if (id)
      set((state) => ({
        changed: [...state.changed, id],
      }));
  },

  getTreeArray: () => {
    const flatTree = getFlatDataFromTree({
      treeData: get().tree,
      getNodeKey: () => 1,
    });
    return flatTree.map((el, index) => ({
      id: el?.node?.id as number,
      rank: index,
      parent: (el?.parentNode?.id as number) || null,
    }));
  },

  onSave: async () => {
    get()
      .getTreeArray()
      .forEach(async (el, i, self) => {
        try {
          i === 0 && set({ loading: true });
          await API.admin.material.updatePosition(el.id, el);
          if (i === self.length - 1) set({ loading: false, changed: [] });
          set({ massSuccess: 'Материалы успешно обновлены' });
          setTimeout(() => set({ massSuccess: '' }), 5000);
        } catch (e) {
          set({ loading: false, massError: `Не удалось обновить все материаллы` });
          setTimeout(() => set({ massError: '' }), 5000);
          throw new Error();
        }
      });
  },

  toggleChecked: (id: number) => {
    const isChecked = get().checked.includes(id);
    set({ checked: isChecked ? get().checked.filter((m) => m !== id) : [...get().checked, id] });
  },

  clearChecked: () => set({ checked: [] }),

  setButtonsFixed: (isButtonsFixed) => set({ isButtonsFixed }),

  setAddMaterialOpen: (addMaterialOpen) => set({ addMaterialOpen }),

  setCopyMaterialOpen: (copyMaterialOpen) => set({ copyMaterialOpen }),

  setMassAddingMaterialOpen: (massAddingMaterialOpen) => set({ massAddingMaterialOpen }),

  toggleId: (editId) => set({ editId }),

  setError: (error) => {
    set({ error });
    setTimeout(() => set({ error: undefined }), 5000);
  },

  setRemoveId: (removeId) => set({ removeId }),

  setAllowCollapse: () => {
    get().allowCollapse
      ? localStorage.removeItem('allowCollapse')
      : localStorage.setItem('allowCollapse', 'allow');
    set({ allowCollapse: get().allowCollapse ? '' : 'allow' });
  },
  massSuccess: '',
  massError: '',
  clearMassNotification: () => set({ massError: '' }),
}));

export const useSidebar = create<{
  width: number;
  initialWidth: number;
  setWidth(width: number): void;
}>((set) => ({
  initialWidth: 345,
  width: 345,
  setWidth: (width) => set({ width }),
}));
