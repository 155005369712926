import { useEffect } from 'react';
import create from 'zustand';
import { createWebsocketUrl } from '../../../utils/link';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';

export const useStatistic = create<{
  onlineUsers: number;
  setOnlineUsers: (users: number) => void;
}>((set) => ({
  onlineUsers: 0,
  setOnlineUsers: (onlineUsers) => set({ onlineUsers }),
}));

export function useConnectToStatistic() {
  const socketUrl = createWebsocketUrl('online_users/');
  const { setOnlineUsers } = useStatistic();

  const { sendJsonMessage } = useWebSocket(socketUrl, {
    shouldReconnect: () => true,
    onMessage: ({ data }) => {
      const { online_users } = JSON.parse(data);
      setOnlineUsers(online_users);
    },
  });

  useEffect(() => {
    sendJsonMessage({});
  }, [sendJsonMessage]);
}
