import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ICourse } from '../../../typings/admin';
import TextEditor from '../../../shared/TextEditor';
import CreatableSelect from '../../../shared/CreatableSelect';
import RadioToggle from '../../../shared/RadioToggle';
import Tooltip from '../../../shared/Tooltip';
import { checkIsIntelleka } from '../../../utils/link';
import ButtonSplit from '../../../shared/ButtonSplit';
import { Alert } from '@mui/material';
import { useCoursesErrors } from '../store/useCourses';
import ReactSelect from '../../../shared/ReactSelect';
import { ThemeButton } from '../../../shared/Button';
import ThemeInput from '../../../shared/ThemeInput';
import { useAddTag, useGetTags } from '../../../cache/admin/tag';
import { useCurrentUser } from '../../../cache/common/user';
import { useGetCourse } from '../../../cache/admin/course';
import { useModerators } from '../../../cache/admin/users/moderators';
import { useAdmins } from '../../../cache/admin/users/admins';
import { useLoadUsers } from '../../../cache/admin/users';
import ConfirmModal from '../../../shared/ConfirmModal';

const AdminCourseForm: React.FC<{
  submit: (data: ICourse) => void;
  edit?: ICourse;
  onDelete?(): void;
  onClose(): void;
  loading?: boolean;
  deleting?: boolean;
}> = ({ submit, edit, onDelete, onClose, loading = false, deleting = false }) => {
  const { handleSubmit, setValue, reset, control, getValues } = useForm<ICourse>({
    defaultValues: {
      description: edit?.description,
      title: edit?.title || '',
      author: edit?.author,
      tag: edit?.tag,
      users: edit?.users,
      moderators: edit?.moderators,
      is_active: edit ? edit.is_active : true,
      teacher: edit?.teacher || '',
    },
  });
  const { data: user } = useCurrentUser();
  const { error, setError } = useCoursesErrors();
  const { data: course, isLoading: courseIsLoading } = useGetCourse(edit);
  const { data: moderators } = useModerators();
  const { data: admins } = useAdmins();

  const { mutate: loadUsers, isLoading: usersLoading, data: users } = useLoadUsers();
  const { data: tags } = useGetTags();

  const onTagCreated = (newTag: number) => setValue('tag', [...(getValues().tag || []), newTag]);

  const { mutate: addTag, isLoading: isCreatingTag } = useAddTag(onTagCreated);
  const [isConfirmOpen, confirmToggle] = useState(false);

  useEffect(() => {
    if (!edit) {
      reset();
      user && setValue('author', user?.id);
    }
  }, [edit, setValue, reset, user]);

  const teachers = moderators?.results.map((moderator) => ({
    value: moderator.id,
    label: moderator.first_name,
  }));

  const administrators = admins?.results.map((a) => ({
    value: a.id,
    label: a.username,
  }));

  const students = users
    ? users.results
        .filter((u) => !course?.users_detail.map((el) => el.id).includes(u.id))
        .map((a) => ({
          value: a.id,
          label: a.username,
        }))
        .concat(course?.users_detail.map((u) => ({ value: u.id, label: u.username })) || [])
    : course?.users_detail.map((u) => ({ value: u.id, label: u.username }));

  const tag = tags?.map((t) => ({ value: t.id, label: t.tag }));

  const authors = teachers?.concat(administrators || []);

  const isIntelleka = checkIsIntelleka();

  const loadOptions = ['загрузить всех', 'загрузить 100', 'загрузить 10'];

  const isAdmin = user?.user_status === 0;

  return (
    <>
      <form onSubmit={handleSubmit(submit)} className="p-5">
        <label className="mb-3 block">
          <div className="mb-1">Название курса</div>
          <Controller
            control={control}
            render={({ field }) => {
              return (
                <ThemeInput {...field} className="w-full" autoFocus isIntelleka={isIntelleka} />
              );
            }}
            name="title"
          />
        </label>
        <label className="mb-3 block">
          <div className="mb-1">Описание</div>
          <Controller
            control={control}
            render={({ field: { value } }) => {
              return (
                <TextEditor onChange={(text) => setValue('description', text)} value={value} />
              );
            }}
            name="description"
          />
        </label>
        {isAdmin && (
          <label className="mb-3 block">
            <div className="mb-1">Автор</div>
            <Controller
              control={control}
              render={({ field: { value, name } }) => {
                return (
                  <ReactSelect
                    isIntelleka={isIntelleka}
                    value={authors?.find((c) => c.value === value)}
                    name={name}
                    options={authors}
                    onChange={(data: any) => data && setValue(name, data?.value)}
                  />
                );
              }}
              name="author"
            />
          </label>
        )}
        <label className="mb-3 block">
          <div className="mb-1">Тэги</div>
          <Controller
            control={control}
            render={({ field: { name, value } }) => {
              return (
                <CreatableSelect
                  isIntelleka={isIntelleka}
                  placeholder={isCreatingTag ? 'Создание...' : 'Выберите или создайте тег'}
                  value={value?.map((el) => ({
                    value: el,
                    label: tags?.find((t) => t.id === el)?.tag || '',
                  }))}
                  name={name}
                  options={tag}
                  onChange={(data) =>
                    Array.isArray(data) &&
                    setValue(
                      name,
                      data.map((m) => m.value),
                    )
                  }
                  isMulti
                  onCreateOption={(tag) => addTag(tag)}
                  isLoading={isCreatingTag}
                />
              );
            }}
            name="tag"
          />
        </label>
        {edit && (
          <label className="mb-3 block">
            <div className="mb-1">
              {usersLoading || courseIsLoading ? (
                <div className="text-gray-400">Загрузка...</div>
              ) : (
                'Ученики'
              )}
            </div>
            <div className="flex flex-col sm:flex-row items-end">
              <div className="w-full">
                <Controller
                  control={control}
                  render={({ field: { name, value } }) => {
                    return (
                      <ReactSelect
                        isIntelleka={isIntelleka}
                        name={name}
                        options={students}
                        onChange={(data) =>
                          Array.isArray(data) &&
                          setValue(
                            name,
                            data.map((m) => m.value),
                          )
                        }
                        value={
                          usersLoading || courseIsLoading
                            ? []
                            : value?.map((value) => ({
                                value,
                                label:
                                  students?.find((s) => s.value === Number(value))?.label || '...',
                              }))
                        }
                        isDisabled={usersLoading || courseIsLoading}
                        isMulti
                      />
                    );
                  }}
                  name="users"
                />
              </div>
              <ButtonSplit
                className="mt-2 sm:ml-2 sm:mt-0 whitespace-nowrap"
                options={loadOptions}
                disabled={usersLoading}
                loading={usersLoading}
                onClick={(value) => {
                  switch (loadOptions.indexOf(value)) {
                    case 0:
                      return loadUsers(10000);
                    case 1:
                      return loadUsers(100);
                    default:
                      return loadUsers(10);
                  }
                }}
                isIntelleka={isIntelleka}
              />
            </div>
          </label>
        )}
        {isAdmin && (
          <label className="mb-3 block">
            <div className="mb-1">Преподаватели</div>
            <Controller
              control={control}
              render={({ field: { name, value } }) => {
                return (
                  <>
                    <ReactSelect
                      isIntelleka={isIntelleka}
                      name={name}
                      className="outline-none hover:outline-none"
                      options={authors}
                      onChange={(data) =>
                        Array.isArray(data) &&
                        setValue(
                          name,
                          data.map((m) => m.value),
                        )
                      }
                      value={teachers?.find((t) => t.value === Number(value))}
                      isMulti
                    />
                  </>
                );
              }}
              name="moderators"
            />
          </label>
        )}
        <label className="block w-fit">
          <Controller
            control={control}
            render={({ field: { name, value } }) => {
              return (
                <RadioToggle
                  label="Активен"
                  checked={value}
                  onChange={() => setValue(name, !value)}
                  isIntelleka={isIntelleka}
                />
              );
            }}
            name="is_active"
          />
        </label>
        <label className="mb-3 block">
          <div className="mb-1">Комментарии</div>
          <Controller
            control={control}
            render={({ field }) => (
              <ThemeInput {...field} className="w-full" isIntelleka={isIntelleka} />
            )}
            name="teacher"
          />
        </label>
        <div className="mt-4 flex justify-between">
          <div>
            <ThemeButton
              type="button"
              className="mr-1"
              onClick={onClose}
              isIntelleka={isIntelleka}
              isSecondary
            >
              Отменить
            </ThemeButton>
            <ThemeButton type="submit" disabled={loading} isIntelleka={isIntelleka}>
              {loading ? 'Сохранение...' : 'Сохранить'}
            </ThemeButton>
          </div>
          {edit &&
            (user?.user_status === 0 ? (
              <>
                <ThemeButton
                  type="button"
                  onClick={() => confirmToggle(true)}
                  disabled={deleting}
                  isIntelleka={isIntelleka}
                >
                  {deleting ? 'Удаление...' : 'Удалить'}
                </ThemeButton>
                <ConfirmModal
                  confirm={() => {
                    onDelete && onDelete();
                  }}
                  isOpen={isConfirmOpen}
                  hide={() => confirmToggle(false)}
                  loading={deleting}
                  isIntelleka={isIntelleka}
                >
                  <div className="text-left">Вы действительно хотите удалить курс?</div>
                </ConfirmModal>
              </>
            ) : (
              <Tooltip
                text={
                  <div className="text-sm text-center">
                    Удаление доступно <br />
                    только администратору
                  </div>
                }
              >
                <ThemeButton
                  type="button"
                  className="opacity-50 cursor-default"
                  isIntelleka={isIntelleka}
                >
                  Удалить
                </ThemeButton>
              </Tooltip>
            ))}
        </div>
        {error && (
          <Alert color="error" className="notification" onClose={() => setError('')}>
            {error}
          </Alert>
        )}
      </form>
    </>
  );
};

export default AdminCourseForm;
