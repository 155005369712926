const SvgChat: React.FC = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 380.72 380.72"
    fill="currentColor"
  >
    <g>
      <g>
        <path
          d="M352.08,149.954c-19.098-18.276-43.174-30.495-69.2-35.12c-7.86-14.051-18.406-26.42-31.04-36.4
			c-29.789-22.896-66.432-35.073-104-34.56c-37.568-0.513-74.211,11.664-104,34.56c-27.12,21.8-43.84,51.8-43.84,85
			c0.051,16.791,4.329,33.298,12.44,48c8.083,14.581,19.053,27.361,32.24,37.56l-7.48,49.44c-0.479,3.145,1.683,6.082,4.828,6.561
			c1.303,0.198,2.634-0.056,3.772-0.721l49.72-29.12c7.977,2.434,16.117,4.292,24.36,5.56c9.265,1.423,18.626,2.132,28,2.12
			c3.2,0,6.373-0.08,9.52-0.24c12.261,11.215,26.578,19.949,42.16,25.72c17.242,6.455,35.51,9.735,53.92,9.68
			c8.035,0,16.059-0.615,24-1.84c6.968-1.074,13.852-2.637,20.6-4.68l42.2,24.6c2.747,1.605,6.274,0.679,7.879-2.068
			c0.665-1.138,0.919-2.469,0.721-3.772l-6.28-41.68c11.19-8.718,20.509-19.601,27.4-32c6.994-12.695,10.681-26.946,10.72-41.44
			C380.37,190.416,370.039,166.912,352.08,149.954z M147.8,271.274c-8.758,0.003-17.504-0.666-26.16-2
			c-8.502-1.296-16.888-3.262-25.08-5.88c-1.551-0.489-3.236-0.301-4.64,0.52l-41.6,24.16l6.16-40.72
			c0.377-2.123-0.455-4.28-2.16-5.6c-13.035-9.572-23.843-21.854-31.68-36c-7.162-13.015-10.931-27.624-10.96-42.48
			c0-29.56,15.04-56.36,39.4-76c27.759-21.26,61.879-32.535,96.84-32c34.962-0.538,69.082,10.737,96.84,32
			c12.4,9.92,39.4,36,39.4,74.48c0,34.4-18.68,59.32-35.52,73.96c-17.143,14.385-37.332,24.684-59.04,30.12
			C175.896,269.108,161.886,270.932,147.8,271.274z M359.8,250.914l0,0.04c-6.657,11.945-15.812,22.314-26.84,30.4
			c-1.78,1.272-2.692,3.438-2.36,5.6l5,33.12l-33.96-20c-1.404-0.82-3.089-1.009-4.64-0.52c-6.964,2.227-14.093,3.898-21.32,5
			c-7.36,1.127-14.795,1.688-22.24,1.68c-17.013,0.072-33.899-2.937-49.84-8.88c-10.772-4.018-20.914-9.554-30.12-16.44
			c16.918-2.795,33.428-7.658,49.16-14.48c12.155-5.561,23.433-12.864,33.48-21.68c13.6-12,39.52-39.48,39.52-82.8
			c-0.519-11.557-2.743-22.973-6.6-33.88c20.636,5.007,39.574,15.401,54.88,30.12c15.715,14.79,24.789,35.302,25.16,56.88
			C369.035,227.609,365.844,239.932,359.8,250.914z"
        />
      </g>
    </g>
    <g>
      <g>
        <circle cx="82" cy="167.314" r="10.2" />
      </g>
    </g>
    <g>
      <g>
        <circle cx="213.6" cy="167.314" r="10.2" />
      </g>
    </g>
    <g>
      <g>
        <circle cx="147.8" cy="167.314" r="10.2" />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);
export default SvgChat;
