import { useMatch } from '@tanstack/react-location';
import AdminCourseTemplate from '../templates/AdminCourseTemplate';
import AdminTestForm from './AdminTestForm';
import { useCreateTest } from '../../../cache/admin/task';

const AdminAddTestToMaterial: React.FC = () => {
  const match = useMatch();
  const courseId = match.params.id;
  const materialId = match.params.materialId;

  const { mutate: create, isLoading: creating } = useCreateTest(courseId, true);

  return (
    <AdminCourseTemplate>
      <AdminTestForm
        open={true}
        onClose={() => window.history.back()}
        submit={create}
        loading={creating}
        materialId={materialId}
      />
    </AdminCourseTemplate>
  );
};

export default AdminAddTestToMaterial;
