/**
 * @module array
 * @category общие утилиты
 * @description утилиты по работе с массивами
 */

import { IUser } from '../typings/admin';
import { IChat } from '../typings/common';

/**
 * Разбивает массив на более мелкие массивы
 * @param array массив с любым содержимым
 * @param chunkSize размер куска
 * @returns массив массивов определяемых, параметром chunkSize
 */
export function splitToChunks(array: any[] = [], chunkSize = 1) {
  return Array(Math.ceil(array.length / chunkSize))
    .fill(1)
    .map((_, index) => index * chunkSize)
    .map((begin) => array.slice(begin, begin + chunkSize));
}

/**
 * Возвращает уникальные значения массива
 * @param value значение элемента массива
 * @param index порядковый номер элемента в массиве
 * @param self начальный массив
 * @returns массив с уникальными значениями без повторяющихся элементов
 */
export function onlyUnique<T extends unknown[]>(
  value: number | string,
  index: number,
  self: T,
): boolean {
  return self.indexOf(value) === index;
}

/**
 * Функция сортирует массив в случайном порядке
 * @param array массив
 * @returns Возвращает массив отсортированный в случайном порядке
 */
export const randomize = <T>(array: T[]) => array.sort(() => 0.5 - Math.random());

/**
 * Функция преобразует число в массив из элементов
 * @param countNumbers число
 * @param startNum номер первого элемента массива
 * @param endNum номер последнего элемента массива
 * @returns Возвращает массив из элементов выбранного диапазона
 */
export function rangeNumbers(countNumbers: number, startNum?: number, endNum?: number) {
  return Array.from(Array(countNumbers).keys())
    .slice(Number(startNum) > 0 ? Number(startNum) - 1 : 0, endNum)
    .map((key) => key + 1);
}

/**
 * Функция сортирует массив по указанному полю в алфавитном порядке
 * @param array массив
 * @param name поле
 * @returns Возвращает отсортированный массив
 */
export const alphaBetSort = <T extends Record<string, any>[]>(array: T, name: string): T => {
  if (name === 'courses') {
    return array.sort((a, b) => {
      const nameA = a.courses.length ? a.courses[0].title.toLowerCase() : 'я',
        nameB = b.courses.length ? b.courses[0].title.toLowerCase() : 'я';
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  }
  if (name === 'company') {
    return array.sort((a, b) => {
      const nameA = a.company ? a.company.title.toLowerCase() : 'я',
        nameB = b.company ? b.company.title.toLowerCase() : 'я';
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  }
  if (name === 'date_joined') {
    return array.sort((a, b) => {
      const nameA = a[name] ? new Date(a[name]).valueOf() : 0,
        nameB = b[name] ? new Date(b[name]).valueOf() : 0;
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  }

  if (name === 'num_users') {
    return array.sort((a, b) => {
      const nameA = a[name] ? a[name] : 99999999,
        nameB = b[name] ? b[name] : 99999999;
      return nameA - nameB;
    });
  }

  return array.sort((a, b) => {
    const nameA = a[name] && typeof a[name] === 'string' ? (a[name] as string).toLowerCase() : 'я';
    const nameB = b[name] && typeof b[name] === 'string' ? (b[name] as string).toLowerCase() : 'я';

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0;
  });
};

/**
 * Функция создает массив из указанного количества индексов
 * @param count количество индексов в массиве
 * @returns массив индексов
 */
export const makeArray = (count: number) => [...Array(count).keys()];

export type TUsersFilter =
  | 'ID'
  | 'USER_NAME'
  | 'LOGIN'
  | 'COURSE'
  | 'COMPANY'
  | 'STATUS'
  | 'START'
  | 'END'
  | 'EXAM'
  | 'CREATED'
  | 'EXAM_PASSED'
  | 'EXAM_NOT_CHECKED'
  | 'NOTE'
  | 'LAST_PASSING_DT';

export const USER_FILTERS = {
  ID: 'ID',
  USER_NAME: 'USER_NAME',
  LOGIN: 'LOGIN',
  COURSE: 'COURSE',
  COMPANY: 'COMPANY',
  STATUS: 'STATUS',
  START: 'START',
  END: 'END',
  EXAM: 'EXAM',
  CREATED: 'CREATED',
  EXAM_PASSED: 'EXAM_PASSED',
  EXAM_NOT_CHECKED: 'EXAM_NOT_CHECKED',
  NOTE: 'NOTE',
  LAST_PASSING_DT: 'LAST_PASSING_DT',
};

/**
 * функция сортирует пользователей по полю указанному в параметрах
 * @param users пользователи
 * @param orderBy поле по которому сортируются пользователи
 * @param isReverse направление сортировки
 * @returns отсортированные пользователи
 */
export const sortUsers = (users: IUser[], orderBy: TUsersFilter, isReverse: boolean) => {
  switch (orderBy) {
    case USER_FILTERS.START: {
      const startSortedUsers = () =>
        users.sort((a, b) => {
          const aDate = a.start_course ? new Date(a.start_course).getTime() : 'я';
          const bDate = b.start_course ? new Date(b.start_course).getTime() : 'я';
          return Number(aDate) - Number(bDate);
        });
      return isReverse ? startSortedUsers().reverse() : startSortedUsers();
    }
    case USER_FILTERS.END: {
      const endSortedUsers = () =>
        users.sort((a, b) => {
          const aDate = a.end_course ? new Date(a.end_course).getTime() : 'я';
          const bDate = b.end_course ? new Date(b.end_course).getTime() : 'я';
          return Number(aDate) - Number(bDate);
        });
      return isReverse ? endSortedUsers().reverse() : endSortedUsers();
    }
    case USER_FILTERS.STATUS:
      return isReverse
        ? users.sort((a, b) => Number(a.is_active) - Number(b.is_active)).reverse()
        : users.sort((a, b) => Number(a.is_active) - Number(b.is_active));
    case USER_FILTERS.LAST_PASSING_DT:
      return isReverse
        ? users.sort(
            (a, b) =>
              new Date(a.last_passing_dt || 0).getTime() -
              new Date(b.last_passing_dt || 0).getTime(),
          )
        : users
            .sort(
              (a, b) =>
                new Date(a.last_passing_dt || 0).getTime() -
                new Date(b.last_passing_dt || 0).getTime(),
            )
            .reverse();
    case USER_FILTERS.NOTE:
      return isReverse
        ? alphaBetSort(users, 'description').reverse()
        : alphaBetSort(users, 'description');
    case USER_FILTERS.LOGIN:
      return isReverse
        ? alphaBetSort(users, 'username').reverse()
        : alphaBetSort(users, 'username');
    case USER_FILTERS.USER_NAME:
      return isReverse
        ? alphaBetSort(users, 'first_name').reverse()
        : alphaBetSort(users, 'first_name');
    case USER_FILTERS.COURSE:
      return isReverse ? alphaBetSort(users, 'courses').reverse() : alphaBetSort(users, 'courses');
    case USER_FILTERS.COMPANY:
      return isReverse
        ? alphaBetSort(users, 'company_title').reverse()
        : alphaBetSort(users, 'company_title');
    case USER_FILTERS.CREATED:
      return isReverse
        ? alphaBetSort(users, 'date_joined')
        : alphaBetSort(users, 'date_joined').reverse();
    case USER_FILTERS.EXAM_PASSED: {
      const examPassedTimeSort = () =>
        users
          .sort((a, b) => {
            const lastPassingsA = a.last_test_passings.filter((el) => el.is_final_task);
            const lastPassingsB = b.last_test_passings.filter((el) => el.is_final_task);
            const aDate = lastPassingsA.length
              ? new Date(lastPassingsA[lastPassingsA.length - 1].finish_time).getTime()
              : 0;
            const bDate = lastPassingsB.length
              ? new Date(lastPassingsB[lastPassingsB.length - 1].finish_time).getTime()
              : 0;
            return aDate - bDate;
          })
          .reverse();
      return isReverse ? examPassedTimeSort().reverse() : examPassedTimeSort();
    }
    case USER_FILTERS.EXAM: {
      const examSort = () =>
        users
          .sort((a, b) => {
            const aDate = a.finish_time_exam ? new Date(a.finish_time_exam).getTime() : Infinity;
            const bDate = b.finish_time_exam ? new Date(b.finish_time_exam).getTime() : Infinity;
            return aDate - bDate;
          })
          .reverse();
      return isReverse ? examSort() : examSort().reverse();
    }
    case USER_FILTERS.EXAM_NOT_CHECKED: {
      const examNotChecked = () => {
        return users.sort((a, b) => {
          const prev = a.on_check;
          const next = b.on_check;
          return prev - next;
        });
      };

      return isReverse ? examNotChecked() : examNotChecked().reverse();
    }
    default:
      return users;
  }
};

/**
 * Функция возвращает данные массива
 * для компонента библиотеки [react-select](https://react-select.com/)
 * @param item элемент массива
 * @returns массив для селекта
 */
export const getDataForSelect = (item: { id: number; title: string }) => ({
  value: item.id,
  label: item.title,
});

/**
 * Функция отфильтровует пользователей ожидающих проверки теста модератором
 * @param users пользователи
 * @returns массив пользователей ожидающих проверку
 */
export const getOnCheckUsers = (users?: IUser[]) =>
  users
    ? users.filter((el) => !!el.on_check && !!el.last_test_passings.length && el.is_active)
    : [];

/**
 * Функция сортирует массив чатов по последнему сообщению
 * @param chats массив чатов
 * @returns отсортированный массив чатов по последнему сообщению
 */
export const sortChatsByLastMessage = (chats?: Partial<IChat>[]) =>
  chats?.sort((a, b) => {
    const aDate = a.last_message_date ? new Date(a.last_message_date).getTime() : 0;
    const bDate = b.last_message_date ? new Date(b.last_message_date).getTime() : 0;
    return Number(bDate) - Number(aDate);
  });
