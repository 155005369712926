import { forwardRef } from 'react';

type TProps = { isIntelleka: boolean; className?: string } & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type Ref = HTMLInputElement;

const ThemeInput = forwardRef<Ref, TProps>(({ isIntelleka, className = '', ...props }, ref) => {
  return (
    <input {...props} className={`${isIntelleka ? 'input-i' : 'input'} ${className}`} ref={ref} />
  );
});

export default ThemeInput;
