import { useQuery } from 'react-query';
import Table from '../../../shared/Table';
import AdminTemplate from '../templates/AdminTemplate';
import moment from 'moment';
import AdminEditCompanies from '../containers/AdminEditCompanies';
import { useState } from 'react';
import { FaMinus, FaPlus, FaRegTimesCircle } from 'react-icons/fa';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import BadgeButton from '../../../shared/BadgeButton';
import { API } from '../../../api';
import UserCourses from '../../../shared/UserCourses';
import { Link, useNavigate } from '@tanstack/react-location';
import { ADMIN_USERS, generateUserLink } from '../routes';
import { useUsersParams } from '../store/useUsers';
import { AdminExamCheck, AdminExamDate } from './AdminExam';

const titles = ['Компания', 'Пользователей', 'Дата создания', 'Действия'];

export const key = 'companies';

const AdminCompanies: React.FC = () => {
  const { data, isLoading } = useQuery(key, () => API.admin.company.all());
  const [openedCompany, setOpenedCompany] = useState<number>(0);
  const [y, setY] = useState<number>(0);

  const { data: users, isLoading: isUsersLoading } = useQuery(
    ['company-users', openedCompany],
    () => API.admin.user.all({ company: openedCompany }),
    {
      enabled: !!openedCompany,
    },
  );

  const users_titles = [
    <div>ФИО</div>,
    <div>Логин</div>,
    <div>Курс</div>,
    <div>Дата создания</div>,
    <div>Компания</div>,
    <div>Статус</div>,
    <div>Экзамен</div>,
    <div>Дата сдачи экзамена</div>,
  ];

  const params = useUsersParams();
  const navigate = useNavigate();

  const users_rows = users?.results
    .sort((a, b) => new Date(a.date_joined).getTime() - new Date(b.date_joined).getTime())
    .reverse()
    .map((u, i) => {
      const userCourses = u.courses?.sort((a, b) => a.id - b.id);

      return [
        <Link to={generateUserLink(u.id, '/')}>{u.first_name}</Link>,
        <Link to={generateUserLink(u.id, '/')}>{u.username}</Link>,
        <UserCourses u={u} i={i} />,
        <>{moment(u.date_joined).format('DD.MM.YYYY')}</>,
        <div className="flex justify-center">
          <BadgeButton
            className="badge bg-gray-badge w-fit text-sm cursor-pointer"
            onClick={() => {
              params.updateParam('company', u.company);
              navigate({ to: ADMIN_USERS });
            }}
          >
            {u.company_title}
          </BadgeButton>
        </div>,
        <div className="flex justify-center">
          {u.is_active ? (
            <AiOutlineCheckCircle className="text-green-500" />
          ) : (
            <FaRegTimesCircle className="text-red-500" />
          )}
        </div>,
        <>
          {userCourses?.map((course, _, self) => {
            return (
              <AdminExamCheck
                key={course.id}
                courseId={course.id}
                last_test_passings={course.last_test_passings}
                userId={u.id}
                isPluralCourses={self.length > 1}
              />
            );
          })}
        </>,
        <>
          {userCourses?.map((course) => {
            return <AdminExamDate key={course.id} testPassings={course.last_test_passings} />;
          })}
        </>,
      ];
    });

  const companies = data?.map((c) => [
    <div className="flex items-center relative">
      <div
        className="mr-1 cursor-pointer"
        onClick={(e) => {
          setOpenedCompany(openedCompany === c.id ? 0 : c.id);
          // console.log(e.clientY, e.pageY, e.screenY, e.movementY);
          openedCompany === c.id ? setY(0) : setY(e.pageY);
        }}
      >
        {openedCompany === c.id ? <FaMinus /> : <FaPlus />}
      </div>
      <div>{c.title}</div>
    </div>,
    <BadgeButton className="text-right badge bg-gray-badge w-fit text-[12px] font-bold ml-[calc(100%-25px)] lg:m-auto cursor-default">
      {c.all_users}
    </BadgeButton>,
    <>{moment(c.created).format('DD.MM.YYYY')}</>,
    <AdminEditCompanies company={c} />,
  ]);

  const isDesktop = window.innerWidth > 991;

  return (
    <AdminTemplate>
      <div>Компаний в системе: {data?.length}</div>
      <Table titles={titles} rows={companies} isLoading={isLoading} />
      <div
        className="absolute w-full bg-white shadow-lg"
        style={{
          display: openedCompany === 0 ? 'none' : 'block',
          width: isDesktop ? window.innerWidth - 64 : window.innerWidth - 32,
          top: y + 20,
        }}
      >
        <Table titles={users_titles} rows={users_rows} isLoading={isUsersLoading} />
      </div>
    </AdminTemplate>
  );
};

export default AdminCompanies;
