import int_logo from '../img/logo_inteleca.svg';
import pr_logo from '../img/logo_promr.svg';

export interface ILogoProps {
  /** Интерфейс интелеки */
  isIntelleka?: boolean;
}

/** Компонент логотипа */
const Logo: React.FC<ILogoProps> = ({ isIntelleka = false }) => {
  const logo = isIntelleka ? int_logo : pr_logo;

  return <img src={logo} alt="logo" className="block w-[120px] md:w-[180px]" />;
};

export default Logo;
