/** Cтрелка */
const ArrowLeft: React.FC<{ className?: string; isIntelleka?: boolean }> = ({
  className = '',
  isIntelleka = false,
}) => {
  return (
    <div
      data-testid="arrow-left"
      className={`
      h-[10px]
      relative
      ${isIntelleka ? 'arrow-left-i' : 'arrow-left'}
      before:content-['']
      before:top-0
      before:left-0
      before:block
      before:h-[7px]
      before:w-[2px]
    before:bg-[#1e1d22]
      before:absolute
      before:rotate-[40deg]
      after:content-['']
      after:top-1
      after:left-0
      after:block
      after:h-[7px]
      after:w-[2px]
      after:rotate-[-40deg]
    after:bg-[#1e1d22]
      after:absolute

      before:transition-all
      before:duration-500
      after:transition-all
      after:duration-500

      ${className}
    `}
    />
  );
};

export default ArrowLeft;
