import { useMedia } from 'use-media';

import { rangeNumbers } from '../utils/array';

/**
 * Хук преобразуент цыфру количества страниц в массив ссылок для компонента пагинации,
 * которые нужно отрендерить на страницах с разной шириной экрана
 * @param activePage активная страница
 * @param countPages количество страниц
 * @returns массив ссылок для компонента пагинации
 */
export const useShortListNumbers = (
  activePage: number,
  countPages: number,
  isLimited?: boolean,
) => {
  const numPages = rangeNumbers(countPages);
  const sm = useMedia({ minWidth: '576px' }) && 3;
  const md = useMedia({ minWidth: '768px' }) && 3;
  const lg = useMedia({ minWidth: '992px' }) && 3;
  const xl = useMedia({ minWidth: '1200px' }) && isLimited ? 1 : 4;
  const siblingPages = xl || lg || md || sm || 2;

  if (numPages.length <= 6) {
    return numPages;
  }

  if (activePage < 4) {
    const shortNumPages: (number | string)[] = rangeNumbers(4);
    shortNumPages.push('...');
    return shortNumPages.concat(numPages.length);
  }

  function setAdoptListPages(size: number) {
    if (size * 2 >= countPages) {
      return numPages;
    }

    const startNum = activePage - (1 + size) < 0 ? 0 : activePage - (1 + size);
    const endNum = activePage + size > numPages.length ? numPages.length : activePage + size;

    const result: (number | string)[] = numPages.slice(startNum, endNum);

    if (startNum > 0) {
      if (activePage - size > 1) {
        result.shift();
      }
      result.unshift('...');
      result.unshift(1);
    }

    if (activePage + size < numPages.length) {
      if (activePage + size <= numPages.length - 1) {
        result.pop();
      }
      result.push('...');
      result.push(numPages.length);
    }

    return result;
  }

  return setAdoptListPages(siblingPages);
};
