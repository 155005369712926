import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Alert } from '@mui/material';
import { IUser } from '../../../typings/admin';
import { useMutation, useQuery } from 'react-query';
import { getToken } from '../../../api/common';
import { IAuthParams } from '../../../typings/common';
import { queryClient } from '../../../hooks/queryClient';
import { LINK_LOGIN } from '../../auth/routes';
import { checkIsIntelleka } from '../../../utils/link';
import ThemeInput from '../../../shared/ThemeInput';

export function useLinkAuthenticateUser(userId: number) {
  return useQuery(['link-token', userId], (): string => '', {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}

const AdminAuthLink: React.FC<{ user: IUser }> = (props) => {
  const [linkCopied, setLinkCopied] = useState(false);

  const { data: authLink } = useLinkAuthenticateUser(props.user.id);
  const {
    mutate: getLink,
    isLoading,
    isError,
  } = useMutation<{ access: string }, unknown, { userId: number } & IAuthParams>(
    async ({ password, username }) => await getToken({ password, username }),
    {
      onSuccess: (token, { userId }) => {
        const link = `${window.location.host}${LINK_LOGIN}/?access=${token.access}`;
        queryClient.setQueryData(['link-token', userId], () => link);
      },
    },
  );

  const copyLinkToBuffer = () => {
    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 3000);
  };

  const isIntelleka = checkIsIntelleka();

  return (
    <label className="w-full mb-1 lg:mb-0">
      <div className="mb-1">Ссылка авторизации</div>

      {!authLink && (
        <ThemeInput
          disabled={isLoading}
          isIntelleka={isIntelleka}
          className="w-full cursor-pointer focus:cursor-pointer"
          onClick={() =>
            getLink({
              userId: props.user.id,
              username: props.user.username,
              password: String(props.user.old_password),
            })
          }
          onChange={() => null}
          value={
            isLoading ? 'Получение...' : isError ? 'Пользователь не активен' : 'Получить ссылку'
          }
        />
      )}

      {authLink && (
        <div className="relative">
          <ThemeInput
            type="text"
            value={authLink}
            className="w-full"
            onChange={() => null}
            isIntelleka={isIntelleka}
          />
          <CopyToClipboard text={authLink || ''} onCopy={copyLinkToBuffer}>
            <button
              type="button"
              className="absolute top-0 right-0 h-full bg-gray-100 px-2 hover:bg-gray-200 transition-all"
            >
              Копировать
            </button>
          </CopyToClipboard>
        </div>
      )}
      {linkCopied && (
        <Alert
          color="info"
          className="fixed top-0 md:top-16 left-0 right-0 m-auto w-full max-w-md"
          onClose={() => setLinkCopied(false)}
        >
          Ссылка скопирована в буфер обмена.
        </Alert>
      )}
    </label>
  );
};

export default AdminAuthLink;
