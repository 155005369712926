import { Tooltip } from '@mui/material';

const CustomTooltip: React.FC<{
  text: string | React.ReactElement;
  bg?: string;
  delayShow?: number;
  delayHide?: number;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  effect?: 'float' | 'solid';
  className?: string;
}> = ({ className = 'text-sm text-center', children, text, placement = 'top' }) => {
  return (
    <Tooltip arrow placement={placement} title={<div className={className}>{text}</div>}>
      <div>{children}</div>
    </Tooltip>
  );
};

export default CustomTooltip;
