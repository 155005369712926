import AdminChatsTemplate from '../templates/AdminChatsTemplate';
import { useQuery } from 'react-query';
import { getAllChats, getCurrentUser } from '../../../api/common';
import Table from '../../../shared/Table';
import { filterSupportChats } from '../../../utils/chat';
import moment from 'moment';
import { Link } from '@tanstack/react-location';
import { generateChat } from '../routes';
import { useChats } from '../store/useChats';
import Pill from '../../../shared/Pill';
import { sortChatsByLastMessage } from '../../../utils/array';
import AdminRemoveChat from '../containers/AdminRemoveChat';

export const adminChatTitles = ['Логин', 'Сообщений', 'Последнее сообщение', ''];

const AdminChatsSupport: React.FC = () => {
  const key = 'support';
  const { data: user } = useQuery('user', getCurrentUser);
  const { data, isFetching } = useQuery(
    key,
    () => getAllChats({ users: user?.id, chat_status: 0, ordering: '-created' }),
    {
      enabled: !!user?.id,
    },
  );

  const { normalizedChats } = useChats();

  sortChatsByLastMessage(data);

  const chats = filterSupportChats(data)?.map((c) => {
    const unreadMessages = c.id ? normalizedChats[c.id] : 0;
    return [
      <Link to={generateChat(true, c.id)} className="cursor-pointer">
        <span className={`${!!unreadMessages ? 'font-bold' : 'font-normal'}`}>
          {c.list_users?.find((u) => u.id !== user?.id)?.username}
        </span>
        {unreadMessages && <Pill background="#6c757d">{unreadMessages}</Pill>}
      </Link>,
      <span className={`${!!unreadMessages ? 'font-bold' : 'font-normal'}`}>{c.num_messages}</span>,
      <span className={`${!!unreadMessages ? 'font-bold' : 'font-normal'}`}>
        {c.last_message_date ? moment(c.last_message_date).format('DD.MM.YYYY') : '-'}
      </span>,
      <>{c.id && <AdminRemoveChat id={c.id} refetchKey={key} />}</>,
    ];
  });

  return (
    <AdminChatsTemplate>
      <Table titles={adminChatTitles} rows={chats} isLoading={!data && isFetching} />
    </AdminChatsTemplate>
  );
};

export default AdminChatsSupport;
