/**
 * @module DatePicker
 * @category Общие компоненты
 */

import 'react-datepicker/dist/react-datepicker.css';

import ru from 'date-fns/locale/ru';
import { SyntheticEvent } from 'react';
import DatePickerComponent, { registerLocale } from 'react-datepicker';

import { normalizeDate } from '../utils/time';
import ThemeInput from './ThemeInput';

registerLocale('ru', ru);

/** Кастомизация компонента из библиотеки [react-datepicker](https://github.com/Hacker0x01/react-datepicker) */
const DatePicker: React.FC<{
  /** событие изменения даты */
  onChange(value: Date | null, field: string, type?: string): void;
  /** значение */
  value: string;
  /** поле */
  field: string;
  /** формат даты */
  dateFormat: string;
  /** айди */
  id?: string;
  /** запрет на редактирование */
  disabled?: boolean;
  /** класс */
  className?: string;
  /** стиль интелеки */
  isIntelleka?: boolean;
}> = ({ onChange, value, field, dateFormat, id, disabled, className = '', isIntelleka }) => {
  return (
    <DatePickerComponent
      className={className}
      popperPlacement="top"
      id={id}
      selected={normalizeDate(value, '-')}
      dateFormat={dateFormat}
      locale="ru"
      customInput={<ThemeInput isIntelleka={!!isIntelleka} className="w-full" value={value} />}
      onChange={(value: Date | null, event: SyntheticEvent<Element, Event> | undefined) => {
        onChange(value, field, event?.nativeEvent?.type);
      }}
      disabled={disabled}
    />
  );
};

export default DatePicker;
