import { useEffect } from 'react';
import useWebSocket from 'react-use-websocket';
import { getClientBrowserAndOs } from '../utils/ua-parser';
import { useTest } from '../ui/client/store/useTest';
import { createWebsocketUrl } from '../utils/link';
import { useCourse } from '../ui/client/store/useCourse';
import { getConnectionStatus } from '../utils/react-use-websocket';
import { useStatistic } from '../ui/client/store/useStatistic';

export function useSendStatisticUser() {
  const { course } = useCourse();
  const { user: currentUser } = useTest();
  const socketUrl = createWebsocketUrl('user_activity/');
  const { sendJsonMessage, readyState } = useWebSocket(socketUrl);
  const { setConnectionStatus } = useStatistic();

  useEffect(() => {
    currentUser && setConnectionStatus(getConnectionStatus(readyState), currentUser?.id);
  }, [readyState, currentUser]);

  const pathname = window.location.pathname;

  useEffect(() => {
    const { os, browser } = getClientBrowserAndOs();
    if (course && currentUser?.user_status === 2) {
      sendJsonMessage({ user: currentUser?.id, os, browser, time: Date.now(), course: course });
    }
  }, [currentUser, course, sendJsonMessage, pathname]);
}
