import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API } from '../../../api';
import { IMaterial, TUpdateMaterial } from '../../../typings/admin';
import { useSortMaterials } from '../../../ui/admin/store/useMaterials';
import { getError } from '../../../utils/api';
import { checkIsFileEmpty } from '../../../utils/link';
import { queryClient } from '../../../hooks/queryClient';
import { getMaterialsKey } from './';

export function useChangeMaterial(courseId: string) {
  const { toggleId, setError } = useSortMaterials();

  return useMutation<IMaterial, unknown, TUpdateMaterial>(
    (data) =>
      API.admin.material.update(data.id, {
        ...data,
        video: checkIsFileEmpty(data.video),
        pdf: checkIsFileEmpty(data.pdf),
      }),
    {
      onSuccess: (material) => {
        queryClient.setQueryData(getMaterialsKey({ course: courseId }), () => {
          const oldData = queryClient.getQueryData<IMaterial[]>(
            getMaterialsKey({ course: courseId }),
          );
          if (oldData) {
            return oldData.map((m) => (m.id === material.id ? material : m));
          }
        });
        toggleId();
      },
      onError: (e) => setError(getError(e as AxiosError)),
    },
  );
}
