import create from 'zustand';

export const useCourseTests = create<{
  openForm: boolean;
  toggleForm: (open: boolean) => void;

  error: string;
  setError(error: string): void;

  editingId?: number;
  setEditingId(id?: number): void;
}>((set) => ({
  openForm: false,
  toggleForm: (openForm) => set({ openForm }),

  error: '',
  setError: (error) => set({ error }),

  setEditingId: (editingId) => set({ editingId }),
}));
