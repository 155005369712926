import { useQuery } from 'react-query';
import { IUser } from '../../../typings/curator';
import Spinner from '../../../shared/Spinner';
import Tooltip from '../../../shared/Tooltip';
import { renderDate } from '../../../utils/time';
import { API } from '../../../api';
import { CURATOR_CACHE, CURATOR_CACHE_OPTIONS } from '../../../cache/curator';

const Previous: React.FC<{ userId: number }> = ({ userId }) => {
  const { data: curatorUserActivity, isFetching } = useQuery(
    [CURATOR_CACHE.ACTIVITY, userId],
    () => API.curator.getActivities({ user: String(userId) }),
    CURATOR_CACHE_OPTIONS,
  );
  const daysActivity = curatorUserActivity?.results.map((activity) => activity.day);

  return (
    <div className="text-sm text-center">
      <span>Последние посещения:</span>
      {isFetching && !daysActivity && (
        <div className="flex justify-center min-h-[20px]">
          <Spinner className="fill-white w-[13px]" />
        </div>
      )}
      {daysActivity
        ?.reverse()
        .map((el, index) => (
          <span className="block" key={index}>
            {renderDate(new Date(el).toString())}
          </span>
        ))
        .slice(0, 30)}
    </div>
  );
};

const CuratorLastVisit: React.FC<{ user: IUser }> = ({ user }) => {
  const { last_activity, id } = user;

  return (
    <div className="text-center">
      {last_activity ? (
        <>
          <Tooltip text={<Previous userId={id} />}>
            <span>{renderDate(last_activity)}</span>
          </Tooltip>
        </>
      ) : (
        <div className="text-gray-600">не заходил на сайт</div>
      )}
    </div>
  );
};

export default CuratorLastVisit;
