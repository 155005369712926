import { Tooltip } from '@mui/material';
import {
  ARROW_ICON,
  CLOSE_SIDEBAR_ICON,
  FULL_SCREEN_ICON,
  OPEN_SIDEBAR_ICON,
  ZOOM_IN_ICON,
  ZOOM_OUT_ICON,
} from '../../../img/data-image';
import SvgNext from '../../../react-svg/SvgNext';
import { checkIsIntelleka } from '../../../utils/link';
import { useMaterial } from '../store/useMaterial';

const PdfHeader: React.FC<{
  setScale(v: number): void;
  scale: number;
  pages: number[];
  page: number;
  goToPage(p: number): void;
  handleFullScreen(): void;
  height: number;
}> = ({ setScale, scale, pages, page, goToPage, handleFullScreen, height = 34 }) => {
  const { toggleSidebar, isSidebarVisible, setNext } = useMaterial();

  const setPageNumber = (page: number) => {
    if (page > pages.length) return;
    if (page < 1) return;
    goToPage(page);
  };

  const isIntelleka = checkIsIntelleka();

  return (
    <div
      style={{ height }}
      className="flex items-center justify-between absolute top-0 w-full z-10 bg-black-default px-[15px] xl:px-[30px] text-gray-pdf_header text-[16px]"
    >
      <Tooltip
        arrow
        title={
          <div className="text-sm -m-1 p-1">
            {isSidebarVisible ? 'Свернуть боковое меню' : 'Развернуть боковое меню'}
          </div>
        }
      >
        <img
          src={isSidebarVisible ? CLOSE_SIDEBAR_ICON : OPEN_SIDEBAR_ICON}
          alt="sidebar-toggle"
          width={30}
          height={28}
          className="cursor-pointer"
          onClick={() => toggleSidebar(!isSidebarVisible)}
        />
      </Tooltip>
      <div className="flex items-center">
        <img
          src={ARROW_ICON}
          alt="prev-page"
          width={28}
          height={26}
          className="cursor-pointer"
          onClick={() => setPageNumber(page - 1)}
        />
        <img
          src={ARROW_ICON}
          alt="next-page"
          width={28}
          height={26}
          className="rotate-180 cursor-pointer"
          onClick={() => setPageNumber(page + 1)}
        />
        <input
          type="text"
          className="w-[52px] h-[30px] px-[10px] rounded text-[16px] mx-[10px] focus:outline-none bg-white"
          value={page}
          disabled
        />
        <span>из {pages.length}</span>
      </div>
      <div className="hidden lg:flex">
        <img
          onClick={() => {
            if (scale === 2) return;
            setScale(scale + 0.25);
          }}
          src={ZOOM_IN_ICON}
          alt="zoom-in"
          width={28}
          height={26}
          className="cursor-pointer"
        />
        <div className="w-[1px] h-[26px] mx-2 bg-gray-pdf_header" />
        <img
          onClick={() => {
            if (scale === 0.5) return;
            setScale(scale - 0.25);
          }}
          src={ZOOM_OUT_ICON}
          alt="zoom-out"
          width={28}
          height={26}
          className="cursor-pointer"
        />
        <select
          onChange={(value) => {
            const v = Number(value.target.value);
            setScale(v > 0 ? v : 0.1);
          }}
          className="bg-black-default ml-2 border-gray-dark border text-[#737678] appearance-none px-5 focus: outline-none cursor-pointer"
          value={scale}
        >
          <option value={0.5}>50%</option>
          <option value={0.75}>75%</option>
          <option value={1}>100%</option>
          <option value={1.25}>125%</option>
          <option value={1.5}>150%</option>
          <option value={1.75}>175%</option>
          <option value={2}>200%</option>
        </select>
      </div>

      <div
        onClick={setNext}
        className={`flex text-white items-center ${
          isIntelleka ? 'hover:text-blue-400' : 'hover:text-active_p'
        } transition-all duration-300 cursor-pointer`}
      >
        <div className="mr-2 mb-1">Далее</div>
        <div className="w-[24px]">
          <SvgNext />
        </div>
      </div>

      <Tooltip
        title={<div className="text-sm text-center">Полноэкранный режим</div>}
        arrow
        placement="top"
      >
        <img
          src={FULL_SCREEN_ICON}
          alt="pdf-full-screen"
          width={30}
          height={28}
          className="hidden lg:block cursor-pointer"
          onClick={handleFullScreen}
        />
      </Tooltip>
    </div>
  );
};

export default PdfHeader;
