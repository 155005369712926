/** Компонент для разделения сообщений в чате по датам  */
const MessageDate: React.FC<{ date: string }> = (props) => {
  return (
    <div className="text-center text-[13px] relative first-letter:capitalize">
      <span className="bg-white inline-flex justify-center z-20 relative px-[15px] text-gray-dark">
        {props.date}
      </span>
      <span className="absolute top-[9px] left-0 w-full h-[1px] bg-gray-line z-10" />
    </div>
  );
};

export default MessageDate;
