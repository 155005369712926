import { isCurrentBuildProd } from '../../../constants';
import { QuickLoginAccess } from '../containers/AdminOneClick';
import AdminTemplate from '../templates/AdminTemplate';
import { SETTINGS_TITLES } from './AdminSettings';

const AdminSettingsAuth: React.FC = () => (
  <AdminTemplate>
    {!isCurrentBuildProd ? (
      <>
        <h1 className="text-[30px] text-center mb-6">{SETTINGS_TITLES[1]}</h1>
        <QuickLoginAccess />
      </>
    ) : (
      <div className="italic">Раздел доступен только на тестовом сервере</div>
    )}
  </AdminTemplate>
);

export default AdminSettingsAuth;
