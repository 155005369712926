import axios from 'axios';

import {
  IAuthPassword,
  ICreatedMassUsers,
  IMassUsers,
  IUser,
  IUsersParams,
  IUsersRes,
  IUserUpdateCourse,
  TCreateUser,
  TUpdateUser,
} from '../../typings/admin';
import { toFormData } from '../../utils/api';

/**
 * функция возвращает массив пользователей
 * @param params параметры
 * @returns массив пользователей
 */
export async function all(params: Partial<IUsersParams>) {
  try {
    const { data } = await axios.get<IUsersRes>(`/api/v1/users/user/`, { params });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function exams(params: Partial<IUsersParams>) {
  try {
    const { data } = await axios.get<IUsersRes>(`/api/v1/users/user_exams/`, { params });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function byId(id: number | string) {
  try {
    const { data } = await axios.get<IUser>(`/api/v1/users/user/${id}/`);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function create(model: TCreateUser) {
  try {
    const { data } = await axios.post<{ message: string }>('/api/v1/users/user/', model);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createUser(model: TCreateUser) {
  try {
    const { data } = await axios.post<{ message: string }>('/api/v1/users/single_user/', model);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function update({ id, ...model }: TUpdateUser) {
  try {
    const { data } = await axios.patch<IUser>(`/api/v1/users/user/${id}/`, model);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createMass(model: IMassUsers) {
  try {
    const { data } = await axios.post<ICreatedMassUsers>(
      '/api/v1/users/mass_users/',
      toFormData(model),
    );

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function remove(userId: number) {
  try {
    const { data } = await axios.delete(`/api/v1/users/user/${userId}/`);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updatePassword(user: IAuthPassword) {
  try {
    const { data } = await axios.put<IAuthPassword>('/api/v1/users/user/change_password/', user);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updateCourse(model: IUserUpdateCourse) {
  try {
    const { data } = await axios.post<IUserUpdateCourse>('/api/v1/users/user_course/', model);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
