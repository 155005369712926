import { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { IAnswer, IQuestion, TCreateQuestion, TUpdateQuestion } from '../../../typings/admin';
import Label from '../../../shared/Label';
import RadioButton from '../../../shared/RadioButton';
import { toBase64 } from '../../../utils/file';
import { checkIsIntelleka } from '../../../utils/link';
import { useQuestions } from '../store/useQuestions';
import { Alert, Modal } from '@mui/material';
import { ThemeButton } from '../../../shared/Button';
import ThemeInput from '../../../shared/ThemeInput';
import ThemeTextarea from '../../../shared/ThemeTextarea';

export interface IVariantTexts {
  [text: string]: string;
}

const AdminQuestionForm: React.FC<{
  open: boolean;
  onClose(): void;
  submit(
    data: (TCreateQuestion | TUpdateQuestion) & { variants?: IVariantTexts } & {
      correctAnswers?: number[];
    },
  ): void;
  loading: boolean;
  edit?: IQuestion;
  answers?: IAnswer[];
  saveAnswers?: (answers: any, correct: any) => void;
}> = (props) => {
  const { handleSubmit, register, setValue, resetField, control, reset } = useForm<IQuestion>();
  const [image, setImage] = useState<unknown>('');
  const [variants, setVariants] = useState<number[]>([]);
  const [correctAnswers, setCorrectAnswers] = useState<number[]>([]);
  const [variantTexts, setVariantText] = useState<IVariantTexts>({});
  const { error, setError } = useQuestions();

  useEffect(() => {
    const el = document.getElementById(`variant-${variants.length - 1}`);
    variants && el?.focus();
  }, [variants]);

  useEffect(() => {
    if (!props.edit && props.open) {
      reset();
      setImage('');
      setVariants([]);
      setCorrectAnswers([]);
      setVariantText({});
    }
    if (props.edit && props.open) {
      setImage('');
    }
  }, [props.open, props.edit, reset]);

  const resetForm = () => {
    reset();
    setImage('');
    setVariants([]);
    setCorrectAnswers([]);
    setVariantText({});
  };

  useEffect(() => {
    props.answers?.forEach(
      (a) => !variantTexts[a.id] && setVariantText({ ...variantTexts, [a.id]: a.text }),
    );
  }, [props.answers, variantTexts]);

  useEffect(() => {
    if (props.open && props.edit) {
      props.answers && setVariants(props.answers.map((a) => a.id));
      props.answers && setCorrectAnswers(props.answers.filter((a) => a.is_true).map((a) => a.id));
    }
  }, [props.answers, props.open, props.edit]);

  useEffect(() => {
    if (props.open) {
      if (props.edit) {
        setValue('text', props.edit.text);
        setValue('score', props.edit.score);
        setValue('is_free_answer', props.edit.is_free_answer);
      } else {
        setValue('score', 1);
        setValue('is_free_answer', false);
      }
    }
  }, [props.edit, setValue, props.open]);

  const onAddFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      try {
        const file = files[0];
        const link = await toBase64(file);

        setImage(link);
        setValue('m_file', file);
      } catch (e) {
        throw Error();
      }
    }
  };

  const handleClose = () => {
    resetForm();
    props.onClose();
  };

  const watchQuestionXlsxFile = useWatch<IQuestion>({ control, name: 'file' });
  const watchQuestionScore = useWatch<IQuestion>({ control, name: 'score' });
  const watchQuestionText = useWatch<IQuestion>({ control, name: 'text' });
  const watchQuestionFreeAnswer = useWatch<IQuestion>({ control, name: 'is_free_answer' });

  const onSubmit = (data: IQuestion) => {
    props.edit
      ? props.submit(data)
      : props.submit({ ...data, correctAnswers, variants: variantTexts });
    props.edit && props.saveAnswers && props.saveAnswers(variantTexts, correctAnswers);
  };

  const isIntelleka = checkIsIntelleka();

  return (
    <Modal open={props.open} onClose={handleClose}>
      <div className="h-full overflow-auto">
        <div className="bg-white rounded max-w-[800px] mt-[1.75rem] m-auto">
          <div className="flex items-center justify-between p-4 border-b border-gray-default">
            <h5 className="text-lg">{props.edit ? 'Редактировать вопрос' : 'Добавить вопрос'}</h5>
            <FaTimes
              onClick={handleClose}
              className="transition-all opacity-60 hover:opacity-100 cursor-pointer"
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <div className="p-4">
              {!watchQuestionXlsxFile && (
                <Label label="Вопрос">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <ThemeTextarea
                        {...field}
                        className="w-full"
                        isIntelleka={isIntelleka}
                        autoFocus
                      />
                    )}
                    name="text"
                  />
                </Label>
              )}
              {!props.edit && (
                <Label label="Добавить файл с вопросами">
                  <input
                    type="file"
                    {...register('file')}
                    className="w-full"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </Label>
              )}
              {!watchQuestionXlsxFile && (
                <>
                  <label className="cursor-pointer hover:bg-slate-500 transition-all py-[10px] px-[23px] bg-[rgb(63,66,87)] rounded-[30px] text-white text-sm">
                    {props.edit?.m_file ? 'Изменить' : 'Добавить'} изображение
                    <input
                      className="hidden"
                      type="file"
                      onChange={onAddFile}
                      accept=".png, .jpg, .jpeg, .giff"
                    />
                  </label>
                  {image && typeof image === 'string' && (
                    <div className="relative w-fit mt-5">
                      <img src={image} alt="preview" className="w-full max-w-[200px]" />
                      <FaTimes
                        onClick={() => {
                          setImage('');
                          resetField('m_file');
                        }}
                        className="opacity-80 hover:opacity-100 transition-all top-[-6px] right-[-6px] bg-white shadow-input_p cursor-pointer p-[2px] text-lg text-active_p absolute rounded-full"
                      />
                    </div>
                  )}
                  {typeof props.edit?.m_file === 'string' && (
                    <img src={props.edit.m_file} alt="media" className="w-full mt-5" />
                  )}
                  <div className="mt-4 flex">
                    <div className="m-full max-w-[200px] sm:mr-2">
                      <label className="mb-2 block">
                        <div>Баллы за вопрос</div>
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <ThemeInput
                              {...field}
                              type="number"
                              className="w-full"
                              isIntelleka={isIntelleka}
                              min={1}
                            />
                          )}
                          name="score"
                        />
                      </label>
                    </div>
                    <Controller
                      control={control}
                      render={({ field: { name, value } }) => {
                        return (
                          <label
                            onClick={() => {
                              if (props.edit?.is_free_answer) return;
                              setValue(name, !value);
                              // if need clear variants on free answer chosen - uncomment next 2 lines
                              // setCorrectAnswers([]);
                              // !value && setVariants([]);
                            }}
                            className="flex cursor-pointer items-center mt-4"
                          >
                            {props.edit?.is_free_answer ? (
                              <div className="opacity-60">
                                <RadioButton
                                  checked={!!value}
                                  isCheckBox
                                  isIntelleka={isIntelleka}
                                />
                              </div>
                            ) : (
                              <RadioButton checked={!!value} isCheckBox isIntelleka={isIntelleka} />
                            )}
                            <span>Вопрос с развернутым ответом</span>
                          </label>
                        );
                      }}
                      name="is_free_answer"
                    />
                  </div>

                  {!watchQuestionFreeAnswer &&
                    variants.map((v) => (
                      <div className="flex items-center mb-2" key={v}>
                        <ThemeInput
                          type="text"
                          className="w-full"
                          isIntelleka={isIntelleka}
                          id={`variant-${v}`}
                          defaultValue={variantTexts[v]}
                          onChange={(e) => setVariantText({ ...variantTexts, [v]: e.target.value })}
                        />
                        <div className="min-w-[200px] sm:ml-2">
                          <label
                            onClick={() => {
                              correctAnswers.includes(v)
                                ? setCorrectAnswers(correctAnswers.filter((a) => a !== v))
                                : setCorrectAnswers([...correctAnswers, v]);
                            }}
                            className="flex cursor-pointer"
                          >
                            <RadioButton
                              checked={correctAnswers.includes(v)}
                              isCheckBox
                              isIntelleka={isIntelleka}
                            />
                            <span className="select-none">Правильный ответ</span>
                          </label>
                        </div>
                      </div>
                    ))}

                  {!props.edit && (
                    <FaPlus
                      className={`${
                        isIntelleka
                          ? 'text-active_i border-2 border-active_i hover:bg-active_i'
                          : 'text-active_p border-2 border-active_p hover:bg-active_p'
                      }   p-[5px] rounded text-[30px] cursor-pointer hover:text-white transition-all`}
                      onClick={() => {
                        setVariants([...variants, variants.length]);
                        setVariantText({ ...variantTexts, [variants.length]: '' });
                        setValue('is_free_answer', false);
                      }}
                    />
                  )}
                </>
              )}
            </div>
            <div className="flex justify-end p-4 border-t border-gray-default">
              <ThemeButton
                className="mr-2"
                isIntelleka={isIntelleka}
                isSecondary
                type="button"
                onClick={handleClose}
              >
                Отменить
              </ThemeButton>
              <ThemeButton
                isIntelleka={isIntelleka}
                type="submit"
                disabled={
                  props.loading ||
                  (!watchQuestionXlsxFile &&
                    (!watchQuestionScore ||
                      !watchQuestionText ||
                      (!watchQuestionFreeAnswer && !correctAnswers.length)))
                }
              >
                {props.loading ? 'Сохранение...' : 'Сохранить'}
              </ThemeButton>
            </div>
          </form>
          {!!error && (
            <Alert color="error" className="notification" onClose={() => setError('')}>
              {error}
            </Alert>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AdminQuestionForm;
