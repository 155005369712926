import axios from 'axios';
import { IBookmark, IBookmarkParams } from '../../typings/client';

export async function getAllBookmarks(params?: IBookmarkParams) {
  try {
    const { data } = await axios.get<IBookmark[]>('/api/v1/client/courses/bookmark/', { params });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createBookmark(model: Partial<IBookmark>) {
  try {
    const { data } = await axios.post<IBookmark>('/api/v1/client/courses/bookmark/', model);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function changeBookmark({ id, ...model }: Partial<IBookmark>) {
  try {
    const { data } = await axios.patch<IBookmark>(`/api/v1/client/courses/bookmark/${id}/`, model);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function deleteBookmark(id: number) {
  try {
    const { data } = await axios.delete(`/api/v1/client/courses/bookmark/${id}/`);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
