import { Link } from '@tanstack/react-location';
import { IUser } from '../typings/admin';
import { generateCourseLink } from '../ui/admin/routes';
import Truncate from './Truncate';

/** Компонент рендера курсов пользователя */
const UserCourses: React.FC<{
  /** объект пользователя */
  u: IUser;
  /** индекс пользователя */
  i: number;
}> = ({ u, i }) => (
  <>
    {u.courses
      ? u.courses
          .sort((a, b) => a.id - b.id)
          .map((course, index, self) => {
            const key = `_id_${course.id}_${u.id}_${index}`;
            return self.length > 1 ? (
              <div key={key} className="text-center">
                <Link
                  id={key}
                  className="whitespace-nowrap"
                  to={generateCourseLink(course.id, '/')}
                >
                  <Truncate
                    count={20}
                    title={course.title}
                    hoverBackGround={i % 2 === 0 ? '#f5f5f5' : '#fff'}
                  />
                </Link>
              </div>
            ) : (
              <div key={key} className="text-center">
                <Link
                  id={key}
                  className="black-link d-block"
                  to={generateCourseLink(course.id, '/')}
                >
                  {course.title}
                </Link>
              </div>
            );
          })
      : '-'}
  </>
);

export default UserCourses;
