/** Компонент рендера чекбокс слайдера */
const RadioToggle: React.FC<{
  /** текст */
  label: string;
  /** включено */
  checked: boolean;
  /** колбек при изменении */
  onChange(): void;
  /** класс для стилизации */
  className?: string;
  /** наработающий */
  disabled?: boolean;
  /** стиль интелеки */
  isIntelleka?: boolean;
}> = ({ label, checked, onChange, className = '', disabled = false, isIntelleka = false }) => {
  return (
    <label className={`relative flex items-center group ${className}`}>
      <input
        type="checkbox"
        className={`${
          disabled ? 'cursor-default bg-transparent' : 'cursor-pointer'
        } absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md`}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <span
        className={`w-8 h-4 flex items-center flex-shrink-0 p-1 bg-gray-300 rounded-full duration-300 ease-in-out ${
          disabled
            ? 'peer-checked:bg-gray-300'
            : `${isIntelleka ? 'peer-checked:bg-active_i' : 'peer-checked:bg-active_p'}`
        } after:w-3 after:h-3 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3`}
      />
      <span className={`ml-2 ${disabled ? 'text-gray-400' : ''}`}>{label}</span>
    </label>
  );
};

export default RadioToggle;
