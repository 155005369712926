import { Tooltip } from '@mui/material';
import moment from 'moment';
import { BiTimeFive } from 'react-icons/bi';
import { IUserDevice } from '../typings/admin';
import { onlyUnique } from '../utils/array';
import { filterActivityByDay } from '../utils/statistic';

/** Компонент рендерит время в админке проведенное пользователем на образовательном портале */
const DeviceTimeItem: React.FC<{
  currentDay: string;
  devices: IUserDevice[];
  className?: string;
}> = ({ className = '', ...props }) => {
  return (
    <div className={`flex items-center ${className}`}>
      <span>{props.currentDay}</span>
      <Tooltip
        arrow
        placement="top"
        title={filterActivityByDay(props.devices, props.currentDay)
          .map((el) => moment(el.payload.time).format('hh:mm'))
          .filter(onlyUnique)
          .join(', ')}
      >
        <div className="ml-1">
          <BiTimeFive />
        </div>
      </Tooltip>
    </div>
  );
};

export default DeviceTimeItem;
