import { useQuery } from 'react-query';
import { FaTimes } from 'react-icons/fa';
import { getAllChats, getCurrentUser } from '../../../api/common';
import Table from '../../../shared/Table';
import { Link, useMatchRoute } from '@tanstack/react-location';
import AdminChatsTemplate from '../templates/AdminChatsTemplate';
import { ADMIN_CHAT_COMPANIES, generateChat } from '../routes';
import { filterSupportChats } from '../../../utils/chat';
import { adminChatTitles } from './AdminChatsSupport';
import moment from 'moment';

const AdminCompanyChats: React.FC = () => {
  const matchRoute = useMatchRoute();
  const company = matchRoute({ to: ADMIN_CHAT_COMPANIES })?.companyId;

  const { data: user } = useQuery('user', getCurrentUser);
  const { data, isFetching } = useQuery(
    ['support', company],
    () => getAllChats({ users: user?.id, chat_status: 0, ordering: '-created', company }),
    {
      enabled: !!user?.id && !!company,
    },
  );

  const chats = filterSupportChats(data)?.map((c) => [
    <Link to={generateChat(true, c.id)} className="cursor-pointer">
      {c.list_users?.find((u) => u.id !== user?.id)?.username}
    </Link>,
    <>{c.num_messages}</>,
    <>{c.last_message_date ? moment(c.last_message_date).format('DD.MM.YYYY') : '-'}</>,
    <FaTimes className="cursor-pointer hover:text-active_p" />,
  ]);

  return (
    <AdminChatsTemplate>
      <Table titles={adminChatTitles} rows={chats} isLoading={!data && isFetching} />
    </AdminChatsTemplate>
  );
};

export default AdminCompanyChats;
