import { useQuery } from 'react-query';
import ClientTemplate from '../templates/ClientTemplate';
import { useCourse } from '../store/useCourse';
import { API } from '../../../api';
import { useGetMaterials } from '../../../cache/client/material';
import { useCourseMaterials } from '../store/useCourseMaterials';
import { useEffect } from 'react';
import { useMaterial } from '../store/useMaterial';
import { keyBy } from 'lodash';

const ClientInfo: React.FC = () => {
  const { data: courses } = useQuery('courses', () => API.client.course.getAllCourses());
  const { course } = useCourse();
  const course_description = courses?.find((c) => c.id === course)?.description || '';
  useGetMaterials(course);
  const { setCourseMaterials } = useCourseMaterials();

  const { materials } = useMaterial();

  useEffect(() => {
    const materialsById = keyBy(materials, 'id');
    setCourseMaterials(materialsById);
  }, [materials, setCourseMaterials]);

  return (
    <ClientTemplate>
      <div dangerouslySetInnerHTML={{ __html: course_description }} className="p-7 children:p-4" />
    </ClientTemplate>
  );
};

export default ClientInfo;
