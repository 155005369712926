import CuratorTemplate from '../templates/CuratorTemplate';
import { useQuery } from 'react-query';
import { renderDate } from '../../../utils/time';
import { useMatch } from '@tanstack/react-location';
import { useCheckCourses } from '../store/useCheckCourses';
import Pagination from '../../../shared/Pagination';
import { getPagination } from '../../../utils/pagination';
import { useUsersParams } from '../store/useUsersParams';
import { useEffect } from 'react';
import Table from '../../../shared/Table';

import CuratorLastVisit from '../containers/CuratoLastVisit';
import CuratorCourseProgress from '../containers/CuratorCourseProgress';
import CuratorFinalResults from '../containers/CuratorFinalResults';
import Tooltip from '../../../shared/Tooltip';
import { checkIsIntelleka } from '../../../utils/link';
import BadgeButton from '../../../shared/BadgeButton';
import { API } from '../../../api';
import { CURATOR_CACHE, CURATOR_CACHE_OPTIONS } from '../../../cache/curator';

const titles = [
  'ФИО',
  'Курсы',
  'Последнее посещение',
  'Период обучения',
  'Промежуточные тесты',
  'Итоговый экзамен',
  'Дата сдачи экзамена',
];

const CuratorUsers: React.FC = () => {
  const { params: usersParams, updateParam, reset: resetParams } = useUsersParams();
  const { checked, reset: resetCourses } = useCheckCourses();

  const { data, isLoading, isFetched } = useQuery(
    [CURATOR_CACHE.USERS, usersParams],
    () => API.curator.getUsers(usersParams),
    CURATOR_CACHE_OPTIONS,
  );

  const { params } = useMatch();
  const { companyId } = params;
  const res = data?.results;

  useEffect(() => {
    return () => {
      resetParams();
      resetCourses();
    };
  }, [resetParams, resetCourses]);

  const users = companyId
    ? data?.results?.filter((user) => companyId === String(user.company.id))
    : checked && checked.length !== 0
    ? res?.filter(
        (user) =>
          user.courses.length && checked.some((r) => user.courses.map((c) => c.id).includes(r)),
      )
    : res;

  const paginationValues = getPagination(data?.count || 0, usersParams.page_size);

  const rows = users?.map((u) => [
    u.first_name,
    <>
      {u.courses.map((c, _, all) => {
        const isPlural = all.length > 1;
        return (
          <BadgeButton
            style={{
              whiteSpace: isPlural ? 'nowrap' : 'normal',
              margin: isPlural ? '2px 0' : '2px auto',
            }}
            className="badge bg-gray-badge_light w-min min-w-[50px] font-[500] text-sm cursor-default"
            key={c.id}
          >
            {isPlural && c.title.length > 18 ? (
              <Tooltip
                text={
                  <div className="text-sm text-center max-w-[200px] whitespace-normal">
                    {c.title}
                  </div>
                }
                placement="top"
              >
                <div>{`${c.title.slice(0, 18)}...`}</div>
              </Tooltip>
            ) : (
              c.title
            )}
          </BadgeButton>
        );
      })}
    </>,
    <CuratorLastVisit user={u} />,
    <CuratorCourseProgress user={u} />,
    <>
      {u.courses
        .sort((a, b) => a.id - b.id)
        .map((course) => {
          return (
            <div data-label={'-'} key={course.id}>
              {course.num_intermediate_tests ? (
                `Сдано ${
                  course.intermediate_passings.filter((passing) => passing.success_passed === 0)
                    .length
                } из ${course.num_intermediate_tests}`
              ) : (
                <div className="text-gray-400">не предусмотрены</div>
              )}
            </div>
          );
        })}
    </>,
    <>
      {u.courses
        .sort((a, b) => a.id - b.id)
        .map((course, _, self) => (
          <CuratorFinalResults
            key={course.id}
            finalPassings={course.final_passings}
            coursesCount={self.length}
          />
        ))}
    </>,
    <>
      {u.courses
        .sort((a, b) => a.id - b.id)
        .map((course) => {
          const successPassings = course.final_passings.filter(
            (passing) => passing.success_passed === 0,
          );
          if (successPassings.length) {
            return <div key={course.id}>{renderDate(successPassings[0].created)}</div>;
          } else {
            return <div key={course.id}>-</div>;
          }
        })}
    </>,
  ]);

  const isIntelleka = checkIsIntelleka();

  return (
    <CuratorTemplate>
      <Table titles={titles} rows={rows} isLoading={isLoading} />
      {isFetched && !companyId && !!data?.results?.length && (
        <div className="px-4 py-3 sm:px-6">
          <Pagination
            pagesCountOptions={paginationValues}
            activePage={usersParams.page}
            pageSize={usersParams.page_size}
            countElements={data?.count || 0}
            onClickNext={() => data?.next && updateParam('page', usersParams.page + 1)}
            onClickPrev={() => data?.previous && updateParam('page', usersParams.page - 1)}
            isIntelleka={isIntelleka}
            onChangeCurrentPage={(page) => updateParam('page', page)}
            onChangePageSize={(pageSize) => {
              updateParam('page', 1);
              updateParam('page_size', pageSize);
            }}
            menuPlacement="top"
          />
        </div>
      )}
    </CuratorTemplate>
  );
};

export default CuratorUsers;
