import { useForm, Controller } from 'react-hook-form';
import { ITest, TCreateTask, TUpdateTask } from '../../../typings/admin';
import Modal from '../../../shared/Modal';
import Label from '../../../shared/Label';
import RSelect from '../../../shared/ReactSelect';
import { useQuery } from 'react-query';
import { useMatch } from '@tanstack/react-location';
import { getDataForSelect } from '../../../utils/array';
import { useEffect, useState } from 'react';
import { toHoursAndMinutes } from '../../../utils/time';
import RadioToggle from '../../../shared/RadioToggle';
import { checkIsIntelleka } from '../../../utils/link';
import { API } from '../../../api';
import { useCourseTests } from '../store/useTests';
import { Alert } from '@mui/material';
import { ThemeButton } from '../../../shared/Button';
import ThemeInput from '../../../shared/ThemeInput';
import ThemeTextarea from '../../../shared/ThemeTextarea';

const AdminTestForm: React.FC<{
  open: boolean;
  onClose(): void;
  submit(data: TCreateTask & TUpdateTask): void;
  loading: boolean;
  edit?: ITest;
  materialId?: string;
}> = ({ open, onClose, submit, loading, edit, materialId }) => {
  const match = useMatch();
  const { params } = match;
  const { data: currentCourseMaterials, isLoading: materialsLoading } = useQuery(
    ['materials-by-course', params.id],
    () => API.admin.material.all({ course: params.id }),
  );

  const [retakeLabel, setRetakeLabel] = useState(0);
  const { error, setError } = useCourseTests();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
  } = useForm<ITest>();

  useEffect(() => {
    if (open) {
      reset();
      setError('');
    }
  }, [open, reset, setError]);

  const courseOptions = currentCourseMaterials?.map(getDataForSelect);

  useEffect(() => {
    if (materialId) {
      setValue('material', Number(materialId));
    } else {
      setValue('material', 0);
    }
    if (edit) {
      setValue('title', edit.title);
      setValue('material', edit.material);
      setValue('description', edit.description);
      setValue('travel_time', edit.travel_time);
      setValue('passing', edit.passing);
      setValue('id', edit.id);
      setValue('retake_seconds', edit.retake_seconds);
      setRetakeLabel(edit.retake_seconds);
      setValue('attempts', edit.attempts);
      setValue('trial_attempts', edit.trial_attempts);
      setValue('trial_percents', edit.trial_percents);
      setValue('is_final', edit.is_final);
      setValue('is_mix', edit.is_mix);
      setValue('is_chance', edit.is_chance);
      setValue('is_miss', edit.is_miss);
      setValue('is_hidden', edit.is_hidden);
      setValue('is_necessarily', edit.is_necessarily);
      setValue('text_explanation', edit.text_explanation);
    } else {
      setValue(
        'text_explanation',
        'Тестовое задание состоит из #number# вопросов. Для успешной сдачи необходимо правильно ответить на #question#% вопросов. На выполнение отводится #time#.',
      );
      setValue('travel_time', '02:00:00');
      setValue('passing', 75);
      setValue('retake_seconds', 86400);
      setRetakeLabel(86400);
      setValue('attempts', 100);
      setValue('trial_attempts', 1);
      setValue('trial_percents', 50);
      setValue('is_final', false);
      setValue('is_mix', true);
      setValue('is_chance', true);
      setValue('is_miss', true);
      setValue('is_hidden', false);
      setValue('is_necessarily', false);
    }
  }, [edit, setValue, open, materialId]);

  const isIntelleka = checkIsIntelleka();

  return (
    <Modal open={open} onClose={onClose} className="rounded max-w-screen-modal_md md:mt-7 p-4">
      <form onSubmit={handleSubmit((data) => submit(data))} className="w-full text-left">
        {!materialId && (
          <Label label={materialsLoading ? 'Загрузка...' : 'Материал'}>
            <Controller
              control={control}
              render={({ field: { name, value } }) => {
                return (
                  <RSelect
                    value={courseOptions?.find((c) => c.value === value)}
                    name={name}
                    options={courseOptions}
                    onChange={(data: any) => data && setValue(name, data?.value)}
                    isDisabled={materialsLoading}
                    isIntelleka={isIntelleka}
                  />
                );
              }}
              name="material"
            />
          </Label>
        )}
        <Label label="Задание">
          {Object.keys(errors).includes('title') && (
            <div className="text-red-500 mb-1">Это поле обязательно для заполнения</div>
          )}
          <Controller
            control={control}
            render={({ field }) => (
              <ThemeInput {...field} autoFocus isIntelleka={isIntelleka} className="w-full" />
            )}
            name="title"
            rules={{ required: true }}
          />
        </Label>
        <Label label="Описание">
          <Controller
            control={control}
            render={({ field }) => (
              <ThemeInput {...field} isIntelleka={isIntelleka} className="w-full" />
            )}
            name="description"
          />
        </Label>
        <Label label="Поясняющая надпись">
          <Controller
            control={control}
            render={({ field }) => (
              <ThemeTextarea {...field} className="w-full" isIntelleka={isIntelleka} />
            )}
            name="text_explanation"
          />
        </Label>
        <div className="flex flex-col lg:flex-row justify-between">
          <Label label="Ответов для зачета (%)">
            <Controller
              control={control}
              render={({ field }) => (
                <ThemeInput
                  {...field}
                  isIntelleka={isIntelleka}
                  className="w-full lg:w-[calc(100%-20px)]"
                  type="number"
                  min={0}
                  max={100}
                />
              )}
              name="passing"
            />
          </Label>
          <Label label="Время на задание (чч:мм:сс)">
            <Controller
              control={control}
              render={({ field }) => (
                <ThemeInput {...field} isIntelleka={isIntelleka} className="w-full" />
              )}
              name="travel_time"
            />
          </Label>
        </div>
        <div className="flex flex-col sm:flex-row justify-between">
          <div className="w-full sm:w-[calc(100%-20px)]">
            <Label label={`До следующей пересдачи (${toHoursAndMinutes(retakeLabel)})`}>
              <Controller
                control={control}
                render={({ field }) => (
                  <ThemeInput
                    {...field}
                    type="number"
                    onChange={(e) => {
                      setRetakeLabel(Number(e.target.value));
                      setValue('retake_seconds', Number(e.target.value));
                    }}
                    isIntelleka={isIntelleka}
                    className="w-full sm:w-[calc(100%-20px)]"
                    min={0}
                  />
                )}
                name="retake_seconds"
              />
            </Label>
          </div>
          <div className="flex flex-col sm:flex-row justify-between w-full sm:w-[calc(100%-20px)]">
            <Label label="Попыток">
              <Controller
                control={control}
                render={({ field }) => (
                  <ThemeInput
                    {...field}
                    isIntelleka={isIntelleka}
                    className="w-full"
                    min={0}
                    type="number"
                  />
                )}
                name="attempts"
              />
            </Label>
            <Label label="Пробных">
              <Controller
                control={control}
                render={({ field }) => (
                  <ThemeInput
                    {...field}
                    isIntelleka={isIntelleka}
                    className="w-full sm:w-[calc(100%-10px)] sm:ml-[5px]"
                    type="number"
                    min={0}
                  />
                )}
                name="trial_attempts"
              />
            </Label>
            <Label label="%">
              <Controller
                control={control}
                render={({ field }) => (
                  <ThemeInput
                    {...field}
                    isIntelleka={isIntelleka}
                    className="w-full"
                    type="number"
                    min={1}
                    max={100}
                  />
                )}
                name="trial_percents"
              />
            </Label>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-between mb-3">
          <div className="w-[calc(100%-20px)]">
            <Controller
              control={control}
              render={({ field: { name, value } }) => {
                return (
                  <RadioToggle
                    label="Является ИТОГОВЫМ"
                    checked={value}
                    onChange={() => setValue(name, !value)}
                    isIntelleka={isIntelleka}
                  />
                );
              }}
              name="is_final"
            />
            <Controller
              control={control}
              render={({ field: { name, value } }) => {
                return (
                  <RadioToggle
                    label="Перемешать ответы"
                    checked={value}
                    onChange={() => setValue(name, !value)}
                    isIntelleka={isIntelleka}
                  />
                );
              }}
              name="is_mix"
            />
            <Controller
              control={control}
              render={({ field: { name, value } }) => {
                return (
                  <RadioToggle
                    label="Вопросы в случайном порядке"
                    checked={value}
                    onChange={() => setValue(name, !value)}
                    isIntelleka={isIntelleka}
                  />
                );
              }}
              name="is_chance"
            />
          </div>
          <div className="w-[calc(100%-20px)]">
            <Controller
              control={control}
              render={({ field: { name, value } }) => {
                return (
                  <RadioToggle
                    label="Пропуск вопросов"
                    checked={value}
                    onChange={() => setValue(name, !value)}
                    isIntelleka={isIntelleka}
                  />
                );
              }}
              name="is_miss"
            />
            <Controller
              control={control}
              render={({ field: { name, value } }) => {
                return (
                  <RadioToggle
                    label="Скрыть результаты"
                    checked={value}
                    onChange={() => setValue(name, !value)}
                    isIntelleka={isIntelleka}
                  />
                );
              }}
              name="is_hidden"
            />
            <Controller
              control={control}
              render={({ field: { name, value } }) => {
                return (
                  <RadioToggle
                    label="Обязательно для дальнейшего прохождения"
                    checked={value}
                    onChange={() => setValue(name, !value)}
                    isIntelleka={isIntelleka}
                  />
                );
              }}
              name="is_necessarily"
            />
          </div>
        </div>
        <div>
          <ThemeButton
            onClick={onClose}
            className="mr-2"
            type="button"
            isSecondary
            isIntelleka={isIntelleka}
          >
            Отменить
          </ThemeButton>
          <ThemeButton isIntelleka={isIntelleka} type="submit" disabled={loading}>
            {loading ? 'Сохранение...' : 'Сохранить'}
          </ThemeButton>
        </div>
      </form>
      {!!error && (
        <Alert color="error" className="notification" onClose={() => setError('')}>
          {error}
        </Alert>
      )}
    </Modal>
  );
};

export default AdminTestForm;
