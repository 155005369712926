import { useQuery } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import { getCurrentUser } from '../../../api/common';
import { useMassMailParams } from '../store/useUsers';
import AdminChatsTemplate from '../templates/AdminChatsTemplate';
import { useCheck } from '../store/useUsers';
import Table from '../../../shared/Table';
import { convertTimestampToDate } from '../../../utils/time';
import Pagination from '../../../shared/Pagination';
import { getPagination } from '../../../utils/pagination';
import { Controller, useForm } from 'react-hook-form';
import { russianEndings } from '../../../utils/string';
import { useState } from 'react';
import { checkIsIntelleka } from '../../../utils/link';
import { API } from '../../../api';
import { ThemeButton } from '../../../shared/Button';
import ThemeTextarea from '../../../shared/ThemeTextarea';

interface IForm {
  message: string;
}

const AdminMassMail: React.FC = () => {
  const { addToast } = useToasts();
  const { data: currentUser } = useQuery('user', getCurrentUser);
  const isAdmin = currentUser?.user_status === 0;
  const { params, updateParam } = useMassMailParams();

  const key = ['users', params];

  const { data: users, isLoading: loadingUsers } = useQuery(key, () => API.admin.user.all(params), {
    refetchOnWindowFocus: false,
  });

  const { checked, setChecked } = useCheck();

  const titles = [
    <input
      type="checkbox"
      disabled={!users?.results.length}
      checked={!!users?.results.length ? checked.length === users?.results?.length : false}
      onChange={() => {
        checked.length ? setChecked([]) : users && setChecked(users.results.map((u) => u.id));
      }}
    />,
    <>ФИО</>,
    <>Логин</>,
    <>Дата создания</>,
    <>Статус</>,
  ];

  const usersData = users?.results?.map((u) => {
    return [
      <input
        type="checkbox"
        checked={checked.includes(u.id)}
        onChange={() => {
          checked.includes(u.id)
            ? setChecked(checked.filter((id) => id !== u.id))
            : setChecked([...checked, u.id]);
        }}
      />,
      <>{u.first_name}</>,
      <>{u.username}</>,
      convertTimestampToDate(u.date_joined, 'DD.MM.YYYY'),
      <>
        {u.is_active ? 'A' : 'H'}/{u.design === 0 ? 'П' : 'И'}
      </>,
    ];
  });

  const paginationValues = getPagination(users?.count || 0, params.page_size);

  const { control, handleSubmit, reset } = useForm<IForm>();

  const [sending, setSending] = useState(false);

  const submit = async (data: IForm) => {
    try {
      setSending(true);
      const messages = await API.admin.message.createMass({
        users: checked,
        message: data.message,
      });
      addToast(
        `Сообщение отправлено ${messages.length} ${russianEndings(checked.length, [
          'пользователю',
          'пользователям',
          'пользователям',
        ])}`,
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );
      reset();
      setChecked([]);
      setSending(false);
    } catch (error) {
      addToast(`Не удалось отправить сообщение`, {
        appearance: 'error',
        autoDismiss: true,
      });
      setSending(false);
    }
  };

  const isIntelleka = checkIsIntelleka();

  return !isAdmin ? (
    <AdminChatsTemplate>
      <div className="italic mt-5">Раздел доступен только для администратора</div>
    </AdminChatsTemplate>
  ) : (
    <AdminChatsTemplate>
      <div className="mb-2 mx-4 lg:mx-0">
        <Pagination
          pagesCountOptions={paginationValues}
          activePage={params.page}
          pageSize={params.page_size}
          countElements={users?.count || 0}
          onClickNext={() => users?.next && updateParam('page', params.page + 1)}
          onClickPrev={() => users?.previous && updateParam('page', params.page - 1)}
          isIntelleka={isIntelleka}
          onChangeCurrentPage={(page) => updateParam('page', page)}
          onChangePageSize={(pageSize) => {
            updateParam('page', 1);
            updateParam('page_size', pageSize);
          }}
        />
      </div>

      <Table titles={titles} rows={usersData} isLoading={loadingUsers} />

      <form onSubmit={handleSubmit(submit)} className="mx-4 lg:mx-0">
        <label className="mt-3 block">
          <span>
            Сообщение (
            {!checked.length
              ? 'пользователи не выбраны'
              : `будет отправлено ${checked.length} пользователям`}
            )
          </span>

          <Controller
            control={control}
            render={({ field }) => (
              <ThemeTextarea
                {...field}
                className="w-full block mt-1 mb-2 min-h-[100px]"
                isIntelleka={isIntelleka}
              />
            )}
            name="message"
          />
        </label>
        <ThemeButton type="submit" isIntelleka={isIntelleka} disabled={!checked.length || sending}>
          {sending ? 'Отправка...' : 'Отправить'}
        </ThemeButton>
      </form>
    </AdminChatsTemplate>
  );
};

export default AdminMassMail;
