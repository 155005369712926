import axios from 'axios';
import { ICurrentUser } from '../../typings/common';

export const USER_CLIENT_URL = '/api/v1/user/user/';

/** функция возвращает объект текущего пользователя */
export async function getCurrentUser() {
  try {
    const { data } = await axios.get<ICurrentUser>(USER_CLIENT_URL);

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
