import { ICurrentUser } from '../typings/common';
import { checkingLinkText, checkIsBase64 } from '../utils/link';
import Avatar from './Avatar';
import MessageTime from './MessageTime';
import MessageImage from './MessageImage';
import MessageLink from './MessageLink';
import MessageText from './MessageText';
import { IChatMessage } from '../ui/common/containers/ChatContainer';
import image_not_found from '../img/image_not_found.png';

/** Компонент сообщения чата */
const Message: React.FC<{
  data: IChatMessage;
  currentUser?: ICurrentUser;
  isIntelleka?: boolean;
}> = ({ isIntelleka = false, ...props }) => {
  const isAuthorMessage = (props.currentUser?.id || 0) === props.data.userId;
  const isLink = checkingLinkText(props.data.mess);
  const isBase64 = checkIsBase64(props.data.mess);

  const isFirebaseImage =
    props.data.mess.length > 23 && props.data.mess.slice(8, 23) === 'firebasestorage';

  return (
    <div
      className={`my-[30px] flex justify-end ${isAuthorMessage ? 'flex-row' : 'flex-row-reverse'}`}
    >
      <div>
        {isBase64 ? (
          <MessageImage image={props.data.mess} />
        ) : isLink ? (
          <MessageLink link={props.data.mess} isAuthorMessage={isAuthorMessage} />
        ) : isFirebaseImage ? (
          <MessageImage image={image_not_found} />
        ) : (
          <MessageText
            text={props.data.mess}
            isAuthorMessage={isAuthorMessage}
            isIntelleka={isIntelleka}
          />
        )}
        <MessageTime date={props.data.created} isAuthorMessage={isAuthorMessage} />
      </div>
      <Avatar
        isAuthorMessage={isAuthorMessage}
        login={props.data.username || props.currentUser?.username}
        isIntelleka={isIntelleka}
      />
    </div>
  );
};

export default Message;
