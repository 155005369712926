import Spinner from './Spinner';

/** Компонент счетчика пользователей */
const UsersCount: React.FC<{ loading?: boolean; count?: number; isIntelleka?: boolean }> = ({
  loading = false,
  count = 0,
  isIntelleka = false,
}) => {
  return (
    <div className="mb-1 flex items-center">
      <span className="pr-2">Пользователей:</span>
      {!loading ? (
        count
      ) : (
        <Spinner
          className={`${isIntelleka ? 'fill-active_i' : 'fill-active_p'} w-[14px] h-3 mr-0`}
        />
      )}
    </div>
  );
};

export default UsersCount;
