import axios from 'axios';
import { IMateralPassingParams, IMaterialPassing } from '../../typings/admin';

export async function all(params?: IMateralPassingParams) {
  try {
    const { data } = await axios.get<IMaterialPassing[]>('/api/v1/courses/material_passing/', {
      params,
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
