import { AxiosError } from 'axios';
import create from 'zustand';
import { getToken } from '../../../api/common';
import { IAuthParams } from '../../../typings/common';
import { getError } from '../../../utils/api';

type IAuth = {
  error: boolean;
  setError(e: boolean): void;
  loginError: boolean;
  setLoginError(e: boolean): void;
  loading: boolean;
  setLoading(e: boolean): void;
  countErrors: number;
  setCountErrors(c: number): void;
  isPwdOpen: boolean;
  setPwdOpen(o: boolean): void;
  closeErrors(): void;
  submit(data: IAuthParams, callback: (token: string) => void): void;
};

export const useAuth = create<IAuth>((set, get) => ({
  error: false,
  setError: (error) => set({ error }),
  loading: false,
  setLoading: (loading) => set({ loading }),
  loginError: false,
  setLoginError: (error) => set({ error }),
  countErrors: 0,
  setCountErrors: (countErrors) => set({ countErrors }),
  isPwdOpen: false,
  setPwdOpen: (isPwdOpen) => set({ isPwdOpen }),
  closeErrors: () => {
    set({ error: false, loginError: false });
  },
  submit: async (data, callback) => {
    set({ loading: true });
    try {
      const res = await getToken(data);
      callback(res.access);
      set({ loading: false });
    } catch (e) {
      const { countErrors, closeErrors } = get();
      const parsed = JSON.parse(getError(e as AxiosError));
      const isLoginFailed =
        parsed.non_field_errors.length && parsed.non_field_errors[0] === 'login_failed';

      set({ error: true });
      set({ loading: false });
      isLoginFailed && set({ loginError: true });
      set({ countErrors: countErrors + 1 });
      if (countErrors === 4) closeErrors();
    }
  },
}));
