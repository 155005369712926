import { recaptchaKey } from '../../../../recaptcha/config';
import { checkIsIntelleka } from '../../../../utils/link';
import { useAuth } from '../../store/useAuth';
import { ISubmitProps } from '../../typings/auth';
import Reaptcha from 'reaptcha';
import SubmitButton from './SubmitButton';
import Spinner from '../../../../shared/Spinner';

const Recaptcha: React.FC<ISubmitProps> = (props) => {
  const { countErrors, setCountErrors, loading } = useAuth();
  const isIntelleka = checkIsIntelleka();

  return (
    <div className="min-h-[50px] bg-white relative">
      <SubmitButton
        isIntelleka={isIntelleka}
        isValid={props.isValid}
        loading={loading}
        isVisible={countErrors < 5}
      />
      <Spinner
        className={`${
          isIntelleka ? 'fill-active_i' : 'fill-active_p'
        } w-[30px] h-[30px] m-auto left-0 right-0 top-[20px] bottom-0 absolute z-0`}
      />
      {countErrors === 5 && <Reaptcha sitekey={recaptchaKey} onVerify={() => setCountErrors(0)} />}
    </div>
  );
};

export default Recaptcha;
