import AdminTemplate from '../templates/AdminTemplate';
import { SETTINGS_TITLES } from './AdminSettings';
import adminRoutes from '../routes';
import curatorRoutes from '../../curator/routes';
import clientRoutes from '../../client/routes';
import { Route } from '@tanstack/react-location';
import { useState } from 'react';
import { AiFillFolderOpen, AiFillFolder, AiOutlineFolder } from 'react-icons/ai';

const RouteItem: React.FC<{ route: Route & { description?: string }; layer: number }> = ({
  route,
  layer,
}) => {
  const [open, toggle] = useState(false);

  return (
    <div>
      <div
        onClick={() => toggle(!open)}
        className={`${
          !!route.children ? 'cursor-pointer hover:text-gray-900' : 'cursor-default'
        } w-fit ml-0 italic flex items-center text-gray-600 mb-1`}
      >
        <div className="mr-1 border h-12 w-12 flex items-center justify-center text-lg">
          {route.children ? open ? <AiFillFolderOpen /> : <AiFillFolder /> : <AiOutlineFolder />}
        </div>
        <div className="border h-12 w-auto px-1">
          <div>{route.description}</div>
          <div className="text-gray-400 text-sm">{route.path}</div>
        </div>
      </div>
      {open &&
        route.children?.map((route, i) => {
          return (
            <div key={i}>
              <div className="ml-[52px] relative">
                <div className="z-10 bg-white relative">
                  <RouteItem route={route} layer={layer + 1} />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export const AdminRouteMap: React.FC = () => {
  return (
    <AdminTemplate>
      <h1 className="text-[30px] text-center mb-6">{SETTINGS_TITLES[2]}</h1>
      <div>
        {adminRoutes.map((route, i) => {
          return <RouteItem key={i} route={route} layer={1} />;
        })}
      </div>
      <div>
        {curatorRoutes.map((route, i) => (
          <RouteItem key={i} route={route} layer={i} />
        ))}
      </div>
      <div>
        {clientRoutes.map((route, i) => (
          <RouteItem key={i} route={route} layer={i} />
        ))}
      </div>
    </AdminTemplate>
  );
};

export default AdminRouteMap;
