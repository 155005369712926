import { Tooltip } from '@mui/material';

const ErrorTooltip: React.FC<{ open: boolean; title: string; isLogin?: boolean }> = (props) => {
  return (
    <Tooltip
      title={<div className="text-[13px] italic">{props.title}</div>}
      open={props.open}
      arrow
      placement={props.isLogin ? 'top-end' : 'bottom-end'}
    >
      <div
        className={`absolute right-0 ${
          props.isLogin ? '-top-20 xl:top-1' : '-bottom-[89px] xl:bottom-1'
        }`}
      />
    </Tooltip>
  );
};

export default ErrorTooltip;
