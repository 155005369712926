import axios from 'axios';
import { IMaterial, IMaterialsParams } from '../../typings/client';

export const CLIENT_MATERIALS_URL = `/api/v1/client/courses/material/`;

export async function getAllMaterials(params?: IMaterialsParams) {
  try {
    const { data } = await axios.get<IMaterial[]>(CLIENT_MATERIALS_URL, {
      params,
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
