import axios from 'axios';

import { ICuratorUserActivityParams, ICuratorUsersActivityPage } from '../../typings/curator';

export const CURATOR_ACTIVITIES_URL = '/api/v1/curator/all_activity/';

export async function getActivities(params: ICuratorUserActivityParams) {
  try {
    const { data } = await axios.get<ICuratorUsersActivityPage>(CURATOR_ACTIVITIES_URL, {
      params,
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
