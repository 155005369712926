/**
 * @module ua-parser
 * @category утилиты по работе с библиотеками
 * @description утилиты по работе с библиотекой [ua-parser-js](https://github.com/faisalman/ua-parser-js)
 */
import { UAParser } from 'ua-parser-js';

/**
 * функция возвращает данные ОС и браузера текущего клиента
 * @returns объект с данными ОС и браузера
 */
export function getClientBrowserAndOs() {
  const clientInfo = new UAParser();
  const browser = clientInfo.getBrowser();
  const os = clientInfo.getOS();

  return { os, browser };
}
