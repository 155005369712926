import { useTest } from '../store/useTest';
import ClientTaskTemplate from '../templates/ClientTaskTemplate';
import { getLastWord } from '../../../utils/string';
import { toHoursAndMinutes } from '../../../utils/time';

const DEFAULT_ERROR = 'Неизвестная ошибка';

const PREVIOUS_ATTEMPT_NOT_FINISHED = {
  input: 'Try passing the test through',
  getOutput: (original: string) =>
    `Тест будет доступен через ${toHoursAndMinutes(Math.round(Number(getLastWord(original))))}`,
};

const ClientTestUiError: React.FC = () => {
  const { error } = useTest();

  return (
    <ClientTaskTemplate>
      <div className="text-red-600">
        {error?.includes(PREVIOUS_ATTEMPT_NOT_FINISHED.input)
          ? PREVIOUS_ATTEMPT_NOT_FINISHED.getOutput(error)
          : DEFAULT_ERROR}
      </div>
    </ClientTaskTemplate>
  );
};

export default ClientTestUiError;
