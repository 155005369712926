import axios from 'axios';
import {
  ICourse,
  ICourseUsersDetail,
  ICoursesParams,
  TCreateCourse,
  TUpdateCourse,
} from '../../typings/admin';

/**
 * функция возвращает массив курсов пользователя
 * @param params параметры
 * @returns массив курсов пользователя
 */
export async function all(params?: Partial<ICoursesParams>) {
  try {
    const { data } = await axios.get<ICourse[]>('/api/v1/courses/course/', { params });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * функция возвращает детальный объект курса
 * @param id айди курса
 * @returns детальный объект курса
 */
export async function byId(id: number | string) {
  try {
    const { data } = await axios.get<ICourseUsersDetail>(`/api/v1/courses/course/${id}/`);

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

/**
 * функция создает новый курс
 * @param model поля курса
 * @returns объект созданного курса
 */
export async function create(model: TCreateCourse) {
  try {
    const { data } = await axios.post<ICourse>('/api/v1/courses/course/', model);

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

/**
 * функция обновляет курс
 * @param param0 поля для обновления
 * @returns объект обновленного курса
 */
export async function update({ id, ...model }: TUpdateCourse) {
  try {
    const { data } = await axios.put<ICourse>(`/api/v1/courses/course/${id}/`, model);

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

/**
 * функция удаляет курс
 * @param id айди курса
 * @returns нет данных ответа
 */
export async function remove(id: number | string) {
  try {
    const { data } = await axios.delete(`/api/v1/courses/course/${id}/`);

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
