import { useEffect } from 'react';
import ClientTemplate from '../templates/ClientTemplate';
import { useMaterial } from '../store/useMaterial';
import { getStructuredMaterials } from '../../../utils/material';
import { useCourseMaterials } from '../store/useCourseMaterials';
import { keyBy } from 'lodash';
import ClientMaterialList from '../containers/ClientMaterialList';
import Spinner from '../../../shared/Spinner';
import { checkIsIntelleka } from '../../../utils/link';
import { useGetMaterials } from '../../../cache/client/material';
import { useCourse } from '../store/useCourse';

const ClientMaterials: React.FC = () => {
  const { setId, materials } = useMaterial();
  const { setCourseMaterials } = useCourseMaterials();
  const { course } = useCourse();
  const { isLoading } = useGetMaterials(course);

  useEffect(() => {
    const materialsById = keyBy(materials, 'id');
    setCourseMaterials(materialsById);
  }, [materials, setCourseMaterials]);

  const structured = materials && getStructuredMaterials(materials);

  const isIntelleka = checkIsIntelleka();

  return (
    <ClientTemplate>
      <div className="px-7 pb-5">
        {isLoading ? (
          <Spinner
            className={`${isIntelleka ? 'fill-active_i' : 'fill-active_p'} w-12 m-auto mt-12`}
          />
        ) : (
          <ClientMaterialList materials={structured} open={setId} />
        )}
      </div>
    </ClientTemplate>
  );
};

export default ClientMaterials;
