import axios from 'axios';

export async function remove(id: number) {
  try {
    const { data } = await axios.delete(`/api/v1/chats/chat/${id}/delete/`);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
