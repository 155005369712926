import { useEffect } from 'react';
import { queryClient } from '../../../hooks/queryClient';
import { TPagination, useOrdering } from '../../../ui/admin/store/useUsers';
import { convertLocalTimeToFormat } from '../../../utils/time';
import { getKey } from './';
import { AdminUsersStore } from './typings';

export function useSetOrderIndexes(params: TPagination) {
  const key = getKey(params);
  const users = queryClient.getQueryData<AdminUsersStore>(key);
  const data = users?.data;
  const { orderBy, setBorderIndexes, isReverse } = useOrdering();

  useEffect(() => {
    if (data) {
      switch (orderBy) {
        case 'STATUS':
          return setBorderIndexes(
            data.results.map((u, i, self) => {
              const nextUser = self[i + 1];
              if (nextUser === undefined) {
                return -1;
              } else {
                return u.is_active === nextUser.is_active ? -1 : i;
              }
            }),
          );
        case 'COMPANY':
          return setBorderIndexes(
            data.results.map((u, i, self) => {
              const nextUser = self[i + 1];
              if (nextUser === undefined) {
                return -1;
              } else {
                return u.company === nextUser.company ? -1 : i;
              }
            }),
          );
        case 'CREATED':
          return setBorderIndexes(
            data.results.map((u, i, self) => {
              const nextUser = self[i + 1];
              const nextDate =
                nextUser && convertLocalTimeToFormat(nextUser.date_joined, 'DD-MM-YYYY');
              const currentDate = convertLocalTimeToFormat(u.date_joined, 'DD-MM-YYYY');
              if (nextUser === undefined) {
                return -1;
              } else {
                return currentDate === nextDate ? -1 : i;
              }
            }),
          );
        default:
          return setBorderIndexes([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, isReverse]);
}
