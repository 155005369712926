import { useNavigate } from '@tanstack/react-location';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API } from '../../../api';
import { ITest, TCreateTask } from '../../../typings/admin';
import { generateCourseTestLink } from '../../../ui/admin/routes';
import { useCourseTests } from '../../../ui/admin/store/useTests';
import { getError } from '../../../utils/api';
import { queryClient } from '../../../hooks/queryClient';
import { getKey } from './';

export function useCreateTest(
  /** айди курса */
  courseId: string,
  /** переход со страницы материалов */
  toMaterial?: boolean,
) {
  const navigate = useNavigate();
  const key = getKey(courseId);
  const { toggleForm, setError } = useCourseTests();
  return useMutation<ITest, unknown, TCreateTask>((data) => API.admin.test.create(data), {
    onSuccess: (res) => {
      queryClient.refetchQueries(key);
      toggleForm(false);
      toMaterial && navigate({ to: generateCourseTestLink(courseId, res.id) });
    },
    onError: (e) => {
      const error = getError(e as AxiosError);
      setError(error);
    },
  });
}
