import LoginForm from '../containers/LoginForm';
import { IAuthProps } from '../typings/auth';
import LoginBackground from '../containers/LoginForm/LoginBackground';

/** Страница авторизации */
const AuthPage: React.FC<IAuthProps> = ({ onLogin }) => {
  return (
    <LoginBackground>
      <LoginForm onLogin={onLogin} />
    </LoginBackground>
  );
};

export default AuthPage;
