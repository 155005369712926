/**
 * @module react-use-websocket
 * @category утилиты по работе с библиотеками
 * @description утилиты по работе с библиотекой [react-use-websocket](https://github.com/robtaussig/react-use-websocket)
 */

import { ReadyState } from 'react-use-websocket';

export type TReadyStateLiteral = 'Connecting' | 'Open' | 'Closing' | 'Closed' | 'Uninstantiated';

export const getConnectionStatus = (readyState: ReadyState) =>
  ({
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState] as TReadyStateLiteral);
