import SearchButton from './SearchButton';
import { useForm, Controller } from 'react-hook-form';
import ThemeInput from './ThemeInput';

/** Компонент формы поиска */
const SearchForm: React.FC<{
  submit?(data: { search: string }): void;
  loading?: boolean;
  isIntelleka?: boolean;
  placeholder?: string;
  className?: string;
  classNameInput?: string;
}> = ({
  loading = false,
  isIntelleka = false,
  submit = (data) => console.log(data),
  placeholder = 'Поиск ученика по ФИО',
  className = 'flex items-center justify-center mb-0 xl:my-3 w-full md:w-fit',
  classNameInput = 'w-full md:w-[300px]',
}) => {
  const { handleSubmit, control } = useForm<{ search: string }>();

  return (
    <form className={className} onSubmit={handleSubmit(submit)}>
      <Controller
        control={control}
        render={({ field }) => (
          <ThemeInput
            {...field}
            type="search"
            placeholder={placeholder}
            className={classNameInput}
            isIntelleka={isIntelleka}
          />
        )}
        name="search"
      />

      <SearchButton loading={loading} type="submit" isIntelleka={isIntelleka} />
    </form>
  );
};

export default SearchForm;
