import { rest } from 'msw';
import { TCreateCourse, TUpdateCourse } from '../../typings/admin';
import { TOKEN_MOCK, USER_MOCK } from '../common';
import { database } from './db';

export const url = 'http://localhost:3000/';

export const tokenHandler = rest.post(`${url}api/v1/token/`, (req, res, ctx) => {
  return res(ctx.json(TOKEN_MOCK));
});

export const currentUserHandler = rest.get(`${url}api/v1/user/user/`, (req, res, ctx) => {
  return res(ctx.json(USER_MOCK));
});

export const getAllCourses = rest.get(`${url}api/v1/courses/course/`, (req, res, ctx) => {
  const courses = database.course.getAll();
  return res(ctx.status(200), ctx.json(courses));
});

const getCourseById = rest.get<number>(`${url}api/v1/courses/course/:id`, (req, res, ctx) => {
  const { id } = req.params;

  const foundCourse = database.course.getAll().find((c) => c.id === Number(id));
  const courseUpdated = {
    ...foundCourse,
    users_detail: foundCourse?.users.map((id) => ({ id, username: `user-${id}` })),
  };

  return res(ctx.status(200), ctx.json(courseUpdated));
});

const createCourse = rest.post<TCreateCourse>(`${url}api/v1/courses/course/`, (req, res, ctx) => {
  const { title, description, is_active } = req.body;

  const newCourse: TCreateCourse = {
    id: Date.now(),
    title,
    description,
    created: `${new Date().toISOString()}`,
    author: 1,
    author_name: 'admin',
    is_active,
    teacher: '',
    visit_num: 0,
    completed_num: 0,
    registered_num: 0,
    moderators: [],
    tag: [],
    users: [],
  };

  const courseCreated = database.course.create(newCourse);

  return res(ctx.status(200), ctx.json(courseCreated));
});

const updateCourse = rest.put<TUpdateCourse>(`${url}api/v1/courses/course/:id`, (req, res, ctx) => {
  const { id } = req.params;

  const courseUpdated = database.course.update({
    where: { id: { equals: Number(id) } },
    data: {
      title: req.body.title,
      description: req.body.description,
      is_active: req.body.is_active,
    },
  });

  return res(ctx.status(200), ctx.json(courseUpdated));
});

const deleteCourse = rest.delete<{ id: string }>(
  `${url}api/v1/courses/course/:id`,
  (req, res, ctx) => {
    const { id } = req.params;

    database.course.delete({ where: { id: { equals: Number(id) } } });

    return res(ctx.status(200));
  },
);

export const getAllChats = rest.get(`${url}api/v1/chats/chat/`, (req, res, ctx) => {
  const chats = database.chat.getAll();
  return res(ctx.status(200), ctx.json(chats));
});

export const getServerTime = rest.get(`${url}api/v1/time/`, (req, res, ctx) => {
  const time = database.time.getAll();
  return res(ctx.status(200), ctx.json(time[0]));
});

export const getUsers = rest.get(`${url}api/v1/users/user/`, (req, res, ctx) => {
  const data = database.user.getAll();
  return res(
    ctx.status(200),
    ctx.json({
      results: data,
      count: 3529,
      next: 'next page',
      previous: null,
    }),
  );
});

export const handlers = [
  tokenHandler,
  currentUserHandler,
  getAllCourses,
  createCourse,
  updateCourse,
  deleteCourse,
  getCourseById,
  getAllChats,
  getServerTime,
  getUsers,
];
