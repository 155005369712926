import { useQuery } from 'react-query';
import Table from '../../../shared/Table';
import { convertTimestampToDate } from '../../../utils/time';
import AdminTemplate from '../templates/AdminTemplate';
import { FaUserTimes } from 'react-icons/fa';
import { keyBy } from 'lodash';
import { useCheck } from '../store/useCurators';
import { useState } from 'react';
import ConfirmModal from '../../../shared/ConfirmModal';
import { russianEndings } from '../../../utils/string';
import AdminAddCurator from '../containers/AdminAddCurator';
import AdminEditCurator from '../containers/AdminEditCurator';
import { checkIsIntelleka } from '../../../utils/link';
import { API } from '../../../api';
import { useCurators, useRemoveCurator } from '../../../cache/admin/users/curators';
import { useToasts } from 'react-toast-notifications';

const AdminCurators: React.FC = () => {
  const { addToast } = useToasts();
  const { data, isLoading } = useCurators();
  const { data: companies } = useQuery('companies', () => API.admin.company.all());
  const companiesById = keyBy(companies, 'id');

  const [confirmVisible, setConfirmVisible] = useState(false);
  const { checked, setChecked } = useCheck();

  const onRemoved = () => {
    setChecked([]);
    setConfirmVisible(false);
  };

  const onError = () => {
    addToast('Не удалось удалить куратора', { appearance: 'error' });
    setConfirmVisible(false);
  };

  const { mutate: deleteUserRequest, isLoading: deleting } = useRemoveCurator(onRemoved, onError);

  const titles = [
    <input
      type="checkbox"
      disabled={!data?.results.length}
      checked={!!data?.results.length ? checked.length === data?.results?.length : false}
      onChange={() => {
        checked.length ? setChecked([]) : data && setChecked(data.results.map((u) => u.id));
      }}
    />,
    'ФИО',
    'Логин',
    'Дата создания',
    'Компании',
    'Примечание',
    'Редактировать',
  ];

  const curators = data?.results.map((c) => [
    <input
      type="checkbox"
      checked={checked.includes(c.id)}
      onChange={() => {
        checked.includes(c.id)
          ? setChecked(checked.filter((id) => id !== c.id))
          : setChecked([...checked, c.id]);
      }}
    />,
    c.first_name,
    c.username,
    convertTimestampToDate(c.date_joined, 'DD.MM.YYYY'),
    <div className="flex justify-center w-full max-w-xs flex-wrap">
      {c.curator_company?.map((c, i) => (
        <div key={i} className="badge bg-gray-badge w-fit m-1 text-sm whitespace-nowrap">
          {companiesById[c]?.title}
        </div>
      ))}
    </div>,
    <>{c.description}</>,
    <AdminEditCurator user={c} />,
  ]);

  const isIntelleka = checkIsIntelleka();

  return (
    <AdminTemplate>
      <div className="mb-1 flex items-center justify-between">
        <AdminAddCurator />
        <FaUserTimes
          className={`cursor-pointer ${!!checked.length ? 'block' : 'hidden'}`}
          onClick={() => setConfirmVisible(true)}
        />
      </div>
      <Table titles={titles} rows={curators?.reverse()} isLoading={isLoading} />
      <ConfirmModal
        isIntelleka={isIntelleka}
        confirm={() => checked.forEach((u) => deleteUserRequest(u))}
        isOpen={!!confirmVisible}
        hide={() => setConfirmVisible(false)}
        loading={deleting}
      >
        {`Удалить ${checked.length} ${russianEndings(checked.length, [
          'пользователя',
          'пользователей',
          'пользователей',
        ])}?`}
      </ConfirmModal>
    </AdminTemplate>
  );
};

export default AdminCurators;
