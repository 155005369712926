/**
 * @module Truncate
 * @category Общие компоненты
 */

import { useState } from 'react';

/**
 * Интерфейс для пропсов компонента {@link Truncate}
 */
export interface ITruncateProps {
  /** кол-во символов превъю текста */
  count: number;
  /** текст */
  title: string;
  /** цвет при наведении */
  hoverBackGround?: string;
}

/**
 * Компонент рендерит передаваемый в параметрах заголовок (title)
 * в ограниченным кол-ве символов (count)? с троеточием в конце.
 * При наведении отображает полный текст
 */
const Truncate: React.FC<ITruncateProps> = (props) => {
  const [open, setOpen] = useState<boolean>(false);

  const isLong = props.title.length > props.count;
  return (
    <>
      {isLong ? (
        <>
          <span
            className="relative"
            style={{ backgroundColor: open ? props.hoverBackGround || '#fff' : 'transparent' }}
            onMouseOver={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
          >
            {open ? props.title : `${props.title.slice(0, props.count)}...`}
          </span>
        </>
      ) : (
        <>{props.title}</>
      )}
    </>
  );
};

export default Truncate;
