import React, { useRef } from 'react';

import { useCourseTasks } from '../store/useCourseTasks';
import ClientPassingIndicator from './ClientPassingIndicator';
import { useTest } from '../store/useTest';
import { IStructured } from '../../../utils/material';

import ClientMaterialDescription from './ClientMaterialDescription';
import ClientCollapseCaret from './ClientCollapseCaret';
import { ITask } from '../../../typings/client';
import { useMaterial } from '../store/useMaterial';
import { checkIsIntelleka } from '../../../utils/link';
import { BiCloudDownload } from 'react-icons/bi';
import { useWindowSize } from 'react-use';
import ClientCheckTaskAvailability from './ClientCheckTaskAvailability';
import { useMaterialCollapse } from '../store/useCollapse';

const Dot: React.FC = () => <div className="text-[24px] absolute -left-3 top-[-15px]">.</div>;

const MaterialItemElement: React.FC<{
  id: number;
  isChild: boolean;
  isLast: boolean;
  isOpen: boolean;
  sidebar?: boolean;
}> = ({ children, id, isChild, isLast, isOpen, sidebar }) => {
  return (
    <li
      key={id}
      className={`flex flex-col justify-start ${
        !isChild
          ? `${!isLast && 'border-b border-b-gray-dark_opacity'} ${
              !isOpen ? 'py-[15px]' : 'pt-[15px]'
            }`
          : sidebar
          ? 'w-full'
          : 'w-fit'
      }`}
    >
      {children}
    </li>
  );
};

const getTitleStyle = (layer: number, sidebar?: boolean) => {
  switch (layer) {
    case 0:
      return { fontSize: sidebar ? 21 : 25 };
    case 1:
      return { fontSize: 17 };
    case 2:
      return { fontSize: 15 };
    default:
      return { fontSize: 13 };
  }
};

const MaterialTitle: React.FC<{
  isOpen: boolean;
  isChild: boolean;
  layer: number;
  index?: number;
  sidebar?: boolean;
  isActive: boolean;
}> = ({ children, isOpen, isChild, index, layer, sidebar, isActive }) => {
  const isIntelleka = checkIsIntelleka();

  return (
    <div
      className={`mr-5 ${
        sidebar
          ? layer === 0
            ? 'ml-5'
            : layer === 1
            ? '-ml-1'
            : layer === 2
            ? '-ml-2'
            : 'ml-[-10px]'
          : ''
      } flex ${isChild ? `${isOpen ? 'mb-[15px]' : 'mb-0'}` : `${isOpen && 'mb-[8px]'}`}`}
    >
      {isChild && layer === 1 && (
        <div
          className={`${
            isActive ? (isIntelleka ? 'text-active_i' : 'text-active_p') : ''
          } mr-1 ml-2`}
        >
          {index}.
        </div>
      )}
      {isChild && layer > 1 && !sidebar && <Dot />}
      <div
        className={`${isActive ? (isIntelleka ? 'text-active_i' : 'text-active_p') : ''}`}
        style={getTitleStyle(layer, sidebar)}
      >
        {children}
      </div>
    </div>
  );
};

const ChildMaterialList: React.FC<{ layer: number }> = ({ layer, children }) => (
  <ul className={`${layer === 2 ? 'pl-4' : 'pl-8'}`}>{children}</ul>
);

const MaterialTask: React.FC<{
  task: ITask;
  layer: number;
  index?: number;
  isChild?: boolean;
  sidebar?: boolean;
  hasChildren?: boolean;
  isActive: boolean;
  titleRef: React.RefObject<HTMLDivElement>;
}> = ({ task, layer, index, isChild, sidebar, hasChildren, titleRef, isActive }) => {
  const { setTask } = useTest();

  return (
    <div className="mb-[15px] relative">
      {layer > 0 && (
        <ClientPassingIndicator
          task={task?.id}
          layer={layer}
          sidebar={sidebar}
          hasChildren={hasChildren}
          titleRef={titleRef}
          isActive={isActive}
        />
      )}
      <div className="flex">
        {isChild && layer === 1 && <div className="font-bold ml-2 mr-1">{index && index + 1}.</div>}
        {isChild && layer > 1 && !sidebar && <Dot />}
        <div
          style={getTitleStyle(layer)}
          className={`${
            sidebar
              ? layer === 0
                ? 'ml-5'
                : layer === 1
                ? 'ml-0'
                : layer === 2
                ? '-ml-2'
                : 'ml-[-10px]'
              : ''
          }`}
        >
          <ClientCheckTaskAvailability
            task={task}
            setTask={() => setTask(task)}
            sidebar={sidebar}
            isMaterialPage
          />
        </div>
      </div>
    </div>
  );
};

const NoMaterials: React.FC<{ sidebar?: boolean }> = ({ sidebar }) => (
  <div className={`text-gray-400 mb-[15px] ${sidebar ? 'ml-5' : 'ml-0'}`}>Нет материалов</div>
);

const ClientMaterialItem: React.FC<{
  item: IStructured;
  open(id: number): void;
  layer: number;
  isChild?: boolean;
  index?: number;
  isLast?: boolean;
  sidebar?: boolean;
}> = ({ item, open, isChild, isLast = false, ...props }) => {
  const { closed, toggleClosed } = useMaterialCollapse();

  const isOpen = !closed.includes(item.id);

  const { tasksByMaterial } = useCourseTasks();
  const titleRef = useRef(null);
  const {
    id: currentMaterialId,
    getLastMaterialsOfTopics,
    isSidebarVisible,
    toggleSidebar,
  } = useMaterial();
  const isActive = item.id === currentMaterialId;

  const task = tasksByMaterial[item.id];

  const { width: windowWidth } = useWindowSize();

  const toggle = () => {
    if (isChild) {
      open(item.id);
      props.sidebar && windowWidth < 992 && isSidebarVisible && toggleSidebar(false);
    } else {
      toggleClosed(item.id);
    }
  };

  const isIntelleka = checkIsIntelleka();

  const lastMaterialsOfTopics = getLastMaterialsOfTopics ? getLastMaterialsOfTopics() : [];

  return (
    <MaterialItemElement
      id={item.id}
      isChild={!!isChild}
      isOpen={isOpen}
      isLast={isLast}
      sidebar={props.sidebar}
    >
      <div onClick={toggle} className="relative cursor-pointer ">
        {!isChild && <ClientMaterialDescription index={props.index} sidebar={props.sidebar} />}
        <div
          className={`flex ${
            isIntelleka ? 'hover:text-active_i' : 'hover:text-active_p'
          } arrowed-title`}
        >
          {isChild && (
            <ClientPassingIndicator
              material={item.id}
              layer={props.layer}
              hasLine={!isLast || !!item.children || !!task}
              sidebar={props.sidebar}
              hasChildren={!!item.children}
              titleRef={titleRef}
              isActive={isActive}
            />
          )}
          <MaterialTitle
            isChild={!!isChild}
            isOpen={isOpen}
            index={props.index}
            layer={props.layer}
            sidebar={props.sidebar}
            isActive={isActive}
          >
            <div ref={titleRef}>
              <span id={`material-${item.id}`} />
              {item.title}
              {isChild && props.sidebar && item.is_download && (
                <div
                  onClick={() => typeof item.pdf === 'string' && window.open(item.pdf, '_blank')}
                  className="pl-1 text-lg min-w-[20px] absolute right-0 top-[1px] download-link"
                >
                  <BiCloudDownload />
                  <div className="download-tooltip bg-black-default text-white w-fit p-1 -right-4 text-[12px] top-[-29px] rounded shadow-lg absolute">
                    Скачать
                  </div>
                </div>
              )}
            </div>
          </MaterialTitle>

          {!isChild && <ClientCollapseCaret isOpen={isOpen} sidebar={props.sidebar} />}
        </div>
      </div>

      {task && (
        <MaterialTask
          task={task}
          layer={props.layer}
          index={props.index}
          isChild={isChild}
          sidebar={props.sidebar}
          hasChildren={!!item.children}
          titleRef={titleRef}
          isActive={isActive}
        />
      )}

      <div style={{ height: isOpen ? 'auto' : 0, overflow: isOpen ? 'initial' : 'hidden' }}>
        {!props.layer && !item.children && <NoMaterials sidebar={props.sidebar} />}
        {item?.children && (
          <ChildMaterialList layer={props.layer}>
            {item.children?.map((c, i, self) => (
              <ClientMaterialItem
                key={c.id}
                item={c}
                open={(id) => open(id)}
                isChild
                layer={props.layer + 1}
                index={i + 1}
                isLast={
                  !!lastMaterialsOfTopics.length
                    ? props.sidebar
                      ? i === self.length - 1
                      : lastMaterialsOfTopics.includes(c.id)
                    : true
                }
                sidebar={props.sidebar}
              />
            ))}
          </ChildMaterialList>
        )}
      </div>
    </MaterialItemElement>
  );
};

export default ClientMaterialItem;
