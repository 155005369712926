import { Link } from '@tanstack/react-location';
import { useQuery } from 'react-query';
import { getUnreadMessages } from '../../../api/common';
import Pill from '../../../shared/Pill';
import AdminOnline from './AdminOnline';
import { getOnCheckUsers } from '../../../utils/array';
import {
  ADMIN_COURSES,
  ADMIN_USERS,
  ADMIN_SUPPORT_CHATS,
  ADMIN_USERS_CHECKING,
  ADMIN_USERS_EXAM,
} from '../routes';
import { useUsersCheckParams } from '../store/useUsers';
import { checkIsIntelleka } from '../../../utils/link';
import { useGetAdminUsers } from '../../../cache/admin/users';
import { useEffect } from 'react';
import { useNotificationsStore } from '../../common/store/useNotifications';

const AdminMenu: React.FC = () => {
  const { params } = useUsersCheckParams();
  const { data } = useGetAdminUsers(params, false);
  const onCheckUsers = getOnCheckUsers(data?.data.results);

  const { data: unreadMessages, refetch } = useQuery('unread', getUnreadMessages);
  const { notifications } = useNotificationsStore();

  useEffect(() => {
    notifications.length > 0 && refetch();
  }, [notifications.length, refetch]);

  const isIntelleka = checkIsIntelleka();

  return (
    <div
      className={`flex-col md:flex-row ${
        isIntelleka ? 'gradient_i' : 'gradient_p'
      } text-[20px] text-white flex items-center justify-between px-8 py-[0.3rem]`}
    >
      <Link to={ADMIN_COURSES}>Курсы</Link>
      <div>
        <Link to={ADMIN_USERS}>Пользователи</Link>
        <AdminOnline />
      </div>
      <Link to={ADMIN_SUPPORT_CHATS}>
        Сообщения
        {!!unreadMessages?.total_unread_messages && (
          <Pill>{unreadMessages.total_unread_messages}</Pill>
        )}
      </Link>
      <Link to={ADMIN_USERS_CHECKING}>
        Проверка
        {!!onCheckUsers?.length && <Pill>{onCheckUsers?.length}</Pill>}
      </Link>
      <Link to={ADMIN_USERS_EXAM}>Экзамен</Link>
    </div>
  );
};

export default AdminMenu;
