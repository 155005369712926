import { isCurrentBuildProd } from '../../../../constants';
import { checkIsIntelleka } from '../../../../utils/link';
import OneClickLogin from '../../../admin/containers/AdminOneClick';
import bg from '../../../../img/authBackground.png';
import bg_i from '../../../../img/intelecaAuthBackground.png';

const LoginBackground: React.FC = ({ children }) => {
  const isIntelleka = checkIsIntelleka();

  return (
    <div
      className="h-[100vh] bg-cover"
      style={{ backgroundImage: `url(${isIntelleka ? bg_i : bg})` }}
    >
      {!isCurrentBuildProd && <OneClickLogin userStatus={5} />}
      <div className="bg-[rgba(0,0,0,.5)] w-full h-full" />
      {children}
    </div>
  );
};

export default LoginBackground;
