import { useMatch } from '@tanstack/react-location';
import { FaEdit } from 'react-icons/fa';
import { ITest } from '../../../typings/admin';
import { checkIsIntelleka } from '../../../utils/link';
import AdminTestForm from '../pages/AdminTestForm';
import { useUpdateTest } from '../../../cache/admin/task';
import { useCourseTests } from '../store/useTests';

const AdminEditTest: React.FC<{ test: ITest }> = (props) => {
  const match = useMatch();
  const courseId = match.params.id;
  const { editingId, setEditingId } = useCourseTests();

  const { mutate: update, isLoading: updating } = useUpdateTest(courseId);

  const isIntelleka = checkIsIntelleka();

  return (
    <>
      <FaEdit
        onClick={() => setEditingId(props.test.id)}
        className={`cursor-pointer ${
          isIntelleka ? 'hover:text-active_i' : 'hover:text-active_p'
        } transition-all`}
      />
      <AdminTestForm
        open={editingId === props.test.id}
        onClose={() => setEditingId()}
        submit={update}
        loading={updating}
        edit={props.test}
      />
    </>
  );
};

export default AdminEditTest;
