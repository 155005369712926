import { Collapse } from 'react-collapse';
import { useQuery } from 'react-query';
import { API } from '../../../api';
import { ITask } from '../../../typings/client';
import { getTaskTopic } from '../../../utils/material';
import { useTestCollapse } from '../store/useCollapse';
import { useCourse } from '../store/useCourse';
import { useTest } from '../store/useTest';
import ClientCheckTaskAvailability from './ClientCheckTaskAvailability';
import ClientCollapseCaret from './ClientCollapseCaret';

const ClientTestCollapse: React.FC<{ test: ITask; isLast: boolean }> = ({ test, isLast }) => {
  const { setTask } = useTest();
  const { course } = useCourse();
  const { closed, toggleClosed } = useTestCollapse();
  const open = !closed.includes(test.id);

  const { data: materials } = useQuery(
    ['materials', course],
    () => API.client.material.getAllMaterials({ course }),
    {
      enabled: !!course,
    },
  );

  return (
    <div
      className={`flex flex-col justify-start border-b ${
        isLast ? 'border-b-transparent' : 'border-b-gray-dark_opacity'
      } py-[15px] transition-all duration-1000`}
    >
      <div onClick={() => toggleClosed(test.id)} className="relative cursor-pointer">
        <div className="text-gray-dark text-[15px]">{test.title}</div>
        <div className="text-[25px] mr-5">{getTaskTopic(test.material, materials)}</div>
        <ClientCollapseCaret isOpen={open} />
      </div>
      <Collapse isOpened={open}>
        <ClientCheckTaskAvailability
          key={test.id}
          task={test}
          setTask={() => setTask(test)}
          noTitle
        />
      </Collapse>
    </div>
  );
};

export default ClientTestCollapse;
