import { useNavigate } from '@tanstack/react-location';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { clearStringHtml } from '../../../utils/string';
import ClientTemplate from '../templates/ClientTemplate';
import { ROOT, INFO, MATERIALS } from '../routes';
import { useCourse } from '../store/useCourse';
import Spinner from '../../../shared/Spinner';
import { checkIsIntelleka } from '../../../utils/link';
import { API } from '../../../api';

const ClientRedirects: React.FC = () => {
  const { data: courses } = useQuery('courses', () => API.client.course.getAllCourses());
  const { course } = useCourse();
  const navigate = useNavigate();

  const [noCourse, setNoCourse] = useState(false);

  useEffect(() => {
    if (course !== undefined) {
      const hasDescription = Boolean(
        clearStringHtml(courses?.find((c) => c.id === course)?.description),
      );
      hasDescription ? navigate({ to: ROOT + INFO }) : navigate({ to: ROOT + MATERIALS });
    } else {
      courses && courses.length === 0 && setNoCourse(true);
    }
  }, [course, courses, navigate]);

  const isIntelleka = checkIsIntelleka();

  return (
    <ClientTemplate>
      <div className="min-h-[calc(100vh-433px)]">
        {noCourse ? (
          <div className="text-gray-500 italic pt-7 px-5">Вам еще не назначен курс</div>
        ) : (
          <div className="flex justify-center items-center min-h-[170px]">
            <Spinner className={`${isIntelleka ? 'fill-active_i' : 'fill-active_p'} w-10`} />
          </div>
        )}
      </div>
    </ClientTemplate>
  );
};

export default ClientRedirects;
