import { FaUniversity, FaUserAlt, FaUserTie } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { getCurrentUser } from '../../../api/common';
import { SidebarLink } from '../../../shared/SidebarLink';
import { checkIsIntelleka } from '../../../utils/link';
import { ADMIN_COMPANIES, ADMIN_USERS, ADMIN_MODERATORS, ADMIN_CURATORS } from '../routes';

const AdminUsersMenu: React.FC = () => {
  const { data: currentUser } = useQuery('user', getCurrentUser);
  const isAdmin = currentUser?.user_status === 0;
  const isIntelleka = checkIsIntelleka();

  return (
    <div
      className={`flex w-full max-w-[700px] ${
        isAdmin ? 'justify-between' : 'justify-start'
      } text-lg mb-8 flex-col md:flex-row`}
    >
      <SidebarLink
        link={ADMIN_COMPANIES}
        icon={<FaUniversity />}
        height="auto"
        border={false}
        divider={false}
        className="pl-0"
        isIntelleka={isIntelleka}
      >
        Компании
      </SidebarLink>
      <SidebarLink
        link={ADMIN_USERS}
        icon={<FaUserAlt />}
        height="auto"
        border={false}
        divider={false}
        className="pl-0 md:pl-6"
        isIntelleka={isIntelleka}
      >
        Пользователи
      </SidebarLink>
      {isAdmin && (
        <>
          <SidebarLink
            link={ADMIN_MODERATORS}
            icon={<FaUserTie />}
            height="auto"
            border={false}
            divider={false}
            className="pl-0 md:pl-6"
            isIntelleka={isIntelleka}
          >
            Преподаватели
          </SidebarLink>
          <SidebarLink
            link={ADMIN_CURATORS}
            icon={<FaUserAlt />}
            height="auto"
            border={false}
            divider={false}
            className="pl-0 md:pl-6"
            isIntelleka={isIntelleka}
          >
            Кураторы
          </SidebarLink>
        </>
      )}
    </div>
  );
};

export default AdminUsersMenu;
