/** Компонент подсказки к тесту */
const TaskHint: React.FC<{ prepared_text: string }> = ({ prepared_text }) => {
  function createMarkup() {
    return { __html: prepared_text.replaceAll('.', '. <br />') };
  }

  return (
    <div
      className="italic leading-none mt-1 text-gray-600"
      dangerouslySetInnerHTML={createMarkup()}
    />
  );
};

export default TaskHint;
