import { useMatch } from '@tanstack/react-location';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API } from '../../../api';
import { IAnswer, TCreateAnswer, TCreateQuestion } from '../../../typings/admin';
import { IVariantTexts } from '../../../ui/admin/containers/AdminQuestionForm';
import { useQuestions } from '../../../ui/admin/store/useQuestions';
import { getError } from '../../../utils/api';
import { queryClient } from '../../../hooks/queryClient';

export function useCreateQuestion(questionsKey: string[]) {
  const match = useMatch();
  const { toggle, setError } = useQuestions();

  const { mutate: addAnswer } = useMutation<IAnswer, unknown, TCreateAnswer>((data) =>
    API.admin.answer.create(data),
  );

  return useMutation<
    unknown,
    unknown,
    TCreateQuestion & { variants: IVariantTexts } & { correctAnswers: number[] }
  >(
    (data) => {
      return data.file?.[0]
        ? API.admin.question.createFromFile({ file: data.file?.[0], task: match.params.testId })
        : API.admin.question
            .create({ ...data, tasks: [Number(match.params.testId)] })
            .then((question) =>
              Object.keys(data.variants).forEach((id) =>
                addAnswer({
                  id: Number(id),
                  text: data.variants[id],
                  question: question.id,
                  is_true: data.correctAnswers.includes(Number(id)),
                }),
              ),
            );
    },
    {
      onSuccess: () => {
        toggle(false);
        queryClient.refetchQueries(questionsKey);
      },

      onError: (e) => setError(getError(e as AxiosError)),
    },
  );
}
