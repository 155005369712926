/** Компонент лейбла для элементов формы */
const Label: React.FC<{ label: string }> = ({ children, label }) => {
  return (
    <label className="mb-5 block w-full">
      <div className="mb-1">{label}</div>
      {children}
    </label>
  );
};

export default Label;
