import { Modal as MuiModal } from '@mui/material';
import { useState } from 'react';
import { useKey } from 'react-use';
import { ThemeButton } from './Button';
import Modal from './Modal';
import Spinner from './Spinner';

/** Компонент рендера модального окна подтверджения */
const ConfirmModal: React.FC<{
  confirm(): void;
  isOpen: boolean;
  hide(): void;
  loading?: boolean;
  className?: string;
  isIntelleka?: boolean;
}> = ({ children, confirm, isOpen, hide, loading = false, className = '', isIntelleka }) => {
  useKey('Escape', hide);

  return (
    <Modal open={isOpen} className={`rounded max-w-[500px] mt-[1.75rem] ${className}`}>
      <div className="border-b border-gray-default p-4">{children}</div>
      <div className="flex items-center justify-end p-4">
        <ThemeButton
          className="mr-2"
          onClick={hide}
          isIntelleka={!!isIntelleka}
          isSecondary
          type="button"
        >
          Отмена
        </ThemeButton>
        <ThemeButton
          className="flex items-center"
          onClick={confirm}
          disabled={loading}
          isIntelleka={!!isIntelleka}
          type="button"
        >
          <span data-testid="confirm">Продолжить</span>
          {loading && (
            <Spinner
              className={`ml-2 w-[14px] ${isIntelleka ? 'fill-active_i' : 'fill-active_p'}`}
            />
          )}
        </ThemeButton>
      </div>
    </Modal>
  );
};

export default ConfirmModal;

export interface IConfirmModalProps {
  /** secr */
  action(): Promise<void> | void;
  /** secr */
  confirmText: string;
  /** secr */
  buttonText: string;
  /** стиль интеллеки */
  isIntelleka: boolean;
}
/**
 * Модальное окно подтверждения с кнопками асинхронного действия или отменой
 */
export const ConfirmModalAsync: React.FC<IConfirmModalProps> = ({
  children,
  action,
  confirmText,
  buttonText,
  isIntelleka,
}) => {
  const [isOpen, toggle] = useState(false);
  const [loading, setLoading] = useState(false);

  const doAction = async () => {
    setLoading(true);
    await action();
    setLoading(false);
    toggle(false);
  };

  return (
    <>
      <MuiModal open={isOpen} onClose={() => toggle(!isOpen)} disableAutoFocus>
        <div className="bg-white rounded max-w-[500px] mt-[1.75rem] m-auto p-4">
          <div className="d-block mb-2">{confirmText}</div>
          <ThemeButton
            className="mr-1"
            onClick={doAction}
            disabled={loading}
            isIntelleka={isIntelleka}
          >
            {buttonText}
            {loading && (
              <Spinner className={`ml-2 ${isIntelleka ? 'fill-active_i' : 'fill-active_p'} w-8`} />
            )}
          </ThemeButton>
          <ThemeButton onClick={() => toggle(false)} isIntelleka={isIntelleka} isSecondary>
            Отмена
          </ThemeButton>
        </div>
      </MuiModal>
      <span onClick={() => toggle(true)}>{children}</span>
    </>
  );
};
