import { useNavigate } from '@tanstack/react-location';
import { useEffect } from 'react';
import { useCookie } from 'react-use';
import { loginRedirects } from '../../../../utils/api';

export const useAuthRedirects = (onLogin: (access: string) => void) => {
  const [access] = useCookie('access');
  const navigate = useNavigate();

  const onSuccess = (access: string) => {
    onLogin(access);
    loginRedirects((to) => navigate({ to, replace: true }), access);
  };

  useEffect(() => {
    access && loginRedirects((to) => navigate({ to, replace: true }), access);
  }, [access, navigate]);

  return { onSuccess };
};
