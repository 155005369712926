import { Alert } from '@mui/material';
import { useMatch, useNavigate } from '@tanstack/react-location';
import { FaRegCommentDots, FaTasks, FaUserAlt } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { createChat, getAllChats, getCurrentUser } from '../../../api/common';
import { useGetSingleUser } from '../../../cache/admin/users';
import { SidebarLink } from '../../../shared/SidebarLink';
import { checkIsIntelleka } from '../../../utils/link';
import {
  USER_VISITINGS,
  USER_COURSE_CHUNK,
  USER_DEVICES,
  generateChat,
  generateUserLink,
} from '../routes';
import AdminTemplate from './AdminTemplate';

const AdminUserTemplate: React.FC = ({ children }) => {
  const match = useMatch();
  const userId = match.params.id;
  const { data: currentUser } = useQuery('user', getCurrentUser);
  const { isError } = useGetSingleUser(Number(userId));
  const { pathname } = match;

  const navigate = useNavigate();

  const toChat = async () => {
    if (!currentUser) return;
    const { user_status, id } = currentUser;

    const chats = await getAllChats({
      chat_status: user_status,
      users: userId,
    });

    const chatToGo = chats.filter((chat) => chat.users.includes(id));

    if (chatToGo.length) {
      return navigate({ to: generateChat(true, chatToGo[0].id) });
    } else {
      try {
        const newChat = await createChat({
          chat_status: user_status,
          users: [Number(userId), id],
        });
        return navigate({ to: generateChat(true, newChat.id) });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const isIntelleka = checkIsIntelleka();

  const isStatisticPage =
    pathname.includes(USER_VISITINGS) ||
    pathname.includes(USER_COURSE_CHUNK) ||
    pathname.includes(USER_DEVICES);

  return (
    <AdminTemplate>
      {isError ? (
        <Alert color="error">Пользователь не найден</Alert>
      ) : (
        <div>
          <div className="mb-4 lg:mb-8 flex flex-col md:flex-row md:items-center w-full max-w-[550px] justify-between">
            <SidebarLink
              link={generateUserLink(userId, '/')}
              icon={<FaUserAlt />}
              height="auto"
              border={false}
              divider={false}
              className="pl-0"
            >
              Профиль пользователя
            </SidebarLink>
            <SidebarLink
              link={generateUserLink(userId, '/visitings')}
              icon={<FaTasks />}
              height="auto"
              border={false}
              divider={false}
              className={`pl-0 md:pl-2 ${
                isStatisticPage
                  ? isIntelleka
                    ? 'text-[#035096]'
                    : `text-[#ff6041]`
                  : 'text-inherit'
              }`}
            >
              Статистика
            </SidebarLink>
            <div
              className={`${
                isIntelleka ? 'hover:text-active_i' : 'hover:text-active_p'
              } text-gray-dark`}
            >
              <SidebarLink
                icon={<FaRegCommentDots />}
                height="auto"
                border={false}
                divider={false}
                noHightlightOnActive
                className="pl-0 md:pl-2"
              >
                <span onClick={toChat}>Чат</span>
              </SidebarLink>
            </div>
          </div>
          <div>{children}</div>
        </div>
      )}
    </AdminTemplate>
  );
};

export default AdminUserTemplate;
