import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useKey } from 'react-use';
import SvgSend from '../react-svg/SvgSend';
import { toBase64 } from '../utils/file';
import { FaTimes } from 'react-icons/fa';
import { AiOutlinePaperClip } from 'react-icons/ai';
import Spinner from './Spinner';
import { ref, uploadBytes } from 'firebase/storage';
import { storage } from '../firebase/config';

const innitialHeight = 30;

/**
 * Форма отправки сообщения в чате
 * @testcases:
 * - форма отправляет сообщения
 */
const ChatForm: React.FC<{
  onSend(message: string): void;
  disabled: boolean;
  sending: boolean;
  isIntelleka?: boolean;
}> = ({ onSend, disabled, sending, isIntelleka }) => {
  const { register, handleSubmit, getValues, setValue, reset, setFocus } =
    useForm<{ message: string; image: string }>();
  const [height, setHeight] = useState(innitialHeight);
  const [image, setImage] = useState('');
  const [isFile, setIsFile] = useState(false);

  useEffect(() => {
    !sending && setFocus('message');
  }, [sending, setFocus]);

  const submit = (value: string) => {
    image ? onSend(image) : onSend(value);
    setHeight(innitialHeight);
    setImage('');
    setIsFile(false);
    setTimeout(() => reset(), 0);
  };

  useKey(
    'Enter',
    (key) => {
      const value = getValues('message');
      if (key.ctrlKey) {
        setHeight(height + 20);
        setValue('message', value.concat('\n'));
      } else {
        submit(value);
      }
    },
    {},
    [height],
  );

  const [loading, setLoading] = useState(false);

  const onClickAdd = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    setLoading(true);

    if (files) {
      const file = files[0];
      const isImage = file.type.slice(0, 5) === 'image';

      if (isImage) {
        try {
          const link = await toBase64(file);
          if (typeof link === 'string') {
            setImage(link);
            setValue('message', `${link.slice(0, 40)}...`);
          }
          setLoading(false);
        } catch (e) {
          setLoading(false);
          throw Error();
        }
      } else {
        try {
          setIsFile(true);
          const storageRef = ref(storage, file.name);
          const snapshot = await uploadBytes(storageRef, files[0]);
          const { name, contentType } = snapshot.metadata;
          const message = `https://firebasestorage.googleapis.com/v0/b/promr-group.appspot.com/o/${name}?alt=media|${contentType?.slice(
            0,
            5,
          )}|${file.name}`;
          setValue('message', message);
          setLoading(false);
        } catch (e) {
          console.log(e);
          setLoading(false);
        }
      }
    }
  };

  return (
    <form
      className="border-t border-gray-dark_opacity flex items-center justify-between py-5 px-7"
      onSubmit={handleSubmit((data) => submit(data.message))}
    >
      {sending || loading ? (
        <Spinner className={`${isIntelleka ? 'fill-active_i' : 'fill-active_p'} w-4`} />
      ) : (
        <>
          {image ? (
            <div className="relative">
              <img src={image} alt="preview" width={50} />
              <FaTimes
                onClick={() => {
                  setImage('');
                  reset();
                }}
                className="absolute -right-2 -top-2 border border-active_p cursor-pointer transition-all hover:p-[1px] bg-white p-[3px] text-active_p rounded-full"
              />
            </div>
          ) : isFile ? (
            <div className="relative">
              <AiOutlinePaperClip className="text-lg" />
              <FaTimes
                onClick={() => {
                  setIsFile(false);
                  reset();
                }}
                className="absolute -right-2 -top-2 border border-active_p cursor-pointer transition-all hover:p-[1px] bg-white p-[3px] text-active_p rounded-full"
              />
            </div>
          ) : (
            <>
              <input
                type="file"
                {...register('image')}
                onChange={onClickAdd}
                id="add-file"
                className="hidden"
              />
              <label className="add-file-icon" htmlFor="add-file" />
            </>
          )}
        </>
      )}
      <textarea
        {...register('message')}
        style={{ height }}
        id="chat-textarea"
        disabled={sending}
        placeholder={sending ? 'Отправка...' : 'Введите текст сообщения...'}
        className="border-0 focus:outline-none w-[calc(100%-90px)] text-black-default text-[15px] pt-1 px-2 pb-0 resize-none bg-transparent overflow-hidden"
        data-testid="chat-textarea"
      />
      <button
        disabled={disabled}
        className={`${isIntelleka ? 'text-active_i' : 'text-active_p'}`}
        type="submit"
        data-testid="chat-form-btn"
      >
        <SvgSend />
      </button>
    </form>
  );
};

export default ChatForm;
