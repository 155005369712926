const SvgClose: React.FC<{ className?: string; onClick(): void }> = ({
  className = '',
  onClick,
}) => (
  <div className={className} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" stroke="#fff">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
    </svg>
  </div>
);

export default SvgClose;
