import { useQuery } from 'react-query';
import { useTest } from '../store/useTest';
import ClientTaskTemplate from '../templates/ClientTaskTemplate';
import ClientTestVariant from './ClientTestVariant';
import ClientTestLoading from './ClientTestLoading';
import ClientTestFreeAnswer from './ClientTestFreeAnswer';
import ClientTestProgress from './ClientTestProgress';
import Button from '../../../shared/Button';
import Media from '../../../shared/Media';
import { checkIsIntelleka } from '../../../utils/link';
import { API } from '../../../api';
import { useCourse } from '../store/useCourse';
import { shuffle } from 'lodash';
import { useMemo } from 'react';

const ClientTestUiQuestion: React.FC = () => {
  const {
    question,
    onNext,
    answers,
    setPluralAnswer,
    setSingleAnswer,
    file,
    freeAnswer,
    getQuestionNumber,
    onSkip,
    isQuestionSkipped,
    isLastNotAnswered,
    skippedQuestions,
    backToSkipped,
    isFirstSkipped,
    task,
  } = useTest();
  const {
    data,
    isFetching: variantsLoading,
    isFetched: variantsFetched,
  } = useQuery(
    ['variants', question?.id],
    () => API.client.answer.getAllAnswers({ question: question?.id, ordering: 'id' }),
    {
      enabled: !!question?.id,
    },
  );

  const variants = useMemo(() => (task?.is_mix ? shuffle(data) : data), [data]);

  const { course } = useCourse();

  if (!question || (variantsLoading && !variantsFetched)) return <ClientTestLoading />;

  const trueVariantsCount = variants?.filter((v) => v.is_true)?.length;
  const isPlural = !!trueVariantsCount && trueVariantsCount > 1;
  const questionNumber = getQuestionNumber();
  const isIntelleka = checkIsIntelleka();

  return (
    <ClientTaskTemplate>
      <div className="mb-1 text-[17px] sm:text-[19px] flex">
        {questionNumber && <div className="mr-3">{questionNumber}.</div>}
        <div>{question.text}</div>
      </div>
      <Media m_file={question.m_file} />
      <div>
        {question.is_free_answer ? (
          <ClientTestFreeAnswer />
        ) : (
          <div className="ml-7 mt-6 mb-8">
            {variants?.map((v, i, self) => {
              const isLast = i === self.length - 1;
              return (
                <div key={v.id}>
                  <ClientTestVariant
                    id={v.id}
                    onChange={() => (isPlural ? setPluralAnswer(v.id) : setSingleAnswer(v.id))}
                    checked={answers.includes(v.id)}
                    label={v.text}
                    isCheckBox={isPlural}
                  />
                  {!isLast && <div className="border-b border-dotted border-gray-line" />}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="text-sm sm:text-[17px] mb-[27px] flex">
        <Button
          onClick={backToSkipped}
          className="w-[136px] h-[50px]"
          disabled={!skippedQuestions.length || isFirstSkipped()}
          isIntelleka={isIntelleka}
        >
          Назад
        </Button>
        <Button
          className="mx-3 w-[136px] h-[50px]"
          onClick={() => course && onNext(course)}
          disabled={!answers.length && !file && !freeAnswer}
          isIntelleka={isIntelleka}
        >
          Ответить
        </Button>
        {!!task?.is_miss && (
          <Button
            className="w-[136px] h-[50px]"
            disabled={isQuestionSkipped() || isLastNotAnswered()}
            onClick={onSkip}
            isIntelleka={isIntelleka}
          >
            Пропустить
          </Button>
        )}
      </div>
      <ClientTestProgress />
    </ClientTaskTemplate>
  );
};

export default ClientTestUiQuestion;
