import { keyBy } from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { API } from '../../../api';
import { queryClient } from '../../../hooks/queryClient';
import { IUsersRes } from '../../../typings/admin';
import { TPagination } from '../../../ui/admin/store/useUsers';
import { getKey, getSingleUserKey, loadedUsers } from './';

export function useGetAdminUsers(params: TPagination, isExamsPage: boolean) {
  const key = getKey(params);

  async function getData() {
    const data = isExamsPage
      ? await API.admin.user.exams(params)
      : await API.admin.user.all(params);
    const usersById = keyBy(data.results, 'id');
    return { data, usersById };
  }

  return useQuery(key, getData, { refetchOnWindowFocus: false });
}

export const useLoadUsers = () => {
  return useMutation<IUsersRes, unknown, number>(
    loadedUsers,
    (page_size) => API.admin.user.all({ user_status: 2, page_size }),
    { onSuccess: async (res) => queryClient.setQueryData(loadedUsers, () => res) },
  );
};

export const useGetSingleUser = (userId: number) => {
  return useQuery(getSingleUserKey(userId), () => API.admin.user.byId(userId), {
    enabled: !!userId,
    retry: false,
  });
};
