/**
 * @module material
 * @category общие утилиты
 * @description утилиты по работе с материалами курса
 */

import { keyBy, last } from 'lodash';
import { IMaterial } from '../typings/admin';
import { onlyUnique } from './array';

export type IStructured = {
  id: number;
  title: string;
  parent?: number | null;
  children?: IStructured[];
  rank: number;
  is_download: boolean;
  pdf: string | File;
};

/**
 * Функция рекурсивно структурирует материалы по вложенностям
 */
export const getStructuredMaterials = <T extends IStructured>(arr: T[], parent?: number) => {
  const resultArray: IStructured[] = [];

  arr.forEach((el) => {
    /*eslint eqeqeq: "off"*/
    if (el.parent == parent) {
      const children = getStructuredMaterials(arr, el.id);

      if (children.length) {
        el.children = children;
      }
      resultArray.push(el);
    }
  });

  return resultArray;
};

/** Типизация прототипа материала для функции {@link sortMaterials} */
export type IMaterialPrototype = {
  rank: number;
  id: number;
};

/**
 * Функция сортирует материал по ключу, представленном в параметрах
 * @param data материалы
 * @param key ключ для сортировки
 * @returns отсортированный массив материалов
 */
export const sortMaterials = <T extends IMaterialPrototype[]>(
  data: T,
  key: keyof IMaterialPrototype,
) => {
  return data.sort((a, b) => {
    if (a[key] === b[key]) {
      return 0;
    }

    if (a[key] > b[key]) {
      return 1;
    } else {
      return -1;
    }
  });
};

/**
 * Функция определяет заголовок материала верхнего уровня
 * по айдишнику материала, к которому привязан тест
 * @param materialId айди материала
 * @param materials массив материалов курса
 * @returns Возвращает заголовок материала верхнего уровня
 */
export const getTaskTopic = (materialId?: number, materials?: IMaterial[]) => {
  if (!materialId || !materials) return '';

  const entities = keyBy(materials, 'id');

  if (!entities || !materialId) return;
  const firstLevel = entities[materialId || 0];
  if (firstLevel?.parent) {
    const secondLevel = entities[firstLevel?.parent];
    if (secondLevel?.parent) {
      const thirdLevel = entities[secondLevel?.parent];
      if (thirdLevel?.parent) {
        return entities[thirdLevel?.parent]?.title;
      } else {
        return thirdLevel?.title;
      }
    } else {
      return secondLevel?.title;
    }
  } else {
    return firstLevel?.title;
  }
};

/**
 * функция считает количество материалов в секции прогрессбара
 * @param material заглавный материал секции
 * @param materials все материалы
 * @param fullStructured материалы структурированые по вложенностям
 * @returns количество материалов в текущей секции прогрессбара
 */
export const getCurrentSectionMaterials = (
  material: IStructured,
  materials: IMaterial[],
  fullStructured?: IStructured[],
) => {
  const lastMaterialRank = last(materials)?.rank;
  const firstRanksOfEachBlock = fullStructured?.map((el) => el.rank);
  const tempRanksData = firstRanksOfEachBlock?.map((rank) => rank - 1).filter((m) => m > 0);
  const lastRanksOfEachBlock =
    lastMaterialRank && tempRanksData ? tempRanksData.concat(lastMaterialRank) : tempRanksData;
  const allMaterialsRanks = materials?.map((el) => el.rank);
  const lastRankOfCurrentSection =
    (lastRanksOfEachBlock &&
      firstRanksOfEachBlock &&
      lastRanksOfEachBlock[firstRanksOfEachBlock?.indexOf(material.rank)]) ||
    0;
  const currentSectionRanks = allMaterialsRanks
    ?.filter((rank) => rank >= material.rank && rank <= lastRankOfCurrentSection)
    .filter(onlyUnique);

  const allMaterialsByRank = keyBy(materials, 'rank');

  /** если текущий материал не возглавляет секцию, возвращаем пустой массив */
  return material.parent
    ? []
    : currentSectionRanks
        .map((rank) => allMaterialsByRank[rank].id)
        .filter((id) => id !== material.id);
};
