const BadgeButton: React.FC<{
  onClick?(): void;
  className?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}> = ({
  onClick = () => null,
  className = 'badge bg-gray-badge whitespace-nowrap font-bold text-[15px] focus:outline-none',
  children,
  disabled = false,
  style = {},
}) => {
  return (
    <button
      className={className}
      onClick={onClick}
      disabled={disabled}
      style={style}
      data-testid="badge-button"
    >
      {children}
    </button>
  );
};

export default BadgeButton;
