import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useMaterial } from '../store/useMaterial';
import ClientToggleSidebarIcon from './ClientToggleSidebarIcon';

const ClientMaterialYoutube: React.FC<{ link: string; onEnded(): void }> = ({ link, onEnded }) => {
  const getSidebarWidth = () => (window.innerWidth > 991 ? 368 : 0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const { tasks, setId } = useMaterial();

  useEffect(() => {
    if (tasks.length) {
      tasks.forEach((t) => setId(t.material));
    }
  }, [setId, tasks]);

  useEffect(() => {
    setWidth(window.innerWidth - getSidebarWidth());
    setHeight(window.innerHeight);
  }, []);

  return (
    <div className="material-video-wrapper">
      <ClientToggleSidebarIcon />
      <ReactPlayer url={link} width={width} height={height} controls playing onEnded={onEnded} />
    </div>
  );
};

export default ClientMaterialYoutube;
