import axios from 'axios';
import {
  IAnswer,
  IAnswerParams,
  IUserAnswer,
  IUserAnswersParams,
  TCreateAnswer,
  TUpdateAnswer,
  TUpdateModeratorUserAnswer,
} from '../../typings/admin';

export async function all(params?: IAnswerParams) {
  try {
    const { data } = await axios.get<IAnswer[]>(`/api/v1/courses/answer/`, { params });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function create(model: TCreateAnswer) {
  try {
    const { data } = await axios.post<IAnswer>('/api/v1/courses/answer/', model);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function update({ id, ...model }: TUpdateAnswer) {
  try {
    const { data } = await axios.put<IAnswer>(`/api/v1/courses/answer/${id}/`, model);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getModeratorUserAnswers(params?: IUserAnswersParams) {
  try {
    const { data } = await axios.get<IUserAnswer[]>(`/api/v1/courses/moderator_user_answers/`, {
      params,
    });

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function checkModeratorAnswer({ id, ...model }: TUpdateModeratorUserAnswer) {
  try {
    const { data } = await axios.put<IUserAnswer>(
      `/api/v1/courses/moderator_user_answer/${id}/`,
      model,
    );

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
