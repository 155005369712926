import axios from 'axios';
import { IChat, IChatParams, IChatStatus } from '../../typings/common';

export const CHATS_URL = `/api/v1/chats/chat/`;

/**
 * функция возвращает массив чатов
 * @param params параметры
 * @returns массив чатов
 */
export async function getAllChats(params?: IChatParams) {
  try {
    const { data } = await axios.get<IChat[]>(CHATS_URL, { params });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createChat(model: IChat) {
  try {
    const { data } = await axios.post<IChat>(CHATS_URL, model);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getChatListByUserId(userId: number, chatStatus: IChatStatus) {
  const url = `${CHATS_URL}?users=${userId}&chat_status=${chatStatus}`;
  try {
    const { data } = await axios.get(url);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
