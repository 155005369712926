import mail_img from '../img/mail.svg';
import phone_img from '../img/phone.svg';
import ContactItem from './ContactItem';

const PHONE_INTELLEKA = '+7(495)268-01-80',
  PHONE_PROMR = '+7(495)663-71-07',
  EMAIL_INTELLEKA = 'support@intelleka.ru',
  EMAIL_PROMR = 'support@promrg.ru';

export interface IContactInfoProps {
  /** Интерфейс интелеки */
  isIntelleka?: boolean;
}

/** Компонент рендера контактной информации в хедере */
const ContactInfo: React.FC<IContactInfoProps> = ({ isIntelleka = false }) => {
  const phoneNumber = isIntelleka ? PHONE_INTELLEKA : PHONE_PROMR;
  const email = isIntelleka ? EMAIL_INTELLEKA : EMAIL_PROMR;

  const phoneTooltip = (
    <div className="text-sm text-center">
      <div>Контактный телефон</div>
      <div>службы поддержки</div>
      <div>{phoneNumber.replaceAll(')', ') ').replaceAll('(', ' (')}</div>
    </div>
  );

  const mailTooltip = (
    <div className="text-sm text-center max-w-[145px]">
      <div>По ЛЮБЫМ вопросам можно обращаться в службу поддержки по электронной почте</div>
      <div>{email}</div>
    </div>
  );

  return (
    <>
      <div className="hidden md:block">
        <ContactItem
          link={`tel:${phoneNumber}`}
          img={phone_img}
          text={phoneNumber}
          tooltip={phoneTooltip}
          tooltipPlacement="bottom"
        />
      </div>
      <div className="hidden lg:block">
        <ContactItem
          link={`mailto:${email}`}
          img={mail_img}
          text={email}
          tooltip={mailTooltip}
          tooltipPlacement="bottom"
        />
      </div>
    </>
  );
};

export default ContactInfo;
