import { useMatch, Link } from '@tanstack/react-location';
import { useToasts } from 'react-toast-notifications';
import { useMutation, useQuery } from 'react-query';
import {
  IOutOfTimeTestPassing,
  ITest,
  TUpdateTask,
  TUpdateTestPassing,
} from '../../../typings/admin';
import Table from '../../../shared/Table';
import { renderDate, secondsToDhms, secondsToTime, toHoursAndMinutes } from '../../../utils/time';
import AdminUserCourseTemplate from '../templates/AdminUserCourseTemplate';
import { generateUserTestPassingLink } from '../routes';
import { useEffect, useState } from 'react';
import { queryClient } from '../../../hooks/queryClient';
import Spinner from '../../../shared/Spinner';
import { checkIsIntelleka } from '../../../utils/link';
import BadgeButton from '../../../shared/BadgeButton';
import { API } from '../../../api';
import ThemeInput from '../../../shared/ThemeInput';

const titles = [
  'Попытки',
  'Количество набранных баллов из числа возможных',
  '% правильных ответов',
  'Затраченное время',
  'Время на прохождение',
  'Дата сдачи теста',
  'Время до пересдачи',
];

const AdminUserCourseTest: React.FC = () => {
  const { addToast } = useToasts();
  const { params } = useMatch();
  const { id: userId, courseId, testId } = params;
  const passingsKey = ['passings', `course-${courseId}`, `task-${testId}`];
  const { data, isFetching } = useQuery(
    passingsKey,
    () =>
      API.admin.test_passing.all({ user: userId, task__material__course: courseId, task: testId }),
    { enabled: !!userId && !!courseId && !!testId },
  );
  const { data: task } = useQuery(['task', testId], () => API.admin.test.byId(testId));

  const passings = data?.map((p, i) => {
    const travelTime = Number(p.travel_time);
    return [
      <Link to={generateUserTestPassingLink(userId, courseId, task?.id, p.id)}>{i + 1}</Link>,
      <Link to={generateUserTestPassingLink(userId, courseId, task?.id, p.id)}>{task?.title}</Link>,
      <>{p.response_rate}</>,
      <>{travelTime < 86400 ? secondsToTime(travelTime) : secondsToDhms(Math.round(travelTime))}</>,
      <>{task?.travel_time}</>,
      <>{p.finish_time && renderDate(p.finish_time)}</>,
      <>{task && toHoursAndMinutes(task.retake_seconds)}</>,
    ];
  });

  useEffect(() => {
    if (task?.attempts) {
      setAttemptsCount(task?.attempts);
    }
  }, [task?.attempts]);

  const [attemptsCount, setAttemptsCount] = useState(0);

  const handleSetAttemptsCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAttemptsCount(Number(e.target.value));
  };

  const {
    mutateAsync: updatePassing,
    isLoading: passingUpdating,
    isSuccess: passingUpdated,
    reset: resetPassing,
  } = useMutation<IOutOfTimeTestPassing, unknown, TUpdateTestPassing>(
    (data) => API.admin.test_passing.change(data),
    { onSuccess: () => queryClient.refetchQueries(passingsKey) },
  );

  const repass = async () => {
    const lastAttempt = data?.length ? data[0] : null;

    if (lastAttempt) {
      updatePassing({ out_of_time: true, id: lastAttempt.id });
    }
  };

  const {
    mutate: updateTest,
    isLoading: updating,
    isSuccess: updated,
    reset: resetTask,
  } = useMutation<ITest, unknown, TUpdateTask>((data) => API.admin.test.update(data));

  useEffect(() => {
    if (passingUpdated) {
      addToast('Пользователю разрешено пересдать тест', {
        appearance: 'success',
        autoDismiss: true,
      });
      resetPassing();
    }
    if (updated) {
      addToast('Количество попыток изменено', {
        appearance: 'success',
        autoDismiss: true,
      });
      resetTask();
    }
  }, [passingUpdated, updated, resetPassing, resetTask, addToast]);

  const isIntelleka = checkIsIntelleka();

  return (
    <AdminUserCourseTemplate>
      <div>
        <div className="mb-2">Количество попыток:</div>
        <div className="flex flex-col lg:flex-row lg:items-center text-sm">
          <ThemeInput
            type="number"
            className="max-h-8 font-bold text-[16px]"
            onChange={handleSetAttemptsCount}
            value={attemptsCount}
            placeholder="Введите количество попыток"
            isIntelleka={isIntelleka}
          />
          <BadgeButton
            className={`my-1 lg:my-0 lg:mx-1 badge bg-gray-badge ${
              updating ? 'opacity-80' : 'opacity-100'
            }`}
            disabled={updating}
            onClick={() => updateTest({ ...task, attempts: attemptsCount, id: Number(testId) })}
          >
            <div className="py-1 min-w-[90px]">{updating ? 'Сохранение...' : 'Сохранить'}</div>
          </BadgeButton>
          {!!passings?.length && (
            <BadgeButton
              onClick={repass}
              className={`badge bg-gray-badge ${passingUpdating ? 'opacity-80' : 'opacity-100'}`}
            >
              <div className="py-1 px-3">
                <div className="flex items-center">
                  {!!data?.length && data[0].success_passed === 0
                    ? 'Сбросить зачет'
                    : 'Пересдать сейчас'}
                  {passingUpdating && <Spinner className="ml-2 fill-gray-badge w-3" />}
                </div>
              </div>
            </BadgeButton>
          )}
        </div>
      </div>
      <Table titles={titles} rows={passings} isLoading={!data && isFetching} />
    </AdminUserCourseTemplate>
  );
};

export default AdminUserCourseTest;
