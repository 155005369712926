/**
 * @module Avatar
 * @category Общие компоненты
 */

import CyrillicToTranslit from 'cyrillic-to-translit-js';
import Tooltip from '@mui/material/Tooltip';

export interface IUser {
  /** айди пользователя */
  id: number;
  /** логин пользователя */
  username: string;
}

export interface IAvatarProps {
  /** принадлежность сообщения текущему пользователю */
  isAuthorMessage?: boolean;
  /** логин автора сообщения */
  login?: string;
  /** интерфейс интеллеки */
  isIntelleka?: boolean;
}

const translit = new CyrillicToTranslit();

/**
 * Компонент аватара пользователя
 * @testcases:
 * - должны рендериться две заглавные буквы логина
 * - при наведении должна появиться подсказка полного логина пользователя
 */
const Avatar: React.FC<IAvatarProps> = ({ login, isAuthorMessage = false, isIntelleka }) => {
  return (
    <div className={`${isAuthorMessage ? 'ml-5' : 'mr-5'}`} data-testid="avatar">
      <Tooltip arrow placement="top" title={<div className="text-sm">{login}</div>}>
        <div
          data-testid="login"
          className={`flex ${
            isAuthorMessage
              ? isIntelleka
                ? 'bg-active_i'
                : 'bg-active_p'
              : isIntelleka
              ? 'bg-gradient-to-r from-[rgba(4,84,139,0.5)] to-active_i'
              : 'bg-gradient-to-r from-[rgba(255,96,65,0.5)] to-active_p'
          } w-[40px] h-[40px] min-w-[40px] items-center justify-center uppercase rounded-[15px] text-center text-md font-bold cursor-pointer text-white`}
        >
          {translit.reverse(`${login?.slice(0, 2)}`)}
        </div>
      </Tooltip>
    </div>
  );
};

export default Avatar;
