import ReactQuill, { Quill } from 'react-quill';
import '../css/quill.snow.css';

const Font = Quill.import('formats/font');

Font.whitelist = ['FSAlbert', 'Arial', 'Times-New-Roman', 'Roboto'];
Quill.register(Font, true);

export interface ITextEditorProps {
  className?: string;
  onChange: (value: string) => void;
  value?: string;
}

/** Компонент textarea с редактором разметки */
const TextEditor: React.FC<ITextEditorProps> = ({ className = '', value = '', ...props }) => {
  return (
    <ReactQuill
      value={value}
      className={className}
      theme="snow"
      onChange={(text) => props.onChange(text)}
      modules={{
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: Font.whitelist }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['link', 'image'],
          ['clean'],
        ],
      }}
      formats={[
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'font',
      ]}
    />
  );
};
export default TextEditor;
