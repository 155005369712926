import { useQuery } from 'react-query';
import { getCurrentUser } from '../../../api/common';
import Chat from '../../common/containers/ChatContainer';
import { createUrlChat, createWebsocketUrl } from '../../../utils/link';
import ClientTemplate from '../templates/ClientTemplate';

const ClientTeacher: React.FC = () => {
  const { data: user } = useQuery('user', getCurrentUser);
  const urlChat = user && createUrlChat('teacher_chat', user.id);
  const wsUrl = urlChat && createWebsocketUrl(urlChat);

  return (
    <ClientTemplate>
      <div>{user && wsUrl && <Chat userId={user?.id} wsUrl={wsUrl} chatStatus={1} />}</div>
    </ClientTemplate>
  );
};

export default ClientTeacher;
