import { useQuery } from 'react-query';
import { getAllTasks } from '../../../api/client/test';
import { ITask } from '../../../typings/client';

export const useGetTasks = (course?: number, onSuccess?: (data: ITask[]) => void) => {
  return useQuery(
    ['tasks', course],
    () => getAllTasks({ material__course__in: course, ordering: 'rank,id' }),
    {
      enabled: !!course,
      onSuccess,
    },
  );
};
