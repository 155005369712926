import { useMatch } from '@tanstack/react-location';
import React, { useEffect } from 'react';
import { useWindowSize } from 'react-use';
import Burger from '../../../shared/Burger';
import { useSidebar } from '../../admin/store/useMaterials';

const AsideTemplate: React.FC<{
  sidebar: React.ReactElement;
  className?: string;
  btnClassName?: string;
  isError?: boolean;
}> = ({ children, sidebar, className = '', btnClassName = '', isError }) => {
  const { width: windowWidth } = useWindowSize(window.innerWidth);
  const { params } = useMatch();

  const {
    width: sidebarWidth,
    setWidth: setSidebarWidth,
    initialWidth: initialSidebarWidth,
  } = useSidebar();

  const isMobile = windowWidth && windowWidth < 992;

  useEffect(() => {
    if (isMobile) {
      setSidebarWidth(0);
    } else {
      setSidebarWidth(initialSidebarWidth);
    }
  }, [initialSidebarWidth, isMobile, setSidebarWidth, windowWidth]);

  return (
    <>
      <Burger
        className={`cursor-pointer block lg:hidden ${
          params.courseId ? 'ml-0' : 'ml-4'
        }  ${btnClassName}`}
        onClick={() => {
          setSidebarWidth(sidebarWidth === 0 ? windowWidth : 0);
        }}
        open={sidebarWidth === windowWidth}
      />
      <div className={className}>
        <div
          className="relative"
          style={{
            minWidth: sidebarWidth,
            maxWidth: sidebarWidth,
          }}
        >
          {isError && (
            <div className="absolute left-0 top-0 bg-white h-full w-full z-10 opacity-50 cursor-not-allowed" />
          )}
          {sidebar}
        </div>
        {sidebarWidth !== windowWidth && <div className="w-full">{children}</div>}
      </div>
    </>
  );
};

export default AsideTemplate;
