import { toHoursAndMinutes } from '../../../utils/time';

export const ClientRepassCounter: React.FC<{ retakeSeconds: number; lastPassingDate?: string }> = (
  props,
) => {
  const today = Date.now();
  /** дата последней сдачи */
  const lastPassingSeconds = props.lastPassingDate ? new Date(props.lastPassingDate).getTime() : 0;
  /** дата пересдачи */
  const nextPassingSeconds = lastPassingSeconds + props.retakeSeconds * 1000;
  /** время осталось */
  const timeLeft = nextPassingSeconds - today;

  return <b>{toHoursAndMinutes(Math.round(timeLeft / 1000))}</b>;
};

export default ClientRepassCounter;
