import moment from 'moment';

/** Компонент рендера времени сообщения */
const MessageTime: React.FC<{
  /** время сообщения */
  date?: string;
  /** принадлежность сообщения автору */
  isAuthorMessage?: boolean;
}> = ({ date = new Date(), isAuthorMessage = false }) => {
  if (!date) return null;

  return (
    <div
      className={`${isAuthorMessage ? 'text-right' : 'text-left'} text-[12px] text-gray-pdf_header`}
    >
      {moment(date).format('HH:mm')}
    </div>
  );
};

export default MessageTime;
