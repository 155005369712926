import { useMatch } from '@tanstack/react-location';
import { useQuery } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { IUser } from '../../../typings/admin';
import AdminUserTemplate from '../templates/AdminUserTemplate';
import RSelect from '../../../shared/ReactSelect';
import { useEffect, useState } from 'react';
import RadioToggle from '../../../shared/RadioToggle';
import { RadioBordered } from '../../../shared/RadioButton';
import DatePicker from '../../../shared/DatePicker';
import { convertLocalTimeToFormat } from '../../../utils/time';
import { getDataForSelect } from '../../../utils/array';
import AdminAuthLink from '../containers/AdminAuthLink';
import { getCurrentUser } from '../../../api/common';
import { Tooltip } from '@mui/material';
import { checkIsIntelleka } from '../../../utils/link';
import { API } from '../../../api';
import { ThemeButton } from '../../../shared/Button';
import ThemeInput from '../../../shared/ThemeInput';
import {
  useChangeSingleUser,
  useChangeUserPassword,
  useGetSingleUser,
  useNotificationsUpdate,
} from '../../../cache/admin/users';

function* enumerate(iterable: any[]) {
  for (const x of iterable) {
    yield x;
  }
}

const AdminUserProfile: React.FC = () => {
  const match = useMatch();
  const userId = match.params.id;
  const { handleSubmit, control, setValue } = useForm<IUser>();

  const { data, isLoading: isLoadingUser } = useGetSingleUser(Number(userId));
  const { data: courses, isLoading: loadingCourses } = useQuery('courses', () =>
    API.admin.course.all(),
  );
  const { data: companies, isLoading: loadingCompanies } = useQuery('companies', () =>
    API.admin.company.all(),
  );

  const { onMutateUser, onMutatePassword, onMutateCourses } = useNotificationsUpdate();

  const { mutate: updateUser, isLoading: updating } = useChangeSingleUser(onMutateUser);
  const { mutate: changePassword, isLoading: updatingPassword } =
    useChangeUserPassword(onMutatePassword);

  const [currentCourses, setCurrentCourses] = useState<{ value: number; label: string }[]>([]);
  const [settings, setSettings] = useState<{
    is_active: boolean;
    group_chat: boolean;
    teacher_chat: boolean;
    support_chat: boolean;
    webinars: boolean;
  }>({
    is_active: !!data?.is_active,
    group_chat: !!data?.group_chat,
    teacher_chat: !!data?.teacher_chat,
    support_chat: !!data?.tech_chat,
    webinars: !!data?.webinars,
  });
  const [isIntelleka, setIsIntelleka] = useState(false);

  useEffect(() => {
    data &&
      setSettings({
        is_active: !!data?.is_active,
        group_chat: !!data?.group_chat,
        teacher_chat: !!data?.teacher_chat,
        support_chat: !!data?.tech_chat,
        webinars: !!data?.webinars,
      });
    setIsIntelleka(data?.design === 1);
  }, [data]);

  useEffect(() => {
    if (data) {
      if (data.courses?.length && !coursesLoading) {
        setCurrentCourses(data.courses.map(getDataForSelect));
      }

      setValue('first_name', data.first_name);
      setValue('company', data.company);
      setValue('username', data.username);
      setValue('old_password', data.old_password);
      setValue('start_course', data.start_course);
      setValue('end_course', data.end_course);
    }
  }, [data, setValue, settings]);

  const coursesList = courses?.map(getDataForSelect) || [];
  const selectCompanies = companies?.map(getDataForSelect);

  const [coursesLoading, setCoursesLoading] = useState(false);

  const submit = (user: IUser) => {
    if (!!data?.id) {
      const req = {
        id: data.id,
        company: user.company,
        design: isIntelleka ? 1 : 0,
        end_course: user.end_course,
        first_name: user.first_name,
        group_chat: settings.group_chat,
        is_active: settings.is_active,
        start_course: user.start_course,
        teacher_chat: settings.teacher_chat,
        tech_chat: settings.support_chat,
        username: user.username,
        webinars: settings.webinars,
      };
      updateUser(req);
    }
    if (data?.id && data.old_password !== user.old_password) {
      changePassword({ password: user.old_password, user_id: data.id });
    }
    const oldCourses = data?.courses?.length && JSON.stringify(data.courses.map(getDataForSelect));
    const newCourses = JSON.stringify(currentCourses);
    if (oldCourses !== newCourses) handleChangeCourses(currentCourses);
  };

  const handleChangeCourses = async (courses: { value: number; label: string }[]) => {
    if (!data?.id) return;
    setCoursesLoading(true);
    /** удаление старых курсов */
    const oldUserCourses = data.courses?.map((course) => course.id);
    if (oldUserCourses?.length) {
      for (const courseId of enumerate(oldUserCourses)) {
        try {
          await API.admin.user.updateCourse({
            action: 'del',
            user_id: data?.id,
            course_id: courseId,
          });
        } catch (e) {
          onMutateCourses({ appearance: 'error' });
        }
      }
    }
    if (courses.length) {
      for (const course of enumerate(courses)) {
        try {
          await API.admin.user.updateCourse({
            action: 'add',
            user_id: data?.id,
            course_id: course.value,
          });
          const isLast = course === courses[courses.length - 1];
          isLast && onMutateCourses({ appearance: 'success' });
        } catch (e) {
          onMutateCourses({ appearance: 'error' });
        }
      }
    }
    setCoursesLoading(false);
  };

  const { data: currentUser } = useQuery('user', getCurrentUser);
  const isAdmin = currentUser?.user_status === 0;

  const isIntellekaStyle = checkIsIntelleka();

  return (
    <AdminUserTemplate>
      <form onSubmit={handleSubmit(submit)} className="w-full max-w-[850px]">
        <label className="mb-4 block">
          <div className="mb-1">ФИО</div>
          <Controller
            control={control}
            render={({ field }) => (
              <ThemeInput
                {...field}
                className="w-full"
                disabled={isLoadingUser}
                isIntelleka={isIntellekaStyle}
              />
            )}
            name="first_name"
          />
        </label>
        <label className="mb-4 block">
          <div className="mb-1">Компания</div>
          <Controller
            control={control}
            render={({ field: { value, name } }) => {
              return (
                <RSelect
                  value={selectCompanies?.find((c) => c.value === value)}
                  name={name}
                  options={selectCompanies}
                  onChange={(data: any) => data && setValue(name, data?.value)}
                  placeholder={loadingCompanies ? 'Загрузка...' : 'Компании'}
                  isDisabled={loadingCompanies}
                  isIntelleka={isIntelleka}
                />
              );
            }}
            name="company"
          />
        </label>
        <div className="flex flex-col lg:flex-row mb-4 justify-between">
          <label className="w-full mb-4 lg:mb-0">
            <div className="mb-1">Логин</div>
            <Controller
              control={control}
              render={({ field }) => (
                <ThemeInput
                  {...field}
                  className="w-full"
                  disabled={isLoadingUser}
                  isIntelleka={isIntellekaStyle}
                />
              )}
              name="username"
            />
          </label>
          <label className="w-full lg:mx-8 mb-4 lg:mb-0">
            <div className="mb-1">Пароль</div>
            <Controller
              control={control}
              name="old_password"
              render={({ field }) => (
                <ThemeInput
                  {...field}
                  className="w-full"
                  disabled={isLoadingUser || updatingPassword}
                  isIntelleka={isIntellekaStyle}
                />
              )}
            />
          </label>
          {data && <AdminAuthLink user={data} />}
        </div>
        <label className="mb-4">
          <div className="mb-1">{coursesLoading ? 'Обновление...' : 'Курсы'}</div>
          <RSelect
            value={currentCourses}
            options={coursesList}
            onChange={(data) => {
              if (Array.isArray(data)) {
                // handleChangeCourses(data);
                setCurrentCourses(data);
              }
            }}
            isDisabled={loadingCourses || coursesLoading || !isAdmin}
            placeholder={loadingCourses || coursesLoading ? 'Загрузка...' : 'Выберите курс'}
            isMulti
          />
        </label>
        <div className="flex flex-col lg:flex-row my-4 justify-between">
          <label className="w-full">
            <div className="mb-1">Дата начала обучения</div>
            <Controller
              control={control}
              render={({ field: { name, value } }) => {
                return (
                  <DatePicker
                    onChange={(value) =>
                      value && setValue(name, convertLocalTimeToFormat(value, 'YYYY-MM-DD'))
                    }
                    value={value}
                    field={name}
                    disabled={isLoadingUser}
                    dateFormat="dd.MM.yyyy"
                    isIntelleka={isIntellekaStyle}
                  />
                );
              }}
              name="start_course"
            />
          </label>
          <label className="w-full mx-0 lg:mx-8 my-4 lg:my-0">
            <div className="mb-1">Дата окончания обучения</div>
            <Controller
              control={control}
              render={({ field: { name, value } }) => {
                return (
                  <DatePicker
                    onChange={(value) =>
                      value && setValue(name, convertLocalTimeToFormat(value, 'YYYY-MM-DD'))
                    }
                    value={value}
                    field={name}
                    disabled={isLoadingUser}
                    dateFormat="dd.MM.yyyy"
                  />
                );
              }}
              name="end_course"
            />
          </label>
          <div className="w-full mx-0 lg:mx-8">
            <RadioToggle
              disabled={isLoadingUser}
              label="Активен"
              checked={settings.is_active}
              className="w-full"
              onChange={() => setSettings({ ...settings, is_active: !settings.is_active })}
              isIntelleka={isIntellekaStyle}
            />
            <RadioToggle
              disabled={isLoadingUser}
              label="Чат с преподавателем"
              checked={settings.teacher_chat}
              className="w-full"
              onChange={() => setSettings({ ...settings, teacher_chat: !settings.teacher_chat })}
            />
            <RadioToggle
              disabled={isLoadingUser}
              label="Чат с одногруппниками"
              checked={settings.group_chat}
              className="w-full"
              onChange={() => setSettings({ ...settings, group_chat: !settings.group_chat })}
            />
            <RadioToggle
              disabled={isLoadingUser}
              label="Служба поддержки"
              checked={settings.support_chat}
              className="w-full"
              onChange={() => setSettings({ ...settings, support_chat: !settings.support_chat })}
            />
            <RadioToggle
              disabled={isLoadingUser}
              label="Вебинары"
              checked={settings.webinars}
              className="w-full"
              onChange={() => setSettings({ ...settings, webinars: !settings.webinars })}
            />
            <div
              className="mb-0 mt-2 flex cursor-pointer items-center"
              onClick={() => setIsIntelleka(false)}
            >
              <RadioBordered checked={!isIntelleka} isIntelleka={isIntellekaStyle} />
              <div>ПромРесурс</div>
            </div>
            <div
              className="mb-1 flex cursor-pointer items-center"
              onClick={() => setIsIntelleka(true)}
            >
              <RadioBordered checked={isIntelleka} isIntelleka={isIntellekaStyle} />
              <div>Интеллека</div>
            </div>
          </div>
        </div>

        <div>
          <ThemeButton
            className="mr-2"
            isSecondary
            type="button"
            isIntelleka={isIntellekaStyle}
            onClick={() => window.history.back()}
          >
            Отменить
          </ThemeButton>
          {!isAdmin ? (
            <Tooltip
              title={<div className="text-sm">Доступно только для администратора</div>}
              placement="top"
              arrow
            >
              <ThemeButton
                className="opacity-50 cursor-default"
                type="button"
                isIntelleka={isIntellekaStyle}
              >
                Сохранить
              </ThemeButton>
            </Tooltip>
          ) : (
            <ThemeButton
              isIntelleka={isIntellekaStyle}
              type="submit"
              disabled={updating || !isAdmin || coursesLoading}
            >
              {updating || coursesLoading || updatingPassword ? 'Сохранение...' : 'Сохранить'}
            </ThemeButton>
          )}
        </div>
      </form>
    </AdminUserTemplate>
  );
};

export default AdminUserProfile;
