import { useMatch } from '@tanstack/react-location';
import { useEffect, useState } from 'react';
import { convertLocalTimeToFormat, in30Days } from '../../../utils/time';
import AdminUserCourseTemplate from '../templates/AdminUserCourseTemplate';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { getStuddingPosition } from '../../../utils/statistic';
import DatePicker from '../../../shared/DatePicker';
import { checkIsIntelleka } from '../../../utils/link';
import Skeleton from '@mui/material/Skeleton';
import { ThemeButton } from '../../../shared/Button';
import {
  useGetUserCourseSettings,
  useAddUserCourseSetting,
} from '../../../cache/common/user_course_settings';
import { useUpdateUserCourseSetting } from '../../../cache/common/user_course_settings/update';
import { IMutateNotification } from '../../../typings/common';
import { useToasts } from 'react-toast-notifications';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 30,
  borderRadius: 2,
  backgroundColor: '#e9ecef',
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: '#ffc107',
  },
}));

const AdminUserCourseProgress: React.FC = () => {
  const { params } = useMatch();
  const { id, courseId } = params;
  const { addToast } = useToasts();

  const { data, refetch, isLoading } = useGetUserCourseSettings({
    user: Number(id),
    course: Number(courseId),
  });

  const onMutate = ({ appearance, type }: IMutateNotification) => {
    const success = `Сроки курса успешно ${type === 'create' ? 'установлены' : 'обновлены'}`;
    const error = `Не удалось ${type === 'create' ? 'установить' : 'обновить'} сроки курса`;
    const text = appearance === 'success' ? success : error;

    addToast(text, { appearance });
  };

  const { mutate: createSetting, isLoading: creating } = useAddUserCourseSetting(onMutate);
  const { mutate: updateSetting, isLoading: updating } = useUpdateUserCourseSetting(onMutate);

  const [start_course, set_start_course] = useState(
    convertLocalTimeToFormat(new Date(), 'YYYY-MM-DD'),
  );
  const [end_course, set_end_course] = useState(in30Days);
  const [formVisible, setFormVisible] = useState(false);

  useEffect(() => {
    courseId && refetch();
  }, [courseId, refetch]);

  const courseSettings = !!data?.length ? data[0] : null;
  const progress = getStuddingPosition(start_course, end_course);

  useEffect(() => {
    courseSettings ? setFormVisible(true) : setFormVisible(false);
    if (courseId) {
      if (courseSettings?.start_course)
        set_start_course(convertLocalTimeToFormat(courseSettings.start_course, 'YYYY-MM-DD'));
      if (courseSettings?.end_course)
        set_end_course(convertLocalTimeToFormat(courseSettings.end_course, 'YYYY-MM-DD'));
    }
  }, [courseId, courseSettings]);

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    !courseSettings
      ? createSetting({
          course: Number(courseId),
          user: Number(id),
          start_course: `${new Date(
            new Date(start_course).getTime() + 1000 * 60 * 60 * 10,
          ).toISOString()}`,
          end_course: `${new Date(
            new Date(end_course).getTime() + 1000 * 60 * 60 * 10,
          ).toISOString()}`,
        })
      : updateSetting({
          id: courseSettings.id,
          course: Number(courseId),
          user: Number(id),
          start_course: `${new Date(
            new Date(start_course).getTime() + 1000 * 60 * 60 * 10,
          ).toISOString()}`,
          end_course: `${new Date(
            new Date(end_course).getTime() + 1000 * 60 * 60 * 10,
          ).toISOString()}`,
        });
  };

  const handleShowForm = () => {
    setFormVisible(true);
  };

  const isIntelleka = checkIsIntelleka();

  return (
    <AdminUserCourseTemplate>
      {isLoading ? (
        <>
          <Skeleton variant="rectangular" width={400} height={118} />
          <Skeleton variant="text" width={100} height={60} />
        </>
      ) : (
        <>
          <div className={`w-full max-w-[400px] ${formVisible ? 'block' : 'hidden'}`}>
            <div className="relative">
              <BorderLinearProgress
                variant="determinate"
                value={progress > 100 ? 100 : progress || 0}
                className="grow w-full relative"
                color="warning"
              />
              {progress >= 100 && <Hint>Курс окончен</Hint>}
              {progress < 0 && <Hint>Курс не начат</Hint>}
            </div>
            <form onSubmit={submit}>
              <div className="flex my-4 justify-between">
                <label className="w-[calc(50%-15px)]">
                  <div className="mb-1">Дата начала обучения</div>
                  <DatePicker
                    onChange={(value) => {
                      value && set_start_course(convertLocalTimeToFormat(value, 'YYYY-MM-DD'));
                    }}
                    field="start_course"
                    value={start_course}
                    dateFormat="dd.MM.yyyy"
                    isIntelleka={isIntelleka}
                  />
                </label>
                <label className="w-[calc(50%-15px)]">
                  <div className="mb-1">Дата окончания обучения</div>
                  <DatePicker
                    onChange={(value) =>
                      value && set_end_course(convertLocalTimeToFormat(value, 'YYYY-MM-DD'))
                    }
                    field="end_course"
                    value={end_course}
                    dateFormat="dd.MM.yyyy"
                  />
                </label>
              </div>
              <ThemeButton type="submit" isIntelleka={isIntelleka} disabled={creating || updating}>
                {creating || updating ? 'Сохранение...' : 'Сохранить'}
              </ThemeButton>
            </form>
          </div>

          <div
            className={`border w-fit p-5 rounded border-dashed flex flex-col ${
              formVisible ? 'hidden' : 'block'
            }`}
          >
            <div className="mb-1 text-gray-400 italic">Срок обучения не установлен</div>
            <ThemeButton
              className="max-w-[210px]"
              isSecondary
              onClick={handleShowForm}
              isIntelleka={isIntelleka}
            >
              Установить
            </ThemeButton>
          </div>
        </>
      )}
    </AdminUserCourseTemplate>
  );
};

export default AdminUserCourseProgress;

const Hint: React.FC = ({ children }) => (
  <div className="absolute left-0 top-0 w-full h-full bg-[rgba(0,0,0,0.2)] flex text-white text-md justify-center items-center">
    {children}
  </div>
);
