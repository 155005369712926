import { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { queryClient } from '../../../hooks/queryClient';
import { IMutateNotification } from '../../../typings/common';
import { useNotifications } from '../../../ui/admin/store/useUsers';
import { usersKey } from '.';

/** Сброс результатов для нотификации через 5 сек. */
export function useNotificationsReset() {
  const {
    successRemovedUsers,
    successStatusUsers,
    errorRemovedUsers,
    errorStatusUsers,
    clearNotifications,
  } = useNotifications();
  useEffect(() => {
    if (
      !!successRemovedUsers.length ||
      !!successStatusUsers.length ||
      !!errorRemovedUsers.length ||
      !!errorStatusUsers.length
    ) {
      setTimeout(clearNotifications, 5000);
    }
  }, [
    successRemovedUsers,
    successStatusUsers,
    errorRemovedUsers,
    errorStatusUsers,
    clearNotifications,
  ]);
}

/** Уведомление об обновлении модели пользователя */
export function useNotificationsUpdate() {
  const { addToast } = useToasts();

  const onMutateUser = ({ appearance }: IMutateNotification) => {
    const isSuccess = appearance === 'success';
    isSuccess && queryClient.refetchQueries(usersKey);

    addToast(
      `${isSuccess ? 'Пользователь успешно обновлен' : 'Не удалось обновить пользователя'}`,
      { appearance },
    );
  };

  const onMutatePassword = ({ appearance }: IMutateNotification) => {
    addToast(
      `${appearance === 'success' ? 'Пароль успешно изменен' : 'Не удалось изменить пароль'}`,
      { appearance },
    );
  };

  const onMutateCourses = ({ appearance }: IMutateNotification) => {
    const isSuccess = appearance === 'success';
    isSuccess && queryClient.refetchQueries(usersKey);

    addToast(
      `${appearance === 'success' ? 'Курсы успешно обновлены' : 'Не удалось обновить курсы'}`,
      { appearance },
    );
  };

  return { onMutateUser, onMutatePassword, onMutateCourses };
}
