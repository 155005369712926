import axios from 'axios';
import { IMassMessage, IResultMassMassage } from '../../typings/admin';

export async function createMass(model: IMassMessage) {
  try {
    const { data } = await axios.post<IResultMassMassage>('/api/v1/chats/mass_message/', model);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
