import { useEffect } from 'react';
import { useMaterial } from '../store/useMaterial';
import ClientToggleSidebarIcon from './ClientToggleSidebarIcon';

const ClientMaterialVideo: React.FC<{ link: string; onEnded(): void }> = ({ link, onEnded }) => {
  const { tasks, setId } = useMaterial();

  useEffect(() => {
    if (tasks.length) {
      tasks.forEach((t) => setId(t.material));
    }
  }, [setId, tasks]);

  return (
    <div className="material-video-wrapper">
      <ClientToggleSidebarIcon />
      <video
        src={link}
        controls={true}
        autoPlay={true}
        className="w-full max-w-full max-h-[100vh]"
        onEnded={onEnded}
      />
    </div>
  );
};

export default ClientMaterialVideo;
