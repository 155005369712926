import Spinner from '../../../shared/Spinner';
import { useMatch, useNavigate } from '@tanstack/react-location';

import { useEffect } from 'react';
import { ROOT } from '../../client/routes';

const AuthLink: React.FC<{ onLogin: (token: string) => void }> = ({ onLogin }) => {
  const { search } = useMatch();
  const navigate = useNavigate();

  useEffect(() => {
    const { access } = search;
    if (access) {
      if (typeof access === 'string') {
        onLogin(access);
        navigate({ to: ROOT, search: {} });
      }
    }
  }, [search, onLogin, navigate]);

  return <Spinner className="w-14 fill-active_p absolute top-0 bottom-0 left-0 right-0 m-auto" />;
};

export default AuthLink;
