/**
 * @module api
 * @category общие утилиты
 * @description утилиты по работе с http-запросами
 */

import type { AxiosError } from 'axios';
import adminRoutes from '../ui/admin/routes';
import clientRoutes from '../ui/client/routes';
import curatorRoutes from '../ui/curator/routes';

/**
 * Превращает обьект ошибки в пользовательский текст
 *
 * @param e обьект ошибки axios
 * @returns строка сообщения об ошибке
 */
export function getError(e: AxiosError): string {
  if (e && e.response && e.response.data) {
    return JSON.stringify(e.response.data);
  } else {
    return 'Неизвестная ошибка';
  }
}

/**
 * функция извлекает из ответа сообщение ошибки
 *
 * @param e обьект ошибки axios
 * @returns строка сообщения об ошибке
 */
export function getErrorMessage(e: AxiosError): string {
  if (e && e.response && e.response.data && e.response.data.message) {
    return e.response.data.message;
  } else {
    return 'Неизвестная ошибка';
  }
}

/**
 * Переводит строку токена в обьект
 *
 * @param token строка токена, которая приходит с сервера
 * @returns все, что зашито в токен на беке
 */
export function parseJwt(token: string): { status: number; user_id: number } {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

/**
 * Функция парсит строку токена, проверяя в ней статус пользователя
 * и переадресовывет его на url соответствующего интерфейса
 * @param callback колбек переадресации
 * @param access строка токена
 */
export const loginRedirects = (callback: (url: string) => void, access?: string) => {
  if (!access) return callback('/');

  const decodedToken = parseJwt(access);
  const { status } = decodedToken;
  switch (status) {
    case 0:
      return callback('/admin');
    case 1:
      return callback('/admin');
    case 2:
      return callback('/client');
    case 3:
      return callback('/curator');
    default:
      return callback('/');
  }
};

/**
 * Функция парсит токен доступа и, взависимости от статуса
 * пользователя, возвращает доступные ему роуты
 * @param access токен доступа
 * @returns массив доступных роутов
 */
export const getAllowedRoutes = (access: string | null) => {
  if (access) {
    const { status } = parseJwt(access);
    switch (status) {
      case 0:
        return adminRoutes;
      case 1:
        return adminRoutes;
      case 2:
        return clientRoutes;
      case 3:
        return curatorRoutes;
      default:
        return [];
    }
  } else {
    return [];
  }
};

/**
 * Функция преобразует объект в FormData
 * @param data объект
 * @returns объект FormData
 */
export function toFormData(data: Record<any, any>): FormData {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      formData.append(key, value);
    }
  });

  return formData;
}

/**
 * Функция добавляет остановку в асинхронной функции в миллисекунда
 * @param ms - милисекунды
 * @returns промис с задержкой
 * */
export function sleep(ms = 2000): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
