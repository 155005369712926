import create from 'zustand';
import { ITask } from '../../../typings/client';

export interface ITasksByMaterial {
  [materialId: string]: ITask;
}

export interface ITasksById {
  [testId: string]: ITask;
}

type ICourseTask = {
  tasksByMaterial: ITasksByMaterial;
  setCourseTasksByMaterial(task: ITasksByMaterial): void;
  tasksById: ITasksByMaterial;
  setCourseTasksById(task: ITasksByMaterial): void;
};

export const useCourseTasks = create<ICourseTask>((set) => ({
  tasksByMaterial: {},
  setCourseTasksByMaterial: (tasksByMaterial) => set(() => ({ tasksByMaterial })),
  tasksById: {},
  setCourseTasksById: (tasksById) => set(() => ({ tasksById })),
}));
