import axios from 'axios';
import { ITag, ITagParams } from '../../typings/admin';

export async function all(params?: ITagParams) {
  try {
    const { data } = await axios.get<ITag[]>('/api/v1/courses/tag/', { params });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function create(tag: string) {
  try {
    const { data } = await axios.post<ITag>('/api/v1/courses/tag/', { tag });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
