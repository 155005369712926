import TestProgress from '../../../shared/TestProgress';
import { useTest } from '../store/useTest';

const ClientTestProgress: React.FC = () => {
  const { answeredQuestions, getCurrentAttemptQuestions, question: currentQuestion } = useTest();
  const questions = getCurrentAttemptQuestions().map((q) => q.id);

  return (
    <TestProgress
      questions={questions}
      answeredQuestions={answeredQuestions}
      current={currentQuestion?.id}
    />
  );
};

export default ClientTestProgress;
