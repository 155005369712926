import { useQuery } from 'react-query';
import { getUserCourseSettings } from '../../../api/common';
import { ISettingParams } from '../../../typings/common';
import { getKey } from '.';

export const useGetUserCourseSettings = ({ user, course }: ISettingParams) => {
  return useQuery(getKey({ user, course }), () => getUserCourseSettings({ user, course }), {
    enabled: !!course && !!user,
  });
};
