import { useMatch } from '@tanstack/react-location';
import { useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { useMutation, useQuery } from 'react-query';
import { IAnswer, IQuestion, TUpdateAnswer } from '../../../typings/admin';
import { queryClient } from '../../../hooks/queryClient';
import { checkIsIntelleka } from '../../../utils/link';
import AdminQuestionForm, { IVariantTexts } from './AdminQuestionForm';
import { API } from '../../../api';
import { useUpdateQuestion } from '../../../cache/admin/question';

const AdminEditQuestion: React.FC<{ questionsKey: string[]; question: IQuestion }> = (props) => {
  const [open, toggle] = useState(false);
  const match = useMatch();

  const { mutate: update, isLoading } = useUpdateQuestion(
    match.params.testId,
    props.question.id,
    toggle,
  );

  const { data: answers } = useQuery(
    ['answers', props.question.id],
    () => API.admin.answer.all({ question: props.question.id, ordering: 'id' }),
    { enabled: open },
  );

  const { mutate: editAnswer } = useMutation<IAnswer, unknown, TUpdateAnswer>(
    (data) => API.admin.answer.update(data),
    { onSuccess: () => queryClient.refetchQueries(['answers', props.question.id]) },
  );

  const updateAnswers = (data: IVariantTexts, corrent: number[]) => {
    Object.keys(data).forEach((id) =>
      editAnswer({
        id: Number(id),
        text: data[id],
        question: props.question.id,
        is_true: corrent.includes(Number(id)),
      }),
    );
  };

  const isIntelleka = checkIsIntelleka();

  return (
    <>
      <FaEdit
        onClick={() => toggle(true)}
        className={`cursor-pointer ${
          isIntelleka ? 'hover:text-active_i' : 'hover:text-active_p'
        } transition-all`}
      />
      <AdminQuestionForm
        open={open}
        onClose={() => toggle(false)}
        submit={update}
        loading={isLoading}
        edit={props.question}
        answers={answers}
        saveAnswers={updateAnswers}
      />
    </>
  );
};

export default AdminEditQuestion;
