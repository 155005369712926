import logout_img from '../img/logout.svg';
import Tooltip from '@mui/material/Tooltip';

/**
 * Компонент рендера имени пользователя и кнопки выхода
 */
const LogOut: React.FC<{
  /** Имя пользователя */
  userName?: string;
  /** Колбек при клике */
  onClick?: () => void;
}> = ({ userName = '', onClick = () => console.log('log out') }) => {
  return (
    <div className="flex items-center">
      <div className="text-right mr-1 text-md sm:text-lg text-[#535658]">{userName}</div>
      {!userName && <div className="w-32 h-6 rounded animate-pulse bg-slate-100 mr-2" />}
      <Tooltip title={<div className="text-sm">Выход из системы</div>} arrow>
        <img
          src={logout_img}
          alt="auth"
          width={21}
          height={22}
          className="cursor-pointer"
          onClick={onClick}
        />
      </Tooltip>
    </div>
  );
};

export default LogOut;
