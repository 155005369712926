import axios from 'axios';
import Cookie from 'js-cookie';

axios.interceptors.request.use(async (requestConfig) => {
  if (requestConfig.url) {
    if (!/^\/api\/v1\/token/.test(requestConfig.url)) {
      const access = Cookie.get('access')?.replaceAll('"', '');
      if (access) {
        requestConfig.headers = {
          ...requestConfig.headers,
          Authorization: `Bearer ${access}`,
        };
      }
    }
  }

  return requestConfig;
});
