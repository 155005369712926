/** Компонент рендера текста сообщения */
const MessageText: React.FC<{
  /** текст сообщения */
  text: string;
  /** принадлежность сообщения текущему пользователю */
  isAuthorMessage?: boolean;
  /** интерфейс интелеки */
  isIntelleka?: boolean;
}> = ({ isAuthorMessage = false, isIntelleka = false, ...props }) => {
  return (
    <div
      className={`${
        isAuthorMessage
          ? `${isIntelleka ? 'bg-active_i' : 'bg-active_p'} text-white`
          : 'bg-gray-message text-black-message'
      }  w-fit p-2 rounded overflow-hidden max-w-md`}
      dangerouslySetInnerHTML={{ __html: props.text.replaceAll('\n', '<br />') }}
    />
  );
};

export default MessageText;
