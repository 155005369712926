import useWebsocket from 'react-use-websocket';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  getChatListByUserId,
  getCurrentUser,
  getMessagesByChatId,
  setUnreadMessagesAsRead,
} from '../../../api/common';
import { IChat, IChatStatus, IMessage } from '../../../typings/common';
import Scrollbars from 'react-custom-scrollbars';
import { useMatch } from '@tanstack/react-location';
import { queryClient } from '../../../hooks/queryClient';
import Chat, { ChatPreloader } from '../../../shared/Chat';
import { checkIsIntelleka } from '../../../utils/link';
import { useNotificationsStore } from '../store/useNotifications';

export interface IChatMessage {
  id: number;
  mess: string;
  created?: string;
  userId: number;
  username?: string;
}

export const readyStateString = ['CONNECTING', 'OPEN', 'CLOSING', 'CLOSED'];

const ChatContainer: React.FC<{
  userId: number;
  wsUrl: string;
  messages?: IMessage[];
  chatStatus?: IChatStatus;
  adminMessagesLoading?: boolean;
}> = (props) => {
  const [messageHistory, setMessageHistory] = useState<IChatMessage[]>([]);
  const { data: currentUser } = useQuery('user', getCurrentUser);
  const { notifications, clearNotifications, filterNotifications } = useNotificationsStore();

  useEffect(() => {
    clearNotifications();
  }, [clearNotifications]);

  const { data: chats } = useQuery(
    ['chats'],
    () => getChatListByUserId(props.userId, props.chatStatus || 0),
    {
      enabled: !props.messages,
    },
  );

  const { mutate: readChat } = useMutation<unknown, unknown, string>(
    (data) => setUnreadMessagesAsRead(data),
    { onSuccess: () => queryClient.refetchQueries('unread') },
  );

  const chat = !props.messages ? chats?.find((el: IChat) => el.users.includes(props.userId)) : null;

  const match = useMatch();
  const chatId = chat?.id || match.params?.id;

  useEffect(() => {
    const currentChatId = Number(chatId);

    if (!!notifications.length) {
      filterNotifications(currentChatId);
    }
  }, [notifications.length, chatId, filterNotifications]);

  useEffect(() => {
    chatId && readChat(String(chatId));
  }, [chatId, readChat]);

  const [sending, setSending] = useState(false);

  const {
    data: messages,
    isLoading,
    isFetched,
  } = useQuery(['messages', chat?.id], () => getMessagesByChatId(chat?.id || 0), {
    enabled: !!chat?.id && !props.messages,
  });

  useEffect(() => {
    props.messages
      ? setMessageHistory(
          props.messages?.map((m) => ({
            id: m.id,
            mess: m.mess,
            created: m.created,
            userId: m.user?.id || 0,
            username: m.user?.username,
          })),
        )
      : messages &&
        setMessageHistory(
          messages?.map((m) => ({
            id: m.id,
            mess: m.mess,
            created: m.created,
            userId: m.user?.id || 0,
            username: m.user?.username,
          })),
        );
  }, [messages, props.messages]);

  const { readyState, sendJsonMessage, lastJsonMessage } = useWebsocket(props.wsUrl, {
    retryOnError: true,
    reconnectInterval: 500,
  });

  const scrollToBottom = useCallback(() => {
    setTimeout(() => scrollBarRef.current?.scrollToBottom(), 0);
  }, []);

  useEffect(() => {
    if (lastJsonMessage) {
      setMessageHistory((prev) =>
        prev.concat({
          mess: lastJsonMessage.message,
          id: lastJsonMessage.id,
          userId: lastJsonMessage.user_id,
          username: lastJsonMessage.username,
        }),
      );
      scrollToBottom();
      setSending(false);
      readChat(chatId);
    }
  }, [lastJsonMessage, scrollToBottom, readChat, chatId]);

  const WS_CONNECTED = readyStateString[readyState] === 'OPEN';
  const WS_CONNECTING = readyStateString[readyState] === 'CONNECTING';
  const WS_CLOSED = readyStateString[readyState] === 'CLOSED';
  const scrollBarRef = useRef<Scrollbars>(null);

  useEffect(() => {
    WS_CONNECTED && scrollToBottom();
  }, [WS_CONNECTED, scrollToBottom]);

  useEffect(() => {
    if (isFetched) {
      scrollToBottom();
    }
  }, [isFetched, scrollToBottom, scrollBarRef]);

  if (!WS_CONNECTING && !messageHistory.length && (isLoading || props.adminMessagesLoading))
    return <ChatPreloader text="Загрузка сообщений..." />;

  const isIntelleka = checkIsIntelleka();

  return (
    <Chat
      messageHistory={messageHistory}
      sendJsonMessage={sendJsonMessage}
      sending={sending}
      setSending={setSending}
      WS_CLOSED={WS_CLOSED}
      WS_CONNECTED={WS_CONNECTED}
      WS_CONNECTING={WS_CONNECTING}
      currentUser={currentUser}
      scrollBarRef={scrollBarRef}
      isIntelleka={isIntelleka}
    />
  );
};

export default ChatContainer;
