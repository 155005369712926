import { Link, MatchRoute } from '@tanstack/react-location';
import { theme_color, theme_color_i } from '../constants';

/** Компонент ссылки сайдбара */
export const SidebarLink: React.FC<{
  icon: React.ReactElement;
  link?: string;
  border?: boolean;
  divider?: boolean;
  className?: string;
  height?: string;
  noHightlightOnActive?: boolean;
  isIntelleka?: boolean;
}> = ({
  children,
  link,
  icon,
  /** индикатор активной текущей страницы в виде бокового бордера */
  border = true,
  divider = true,
  className = '',
  height = '83px',
  noHightlightOnActive = false,
  isIntelleka = false,
}) => {
  return (
    <Link
      className={`${divider ? 'border-b' : 'border-b-0'} ${
        isIntelleka ? 'hover:text-active_i' : 'hover:text-active_p'
      } relative flex items-center pl-6 border-b border-b-gray-ultra_light transition-all text-lg text-gray-dark ${className}`}
      to={link}
      style={{ height }}
      getActiveProps={() => ({
        style: {
          color: noHightlightOnActive ? 'inherit' : isIntelleka ? theme_color_i : theme_color,
        },
      })}
    >
      {border && (
        <MatchRoute to={link}>
          <div
            className={`absolute left-0 top-p w-[5px] h-full ${
              isIntelleka ? 'bg-active_i' : 'bg-active_p'
            }`}
          />
        </MatchRoute>
      )}
      <div className="w-[22px] opacity-80 mr-3">{icon}</div>
      {children}
    </Link>
  );
};
