import { Link, useMatch } from '@tanstack/react-location';
import { keyBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getAllChats, getCurrentUser, getMessagesByChatId } from '../../../api/common';
import Chat from '../../common/containers/ChatContainer';
import Tooltip from '../../../shared/Tooltip';
import { createUrlChat, createWebsocketUrl } from '../../../utils/link';
import { hasLocationMatches } from '../../../utils/link';
import { generateUserLink } from '../routes';
import AdminChatsTemplate from '../templates/AdminChatsTemplate';
import { key } from './AdminCompanies';
import { API } from '../../../api';
import { Alert } from '@mui/material';

const AdminChat: React.FC = () => {
  const match = useMatch();
  const { params } = match;
  const isTeacherChat = hasLocationMatches('teacher_chat');
  const { data: user } = useQuery('user', getCurrentUser);
  const urlChat = user && createUrlChat(isTeacherChat ? 't_chat' : 's_chat', user.id, params.id);
  const wsUrl = urlChat && createWebsocketUrl(urlChat);

  const [companionName, setCompanionName] = useState<string>();
  const [companionCompany, setCompanionCompany] = useState<number>();
  const [companionId, setCompanionId] = useState<number>();

  const {
    data: messages,
    isLoading,
    isError,
  } = useQuery(['messages', params.id], () => getMessagesByChatId(params.id), {
    enabled: !!params.id,
    retry: false,
  });

  const { data: chats } = useQuery(
    ['chats', params.id],
    () => getAllChats({ users: 1, chat_status: 0 }),
    {
      enabled: !!params.id,
    },
  );

  const { data: companiesData } = useQuery(key, () => API.admin.company.all());

  const companies = keyBy(companiesData, 'id');

  const currentChat = chats?.find((el) => el.id === Number(params.id));

  useEffect(() => {
    if (!currentChat) return;
    const clientsInChat = currentChat.list_users?.filter((user) => user.user_status === 2);
    const firstCompanion = clientsInChat?.length ? clientsInChat[0] : void 0;

    const companionName = firstCompanion?.first_name;
    const companionId = firstCompanion?.id;
    const companionCompany = firstCompanion?.company;
    setCompanionName(companionName);
    setCompanionId(companionId);
    setCompanionCompany(companionCompany);
  }, [currentChat, user]);

  return (
    <AdminChatsTemplate>
      {isError ? (
        <Alert color="error" className="mx-4">
          Чат не найден
        </Alert>
      ) : (
        <>
          <Link to={generateUserLink(companionId, '/')} className="justify-center flex">
            <Tooltip text={<div className="text-sm">Перейти в профиль</div>} placement="top">
              <div className="text-center">
                {companionCompany
                  ? `${companionName}, ${companies[companionCompany]?.title}`
                  : companionName}
              </div>
            </Tooltip>
          </Link>
          {user && wsUrl && (
            <Chat
              userId={user.id}
              wsUrl={wsUrl}
              messages={messages}
              adminMessagesLoading={isLoading}
            />
          )}
        </>
      )}
    </AdminChatsTemplate>
  );
};

export default AdminChat;
