import { useMutation } from 'react-query';
import { API } from '../../../api';
import { queryClient } from '../../../hooks/queryClient';
import { IQuestion } from '../../../typings/admin';
import { useQuestions } from '../../../ui/admin/store/useQuestions';
import { getQuestionsKey } from './';

export const useRemoveQuestion = (testId: string) => {
  const { setConfirm } = useQuestions();
  const questionsKey = getQuestionsKey(testId);

  return useMutation<unknown, unknown, number>((data) => API.admin.question.remove(data), {
    onSuccess: (_, id) => {
      setConfirm();
      queryClient.setQueryData(questionsKey, () => {
        const questions = queryClient.getQueryData<IQuestion[]>(questionsKey);
        if (questions) {
          return questions.filter((q) => q.id !== id);
        }
      });
    },
  });
};
