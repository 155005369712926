import { BASE_CACHE_OPTIONS } from '../common';

export const CURATOR_CACHE = {
  ACTIVITY: 'activity',
  SETTINGS: 'settings',
  USERS: 'users',
};

export const CURATOR_CACHE_OPTIONS = {
  ...BASE_CACHE_OPTIONS,
};
