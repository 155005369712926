import { useEffect, useState } from 'react';
import DragAndDrop from '../../../shared/DragAndDrop';
import { useTest } from '../store/useTest';
import { UPLOAD_IMAGE } from '../../../img/data-image';
import { checkIsIntelleka } from '../../../utils/link';

const Tab: React.FC<{ active: boolean; onClick(): void }> = ({ children, active, onClick }) => {
  const isIntelleka = checkIsIntelleka();

  return (
    <div
      className={`hover:bg-gray-50 border transition-all cursor-pointer p-3 rounded-t bg-white mb-[-1px] ${
        active
          ? 'border-b-white'
          : `border-white border-b-gray-default ${isIntelleka ? 'text-active_i' : 'text-active_p'}`
      } `}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const ClientTestFreeAnswer: React.FC = () => {
  const { freeAnswer, setFreeAnswer, setFile } = useTest();
  const [isFile, setIsFile] = useState(false);

  const [fileName, changeFileName] = useState('');

  useEffect(() => {
    changeFileName('');
  }, []);

  const handleDrop = (files: FileList) => {
    setFile(files[0]);
    changeFileName(files[0].name);
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) {
      setFile(files[0]);
      changeFileName(files[0].name);
    }
  };

  return (
    <div className="my-5 text-[#495057]">
      <div className="flex text-[19px] border-b border-gray-default mb-5">
        <Tab active={!isFile} onClick={() => setIsFile(!isFile)}>
          Текст
        </Tab>
        <Tab active={isFile} onClick={() => setIsFile(!isFile)}>
          Прикрепить файл
        </Tab>
      </div>
      {isFile ? (
        <DragAndDrop handleDrop={handleDrop}>
          <input
            type="file"
            id="file_answer"
            className="hidden"
            onChange={onChangeInput}
            accept=".txt"
          />
          <img className="w-[142px] block mx-auto mt-10 mb-4" src={UPLOAD_IMAGE} alt="upload" />
          {fileName ? (
            <div className="text-center">{fileName}</div>
          ) : (
            <div className="text-center">
              <span className="bg-gray-line py-[5px] px-[15px] text-md">Загрузите</span> или
              перетащите сюда файл
            </div>
          )}
        </DragAndDrop>
      ) : (
        <textarea
          className="w-full min-h-[150px] resize-y border border-[#767676] rounded-[2px] focus:outline-none p-3"
          placeholder="Ответ"
          value={freeAnswer}
          onChange={(e) => setFreeAnswer(e.target.value)}
        />
      )}
    </div>
  );
};

export default ClientTestFreeAnswer;
