import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { API } from '../../../api';
import { queryClient } from '../../../hooks/queryClient';
import { IUser, IUsersRes, TCreateUser, TUpdateUser } from '../../../typings/admin';
import { curatorsKey } from './keys';

export const useCurators = () =>
  useQuery(curatorsKey, () => API.admin.user.all({ user_status: 3 }), {
    staleTime: 9999999,
  });

export const useRemoveCurator = (onSuccess: () => void, onError: () => void) =>
  useMutation<unknown, unknown, number>((user) => API.admin.user.remove(user), {
    onSuccess: (_, user) => {
      queryClient.setQueryData(curatorsKey, () => {
        const users = queryClient.getQueryData<IUsersRes>(curatorsKey);
        if (users) {
          return { ...users, results: users.results?.filter((u) => u.id !== user) };
        }
      });
      onSuccess();
    },
    onError,
  });

export const useAddCurator = (onSuccess: () => void, onError: (e: AxiosError) => void) =>
  useMutation<{ message: string }, unknown, TCreateUser>(
    (data) => API.admin.user.create({ ...data, user_status: 3 }),
    {
      onSuccess: (_, user) => {
        onSuccess();
        queryClient.refetchQueries(curatorsKey);
        queryClient.setQueryData(curatorsKey, () => {
          const oldData = queryClient.getQueryData<IUsersRes>(curatorsKey);
          if (oldData) {
            return { ...oldData, results: [...oldData.results, user] };
          }
        });
      },
      onError: (e) => onError(e as AxiosError),
    },
  );

export const useEditCurator = (onSuccess: () => void, onError: (e: AxiosError) => void) =>
  useMutation<IUser, unknown, TUpdateUser>(
    (data) => {
      if (data.password)
        API.admin.user.updatePassword({ password: data.password, user_id: data.id });
      return API.admin.user.update(data);
    },
    {
      onSuccess: (_, user) => {
        onSuccess();
        queryClient.refetchQueries(curatorsKey);
        queryClient.setQueryData(curatorsKey, () => {
          const oldData = queryClient.getQueryData<IUsersRes>(curatorsKey);
          if (oldData) {
            return { ...oldData, results: [...oldData.results, user] };
          }
        });
      },
      onError: (e) => onError(e as AxiosError),
    },
  );
