export const USER_MOCK = {
  id: 1,
  username: 'admin',
  first_name: '',
  last_name: '',
  design: 0,
  old_password: '',
  user_status: 0,
  is_active: true,
  teacher_chat: true,
  group_chat: false,
  tech_chat: true,
};

const messagesMock = [
  {
    id: 1026,
    user: {
      id: 1,
      username: 'admin',
      first_name: '',
      last_name: '',
      user_status: 0,
      time_limitation: null,
      company: null,
    },
    created: '2022-06-29T13:27:10.306960Z',
    updated: '2022-06-29T13:27:10.307008Z',
    mess: 'Добрый день, как у вас дела?',
    image: null,
    chat: 881,
  },
  {
    id: 1025,
    user: {
      id: 4628,
      username: 'rv.aliev',
      first_name: 'Алиев Роман Васильевич',
      last_name: '',
      user_status: 2,
      time_limitation: null,
      company: 41,
    },
    created: '2022-06-29T13:25:19.824293Z',
    updated: '2022-06-29T13:25:19.824331Z',
    mess: 'Добрый, хорошо, а у вас?',
    image: null,
    chat: 881,
  },
];

export const CHAT_MESSAGES_MOCK = messagesMock.map((m) => ({
  id: m.id,
  mess: m.mess,
  created: m.created,
  userId: m.user.id,
  username: m.user.username,
}));

/**
 * Функция преобразует обьект сообщения для отправки из текста
 * @param mess текст сообщения
 * @returns обьект сообщения
 */
export const getMockMessage = (mess: string) => {
  return {
    created: `${new Date()}`,
    id: Date.now(),
    mess,
    userId: 1,
    username: 'admin',
  };
};

export const TOKEN_MOCK = {
  refresh:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTY1NzQ1MjY0OCwianRpIjoiMTE1Zjk4OGNjMmJlNDk5Yzg3NmU3YWU0NDA1MjMxYzUiLCJ1c2VyX2lkIjoxLCJzdGF0dXMiOjAsInN0YXR1c19kaXNwbGF5IjoiXHUwNDEwXHUwNDM0XHUwNDNjXHUwNDM4XHUwNDNkXHUwNDM4XHUwNDQxXHUwNDQyXHUwNDQwXHUwNDMwXHUwNDQyXHUwNDNlXHUwNDQwIn0.3ZT6ZxhvM-bWFwLXPaSU7bJn6cbmbdNwWaQG7Qdl5Yc',
  access:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjU5OTU4MjQ4LCJqdGkiOiI1MWNlMDZiNjVkNTA0NWY2OTdmYjRmYjQ3ZjNhYjQ3MSIsInVzZXJfaWQiOjEsInN0YXR1cyI6MCwic3RhdHVzX2Rpc3BsYXkiOiJcdTA0MTBcdTA0MzRcdTA0M2NcdTA0MzhcdTA0M2RcdTA0MzhcdTA0NDFcdTA0NDJcdTA0NDBcdTA0MzBcdTA0NDJcdTA0M2VcdTA0NDAifQ.o9VTd0iVbTVgDalUiprDkln-Ym-XjTYZWGnlMV7UgUY',
};
