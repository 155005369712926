import { useMatch } from '@tanstack/react-location';
import { FaListOl, FaInfoCircle, FaPencilAlt } from 'react-icons/fa';
import { SidebarLink } from '../../../shared/SidebarLink';
import { ImSpinner } from 'react-icons/im';
import { generateCourseLink } from '../routes';
import SvgBook from '../../../react-svg/SvgBook';
import SvgList from '../../../react-svg/SvgList';
import { checkIsIntelleka } from '../../../utils/link';
import { hasLocationMatches } from '../../../utils/link';
import { useState } from 'react';
import AdminEditCourse from './AdminEditCourse';
import { useMutation, useQuery } from 'react-query';
import { IMaterial, IMaterialCopy } from '../../../typings/admin';
import { useSortMaterials } from '../store/useMaterials';
import RadioToggle from '../../../shared/RadioToggle';
import AdminMaterialForm from './AdminMaterialForm';
import { queryClient } from '../../../hooks/queryClient';
import { getMaterialsKey, useAddMaterial } from '../../../cache/admin/material';
import Modal from '../../../shared/Modal';
import SelectBase from '../../../shared/ReactSelect';
import Spinner from '../../../shared/Spinner';
import { useToasts } from 'react-toast-notifications';
import AdminMaterialMassAdding from './AdminMaterialMassAdding';
import BadgeButton from '../../../shared/BadgeButton';
import { useWindowSize } from 'react-use';
import { API } from '../../../api';
import { ThemeButton } from '../../../shared/Button';
import { Alert, Tooltip } from '@mui/material';
import { useGetCourseById } from '../../../cache/admin/course';

const AdminCourseMenu: React.FC<{ materialsCount: number }> = (props) => {
  const match = useMatch();
  const courseId = match.params.id;
  const isMaterialsPage = hasLocationMatches('materials');
  const isIntelleka = checkIsIntelleka();

  return (
    <div className="min-h-[600px] flex flex-col lg:border-r border-gray-ultra_light overflow-hidden">
      <SidebarLink
        link={generateCourseLink(courseId, '/')}
        icon={<FaInfoCircle />}
        isIntelleka={isIntelleka}
      >
        Информация о курсе
      </SidebarLink>
      <SidebarLink
        link={generateCourseLink(courseId, '/content')}
        icon={<FaListOl />}
        isIntelleka={isIntelleka}
      >
        Содержание курса
      </SidebarLink>

      <SidebarLink
        link={generateCourseLink(courseId, '/materials')}
        icon={<SvgBook />}
        isIntelleka={isIntelleka}
      >
        Материалы
      </SidebarLink>

      <SidebarLink
        link={generateCourseLink(courseId, '/tests')}
        icon={<SvgList />}
        isIntelleka={isIntelleka}
      >
        Тесты
      </SidebarLink>

      <EditCourseBlock />

      {isMaterialsPage && <Buttons materialsCount={props.materialsCount} />}
    </div>
  );
};

const Buttons: React.FC<{ materialsCount: number }> = (props) => {
  const {
    changed,
    onSave,
    loading,
    checked,
    tree,
    clearChecked,
    isButtonsFixed,
    setAllowCollapse,
    allowCollapse,
    massError,
    clearMassNotification,
    massSuccess,
  } = useSortMaterials();
  const [withLink, setWithLink] = useState(false);
  const [single, setSingle] = useState(false);
  const { copyMaterialOpen: open, setCopyMaterialOpen: toggle } = useSortMaterials();
  const { data: dataCourses } = useQuery('courses', () => API.admin.course.all());
  const initialCourse = { value: 0, label: '' };
  const [course, setCourse] = useState<{ value: number; label: string }>(initialCourse);

  const isIncludesParent = tree.map((el) => el.id).some((r) => checked.indexOf(r) >= 0);
  const courses = dataCourses?.map((course) => ({ value: course.id, label: course.title }));
  const isIntelleka = checkIsIntelleka();

  const onSuccess = () => {
    queryClient.refetchQueries(getMaterialsKey({ course: String(course.value) }));
    setCourse(initialCourse);
    toggle(false);
    clearChecked();
    setSingle(false);
    setWithLink(false);
  };

  const { addToast } = useToasts();

  const onError = () => {
    toggle(false);
    addToast('Не удалось скопировать выбранные материалы', {
      appearance: 'error',
      autoDismiss: true,
    });
  };

  const { mutate: singleCopy, isLoading: singleCopying } = useMutation<
    IMaterial,
    unknown,
    IMaterialCopy
  >((data) => API.admin.material.copySingle(data), { onSuccess, onError });

  const { mutate: fullCopy, isLoading: fullCopying } = useMutation<
    IMaterial,
    unknown,
    IMaterialCopy
  >((data) => API.admin.material.copyFull(data), { onSuccess, onError });

  const { mutate: linkCopy, isLoading: linkCopying } = useMutation<
    IMaterial,
    unknown,
    IMaterialCopy
  >((data) => API.admin.material.copyFull(data), { onSuccess, onError });

  const confirm = () => {
    if (single) {
      checked.forEach((m) => singleCopy({ material_id: m, course_id: course.value }));
    } else {
      !withLink
        ? tree
            .filter((el) => checked.includes(el.id))
            ?.forEach((m) => fullCopy({ course_id: course.value, material_id: m.id }))
        : tree
            .filter((el) => checked.includes(el.id))
            ?.forEach((m) => linkCopy({ course_id: course.value, material_id: m.id }));
    }
  };

  const copying = singleCopying || fullCopying || linkCopying;

  const { width: windowWidth } = useWindowSize(window.innerWidth);
  const isMobile = windowWidth && windowWidth < 992;

  return (
    <div
      className={`z-10 bg-white pl-5 mt-4 ${
        isButtonsFixed && !isMobile ? 'fixed top-6' : 'relative'
      }`}
    >
      {!!massError && (
        <Alert color="error" className="notification" onClose={() => clearMassNotification()}>
          {massError}
        </Alert>
      )}

      {!!massSuccess && !loading && (
        <Alert color="success" className="notification" onClose={() => clearMassNotification()}>
          {massSuccess}
        </Alert>
      )}

      {loading && (
        <Alert color="info" className="notification" icon={false}>
          <div className="flex items-center">
            <ImSpinner className="text-[20px] animate-spin" />
            <div className="ml-2">Обновление материалов...</div>
          </div>
        </Alert>
      )}

      <AddMaterial materialsCount={props.materialsCount} />
      <AdminMaterialMassAdding materialsCount={props.materialsCount} />
      <BadgeButton
        onClick={onSave}
        disabled={!changed.length || loading}
        style={{ opacity: !changed.length || loading ? 0.6 : 1 }}
        className="badge bg-gray-badge w-fit mb-1 focus:outline-none"
      >
        {loading ? 'Сохранение...' : 'Сохранить'}
      </BadgeButton>
      <BadgeButton
        className="badge bg-gray-badge w-fit mb-1 focus:outline-none"
        style={{ opacity: !isIncludesParent && !single ? 0.6 : 1 }}
        disabled={!isIncludesParent && !single}
        onClick={() => toggle(true)}
      >
        Копировать
      </BadgeButton>

      <RadioToggle
        checked={withLink}
        onChange={() => {
          setWithLink(!withLink);
          single && setSingle(false);
        }}
        label="Со ссылкой"
        isIntelleka={isIntelleka}
      />
      <RadioToggle
        checked={single}
        onChange={() => {
          setSingle(!single);
          withLink && setWithLink(false);
        }}
        label="По одному"
      />
      <Tooltip title={<div className="text-sm">Только в админке</div>} arrow placement="bottom">
        <div>
          <RadioToggle
            checked={!!allowCollapse}
            onChange={setAllowCollapse}
            label="Сворачивание блоков"
          />
        </div>
      </Tooltip>
      <Modal
        open={open}
        onClose={() => toggle(false)}
        className="rounded max-w-screen-modal_md md:mt-7 p-4"
      >
        <form onSubmit={confirm}>
          <label>
            <div className="mb-1">Выберите курс</div>
            <SelectBase
              id="choose-course"
              onChange={(value: any) => {
                if (value && 'label' in value) {
                  setCourse(value);
                }
              }}
              value={course}
              placeholder="Выберите курс"
              options={courses || []}
              isIntelleka={isIntelleka}
            />
          </label>
          <div className="flex items-center justify-start mt-3">
            <ThemeButton
              className="mr-2"
              isSecondary
              onClick={() => toggle(false)}
              type="button"
              isIntelleka={isIntelleka}
            >
              Отмена
            </ThemeButton>
            <ThemeButton
              className="flex items-center"
              onClick={confirm}
              disabled={copying}
              type="submit"
              isIntelleka={isIntelleka}
            >
              <span>{copying ? 'Копирование...' : 'Продолжить'}</span>
              {copying && (
                <Spinner
                  className={`ml-2 w-[14px] ${isIntelleka ? 'fill-active_i' : 'fill-active_p'}`}
                />
              )}
            </ThemeButton>
          </div>
        </form>
      </Modal>
    </div>
  );
};

const AddMaterial: React.FC<{ materialsCount: number }> = (props) => {
  const match = useMatch();
  const courseId = match.params.id;
  const { addMaterialOpen: open, setAddMaterialOpen: toggle } = useSortMaterials();
  const { mutate: addMaterial, isLoading } = useAddMaterial(courseId, props.materialsCount);

  return (
    <>
      <BadgeButton onClick={() => toggle(true)} className="badge bg-gray-badge w-fit mb-1">
        Добавить материал
      </BadgeButton>
      <AdminMaterialForm
        onClose={() => toggle(false)}
        open={open}
        submit={addMaterial}
        loading={isLoading}
      />
    </>
  );
};

const EditCourseBlock: React.FC = () => {
  const match = useMatch();
  const courseId = match.params.id;
  const isIntelleka = checkIsIntelleka();
  const [open, toggle] = useState(false);
  const { data } = useGetCourseById(courseId);
  return (
    <>
      <div
        className={`${isIntelleka ? 'hover:text-active_i' : 'hover:text-active_p'} text-gray-dark`}
      >
        <SidebarLink
          icon={<FaPencilAlt />}
          border={false}
          divider={false}
          noHightlightOnActive
          isIntelleka={isIntelleka}
        >
          <div onClick={() => toggle(true)}>Редактировать</div>
        </SidebarLink>
      </div>
      <AdminEditCourse edit={open && data ? data : undefined} onClose={() => toggle(false)} />
    </>
  );
};

export default AdminCourseMenu;
