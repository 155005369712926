import axios from 'axios';
import { ITestPassing, ITestPassingsParams } from '../../typings/client';
import { toFormData } from '../../utils/api';

export const CLIENT_TEST_PASSINGS_URL = '/api/v1/client/courses/test_passing/';

export async function getAllTestPassings(params?: ITestPassingsParams) {
  try {
    const { data } = await axios.get<ITestPassing[]>(CLIENT_TEST_PASSINGS_URL, {
      params,
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createTestPassing(model: Partial<ITestPassing>) {
  try {
    const { data } = await axios.post<ITestPassing>(
      '/api/v1/client/courses/test_passing/',
      toFormData(model),
    );

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function endTestPassing(id: number) {
  try {
    const { data } = await axios.get<ITestPassing>(
      `/api/v1/client/courses/end_test_passing/${id}/`,
    );

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
