import axios from 'axios';
import { ITask, ITasksParams } from '../../typings/client';

export const CLIENT_TASKS_URL = `/api/v1/client/courses/task/`;

export async function getAllTasks(params?: ITasksParams) {
  try {
    const { data } = await axios.get<ITask[]>(CLIENT_TASKS_URL, {
      params,
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
