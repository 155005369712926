import { useMutation } from 'react-query';
import { getKey } from '.';
import { createUserCourseSettings } from '../../../api/common';
import { queryClient } from '../../../hooks/queryClient';
import { IMutateNotification, ISettingData } from '../../../typings/common';

export const useAddUserCourseSetting = (onMutate: (data: IMutateNotification) => void) => {
  return useMutation<ISettingData, unknown, ISettingData>(
    (model) => createUserCourseSettings(model),
    {
      onSuccess: ({ user, course }) => {
        queryClient.refetchQueries(getKey({ user, course }));
        onMutate({ type: 'create', appearance: 'success' });
      },
      onError: () => onMutate({ type: 'create', appearance: 'error' }),
    },
  );
};
