import { ICourseClient } from '../../../typings/client';
import RadioButton from '../../../shared/RadioButton';
import { checkIsIntelleka } from '../../../utils/link';
import { useMaterial } from '../store/useMaterial';

const ClientCoursesMenu: React.FC<{
  setStorageCourseId(courseId?: number): void;
  courses?: ICourseClient[];
  course?: number;
}> = ({ setStorageCourseId, courses, course }) => {
  const { setMaterials } = useMaterial();
  if (!!courses && courses.length < 2) return null;
  const isIntelleka = checkIsIntelleka();

  return (
    <div className="flex flex-col pl-6 my-4">
      {courses?.map((c) => {
        const checked = course === c.id;
        return (
          <label key={c.id} className="my-2">
            <input
              type="radio"
              name="courses"
              checked={checked}
              onChange={() => {
                setStorageCourseId(c.id);
                setMaterials([]);
              }}
              className="hidden"
            />
            <div
              className={`flex cursor-pointer ${
                isIntelleka ? 'hover:text-active_i' : 'hover:text-active_p'
              }`}
            >
              <RadioButton checked={checked} isIntelleka={isIntelleka} />
              <div className="pl-2 text-[19px] transition-all">{c.title}</div>
            </div>
          </label>
        );
      })}
    </div>
  );
};

export default ClientCoursesMenu;
