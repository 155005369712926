import axios from 'axios';
import { ICoursesClient, IParams } from '../../typings/client';

export const CLIENT_COURSE_URL = `/api/v1/client/courses/course/`;

export async function getAllCourses(params?: IParams) {
  try {
    const { data } = await axios.get<ICoursesClient>(CLIENT_COURSE_URL, { params });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
