import { useCheck, useOrdering } from '../store/useUsers';
import BadgeButton from '../../../shared/BadgeButton';
import UserCourses from '../../../shared/UserCourses';
import AdminUserSettings from './AdminUserSetings';
import { Link } from '@tanstack/react-location';
import Table from '../../../shared/Table';
import { convertTimestampToDate } from '../../../utils/time';
import { generateUserLink } from '../routes';
import { sortUsers } from '../../../utils/array';
import { AdminExamCheck, AdminExamDate } from '../pages/AdminExam';
import { IUser, IUsersRes } from '../../../typings/admin';

const AdminUsersTable: React.FC<{
  data?: IUsersRes;
  isOnCheckPage: boolean;
  onCheckUsers: IUser[] | null;
  isLoading: boolean;
  onClickCompany(company: number): void;
}> = ({ data, isOnCheckPage, onCheckUsers, isLoading, onClickCompany }) => {
  const { isReverse, orderBy, setOrdering, borderIndexes } = useOrdering();
  const { checked, setChecked } = useCheck();

  const titles = [
    <input
      type="checkbox"
      disabled={!data?.results.length}
      checked={!!data?.results.length ? checked.length === data?.results?.length : false}
      onChange={() => {
        checked.length ? setChecked([]) : data && setChecked(data.results.map((u) => u.id));
      }}
    />,
    <div className="title-sort" onClick={() => setOrdering('USER_NAME')}>
      ФИО
    </div>,
    <div className="title-sort" onClick={() => setOrdering('LOGIN')}>
      Логин
    </div>,
    <div className="title-sort" onClick={() => setOrdering('CREATED')}>
      Дата создания
    </div>,
    'Курс',
    <div className="title-sort" onClick={() => setOrdering('COMPANY')}>
      Компания
    </div>,
    <div className="title-sort" onClick={() => setOrdering('STATUS')}>
      Статус
    </div>,
    'Начало обучения',
    'Окончание обучения',
    <div>Экзамен</div>,
    'Дата сдачи экзамена',
  ];

  const sorted = data
    ? isOnCheckPage
      ? onCheckUsers
      : sortUsers(data.results, orderBy, isReverse)
    : [];

  const users = sorted?.map((u, i) => {
    const userCourses = u.courses?.sort((a, b) => a.id - b.id);

    return [
      <input
        type="checkbox"
        checked={checked.includes(u.id)}
        onChange={() => {
          checked.includes(u.id)
            ? setChecked(checked.filter((id) => id !== u.id))
            : setChecked([...checked, u.id]);
        }}
      />,
      <Link to={generateUserLink(u.id, '/')}>{u.first_name}</Link>,
      <Link to={generateUserLink(u.id, '/')}>{u.username}</Link>,
      convertTimestampToDate(u.date_joined, 'DD.MM.YYYY'),
      <UserCourses u={u} i={i} />,
      <div className="flex justify-center">
        {u.company_title ? (
          <BadgeButton
            onClick={() => onClickCompany(u.company)}
            className="badge bg-gray-badge text-sm cursor-pointer"
          >
            {u.company_title}
          </BadgeButton>
        ) : (
          '-'
        )}
      </div>,
      <>
        {u.is_active ? 'A' : 'H'}/{u.design === 0 ? 'П' : 'И'}
      </>,
      <AdminUserSettings u={u} start />,
      <AdminUserSettings u={u} />,
      <>
        {userCourses?.map((course, _, self) => {
          return (
            <AdminExamCheck
              key={course.id}
              courseId={course.id}
              last_test_passings={course.last_test_passings}
              userId={u.id}
              isPluralCourses={self.length > 1}
            />
          );
        })}
      </>,
      <>
        {userCourses?.map((course) => {
          return <AdminExamDate key={course.id} testPassings={course.last_test_passings} />;
        })}
      </>,
    ];
  });

  return <Table titles={titles} rows={users} isLoading={isLoading} borderIndexes={borderIndexes} />;
};

export default AdminUsersTable;
