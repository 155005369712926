import { useMutation } from 'react-query';
import { API } from '../../../api';
import { queryClient } from '../../../hooks/queryClient';
import { ITag } from '../../../typings/admin';
import { key } from './';

export const useAddTag = (onTagCreated: (newTag: number) => void) => {
  return useMutation<ITag, unknown, string>((tag) => API.admin.tag.create(tag), {
    onSuccess: (data) => {
      queryClient.setQueryData(key, () => {
        const oldData = queryClient.getQueryData<ITag[]>(key);
        onTagCreated(data.id);

        if (oldData) {
          return [...oldData, data];
        }
      });
    },
  });
};
