import CuratorUsers from './pages/CuratorUsers';
import CuratorCompanies from './pages/CuratorCompanies';
import CuratorSupport from './pages/CuratorSupport';
import Page404 from '../common/pages/Page404';
import { TCustomRoute } from '../admin/routes';

export const ROOT = '/curator',
  USERS = '/',
  COMPANIES = '/companies',
  SUPPORT = '/support';

const routes: TCustomRoute[] = [
  {
    path: ROOT,
    description: 'Интерфейс куратора',
    children: [
      { path: USERS, element: <CuratorUsers />, description: 'Страница пользователей' },
      {
        path: COMPANIES,
        description: 'Страница компаний',
        children: [
          { path: '/', element: <CuratorCompanies />, description: 'Компании куратора' },
          {
            path: '/:companyId',
            element: <CuratorUsers />,
            description: 'Страница пользователей компании',
          },
        ],
      },
      { path: SUPPORT, element: <CuratorSupport />, description: 'Страница чата поддержки' },
      { path: '*', element: <Page404 />, description: 'Страница 404' },
    ],
  },
];

export default routes;
