import axios from 'axios';
import { IStatisticParams, IStatisticUser } from '../../typings/client';

export const ACTIVITY_URL = `/api/v1/client/users/activity/`;

export async function getClientStatistic(params: IStatisticParams) {
  try {
    const { data } = await axios.get<IStatisticUser>(ACTIVITY_URL, {
      params,
    });

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
