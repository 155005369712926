import ClientTaskTemplate from '../templates/ClientTaskTemplate';
import { ImSpinner } from 'react-icons/im';

const ClientTestLoading: React.FC = () => {
  return (
    <ClientTaskTemplate>
      <div className="min-h-[400px] flex justify-center items-center">
        <ImSpinner className="text-[30px] animate-spin" />
      </div>
    </ClientTaskTemplate>
  );
};

export default ClientTestLoading;
