import { useState } from 'react';
import { TCreateUser } from '../../../typings/admin';
import BadgeButton from '../../../shared/BadgeButton';
import Modal from '../../../shared/Modal';
import AdminMasterForm from './AdminMasterForm';
import { useAddModerator } from '../../../cache/admin/users';

const AdminAddModerator: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');

  const onSuccess = () => setOpen(false);

  const { mutate: addUser, isLoading: adding } = useAddModerator(onSuccess, (error) => {
    setError(error);
    setTimeout(() => setError(''), 3000);
  });

  const submit = (data: TCreateUser) => addUser(data);

  return (
    <div>
      <BadgeButton onClick={() => setOpen(true)}>Добавить преподавателя</BadgeButton>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        className="rounded max-w-screen-modal_md md:mt-7 p-4"
      >
        <AdminMasterForm
          submit={submit}
          loading={adding}
          onClose={() => setOpen(false)}
          open={open}
          error={error}
          setError={setError}
        />
      </Modal>
    </div>
  );
};

export default AdminAddModerator;
