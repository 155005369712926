import axios from 'axios';
import { IServerTime } from '../../typings/common';

export const TIME_URL = '/api/v1/time/';

/** функция возвращает серверное время */
export async function getServerTime() {
  try {
    const { data } = await axios.get<IServerTime>(TIME_URL);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
