import AdminUserCourseTemplate from '../templates/AdminUserCourseTemplate';
import { useMutation, useQuery } from 'react-query';
import { useMatch, useNavigate } from '@tanstack/react-location';
import { IUserAnswer, TUpdateModeratorUserAnswer } from '../../../typings/admin';
import Table from '../../../shared/Table';
import { keyBy } from 'lodash';
import { generateUserTestPassingLink } from '../routes';
import { useKey } from 'react-use';
import { useState } from 'react';
import { queryClient } from '../../../hooks/queryClient';
import { getCurrentUser } from '../../../api/common';
import Tooltip from '../../../shared/Tooltip';
import { checkIsIntelleka } from '../../../utils/link';
import { BsCheck } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';
import { ImSpinner } from 'react-icons/im';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { API } from '../../../api';
import { ThemeButton } from '../../../shared/Button';

const titles = [
  '№',
  'Вопрос',
  'Варианты ответов',
  'Ответ пользователя',
  'Правильные ответы',
  'Кол-во макс баллов за вопрос',
  'Кол-во баллов набранных пользователем',
];

const AdminUserCourseTestAttempt: React.FC = () => {
  const navigate = useNavigate();
  const { params } = useMatch();
  const { id, courseId, attemptId, testId } = params;
  const {
    data: answers,
    isLoading: isLoadingAnswers,
    isFetched,
  } = useQuery(
    ['answers', `passing-${attemptId}`],
    () => API.admin.answer.getModeratorUserAnswers({ passing: attemptId }),
    { enabled: !!attemptId },
  );

  const { data: task, isFetching: taskFetching } = useQuery(
    ['task', testId],
    () => API.admin.test.byId(testId),
    {
      enabled: !!testId,
    },
  );

  const { data: currentUser } = useQuery('user', getCurrentUser);

  const { data: questions } = useQuery(
    ['questions', `task-${testId}`],
    () => API.admin.question.all({ tasks: testId }),
    { enabled: !!testId },
  );

  const back = () => navigate({ to: generateUserTestPassingLink(id, courseId, testId) });

  useKey('Escape', back);

  const { mutate: checkAnswer, isLoading } = useMutation<
    IUserAnswer,
    unknown,
    TUpdateModeratorUserAnswer
  >((answer) => API.admin.answer.checkModeratorAnswer(answer), {
    onSuccess: () => {
      queryClient.refetchQueries([`answers`, `passing-${attemptId}`]);
      setRate({});
    },
  });

  const questionsById = keyBy(questions, 'id');

  const save = () => {
    Object.keys(rate).forEach((answerId) => {
      checkAnswer({
        user_points: rate[answerId],
        verifier: currentUser?.id,
        id: Number(answerId),
      });
    });
  };

  const renderUserAnswer = (answer: IUserAnswer) => {
    if (!answer) return '-';
    const answers = answer.answers.length ? answer.answers : null;
    if (answers) {
      return answers.map((answerId, index) => {
        const answerArray = answer.variants.find((variant) => variant[0] === answerId) || [];
        return answerArray.length > 2 ? (
          <div key={index}>
            <i>{answerArray[1]}</i>
          </div>
        ) : (
          '='
        );
      });
    }
    if (answer.text) return <i>{answer.text}</i>;
    if (answer.file)
      return (
        <div className="flex items-center justify-center font-bold text-blue-500 hover:text-blue-900 transition-all">
          <AiOutlineCloudDownload />
          <a href={answer.file} target="_blank" className="ml-1" rel="noreferrer">
            Скачать файл
          </a>
        </div>
      );
  };

  const [rate, setRate] = useState<Record<string, number>>({});

  const maxScores = questions?.reduce((prev, cur) => prev + cur.score, 0);
  const userSavedScored = answers?.reduce((prev, cur) => prev + cur.get_user_points, 0) || 0;
  const userScores = Object.values(rate)?.reduce((prev, cur) => prev + cur, userSavedScored);
  const percents = maxScores ? (userScores ? Math.round((userScores / maxScores) * 100) : 0) : 0;

  const rows = answers?.map((a, i) => [
    i + 1,
    questionsById[a.question]?.text,
    <>{a.variants.map((v) => <div key={v[0]}>{v[1]}</div>) || '-'}</>,
    <>{renderUserAnswer(a)}</>,
    <>
      {!!a.variants?.length
        ? a.variants
            .filter((variant) => variant[2])
            .map((variant, index) => <div key={index}>{variant[1]}</div>)
        : '-'}
    </>,
    <>{a.max_points}</>,
    <>
      {a.text || a.file ? (
        a.verifier ? (
          a.get_user_points
        ) : (
          <Tooltip
            placement="top"
            text={<div className="text-sm">Максимальная оценка - {a.max_points}</div>}
          >
            <input
              className="input"
              type="number"
              value={rate[a.id] || 0}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = Number(e.target.value);
                if (value < 0) return 0;
                if (value > a.max_points) return a.max_points;
                setRate({ ...rate, [a.id]: value });
              }}
            />
          </Tooltip>
        )
      ) : (
        a.get_user_points
      )}
    </>,
  ]);

  const isIntelleka = checkIsIntelleka();

  return (
    <AdminUserCourseTemplate>
      <div className="bg-[rgba(0,0,0,.5)] fixed z-[999999999] w-full h-[100vh] left-0 top-0">
        <div className="rounded px-3 py-1 h-[calc(100vh-80px)] overflow-auto absolute left-0 right-0 top-2 bg-white m-auto w-[calc(100%-40px)]">
          <Table titles={titles} rows={rows} isLoading={isLoadingAnswers} />
          {isFetched && !answers?.length && (
            <div className="text-center py-7 text-gray-500">Нет ни одного ответа</div>
          )}
          <div className="flex items-center justify-between rounded-b p-3 fixed bottom-3 left-0 right-0 m-auto bg-white w-[calc(100%-40px)]">
            <div className="text-sm py-1 sm:text-md">
              <ThemeButton className="mr-2" isSecondary isIntelleka={isIntelleka} onClick={back}>
                Закрыть
              </ThemeButton>
              <ThemeButton
                isIntelleka={isIntelleka}
                onClick={save}
                disabled={isLoading || !Object.keys(rate).length}
              >
                {isLoading
                  ? 'Сохранение...'
                  : `Сохранить ${taskFetching ? '' : '(' + percents + '%)'}`}
              </ThemeButton>
            </div>
            {taskFetching ? (
              <Preliminarily text="Загрузка...">
                <ImSpinner className="text-[30px] animate-spin" />
              </Preliminarily>
            ) : (
              <>
                {!!task && percents < task.passing ? (
                  <Preliminarily className="text-red-600" text="Не Зачет">
                    <IoMdClose className="text-[30px]" />
                  </Preliminarily>
                ) : (
                  <Preliminarily className="text-green-600" text="Зачет">
                    <BsCheck className="text-[30px]" />
                  </Preliminarily>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </AdminUserCourseTemplate>
  );
};

const Preliminarily: React.FC<{ text: string; className?: string }> = ({
  text,
  className,
  children,
}) => {
  return (
    <div className={`flex items-center ${className}`}>
      <span className="hidden sm:block text-md sm:text-lg mr-1">{text}</span>
      {children}
    </div>
  );
};

export default AdminUserCourseTestAttempt;
