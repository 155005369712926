import { forwardRef } from 'react';

/**
 * Компонент кнопки
 * @testcases:
 * - при клике срабатывает колбек
 */
const Button: React.FC<{
  isSecondary?: boolean;
  className?: string;
  onClick(): void;
  disabled?: boolean;
  isIntelleka?: boolean;
}> = ({ isSecondary = false, className = '', isIntelleka, ...rest }) => {
  return (
    <button
      data-testid="button"
      disabled={rest.disabled}
      onClick={rest.onClick}
      className={`${
        isSecondary
          ? `bg-white ${
              isIntelleka
                ? 'text-active_i border-active_i hover:bg-active_i_hover'
                : 'text-active_p border-active_p hover:bg-active_p_hover'
            } px-3 py-[5px] rounded border-2  hover:text-white transition-all duration-300`
          : `text-white px-3 py-[5px] rounded border-2  transition-all duration-300 ${
              isIntelleka
                ? 'bg-active_i border-active_i hover:bg-active_i_hover disabled:bg-blue-300 disabled:border-blue-300'
                : 'bg-active_p border-active_p hover:bg-active_p_hover disabled:bg-red-300 disabled:border-red-300'
            }`
      } ${className}`}
    >
      {rest.children}
    </button>
  );
};

export default Button;

export const ThemeButton = forwardRef<
  HTMLButtonElement,
  { isIntelleka: boolean; className?: string; isSecondary?: boolean } & React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
>(({ isIntelleka, className = '', isSecondary = false, children, ...props }, ref) => {
  return (
    <button
      {...props}
      ref={ref}
      className={`${
        isSecondary
          ? isIntelleka
            ? 'secondary-btn-i'
            : 'secondary-btn'
          : isIntelleka
          ? 'primary-btn-i'
          : 'primary-btn'
      } ${className}`}
    >
      {children}
    </button>
  );
});
