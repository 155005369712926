import AdminTemplate from '../templates/AdminTemplate';
import { SETTINGS_TITLES } from './AdminSettings';
import { useQuery } from 'react-query';
import Spinner from '../../../shared/Spinner';
import { API } from '../../../api';
import { checkIsIntelleka } from '../../../utils/link';

const Result: React.FC<{ title: string; loading: boolean; res?: number }> = ({
  res = '',
  ...props
}) => {
  const isIntelleka = checkIsIntelleka();

  return (
    <li className="flex items-center">
      {props.title} -
      {props.loading ? (
        <Spinner className={`${isIntelleka ? 'fill-active_i' : 'fill-active_p'} w-4 ml-1`} />
      ) : (
        <b className="ml-1">{res}</b>
      )}
    </li>
  );
};

const AdminSettingsTerms: React.FC = () => {
  const { data: courses, isLoading: coursesLoading } = useQuery('all-courses', () =>
    API.admin.course.all(),
  );
  const { data: users, isLoading: usersLoading } = useQuery('all-users', () =>
    API.admin.user.all({ user_status: 2, page_size: 1 }),
  );
  const { data: companies, isLoading: companiesLoading } = useQuery('all-companies', () =>
    API.admin.company.all(),
  );
  return (
    <AdminTemplate>
      <h1 className="text-[30px] text-center mb-6">{SETTINGS_TITLES[0]}</h1>
      <ul className="max-w-[1100px] m-auto">
        <Result title="Всего курсов" loading={coursesLoading} res={courses?.length} />
        <Result title="Всего пользователей" loading={usersLoading} res={users?.count} />
        <Result title="Всего компаний" loading={companiesLoading} res={companies?.length} />
      </ul>
    </AdminTemplate>
  );
};

export default AdminSettingsTerms;
