import axios from 'axios';
import { IQuiclkLogin } from '../../typings/common';

export async function getQuickLogin() {
  try {
    const { data } = await axios.get<IQuiclkLogin>(`/api/v1/quickauth/`);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function changeQuickLogin(params: IQuiclkLogin) {
  try {
    const { data } = await axios.patch<'success'>(`/api/v1/quickauth/change`, params);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
