import { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useMedia } from 'react-use';
import Spinner from '../../../shared/Spinner';
import { rangeNumbers } from '../../../utils/array';
import { isElementVisibleOnScreen, toggleFullScreen } from '../../../utils/dom';
import { checkIsIntelleka } from '../../../utils/link';
import { useMaterial } from '../store/useMaterial';
import ClientPdfHeader from './ClientPdfHeader';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PdfReader: React.FC<{ pdf: string; onRead(): void }> = ({ onRead, ...props }) => {
  const pdfRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState<number>(1);
  const { page, setPage, tasks, clearTasks, setId, pages, setPages } = useMaterial();
  const [pageElements, setPageElements] = useState<(HTMLElement | null)[]>([]);
  const isDesktop = useMedia('(min-width: 992px)');

  useEffect(() => {
    if (props.pdf) setPage(1);
  }, [props.pdf, setPage]);

  useEffect(() => {
    if (tasks.length) {
      tasks.forEach((t) => {
        setId(t.material);
        setTimeout(() => {
          goToPage(t.page);
          setPage(t.page);
        }, 500);
      });
      clearTasks();
    }
  }, [clearTasks, setId, setPage, tasks]);

  const [isRead, setIsRead] = useState(false);

  useEffect(() => {
    !!props.pdf && setIsRead(false);
    return () => setPages([]);
  }, [props.pdf]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPages(rangeNumbers(numPages));
  };

  useEffect(() => {
    if (pages.length) {
      const pageElements = pages.map((page) => {
        return document.getElementById(`page-${page}`);
      });
      setPageElements(pageElements);
    }
  }, [pages]);

  const onScroll = () => {
    const visibleElements = pageElements
      .map((el, index) => (el && isElementVisibleOnScreen(el) ? index + 1 : null))
      .filter((el) => el);
    const visiblePage = visibleElements[visibleElements.length - 1];
    visiblePage && setPage(visiblePage);
    if (page === pages.length && !isRead) {
      onRead();
      setIsRead(true);
    }
  };

  const handleFullScreen = () => {
    const element = document.getElementById('material-view');
    toggleFullScreen(element);
  };

  const goToPage = (page: number) => {
    if (page) {
      const pageElement = document.getElementById(`page-${page}`);
      const headerHeight = 34;
      if (pageElement) {
        const offset = pageElement.offsetTop - headerHeight;
        pdfRef.current?.scrollTo({ top: offset });
      }
    }
  };

  const isIntelleka = checkIsIntelleka();
  const headerHeight = 34;

  return (
    <div className="pt-8">
      <ClientPdfHeader
        setScale={setScale}
        scale={scale}
        pages={pages}
        page={page}
        goToPage={goToPage}
        handleFullScreen={handleFullScreen}
        height={headerHeight}
      />
      <Document
        file={props.pdf}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={
          <Spinner
            className={`w-10 ${isIntelleka ? 'fill-active_i' : 'fill-active_p'} m-auto mt-16`}
          />
        }
        noData=""
        error=""
      >
        <div
          ref={pdfRef}
          className="overflow-auto bg-[#545454]"
          onScroll={onScroll}
          style={{ height: `calc(100vh - ${headerHeight}px)` }}
        >
          {pages.length &&
            pages.map((page) => {
              return (
                <div id={`page-${page}`} key={page}>
                  <Page
                    className="pdf-page mb-3"
                    key={page}
                    loading=""
                    width={isDesktop ? window.innerWidth - 400 : window.innerWidth}
                    pageNumber={page}
                    scale={scale}
                    renderMode="svg"
                    renderTextLayer={false}
                  />
                </div>
              );
            })}
        </div>
      </Document>
    </div>
  );
};

export default PdfReader;
