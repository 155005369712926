import AsideTemplate from '../../common/templates/AsideTemplate';
import AdminChatsMenu from '../containers/AdminChatsMenu';
import AdminTemplate from './AdminTemplate';

const AdminChatsTemplate: React.FC = ({ children }) => {
  return (
    <AdminTemplate>
      <AsideTemplate btnClassName="mb-3" sidebar={<AdminChatsMenu />} className="flex">
        {children}
      </AsideTemplate>
    </AdminTemplate>
  );
};

export default AdminChatsTemplate;
