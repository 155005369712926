import { useState } from 'react';
import { theme_color, theme_color_i } from '../../../constants';
import RadioButton from '../../../shared/RadioButton';
import { checkIsIntelleka } from '../../../utils/link';

const ClientTaskVariant: React.FC<{
  id: number;
  onChange(): void;
  checked: boolean;
  label: string;
  isCheckBox: boolean;
}> = (props) => {
  const defaultColor = '#989a9b';
  const isIntelleka = checkIsIntelleka();
  const hoverColor = isIntelleka ? theme_color_i : theme_color;

  const [color, setColor] = useState(defaultColor);

  return (
    <label
      className={`cursor-pointer transition-all duration-300 flex items-top text-[17px] my-3 ${
        isIntelleka ? 'hover:text-active_i' : 'hover:text-active_p'
      }`}
      key={props.id}
      onMouseOver={() => setColor(hoverColor)}
      onMouseLeave={() => setColor(defaultColor)}
    >
      <input
        key={props.id}
        type="checkbox"
        checked={props.checked}
        onChange={props.onChange}
        className="hidden"
      />
      <RadioButton
        checked={props.checked}
        color={color}
        isCheckBox={props.isCheckBox}
        isIntelleka={isIntelleka}
      />
      <div className="ml-3">{props.label}</div>
    </label>
  );
};

export default ClientTaskVariant;
