import axios from 'axios';
import { ISettingData, ISettingParams } from '../../typings/common';

export const SETTINGS_URL = '/api/v1/client/courses/user_course_settings/';

export async function getUserCourseSettings(params?: ISettingParams) {
  try {
    const { data } = await axios.get<ISettingData[]>(SETTINGS_URL, { params });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createUserCourseSettings(model: ISettingData) {
  try {
    const { data } = await axios.post<ISettingData>(SETTINGS_URL, model);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updateUserCourseSettings(model: ISettingData) {
  try {
    const { data } = await axios.patch<ISettingData>(
      `/api/v1/client/courses/user_course_settings_detail/${model.id}/`,
      { start_course: model.start_course, end_course: model.end_course },
    );

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
