import { useUsersParams } from '../store/useUsers';
import AdminRenderUsers from '../containers/AdminRenderUsers';

const AdminUsers: React.FC = () => {
  const params = useUsersParams();

  return <AdminRenderUsers {...params} />;
};

export default AdminUsers;
