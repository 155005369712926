import { Outlet, Router } from '@tanstack/react-location';
import { ReactLocationDevtools } from '@tanstack/react-location-devtools';
import AuthPage from '../../auth/pages/AuthPage';
import { getAllowedRoutes } from '../../../utils/api';
import Page404 from '../../../ui/common/pages/Page404';
import AuthLink from '../../auth/pages/AuthLink';
import { LINK_LOGIN } from '../../../ui/auth/routes';
import { isDev } from '../../../constants';
import { location } from '../../../hooks/reactLocation';

type TProps = { access: string | null; updateCookie: (t: string) => void };

const AppRoutes: React.FC<TProps> = (props) => (
  <Router
    location={location}
    routes={[
      { path: '/', element: <AuthPage onLogin={props.updateCookie} /> },
      { path: LINK_LOGIN, element: <AuthLink onLogin={props.updateCookie} /> },
      ...getAllowedRoutes(props.access),
      { path: '*', element: <Page404 /> },
    ]}
  >
    <Outlet />
    {isDev && <ReactLocationDevtools initialIsOpen={false} />}
  </Router>
);

export default AppRoutes;
