import { useTest } from '../store/useTest';
import { checkIsIntelleka } from '../../../utils/link';
import TestResult from '../../../shared/TestResult';

const ClientTestUiResults: React.FC = () => {
  const { getCurrentAttemptQuestions, onClose, passing, task, isTrial } = useTest();

  const notPassed = passing?.success_passed !== 0;
  const isChecking = passing?.success_passed === 1;

  const questionsCount = getCurrentAttemptQuestions().length || 0;

  const isIntelleka = checkIsIntelleka();

  return (
    <TestResult
      notPassed={notPassed}
      isChecking={isChecking}
      isHidden={!!task?.is_hidden}
      onClose={onClose}
      isIntelleka={isIntelleka}
      questionsCount={questionsCount}
      isTrial={isTrial}
      responseRate={passing?.response_rate || '0%'}
      retakeSeconds={passing?.retake_seconds}
      rate={task?.passing}
    />
  );
};

export default ClientTestUiResults;
