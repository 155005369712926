import { ICourse } from '../../../typings/admin';
import Modal from '../../../shared/Modal';
import AdminCourseForm from './AdminCourseForm';
import { useUpdateCourse } from '../../../cache/admin/course';
import { useRemoveCourse } from '../../../cache/admin/course';

const AdminEditCourse: React.FC<{ edit?: ICourse; onClose(): void }> = ({ edit, onClose }) => {
  const { mutate: editCourse, isLoading: editing } = useUpdateCourse(onClose, edit);
  const { mutate: removeCourse, isLoading: deleting } = useRemoveCourse(onClose, edit?.id);

  return (
    <Modal open={!!edit} onClose={onClose} className="rounded max-w-[1000px] mt-0 sm:mt-[1.75rem]">
      <AdminCourseForm
        submit={editCourse}
        edit={edit}
        onDelete={() => edit && removeCourse(edit.id)}
        onClose={onClose}
        loading={editing}
        deleting={deleting}
      />
    </Modal>
  );
};

export default AdminEditCourse;
