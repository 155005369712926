import styled, { css } from 'styled-components';

import { PARTICLES_NUM } from '../constants';
import { getRandomInt } from '../utils/number';

function createCSS() {
  let styles = '';

  const anims = ['float', 'floatreverse', 'float2', 'floatreverse2'];

  for (let i = 0; i < PARTICLES_NUM; i += 1) {
    const blur = i * 0.02;
    styles += `
      &:nth-child(${i}) {
        top: ${getRandomInt(100) * 6}px;
        left: ${getRandomInt(250) * 6}px;
        font-size: ${getRandomInt(40)}px;
        filter: blur(${blur}px);
        animation: ${getRandomInt(100)}s ${anims[getRandomInt(4)]} infinite;
      }
     `;
  }

  return css`
    ${styles}
  `;
}

/** Компонент для рендера частиц на странице 404 */
export const Particle = styled.div`
  position: absolute;
  display: block;
  pointer-events: none;
  ${createCSS()};

  @keyframes float {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(180px);
    }
  }

  @keyframes floatReverse {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-180px);
    }
  }

  @keyframes float2 {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(28px);
    }
  }

  @keyframes floatReverse2 {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-28px);
    }
  }
`;

export default Particle;
