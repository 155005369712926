import { Alert } from '@mui/material';
import { useNotifications } from '../store/useUsers';

const AdminUsersSuccess: React.FC = () => {
  const { successRemovedUsers, successStatusUsers, clearNotifications } = useNotifications();

  return (
    <>
      {!!successRemovedUsers.length && (
        <Alert color="success" className="notification" onClose={() => clearNotifications()}>
          Пользователи <b>{successRemovedUsers.join(', ')}</b> успешно удалены
        </Alert>
      )}
      {!!successStatusUsers.length && (
        <Alert color="success" className="notification" onClose={() => clearNotifications()}>
          Пользователи <b>{successStatusUsers.join(', ')}</b> успешно обновлены
        </Alert>
      )}
    </>
  );
};

export default AdminUsersSuccess;
