/**
 * @module useChangeDocumentTitle
 * @category Хуки
 */

import { useEffect } from 'react';
import { checkIsIntelleka } from '../utils/link';

/**
 * Хук меняет заголовок документа и favicon, в зависимости от того,
 * входит ли текущий домен в группу intellekaDomains
 */
export function useChangeDocumentTitle() {
  const isIntelleka = checkIsIntelleka();

  useEffect(() => {
    if (isIntelleka) {
      let link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");

      if (!link) {
        link = document.createElement('link');
      }

      link.rel = 'icon';
      link.href = '/intelleca.svg';
      document.getElementsByTagName('head')[0].appendChild(link);
      document.title = 'Интеллека | Учебный центр';
    } else {
      document.title = 'ПромРесурс | Учебный центр';
    }
  }, [isIntelleka]);
}
