import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import RSelect from './ReactSelect';

import { useShortListNumbers } from '../hooks/useShortListNumbers';
import { getCountPages } from '../utils/pagination';

export interface IProps {
  /** активная страница */
  activePage: number;
  /** Количество элементов на странице */
  pageSize: number;
  /** количество элементов всего */
  countElements: number;
  /** Значения для выбора количества элементов на странице */
  pagesCountOptions: { value: number; label: string }[];
  /** Колбек при клике на кнопку "Далее" */
  onClickNext: () => void;
  /** Колбек при клике на кнопку "Назад" */
  onClickPrev: () => void;
  /** Интерфейс интеллеки */
  isIntelleka: boolean;
  /** Колбек при изменении текущей страницы */
  onChangeCurrentPage: (page: number) => void;
  /** Колбек при изменении количества элементов на странице */
  onChangePageSize: (page: number) => void;
  /** Положение меню селекта **/
  menuPlacement?: 'top' | 'bottom' | 'auto';
}

/** Компонент пагинации */
const Pagination: React.FC<IProps> = ({
  activePage = 1,
  countElements = 1,
  isIntelleka,
  pageSize,
  menuPlacement = 'bottom',
  ...props
}) => {
  const countPages = getCountPages(countElements, pageSize);
  const numPages = useShortListNumbers(activePage, countPages, true);

  return (
    <div className="bg-white flex items-center justify-between">
      <div className="flex-1 flex justify-between sm:hidden">
        <span
          onClick={props.onClickPrev}
          className="cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Предыдущая
        </span>
        <span
          onClick={props.onClickNext}
          className="cursor-pointer ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Следующая
        </span>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-end sm:justify-between">
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <span
              onClick={props.onClickPrev}
              className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Предыдущая</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            {numPages.map((page, index) => {
              const isLink = page !== activePage;
              const isDots = typeof page === 'string';
              return (
                <span
                  key={index}
                  aria-current="page"
                  onClick={() => isLink && !isDots && props.onChangeCurrentPage(page)}
                  className={`relative font-medium text-sm items-center px-4 py-2 border ${
                    !isLink
                      ? `z-10 cursor-default ${
                          isIntelleka
                            ? 'text-active_i border-active_i bg-active_light_i'
                            : 'text-active_p border-active_p bg-active_light_p'
                        } inline-flex`
                      : `${
                          isDots ? 'cursor-default' : 'cursor-pointer hover:bg-gray-50'
                        } bg-white border-gray-300 text-gray-500 hidden md:inline-flex`
                  }  `}
                >
                  {page}
                </span>
              );
            })}
            <span
              onClick={props.onClickNext}
              className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Следующая</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          </nav>
        </div>

        <RSelect
          options={props.pagesCountOptions}
          onChange={(data: any) => props.onChangePageSize(data?.value)}
          defaultValue={{ label: `${pageSize}`, value: pageSize }}
          className="min-w-[210px]"
          isIntelleka={isIntelleka}
          menuPlacement={menuPlacement}
        />
      </div>
    </div>
  );
};

export default Pagination;
