import { TPagination } from '../../../ui/curator/store/useUsersParams';

export const moderatorsKey = 'moderators';
export const curatorsKey = 'curators';
export const adminsKey = 'admins';
export const loadedUsers = 'loaded-users';
export const usersKey = 'users';

export const getKey = (params: TPagination) => ['users', params];
export const getSingleUserKey = (userId: number) => [usersKey, 'user', userId];
