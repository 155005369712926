import { last } from 'lodash';
import create from 'zustand';
import { API } from '../../../api';
import { queryClient } from '../../../hooks/queryClient';
import { IMaterial as IClientMaterial } from '../../../typings/client';
import { getStructuredMaterials } from '../../../utils/material';

interface ITask {
  type: 'goTo';
  material: number;
  page: number;
}

type IMaterial = {
  id?: number;
  setId(id?: number): void;

  isSidebarVisible: boolean;
  toggleSidebar(v: boolean): void;

  setMaterials(m: IClientMaterial[]): void;
  materials: IClientMaterial[];
  getLastMaterialsOfTopics?(): number[];

  setNext(): void;

  courseNotStarted: boolean;
  materialToContinue?: number;
  setMaterialToContinue(m: IClientMaterial[]): void;

  page: number;
  setPage(p: number): void;

  tasks: ITask[];
  setTask(t: ITask): void;
  clearTasks(): void;

  pages: number[];
  setPages(p: number[]): void;
};

export const useMaterial = create<IMaterial>((set, get) => ({
  id: undefined,
  setId: (id) => {
    set(() => ({ id }));
    queryClient.refetchQueries('all-passings');
  },

  isSidebarVisible: true,
  toggleSidebar: (isSidebarVisible) => set(() => ({ isSidebarVisible })),

  materials: [],
  setMaterials: (materials) => set(() => ({ materials })),

  getLastMaterialsOfTopics: () => {
    const { materials } = get();
    const structured = getStructuredMaterials(materials);
    const lastElementOfEachBlock = structured.map((el) => {
      const lastElement = last(el.children);
      const lastElementsChildren = lastElement?.children;
      const lastElementsLastElementsChildren = last(lastElementsChildren)?.children;

      return !!lastElementsChildren
        ? lastElementsLastElementsChildren
          ? last(lastElementsLastElementsChildren) || { id: 0 }
          : last(lastElementsChildren) || { id: 0 }
        : lastElement || { id: 0 };
    });
    const lastElementsIds = lastElementOfEachBlock.map((el) => el.id);

    return lastElementsIds;
  },

  setNext: () => {
    const currentMaterial = get().materials.find((m) => m.id === get().id);
    const nextMaterial = get().materials.find((m) => m.rank === (currentMaterial?.rank || 0) + 1);
    if (nextMaterial) {
      set({ id: nextMaterial.id });
      if (!nextMaterial.pdf && !nextMaterial.video && !nextMaterial.video_link) {
        get().setNext();
      }
    }
  },

  courseNotStarted: false,

  setMaterialToContinue: async (materials) => {
    try {
      const passings = await API.client.material_passing.getMaterialPassings({ status: 2 });
      if (passings.length === 0) set({ courseNotStarted: true });
      const passedMaterials = passings.map((p) => p.material);
      const materialsWithFile = materials.filter((m) => !!m.pdf || !!m.video || !!m.video_link);
      const notPassedMaterials = materialsWithFile
        .filter((m) => !passedMaterials.includes(m.id))
        .sort((a, b) => a.rank - b.rank);

      if (notPassedMaterials.length) set({ materialToContinue: notPassedMaterials[0].id });
    } catch (e) {
      throw new Error();
    }
  },

  page: 1,
  setPage: (page) => set({ page }),

  tasks: [],
  setTask: (task) => set({ tasks: [...get().tasks, task] }),
  clearTasks: () => set({ tasks: [] }),

  pages: [],
  setPages: (pages) => set({ pages }),
}));
