import { useTest } from '../store/useTest';
import ClientTaskTemplate from '../templates/ClientTaskTemplate';
import { russianEndings } from '../../../utils/string';
import { toHoursAndMinutes } from '../../../utils/time';
import RadioToggle from '../../../shared/RadioToggle';
import Tooltip from '../../../shared/Tooltip';
import { useQuery } from 'react-query';
import { checkIsIntelleka } from '../../../utils/link';
import { API } from '../../../api';
import { ThemeButton } from '../../../shared/Button';
import { countByPercent } from '../../../utils/number';

const ClientTestUiStart: React.FC = () => {
  const btnStyles = () => ({
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
  });

  const {
    isTrial,
    toggleTrial,
    task,
    trialAttemptsExceeded,
    onStart,
    questions,
    getCurrentAttemptQuestions,
    onClose,
  } = useTest();

  const { refetch: refetchPassings } = useQuery(
    ['test-passings', task?.id],
    () => API.client.test_passing.getAllTestPassings({ task: task?.id }),
    { enabled: false },
  );

  const trialAttempts = task?.trial_attempts || 0;

  const questionsCount = getCurrentAttemptQuestions().length || 0;
  const trial_questions_count = Math.round(
    countByPercent(questions.length, Number(task?.trial_percents || 0)),
  );

  const successQuesions = Math.ceil((questionsCount / 100) * (task?.passing || 0));

  const trialTooltip = `Вам предоставляется ${trialAttempts} ${russianEndings(trialAttempts, [
    'пробная попытка',
    'пробные попытки',
    'пробных попыток',
  ])} сдать тестирование. Пробное тестирование состоит из ${trial_questions_count} ${russianEndings(
    trial_questions_count,
    ['вопроса', 'вопросов', 'вопросов'],
  )}`;

  const isIntelleka = checkIsIntelleka();

  return (
    <ClientTaskTemplate>
      <p>
        Для успешной сдачи необходимо ответить правильно на
        <strong className="pl-1 pr-1">
          {task?.passing}% ({successQuesions}
          {russianEndings(successQuesions, [' вопрос', ' вопроса', ' вопросов'])})
        </strong>
      </p>
      <p>Если какой-то вопрос вызывает у вас затруднения, вы можете его пропустить. </p>
      <p>
        Если сомневаетесь в ответе, вы можете вернуться к материалам курса, но время отведенное на
        тестирование будет идти.
      </p>
      {!!task?.retake_seconds && (
        <p>
          Если вы не смогли сдать тест с первого раза, не расстраивайтесь, следующая попытка станет
          доступна всего через {toHoursAndMinutes(task.retake_seconds, true)} а пока у вас будет
          время повторить материалы курса.
        </p>
      )}
      <p>
        <strong> Удачи!</strong>
      </p>
      <div className="flex justify-center flex-col md:flex-row text-[19px] mt-12">
        <ThemeButton
          style={btnStyles()}
          isIntelleka={isIntelleka}
          className="md:mr-5 md:max-h-[52px] mb-2 md:mb-0"
          onClick={onClose}
          isSecondary
        >
          Вернуться к обучению
        </ThemeButton>
        <div>
          <ThemeButton
            style={btnStyles()}
            isIntelleka={isIntelleka}
            className="w-full"
            onClick={async () => {
              await onStart();
              await refetchPassings();
            }}
          >
            Начать тестирование
          </ThemeButton>
          {!!task?.trial_attempts && (
            <>
              {!trialAttemptsExceeded ? (
                <Tooltip
                  placement="bottom"
                  text={
                    <div className="max-w-[200px] text-center text-[16px] leading-[18px] p-1">
                      {trialTooltip}
                    </div>
                  }
                >
                  <RadioToggle
                    label="Пробная попытка"
                    checked={trialAttemptsExceeded ? false : isTrial}
                    onChange={() => (trialAttemptsExceeded ? null : toggleTrial())}
                    className="mt-4 text-[16px]"
                    isIntelleka={isIntelleka}
                  />
                </Tooltip>
              ) : (
                <RadioToggle
                  label="Пробная попытка израсходована"
                  checked={true}
                  onChange={() => null}
                  className="mt-4 text-[16px]"
                  disabled
                />
              )}
            </>
          )}
        </div>
      </div>
    </ClientTaskTemplate>
  );
};

export default ClientTestUiStart;
