import { useMatch, Link } from '@tanstack/react-location';
import { useQuery } from 'react-query';
import { API } from '../../../api';
import { theme_color, theme_color_i } from '../../../constants';
import { checkIsIntelleka } from '../../../utils/link';
import AsideTemplate from '../../common/templates/AsideTemplate';
import { generateUserCourseDetailsLink, generateUserTestPassingLink } from '../routes';
import AdminUserStatisticTemplate from './AdminUserStatisticTemplate';

const MenuLink: React.FC<{ to?: string }> = ({ children, to }) => {
  const isIntelleka = checkIsIntelleka();

  return (
    <Link
      to={to}
      className="hover:bg-[#f1f1f1] pl-2 uppercase text-[0.875rem] leading-[1.25] text-gray-medium block py-3 transition-all duration-300 pr-2"
      getActiveProps={() => ({
        style: {
          color: '#000',
          borderRight: `2px solid ${isIntelleka ? theme_color_i : theme_color}`,
        },
      })}
    >
      {children}
    </Link>
  );
};

const StatisticLink: React.FC<{ to?: string }> = ({ children, to }) => {
  return (
    <Link
      to={to}
      className="mt-2 text-gray-500 block py-2 px-4 rounded-tl rounded-tr hover:text-black-default border-b-[#dee2e6] transition-all border-b border-transparent"
      getActiveProps={() => ({
        style: {
          color: '#000',
          borderRight: '1px solid #dee2e6',
          borderTop: '1px solid #dee2e6',
          borderLeft: '1px solid #dee2e6',
          borderBottom: '1px solid transparent',
        },
      })}
    >
      {children}
    </Link>
  );
};

const AdminUserCourseTemplate: React.FC = ({ children }) => {
  const { params, pathname } = useMatch();
  const { id, courseId } = params;
  const { data } = useQuery(['user', id], () => API.admin.user.byId(id), { enabled: !!id });
  const { data: tests } = useQuery(
    ['tests', `course-${courseId}`],
    () => API.admin.test.all({ material__course: courseId }),
    {
      enabled: !!courseId,
    },
  );

  const getLastElementInPath = () => {
    const splittedPath = pathname.split('/').filter((el) => !!el);
    return splittedPath.length ? splittedPath[splittedPath.length - 1] : null;
  };

  const isProgressTab = getLastElementInPath() === courseId;

  return (
    <AdminUserStatisticTemplate>
      <AsideTemplate
        className="flex overflow-hidden"
        sidebar={
          <div className="lg:border-r max-w-[345px] border-gray-200 overflow-hidden">
            {data?.courses?.map((c) => (
              <MenuLink key={c.id} to={generateUserTestPassingLink(id, c.id)}>
                {c.title}
              </MenuLink>
            ))}
          </div>
        }
      >
        <div className="w-full lg:px-3">
          <div className="flex w-full flex-wrap mb-3 text-[0.875rem]">
            <Link
              to={generateUserTestPassingLink(id, courseId)}
              className={`mt-2 rounded-tr rounded-tl border-[#dee2e6] ${
                isProgressTab
                  ? 'border-t border-r border-l '
                  : 'border-b text-gray-500 hover:text-black-default'
              } block py-2 px-4  transition-all`}
            >
              Прогресс
            </Link>
            <StatisticLink to={generateUserCourseDetailsLink(id, courseId)}>
              Подробные результаты
            </StatisticLink>
            {tests?.map((t) => (
              <StatisticLink key={t.id} to={generateUserTestPassingLink(id, courseId, t.id)}>
                {t.title}
              </StatisticLink>
            ))}
          </div>
          <div>{children}</div>
        </div>
      </AsideTemplate>
    </AdminUserStatisticTemplate>
  );
};

export default AdminUserCourseTemplate;
