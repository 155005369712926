import { useQuery } from 'react-query';
import { keyBy } from 'lodash';
import type { Dictionary } from 'lodash';
import { IUser, ISettingData } from '../../../typings/curator';
import { getStuddingPosition } from '../../../utils/statistic';
import { API } from '../../../api';
import CourseProgress from '../../../shared/CourseProgress';
import { CURATOR_CACHE, CURATOR_CACHE_OPTIONS } from '../../../cache/curator';

const CuratorCourseProgress: React.FC<{ user: IUser }> = ({ user }) => {
  const { data: coursesDates } = useQuery(
    CURATOR_CACHE.SETTINGS,
    () => API.curator.getSettings(),
    CURATOR_CACHE_OPTIONS,
  );

  const currentUserSettings = coursesDates?.filter((el) => el.user === user.id);
  const datesByCourseId: Partial<Dictionary<ISettingData>> = keyBy(currentUserSettings, 'course');

  return (
    <>
      {user.courses
        .sort((a, b) => a.id - b.id)
        .map((course, _, self) => {
          const isDatesUnset = !datesByCourseId[course.id]?.id;
          const startDate = datesByCourseId[course.id]?.start_course;
          const endDate = datesByCourseId[course.id]?.end_course;

          if (isDatesUnset || !startDate || !endDate) return <div key={course.id}>-</div>;

          const passed = getStuddingPosition(startDate, endDate);
          const isShort = self.length > 1;
          return (
            <CourseProgress
              key={course.id}
              courseId={course.id}
              passed={passed}
              isShort={isShort}
              startDate={startDate}
              endDate={endDate}
            />
          );
        })}
    </>
  );
};

export default CuratorCourseProgress;
