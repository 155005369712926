import moment from 'moment';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import Tooltip from '@mui/material/Tooltip';
import { russianEndings } from '../../../utils/string';
import { useMaterial } from '../store/useMaterial';
import { checkIsIntelleka } from '../../../utils/link';
import { API } from '../../../api';
import { useStatistic } from '../store/useStatistic';
import TimeCosts from '../../../shared/TimeCosts';

const Block: React.FC<{ isLast?: boolean; className?: string }> = ({
  isLast = false,
  className = '',
  children,
}) => {
  return (
    <div
      className={`h-[90px] w-full pt-4 text-center ${
        !isLast && 'border-r border-r-gray-default'
      } ${className}`}
    >
      {children}
    </div>
  );
};

const ClientCourseSummary: React.FC<{ course?: number; user?: number }> = ({ course, user }) => {
  const { data: settings, refetch: refetchSettings } = useQuery(
    ['settings'],
    () => API.client.settings.getClientSettings({ course, user }),
    {
      enabled: !!user && !!course,
    },
  );

  const currentCourseSettings = settings?.find((s) => s.course === course);
  const start_course = currentCourseSettings?.start_course;
  const end_course = currentCourseSettings?.end_course;

  const { data: statistic, refetch: refetchStatistic } = useQuery('statistic', () =>
    API.client.activity.getClientStatistic({ user, course_id: course }),
  );

  const getStatisticCountHours = () => {
    if (statistic) {
      const getStatisticCountSeconds = statistic
        .filter((data) => Number(data.course_id) === course)
        .map((el) => el.seconds)
        .reduce((acc, cur) => acc + cur, 0);

      return Math.round(getStatisticCountSeconds / 60 / 60);
    }

    return 0;
  };

  useEffect(() => {
    course && refetchSettings();
    course && refetchStatistic();
  }, [course, refetchSettings, refetchStatistic]);

  const { materialToContinue, courseNotStarted, setId } = useMaterial();

  const statisticTimeString =
    getStatisticCountHours() < 1
      ? 'менее 1 часа'
      : getStatisticCountHours() +
        ` ${russianEndings(getStatisticCountHours(), ['час', 'часа', 'часов'])}`;

  const blocks = [
    {
      label: 'дата открытия',
      value: start_course ? moment(start_course).format('DD.MM.YYYY') : '-',
    },
    { label: 'дата закрытия', value: end_course ? moment(end_course).format('DD.MM.YYYY') : '-' },
    { label: 'временные затраты', value: statisticTimeString },
  ];

  const tooltips = [
    'Дата открытия курса',
    'Дата закрытия курса. Если Вы не успеваете пройти курс за отведённое время, свяжитесь со службой поддержки по электронной почте support@promrg.ru с просьбой его продлить',
    'Приблизительное время, проведенное на образовательном портале',
  ];

  const isIntelleka = checkIsIntelleka();
  const { connectionStatus } = useStatistic();

  const ContinueBlock = () => (
    <button
      className={`mt-1 ${
        isIntelleka
          ? 'bg-active_i disabled:bg-[rgba(3,84,139,0.5)]'
          : 'bg-active_p disabled:bg-red-300'
      } rounded text-white text-md py-3 px-[25px]`}
      onClick={() => typeof materialToContinue === 'number' && setId(materialToContinue)}
      disabled={!materialToContinue}
    >
      {courseNotStarted
        ? 'Начать обучение'
        : materialToContinue
        ? 'Продолжить обучение'
        : 'Нет новых материалов'}
    </button>
  );

  return (
    <div className="relative">
      <div className="absolute left-0 right-0 m-auto w-full max-w-[1023px] mx-auto mt-[-50px] bg-white rounded-[5px] flex justify-between">
        {blocks.map((el, i) => {
          const isTimeCosts = i === 2;

          return (
            <Block key={i}>
              <div className="flex flex-col justify-center w-full">
                <div className="text-sm lg:text-md text-gray-dark">{el.label}</div>
                <div className="text-sm lg:text-lg font-bold pt-[3px] flex justify-center">
                  {isTimeCosts ? (
                    <TimeCosts
                      value={el.value}
                      connectionStatus={connectionStatus}
                      tooltipText={tooltips[i]}
                    />
                  ) : (
                    <Tooltip arrow title={<div className="tooltip-summary">{tooltips[i]}</div>}>
                      <div>{el.value}</div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </Block>
          );
        })}
        <Block isLast className="min-w-[325px] hidden lg:block">
          <ContinueBlock />
        </Block>
      </div>
    </div>
  );
};

export default ClientCourseSummary;
