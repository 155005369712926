import axios from 'axios';
import { ISettingData, ISettingParams } from '../../typings/client';

export const CLIENT_USER_COURSE_SETTINGS = '/api/v1/client/courses/client_user_course_settings/';

export async function getClientSettings(params?: ISettingParams) {
  try {
    const { data } = await axios.get<ISettingData[]>(CLIENT_USER_COURSE_SETTINGS, {
      params,
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
