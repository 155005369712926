import { useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { IUser } from '../../../typings/admin';
import AdminMasterForm from './AdminMasterForm';
import Modal from '../../../shared/Modal';
import { checkIsIntelleka } from '../../../utils/link';
import { useEditCurator } from '../../../cache/admin/users/curators';
import { getError } from '../../../utils/api';

const AdminEditCurator: React.FC<{ user: IUser }> = ({ user }) => {
  const [open, toggle] = useState(false);
  const [error, setError] = useState('');

  const { mutate: updateUser, isLoading: updating } = useEditCurator(
    () => toggle(false),
    (error) => {
      setError(getError(error));
      setTimeout(() => setError(''), 3000);
    },
  );

  const isIntelleka = checkIsIntelleka();

  return (
    <div className="flex justify-end lg:justify-center">
      <FaEdit
        className={`cursor-pointer ${
          isIntelleka ? 'hover:text-active_i' : 'hover:text-active_p'
        } transition-all`}
        onClick={() => toggle(true)}
      />
      <Modal
        open={open}
        onClose={() => toggle(false)}
        className="rounded max-w-screen-modal_md md:mt-7 p-4"
      >
        <AdminMasterForm
          submit={(data) => updateUser(data)}
          loading={updating}
          onClose={() => toggle(false)}
          open={open}
          edit={user}
          curator
          error={error}
          setError={setError}
        />
      </Modal>
    </div>
  );
};

export default AdminEditCurator;
