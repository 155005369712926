import { IMaterialPassing, ITest } from '../../../typings/admin';
import PassingStatus from '../../../shared/PassingStatus';
import { IStructured } from '../../../utils/material';
import AdminTestResult from './AdminTestResult';

const AdminMaterialItem: React.FC<{
  item: IStructured;
  materialsPassingByMaterialId?: {
    [x: string]: IMaterialPassing;
  };
  testsByMaterialId?: { [x: string]: ITest };
}> = ({ item, materialsPassingByMaterialId, testsByMaterialId }) => {
  const passing = materialsPassingByMaterialId && materialsPassingByMaterialId[item.id];
  const test = testsByMaterialId && testsByMaterialId[item.id];

  return (
    <li className="flex flex-col justify-start transition-all w-fit">
      <div className="relative">
        <div className="min-w-[17px] absolute top-[3px]">
          <PassingStatus status={passing?.status} className="mr-1 w-4 h-4" />
        </div>
        <div className="ml-[20px]">{item.title}</div>
      </div>
      {test && <AdminTestResult test={test} />}
      <div>
        {item?.children && (
          <ul className="pl-6">
            {item.children?.map((c) => (
              <AdminMaterialItem
                key={c.id}
                item={c}
                materialsPassingByMaterialId={materialsPassingByMaterialId}
                testsByMaterialId={testsByMaterialId}
              />
            ))}
          </ul>
        )}
      </div>
    </li>
  );
};

export default AdminMaterialItem;
