import Modal from '../../../shared/Modal';
import AdminCourseForm from './AdminCourseForm';
import BadgeButton from '../../../shared/BadgeButton';
import { useCoursesForm } from '../store/useCourses';
import { useAddCourse } from '../../../cache/admin/course';

const AdminAddCourse: React.FC = () => {
  const { open, toggle } = useCoursesForm();
  const { mutate: addCourse, isLoading: adding } = useAddCourse();

  return (
    <div>
      <BadgeButton onClick={() => toggle(true)}>Добавить курс</BadgeButton>
      <Modal
        open={open}
        onClose={() => toggle(false)}
        className="rounded max-w-[1000px] mt-0 sm:mt-[1.75rem]"
      >
        <AdminCourseForm submit={addCourse} onClose={() => toggle(false)} loading={adding} />
      </Modal>
    </div>
  );
};

export default AdminAddCourse;
