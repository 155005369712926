import axios from 'axios';
import {
  IStatisticUser,
  IUserActivityParams,
  IUserDevice,
  IUserDeviceParams,
} from '../../typings/admin';

export async function devices(params: IUserDeviceParams) {
  try {
    const { data } = await axios.get<IUserDevice[]>(`/api/v1/users/user_activity_details/`, {
      params,
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function visitings(params: IUserActivityParams) {
  try {
    const { data } = await axios.get<IStatisticUser>(`/api/v1/users/user_activity/`, { params });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
