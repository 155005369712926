import axios from 'axios';
import { IQuestion, IQuestionsParams } from '../../typings/client';

export async function getAllQuestions(params?: IQuestionsParams) {
  try {
    const { data } = await axios.get<IQuestion[]>(`/api/v1/client/courses/question/`, {
      params,
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
