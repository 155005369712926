import CuratorTemplate from '../templates/CuratorTemplate';
import { ROOT, COMPANIES } from '../routes';
import { useNavigate } from '@tanstack/react-location';
import { useQuery } from 'react-query';
import Table from '../../../shared/Table';
import { API } from '../../../api';
import { CURATOR_CACHE, CURATOR_CACHE_OPTIONS } from '../../../cache/curator';

const titles = ['Название', 'Количество пользователей'];

const CuratorCompanies: React.FC = () => {
  const { data: users, isLoading } = useQuery(
    CURATOR_CACHE.USERS,
    () => API.curator.getUsers(),
    CURATOR_CACHE_OPTIONS,
  );

  const navigate = useNavigate();

  const companies = users?.results.reduce((result, user) => {
    if (user.company && !result.find(({ id }) => id === user.company.id)) {
      return result.concat(user.company);
    }

    return result;
  }, [] as { id: number; title: string }[]);

  const navigateToUsers = async (company: { id: number; title: string }) => {
    return navigate({ to: `${ROOT + COMPANIES}/${company.id}` });
  };

  const rows = companies?.map((c) => [
    <div className="cursor-pointer" onClick={() => navigateToUsers(c)}>
      {c.title}
    </div>,
    <div className="cursor-pointer" onClick={() => navigateToUsers(c)}>
      {users?.results.filter((user) => user.company?.id === c.id).length}
    </div>,
  ]);

  return (
    <CuratorTemplate>
      <div className="mb-1">
        <Table titles={titles} rows={rows} isLoading={isLoading} />
      </div>
    </CuratorTemplate>
  );
};

export default CuratorCompanies;
