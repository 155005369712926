import { useConnectToStatistic } from '../../admin/store/useStatistic';
import { useChatNotifications } from '../store/useNotifications';
import ClientStatistic from '../../../ui/client/containers/ClientStatistic';
import { parseJwt } from '../../../utils/api';

/**
 * Компонент рендерится только если пользователь вошел в систему
 **/
const LoggedInOnly: React.FC<{ access: string }> = ({ access }) => {
  const { user_id, status } = parseJwt(access);

  useConnectToStatistic();
  useChatNotifications(user_id);

  const isClient = status === 2;

  return isClient ? <ClientStatistic /> : null;
};

export default LoggedInOnly;
