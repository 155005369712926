import create from 'zustand';
import type { AxiosError } from 'axios';
import { ICurrentUserClient, IQuestion, ITask, ITestPassing } from '../../../typings/client';
import { getError } from '../../../utils/api';
import { hasTimeToPass, isLastPassingClosed } from '../../../utils/passing';
import { API } from '../../../api';
import { countByPercent } from '../../../utils/number';
import { queryClient } from '../../../hooks/queryClient';
import { getTestBassingsByCourseKey } from '../../../cache/client/passing/keys';
import { shuffle } from 'lodash';

type IUi = 'start' | 'question' | 'results' | 'loading' | 'error';

type ITest = {
  /** загружается в ClientTemplate */
  user?: ICurrentUserClient;

  /** загружается в хуке */
  task?: ITask;
  ui: IUi;
  questions: IQuestion[];
  answers: number[];
  freeAnswer: string;
  question?: IQuestion;
  answeredQuestions: number[];
  skippedQuestions: IQuestion[];
  passing?: ITestPassing;
  error?: string;
  isTrial: boolean;
  trialAttemptsExceeded: boolean;
  file?: File;
  isConfirmVisible: boolean;
};

type ISetTest = {
  onOpen(id: number): void;
  onStart(): Promise<void>;
  onNext(course: number): void;
  onClose(): void;
  setUser(u: ICurrentUserClient): void;
  setTask(t?: ITask): void;
  setUi(ui: IUi): void;
  setPluralAnswer(variant: number): void;
  setSingleAnswer(variant: number): void;
  setFreeAnswer(answer: string): void;
  setFile(file: File): void;
  onSkip(): void;
  toggleTrial(): void;
  getQuestionNumber(): number | null;
  isLastQuestion(): boolean;
  getCurrentAttemptQuestions(): IQuestion[];
  getCurrentAttemptNextQuestion(): IQuestion | undefined;
  isQuestionSkipped(): boolean;
  isLastNotAnswered(): boolean;
  backToSkipped(): void;
  isFirstSkipped(): boolean;
  handleClose(): void;
  hideConfirmWindow(): void;
};

const defaultValues: ITest = {
  trialAttemptsExceeded: false,
  task: undefined,
  ui: 'loading',
  questions: [],
  answers: [],
  question: undefined,
  answeredQuestions: [],
  skippedQuestions: [],
  passing: undefined,
  error: undefined,
  freeAnswer: '',
  isTrial: false,
  file: undefined,
  isConfirmVisible: false,
};

export const useTest = create<ITest & ISetTest>((set, get) => ({
  ...defaultValues,
  setUser: (user) => set(() => ({ user })),
  setTask: (task) => set(() => ({ task })),
  setUi: (ui) => set(() => ({ ui })),
  setPluralAnswer: (variant) =>
    set((state) => ({
      answers: state.answers.includes(variant)
        ? state.answers.filter((a) => a !== variant)
        : [...state.answers, variant],
    })),
  setSingleAnswer: (variant) => set({ answers: [variant] }),
  setFreeAnswer: (freeAnswer) => set(() => ({ freeAnswer })),
  setFile: (file) => set(() => ({ file })),

  /** Открытие модального окна теста */
  onOpen: async (task) => {
    set({ ui: 'loading' });
    const passings = await API.client.test_passing.getAllTestPassings({ task });
    const questions = await API.client.question.getAllQuestions({ tasks: task });
    set({ questions: get().task?.is_chance ? shuffle(questions) : questions.reverse() });
    /** ЕСЛИ ПОСЛЕДНЯЯ ПОПЫТКА ЗАКРЫТА ИЛИ ОТСУТСТВУЕТ: */
    if (isLastPassingClosed(passings)) {
      /** Показываем окно старта теста */
      set({ ui: 'start' });
      const trial_attempts = passings.filter((p) => p.is_trial);
      if (trial_attempts.length >= (get().task?.trial_attempts || 0)) {
        set({ trialAttemptsExceeded: true });
      }
    } else {
      /** ЕСЛИ ПОСЛЕДНЯЯ ПОПЫТКА НЕ ЗАКРЫТА: */
      /**
       * a) если время на прохождение теста еще есть,
       * отфильтровать вопросы, на которые пользователь уже ответил в этом тесте
       * загрузить данные для продолжения теста (оставшееся время, следующий вопрос)
       */
      const lastPassing = passings[0];
      if (hasTimeToPass(lastPassing, get().task?.travel_time)) {
        try {
          const userAnswers = await API.client.answer.getUserAnswers({ passing: lastPassing.id });
          const answeredQuestions = userAnswers.map((q) => q.question);
          set({ answeredQuestions, isTrial: lastPassing.is_trial });
          const question = get().getCurrentAttemptNextQuestion();
          set({
            ui: 'question',
            passing: lastPassing,
            question,
          });
        } catch (e) {
          set({ ui: 'error', error: getError(e as AxiosError<unknown>) });
        }
      } else {
        /**
         * а) если время на прохождение теста истекло,
         * закрыть эту попытку
         */
        try {
          await API.client.test_passing.endTestPassing(passings[0].id);
          set({ passing: passings[0], ui: 'results' });
        } catch (e) {
          set({ ui: 'error', error: getError(e as AxiosError<unknown>) });
        }
      }
    }
  },

  /** Старт теста */
  onStart: async () => {
    set({ ui: 'loading' });
    const questions = get().questions;
    const user = get().user;

    const question = !!questions?.length && questions[0];

    try {
      if (question) {
        const passing = await API.client.test_passing.createTestPassing({
          task: get().task?.id,
          user: user?.id,
          is_trial: get().isTrial,
        });
        set({ passing, question, ui: 'question' });
      } else {
        set({ ui: 'results' });
      }
    } catch (e) {
      set({ ui: 'error', error: getError(e as AxiosError<unknown>) });
    }
  },

  /** Переключение на следующий вопрос */
  onNext: async (course: number) => {
    set({ ui: 'loading' });
    const {
      passing,
      question,
      answers,
      freeAnswer,
      file,
      getCurrentAttemptNextQuestion,
      isQuestionSkipped,
      skippedQuestions,
    } = get();

    if (!passing?.id || !question?.id)
      return set({ ui: 'error', error: 'Возникла непредвиденная ошибка' });

    /** Если идет ответ на ранее пропущенный вопрос, удаляем его из пропущенных */
    isQuestionSkipped() &&
      set({ skippedQuestions: skippedQuestions.filter((q) => q.id !== question.id) });

    try {
      await API.client.answer.createUserAnswer({
        passing: passing.id,
        question: question.id,
        answers,
        text: freeAnswer,
        file,
      });

      set({ answers: [] });

      const nextQuestion = getCurrentAttemptNextQuestion();

      if (nextQuestion) {
        set((state) => ({
          question: nextQuestion,
          answeredQuestions: state.answeredQuestions.concat(question.id),
          ui: 'question',
          freeAnswer: '',
        }));
      } else {
        const closed = await API.client.test_passing.endTestPassing(passing.id);
        set({
          ui: 'results',
          passing: closed,
          question: undefined,
          answeredQuestions: [],
        });

        queryClient.refetchQueries(getTestBassingsByCourseKey(course));
      }
    } catch (e) {
      set({ ui: 'error', error: getError(e as AxiosError<unknown>) });
    }
  },

  /** Пропуск вопроса */
  onSkip: () => {
    const { getCurrentAttemptNextQuestion, question, skippedQuestions } = get();

    const nextQuestion = getCurrentAttemptNextQuestion();

    if (question)
      set({
        /** перемещаем вопрос из questions в skippedQuestions */
        skippedQuestions: [...skippedQuestions, question],
        question: nextQuestion,
        ui: 'question',
        freeAnswer: '',
      });
  },

  /** Закрытие модального окна теста */
  onClose: () => set(defaultValues),

  toggleTrial: () =>
    set({
      isTrial: !get().isTrial,
    }),

  getQuestionNumber: () => {
    const question = get().question;
    const questions = get().questions;
    return question && questions.length ? questions.indexOf(question) + 1 : null;
  },

  isLastQuestion: () => get().getQuestionNumber() === get().getCurrentAttemptQuestions().length,

  /** Вопросы текущей попытки */
  getCurrentAttemptQuestions: () =>
    get().isTrial
      ? get().questions.slice(
          0,
          Math.round(
            countByPercent(get().questions.length, Number(get().task?.trial_percents || 0)),
          ),
        )
      : get().questions,

  /** Следующий вопрос текущей попытки */
  getCurrentAttemptNextQuestion: () => {
    const { getCurrentAttemptQuestions, answeredQuestions, question, skippedQuestions } = get();
    const allQuestions = getCurrentAttemptQuestions();

    const allNotAnsweredQuestions = allQuestions.filter(
      (q) =>
        ![...answeredQuestions, ...skippedQuestions.map((q) => q.id), question?.id].includes(q.id),
    );

    /**
     * если у теста активна опция "в случайном порядке", выбираем рандомный вопрос
     * иначе - следующий по порядку
     */
    const nextQuestion = allNotAnsweredQuestions[0];

    return !!allQuestions.length
      ? nextQuestion
        ? nextQuestion
        : !!skippedQuestions.length
        ? skippedQuestions.filter((q) => question?.id !== q.id)[0]
        : undefined
      : undefined;
  },

  /** функция проверяет пропускался ли текущий вопрос */
  isQuestionSkipped: () => {
    const { question, skippedQuestions } = get();
    return !!question && skippedQuestions.includes(question);
  },

  /** функция проверяет является ли текущий вопрос последним из оставшихся */
  isLastNotAnswered: () => {
    const { answeredQuestions, getCurrentAttemptQuestions } = get();
    return getCurrentAttemptQuestions().length - answeredQuestions.length === 1;
  },

  /** функция возвращает интерфейс теста к последнему пропущенному вопросу */
  backToSkipped: () => {
    const { skippedQuestions, isQuestionSkipped, question } = get();
    if (!skippedQuestions.length || !question) return;

    if (isQuestionSkipped()) {
      const currentQuestionIndex = question && skippedQuestions.indexOf(question);
      const prevSkippedQuestion = skippedQuestions.find((_, i) => i === currentQuestionIndex - 1);

      set(() => ({
        question: prevSkippedQuestion,
      }));
    } else {
      set(() => ({
        question: skippedQuestions[skippedQuestions.length - 1],
      }));
    }
    set(() => ({
      ui: 'question',

      /** сбрасываем введенные, но не отправленные ответы предыдущего вопроса  */
      freeAnswer: '',
      file: undefined,
      answers: [],
    }));
  },

  /** функция проверяет является ли текущий вопрос первым в списке пропущенных */
  isFirstSkipped: () => {
    const { skippedQuestions, question } = get();
    const currentQuestionIndex = question ? skippedQuestions.indexOf(question) : 0;
    return currentQuestionIndex === 0;
  },

  /**
   * функция обрабатывает событие закрывая окно теста.
   * Во время прохождения теста требует окно подтверждкния
   */
  handleClose: () => (get().ui === 'question' ? set({ isConfirmVisible: true }) : get().onClose()),

  /** функция прячет окно подтверждения о закрытии теста */
  hideConfirmWindow: () => set({ isConfirmVisible: false }),
}));
