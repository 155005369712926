import axios from 'axios';
import { IPagesUsersList, IParamsUsersList } from '../../typings/curator';

export const CURATOR_USERS_URL = '/api/v1/curator/ref/users/';

export async function getUsers(params?: IParamsUsersList) {
  try {
    const { data } = await axios.get<IPagesUsersList>(CURATOR_USERS_URL, { params });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
