import mailWhite from '../img/mailWhite.png';
import phoneWhite from '../img/phoneWhite.png';
import SvgClose from '../react-svg/SvgClose';

/** Компонент рендера ошибки авторизации */
const AuthError: React.FC<{
  /** колбек при закрытии */
  onClose?(): void;
  /** стиль интеллеки */
  isIntelleka?: boolean;
  /** ширина */
  width?: number;
  /** высота */
  height?: number;
  /** класс */
  className?: string;
}> = ({
  onClose = () => null,
  isIntelleka = false,
  width = 300,
  height = 294,
  className = 'relative',
}) => {
  const intelecaMail = isIntelleka ? 'support@intelleka.ru' : 'support@promrg.ru';
  const intelecaPhone = isIntelleka ? '+7(495)268-01-80' : '+7 (495) 663-71-07';
  return (
    <div
      style={{ width, height }}
      className={`flex items-center justify-center bg-black-default ${className}`}
      data-testid="auth-error"
    >
      <div className="text-[rgba(235,235,235)] text-center">
        <div className="text-yellow-border leading-[26px] text-lg">Возникли проблемы?</div>
        <div className="text-[15px] italic mt-3 mb-8">
          Обратитесь в нашу службу <br /> технической поддержки
        </div>
        <div className="flex justify-center items-center my-2">
          <img src={phoneWhite} alt="phone" className="w-[18px] h-[18px] mr-2" />
          <a className="text-md" href={`tel:${intelecaPhone}`}>
            {intelecaPhone}
          </a>
        </div>
        <div className="flex justify-center items-center">
          <img src={mailWhite} alt="mail" className="w-[18px] h-[18px] mr-2" />
          <a className="text-md underline" href={`mailto:${intelecaMail}`}>
            {intelecaMail}
          </a>
        </div>

        <SvgClose
          onClick={onClose}
          className="w-5 absolute top-0 right-0 opacity-60 hover:opacity-100 transition-all cursor-pointer"
        />
      </div>
    </div>
  );
};

export default AuthError;
