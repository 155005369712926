import { useQuery } from 'react-query';
import { getAllTestPassings } from '../../../api/client/test_passing';
import { getTestBassingsByCourseKey as getKey } from './keys';

/** хук возвращает все успешные попытки текущего курса */
export const useSuccessTestPassingsByCourse = (course?: number) => {
  return useQuery(getKey(course), () => getAllTestPassings({ success_passed: '0' }), {
    enabled: !!course,
  });
};
