import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from './hooks/queryClient';
import { ToastProvider } from 'react-toast-notifications';
import './api/interceptors';
import App from './App';
import { isDev, isMockServer } from './constants';

if (isMockServer) {
  const { worker } = require('./mocks/server/browser');
  worker.start();
}

/**
 * Инициализация приложения
 */
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      <ToastProvider autoDismiss autoDismissTimeout={4000} placement="top-center">
        <App />
      </ToastProvider>
    </React.StrictMode>
    {isDev && <ReactQueryDevtools initialIsOpen={false} />}
  </QueryClientProvider>,

  document.getElementById('root'),
);
