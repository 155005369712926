import axios from 'axios';

import { ISettingData, ISettingParams } from '../../typings/curator';

export async function getSettings(params?: ISettingParams) {
  try {
    const { data } = await axios.get<ISettingData[]>('/api/v1/curator/user_course_settings/', {
      params,
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
