import axios from 'axios';

const URL = `/api/v1/user/user_log_errors/`;

export const getUserLogErrors = async (params: { user: number }) => {
  try {
    const { data } = await axios.get(URL, { params });

    return data;
  } catch (e) {
    throw Error();
  }
};

export const createUserLogError = async (user: number) => {
  try {
    const { data } = await axios.post(URL, { user });

    return data;
  } catch (e) {
    throw Error();
  }
};
