/**
 * @module string
 * @category общие утилиты
 * @description утилиты по работе со строками
 */

/**
 * функция принимает число с вариантами окончания и возвращает подходящее, в зависимости от числа
 * @param n число
 * @param titles склоняемые заголовки
 * @returns строку c подходящим склоняемым заголовком
 */
export function russianEndings(n: number, titles: string[]) {
  return titles[
    n % 10 === 1 && n % 100 !== 11
      ? 0
      : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
      ? 1
      : 2
  ];
}

/**
 * функция очищает строку от html тегов, возвращая только ее контент
 * @param stringWithHtml строка с html-тегами
 * @returns возвращает чистую строку без тегов
 */
export function clearStringHtml(stringWithHtml?: string) {
  return stringWithHtml?.replace(/(<([^>]+)>)/gi, '').trim();
}

/**
 * Функция обезает строку по параметру максимальной длинны и добавляет троеточие в конце
 * @param str строка
 * @param max максимальная длинна
 * @returns Возвращает обрезанную строку с троеточием в конце
 */
export const maxStringLength = (str: string, max: number) =>
  str.length <= max ? str : `${str.slice(0, max)}...`;

/**
 * Функция обрезает строку до условного символа или строки
 * @param string строка
 * @param sign символ или строка
 * @returns обрезанная строка
 */
export const sliceBefore = (string: string, sign?: string) => {
  const divider = sign || '=';
  return string.split(divider).length < 2 ? string : string.split(divider)[1];
};

/**
 * Функция возвращает последнее слово строки
 * @param words строка с набором слов
 * @returns последнее слово
 */
export const getLastWord = (words: string) => {
  const n = words.split(' ');
  return n[n.length - 1];
};

/**
 * Функция вырезает числа из строки, на основе регулярного выражения
 * @param str строка
 * @returns числа
 */
export const getNumbersFromString = (str: string) => str.match(/\d/g)?.join('');

/**
 * Функция проверяет парсится ли строка в валидный JSON формат
 * @param str строка
 * @returns true если JSON, false - если нет
 */
export const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
