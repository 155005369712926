import axios from 'axios';
import { IMessage, IUnreadMessagesResponse } from '../../typings/common';

export const UNREAD_MESSAGES_URL = `/api/v1/chats/unread_message/`;
export const MESSAGES_URL = `/api/v1/chats/message/`;

export async function getUnreadMessages() {
  try {
    const { data } = await axios.get<IUnreadMessagesResponse>(UNREAD_MESSAGES_URL);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function setUnreadMessagesAsRead(idChat: string) {
  try {
    const { data } = await axios.post(`${MESSAGES_URL}is_read/`, { chat_id: idChat });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getMessagesByChatId(chatId: string) {
  try {
    const { data } = await axios.get<IMessage[]>(MESSAGES_URL, {
      params: { chat: chatId, ordering: 'created' },
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
