import SvgClose from '../react-svg/SvgClose';

const NotificationItem: React.FC<{
  author: string;
  message_text: string;
  onClick(): void;
  onClose(): void;
}> = (props) => (
  <div className="relative block bg-[rgba(0,0,0,0.6)] text-white p-3 mb-2 rounded max-w-xs min-w-[315px]">
    <b>{props.author}:</b>
    <div
      onClick={props.onClick}
      className="block mr-4 opacity-80 hover:opacity-100 transition-all cursor-pointer"
    >
      {props.message_text.length < 90 ? (
        props.message_text
      ) : (
        <>
          {props.message_text.slice(0, 90)}...
          <span className="italic text-right ml-1">(читать далее)</span>
        </>
      )}
    </div>

    <SvgClose
      onClick={props.onClose}
      className="w-5 h-5 absolute top-0 right-[6px] bottom-0 m-auto opacity-60 hover:opacity-100 transition-all cursor-pointer"
    />
  </div>
);

export default NotificationItem;
