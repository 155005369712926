import create from 'zustand';

interface ICollapse {
  closed: number[];
  toggleClosed: (id: number) => void;
}

const createStore = create<ICollapse>((set, get) => ({
  closed: [],
  toggleClosed: (id) => {
    const { closed } = get();
    set({
      closed: closed.includes(id) ? closed.filter((el) => el !== id) : closed.concat(id),
    });
  },
}));

export const useMaterialCollapse = createStore;
export const useTestCollapse = createStore;
