import { useEffect } from 'react';
import ClientTemplate from '../templates/ClientTemplate';
import { useCourse } from '../store/useCourse';
import ClientTestCollapse from '../containers/ClientTestCollapse';
import Spinner from '../../../shared/Spinner';
import { checkIsIntelleka } from '../../../utils/link';
import { useGetTasks } from '../../../cache/client/task';

const ClientTests: React.FC = () => {
  const { course } = useCourse();

  const { data: tasks, isLoading, refetch } = useGetTasks(course);

  useEffect(() => {
    course && refetch();
  }, [course, refetch]);

  const isIntelleka = checkIsIntelleka();

  return (
    <ClientTemplate>
      <div className="px-7 pb-5">
        {isLoading ? (
          <Spinner
            className={`${isIntelleka ? 'fill-active_i' : 'fill-active_p'} w-12 m-auto mt-12`}
          />
        ) : (
          tasks?.map((t, i, self) => {
            const isLast = i === self.length - 1;
            return <ClientTestCollapse key={t.id} test={t} isLast={isLast} />;
          })
        )}
      </div>
    </ClientTemplate>
  );
};

export default ClientTests;
