/**
 * @module LightBox
 * @category Общие компоненты
 */

import { FaWindowClose, FaPlus, FaMinus } from 'react-icons/fa';
import { useState } from 'react';

/** Интерфейс для пропсов компонента [[`LightBox`]] */
export interface ILightBoxProps {
  mainSrc: string;
  onCloseRequest(): void;
}

/** Компонент отображения картинки в полном размере */
const LightBox: React.FC<ILightBoxProps> = ({ onCloseRequest, mainSrc }) => {
  const [scale, setScale] = useState(1);

  return (
    <div className="fixed top-0 left-0 bg-[rgba(0,0,0,0.5)] w-full h-full z-[999999999999999] overflow-auto select-none">
      <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
        <img
          src={mainSrc}
          style={{ transform: `scale(${scale})` }}
          alt="lightBox"
          className="max-w-full max-h-full transition-all"
        />
      </div>
      <div className="bg-gray-300 p-2 absolute flex justify-between rounded w-[100px] top-2 left-[50%] translate-x-[-51px] h-[auto] bg-[rgba(255, 255, 255, 0.5)] z-30">
        <FaPlus className="svg-media-icon" onClick={() => setScale(scale + 0.2)} />
        <FaMinus className="svg-media-icon" onClick={() => setScale(scale - 0.2)} />
        <FaWindowClose className="svg-media-icon" onClick={onCloseRequest} />
      </div>
    </div>
  );
};

export default LightBox;
