const ClientMaterialDescription: React.FC<{ index?: number; sidebar?: boolean }> = ({
  index,
  sidebar = false,
}) => (
  <div className={`text-gray-dark ${sidebar ? 'text-[11px] ml-5' : 'text-[15px]'}`}>
    Часть {index}
  </div>
);

export default ClientMaterialDescription;
