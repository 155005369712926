import { useState } from 'react';
import { useMutation } from 'react-query';
import { FaTimes } from 'react-icons/fa';
import ConfirmModal from '../../../shared/ConfirmModal';
import { queryClient } from '../../../hooks/queryClient';
import { IChat } from '../../../typings/common';
import { checkIsIntelleka } from '../../../utils/link';
import { API } from '../../../api';

const AdminRemoveChat: React.FC<{ id: number; refetchKey: string }> = ({ id, refetchKey }) => {
  const [confirmVisible, toggle] = useState(false);

  const { mutate: remove, isLoading: removing } = useMutation<unknown, unknown, number>(
    (data) => API.admin.chat.remove(data),
    {
      onSuccess: (_, id) => {
        toggle(false);
        queryClient.setQueryData(refetchKey, () => {
          const oldData = queryClient.getQueryData<IChat[]>(refetchKey);
          if (oldData) {
            return oldData.filter((chat) => chat.id !== id);
          }
        });
      },
    },
  );
  const isIntelleka = checkIsIntelleka();

  return (
    <>
      <FaTimes
        onClick={() => toggle(true)}
        className={`cursor-pointer ${isIntelleka ? 'hover:text-active_i' : 'hover:text-active_p'}`}
      />
      <ConfirmModal
        confirm={() => remove(id)}
        isOpen={confirmVisible}
        hide={() => toggle(false)}
        loading={removing}
        isIntelleka={isIntelleka}
      >
        <div className="text-left">Вы действительно хотите удалить чат?</div>
      </ConfirmModal>
    </>
  );
};

export default AdminRemoveChat;
