import { useMatch } from '@tanstack/react-location';
import { keyBy } from 'lodash';
import moment from 'moment';
import { useQuery } from 'react-query';
import { API } from '../../../api';
import Table from '../../../shared/Table';
import { secondsToTime } from '../../../utils/time';
import AdminUserStatisticTemplate from '../templates/AdminUserStatisticTemplate';

const titles = ['#', 'Дата активности', 'Время в системе', 'Курс'];

const AdminUserVisitings: React.FC = () => {
  const { params } = useMatch();
  const { id } = params;
  const { data, isFetching } = useQuery(['activity', 'user', id], () =>
    API.admin.activity.visitings({ user: id }),
  );
  const { data: courses } = useQuery('courses', () => API.admin.course.all());

  const coursesObject = courses ? keyBy(courses, 'id') : {};

  const sorted = data
    ?.map((el) => ({ ...el, unixDate: new Date(el.day).getTime() }))
    .sort((a, b) => a.unixDate - b.unixDate);

  const rows = sorted?.map((a, i) => [
    i + 1,
    <>{!!a.day && moment(a.day).format('DD.MM.YYYY')}</>,
    <>{secondsToTime(a.seconds)}</>,
    <>{coursesObject[a.course_id]?.title}</>,
  ]);

  return (
    <AdminUserStatisticTemplate>
      <Table titles={titles} rows={rows} isLoading={!data && isFetching} />
    </AdminUserStatisticTemplate>
  );
};

export default AdminUserVisitings;
