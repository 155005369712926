import axios from 'axios';
import { ICompany, IParamsCompanies, TUpdateCompany } from '../../typings/admin';

export async function all(params?: Partial<IParamsCompanies>) {
  try {
    const { data } = await axios.get<ICompany[]>('/api/v1/users/company/', {
      params,
    });

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function update({ id, ...model }: TUpdateCompany) {
  try {
    const { data } = await axios.put<ICompany>(`/api/v1/users/company/${id}/`, model);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function remove(id: number) {
  try {
    const { data } = await axios.delete(`/api/v1/users/company/${id}/`);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
