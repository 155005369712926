import create from 'zustand';
import { queryClient } from '../../../hooks/queryClient';

type ICourse = {
  course?: number;
  setCourse(course: number): void;
};

export const useCourse = create<ICourse>((set, get) => ({
  course: undefined,
  setCourse: (course) => {
    const { course: prevCourse } = get();
    prevCourse !== course && queryClient.removeQueries(['materials', course]);
    set(() => ({ course }));
  },
}));
