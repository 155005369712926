import create from 'zustand';
import { createUserLogError } from '../../../api/common/user_log_errors';
import { TReadyStateLiteral } from '../../../utils/react-use-websocket';

export const useStatistic = create<{
  connectionStatus: TReadyStateLiteral;
  setConnectionStatus: (data: TReadyStateLiteral, user: number) => void;
}>((set) => ({
  connectionStatus: 'Connecting',
  setConnectionStatus: (connectionStatus, user) => {
    set({ connectionStatus });
    if (connectionStatus === 'Closed') createUserLogError(user);
  },
}));
