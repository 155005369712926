import axios from 'axios';
import { IAnswer, IAnswersParams, IParamsUserAnswers, IUserAnswer } from '../../typings/client';
import { toFormData } from '../../utils/api';
import { cleanObject } from '../../utils/object';

export async function getUserAnswers(params?: IParamsUserAnswers) {
  try {
    const { data } = await axios.get<IUserAnswer[]>(`/api/v1/client/testing/user_answer/`, {
      params,
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createUserAnswer(answer: IUserAnswer) {
  const answerData = answer.file ? toFormData(cleanObject({ ...answer, answers: null })) : answer;

  try {
    const { data } = await axios.post<IUserAnswer>(
      '/api/v1/client/testing/user_answer/',
      answerData,
    );

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getAllAnswers(params: IAnswersParams) {
  try {
    const { data } = await axios.get<IAnswer[]>(`/api/v1/client/courses/answer/`, { params });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
