import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { IBookmark } from '../typings/client';
import { ThemeButton } from './Button';
import ThemeInput from './ThemeInput';

/**
 * Форма создания/редактирования закладки
 * @testcases:
 * - при сабмите формы, колбек должен возвращать обьект заполненных полей
 */
const BookmarkForm: React.FC<{
  onClose(): void;
  material: string;
  submit(data: { title: string }): void;
  loading: boolean;
  isIntelleka: boolean;
  page: number;
  edit?: boolean;
  pagesCount?: number;
}> = ({ onClose, material, submit, loading, isIntelleka, page, edit, pagesCount = 5 }) => {
  const { handleSubmit, setValue, control } = useForm<IBookmark>();

  useEffect(() => {
    if (material) {
      setValue('title', material);
      setValue('page', page);
    }
  }, [material, setValue, page]);

  return (
    <div className="px-4 py-3 bg-white rounded" data-testid="bookmark-form">
      <div>Название закладки</div>
      <form onSubmit={handleSubmit(submit)}>
        <div className="flex">
          <Controller
            control={control}
            render={({ field }) => (
              <ThemeInput {...field} isIntelleka={isIntelleka} className="w-full my-4" />
            )}
            name="title"
          />
          {!!edit && (
            <Controller
              control={control}
              render={({ field }) => (
                <ThemeInput
                  {...field}
                  isIntelleka={isIntelleka}
                  className="w-[70px] my-4 ml-2"
                  type="number"
                  max={pagesCount}
                  min={1}
                />
              )}
              name="page"
            />
          )}
        </div>

        <div className="flex">
          <ThemeButton
            className="mr-2"
            type="submit"
            disabled={loading}
            data-testid="bookmark-submit"
            isIntelleka={isIntelleka}
          >
            {loading ? 'Сохранение...' : 'Сохранить'}
          </ThemeButton>
          <ThemeButton isIntelleka={isIntelleka} type="button" onClick={onClose} isSecondary>
            Отмена
          </ThemeButton>
        </div>
      </form>
    </div>
  );
};

export default BookmarkForm;
