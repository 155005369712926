import * as admin from './admin';
import * as curator from './curator';
import * as common from './common';
import * as client from './client';

export const API = {
  admin,
  curator,
  common,
  client,
};
