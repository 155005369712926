import { first } from 'lodash';
import create from 'zustand';
import { ITask, ITestPassing } from '../../../typings/client';
import { stringDateToSeconds, stringToSeconds } from '../../../utils/time';

/** Статусы теста на фронте */
export type ITEST_STATUS = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | null;

// # Статусы прохождения теста, которые приходят с бека

// 0 Тест пройден успешно
// 1 На проверке
// 2 Превышено допустимое время прохождения
// 3 Превышено допустимое кол-во попыток
// 4 Процент прохождения не набран
// 5 тест закончен, но есть неотвеченные вопросы

type ITests = {
  tasksDuration: Record<number, number>;
  taskStatuses?: Record<number, ITEST_STATUS>;
  serverTime?: string;

  setTaskDuration(id: number, duration: number): void;
  decrementTaskDuration(id: number): void;
  setServerTime(time: string): void;
  /**
    * Функция анализирует СТАТУС ДОСТУПНОСТИ ТЕСТА на основе предыдущих попыток:
    * status 0 - попыток сдачи не было, тест доступен
    * status 1 - последняя попытка не закрыта, время на прохождение есть, тест доступен
    * status 2 - последняя попытка не закрыта, время на прохождение закончилось,
                тест доступен только для закрытия попытки (НЕ АКТУАЛЬНО НА СТЕЙДЖ И ПРОД СЕРВЕРАХ)
    * status 3 - последняя попытка закрыта, тест пройден, но доступен для пересдачи.
    * status 4 - последняя попытка закрыта, тест пройден, пересдача не доступна
    * status 5 - последняя попытка закрыта, тест на проверке
    * status 6 - последняя попытка закрыта, тест не пройден, но доступен для пересдачи
    * status 7 - последняя попытка закрыта, тест не пройден, пересдача будет доступна спустя время
    * status 8 - последняя попытка закрыта, тест не пройден, попытки пересдачи закончились
    * status 9 - последняя попытка закрыта и была тестовой
    * @param test - тест
    * @param passings - массив попыток сдачи текущего теста
    * @returns статус 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | null;
   */
  setTestStatus(test: ITask, passings: ITestPassing[]): void;
};

export const useTests = create<ITests>((set, get) => ({
  status: undefined,
  tasksDuration: {},
  setTaskDuration: (id, duration) =>
    set({ tasksDuration: { ...get().tasksDuration, [id]: duration } }),
  decrementTaskDuration: (id) => {
    set({ tasksDuration: { ...get().tasksDuration, [id]: get().tasksDuration[id] - 1 } });
  },
  /** Статус доступности теста */
  setTestStatus: async (test: ITask, passings: ITestPassing[]) => {
    const time = get().serverTime;

    const setStatus = (status: ITEST_STATUS) =>
      set({ taskStatuses: { ...get().taskStatuses, [test.id]: status } });

    if (!passings.length) return setStatus(0);

    const lastPassing = first(passings);

    if (!lastPassing) return setStatus(0);

    const testTravelTime = stringToSeconds(test.travel_time);
    const currentTime = stringDateToSeconds(new Date(`${time}Z`));
    const startTime = stringDateToSeconds(new Date(lastPassing.start_time));
    const timePassedSinceStarted = currentTime - startTime;
    const successPassed = lastPassing.success_passed;
    const hasTimeToFinish = timePassedSinceStarted < testTravelTime;

    if (!lastPassing.finish_time) {
      return setStatus(hasTimeToFinish ? 1 : 2);
    }

    if (lastPassing.is_trial) {
      return setStatus(9);
    }

    switch (successPassed) {
      case 0:
        return setStatus(lastPassing.out_of_time ? 3 : 4);
      case 1:
        return setStatus(5);
      default: {
        const finishTime = stringDateToSeconds(lastPassing.finish_time);
        const retakeSeconds = test.retake_seconds;
        const testPassingsLeft = test.attempts - passings.length;
        if (testPassingsLeft > 0) {
          if (currentTime - finishTime >= retakeSeconds) {
            return setStatus(6);
          } else {
            return setStatus(lastPassing.out_of_time ? 6 : currentTime - finishTime < 0 ? 6 : 7);
          }
        } else {
          return setStatus(8);
        }
      }
    }
  },
  setServerTime: (serverTime) => set({ serverTime }),
}));
