import { useUsersExamParams } from '../store/useUsers';
import AdminRenderUsers from '../containers/AdminRenderUsers';

const AdminUsersExam: React.FC = () => {
  const params = useUsersExamParams();

  return <AdminRenderUsers {...params} />;
};

export default AdminUsersExam;
