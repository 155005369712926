import { useQuery } from 'react-query';
import { API } from '../../../api';
import { IMaterial } from '../../../typings/client';

export const useGetMaterials = (course?: number, onSuccess?: (m: IMaterial[]) => void) => {
  return useQuery(['materials', course], () => API.client.material.getAllMaterials({ course }), {
    enabled: !!course,
    onSuccess,
    staleTime: 30000,
  });
};
