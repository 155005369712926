import { useMatch } from '@tanstack/react-location';
import { useQuery } from 'react-query';
import { API } from '../../../api';
import DetailTestResult from '../../../shared/DetailTestResult';
import { ITest } from '../../../typings/admin';
import { generateUserTestPassingLink } from '../routes';

const AdminTestResult: React.FC<{ test: ITest }> = ({ test }) => {
  const { params } = useMatch();

  const { id, courseId } = params;
  const { data } = useQuery(
    ['test-passings', `test-${test.id}`, `user-${id}`],
    () => API.admin.test_passing.all({ task: test.id, user: id }),
    { enabled: !!test.id && !!id },
  );

  const lastPassing = !!data?.filter((p) => !p.is_trial).length ? data[0] : null;
  const rate = Number(lastPassing?.response_rate?.replace('%', '')) || 0;
  const balls = Math.round(rate / (100 / Number(test.num_questions)));

  return (
    <DetailTestResult
      title={test.title}
      hasResults={lastPassing !== null}
      link={generateUserTestPassingLink(id, courseId, test.id, lastPassing?.id)}
      onCheck={lastPassing?.success_passed === 1}
      isSuccess={lastPassing?.success_passed === 0}
      count={test.num_questions}
      passed={balls || 0}
      percents={lastPassing?.response_rate || '0%'}
    />
  );
};

export default AdminTestResult;
