/**
 * @module file
 * @category общие утилиты
 * @description утилиты по работе с файлами
 */

/**
 * функция преобразует файл изображения в строку base64
 * @param file файл изображения
 * @returns строка base64
 */
export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
