import 'react-date-range/dist/styles.css';
import '../../../css/styles-theme.css';
import { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { checkIsIntelleka } from '../../../utils/link';
import type { Range } from 'react-date-range';
import { DateRange } from 'react-date-range';
import { FaCalendar, FaTimes } from 'react-icons/fa';
import { theme_color, theme_color_i } from '../../../constants';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { useStatistic } from '../store/useStatistic';
import Chart from '../../../shared/Chart';
import { convertTimestampToDate } from '../../../utils/time';
import { API } from '../../../api';
import { useQuery } from 'react-query';

const weekAgo = 1000 * 60 * 60 * 24 * 7;

const RangeWrapper = styled.div<{ isVisible: boolean; isIntelleka: boolean }>`
  width: 100%;
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  position: fixed;
  background: #fff;
  padding: 15px 0 5px;
  box-shadow: 0 0 12px 0px rgb(0 0 0 / 50%);
  right: 0;
  margin: auto;
  left: 0;
  max-width: 333px;
  border-radius: 2px;
  margin-top: -7px;
  .rdrDateDisplayItemActive {
    border-color: ${(props) => (props.isIntelleka ? theme_color_i : theme_color)};
  }
  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge {
    background: ${(props) => (props.isIntelleka ? theme_color_i : theme_color)};
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 5px;
  }
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    background: rgba(255, 255, 255, 0.09);
    position: absolute;
    top: 3px;
    left: 0;
    right: 0;
    bottom: 3px;
    pointer-events: none;
    border-color: ${(props) => (props.isIntelleka ? theme_color_i : theme_color)};
    z-index: 1;
  }
  .rdrDayToday .rdrDayNumber span::after {
    content: '';
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background: ${(props) => (props.isIntelleka ? theme_color_i : theme_color)};
  }
  .rdrDefinedRangesWrapper .rdrStaticRangeSelected {
    color: ${(props) => (props.isIntelleka ? theme_color_i : theme_color)};
    font-weight: 600;
  }
  .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber::after {
    content: '';
    border: 1px solid ${(props) => (props.isIntelleka ? theme_color_i : theme_color)};
    border-radius: 1.333em;
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: 0;
    right: 0;
    background: transparent;
  }
`;

const Calendar: React.FC<{
  setPeriod: (start?: Date, end?: Date) => void;
  dates: { start?: Date; end?: Date };
}> = (props) => {
  const [calendarIsOpen, setCalendarIsOpen] = useState<boolean>(false);

  const [ranges, setRanges] = useState<Range>({
    startDate: props.dates.start,
    endDate: props.dates.end,
  });

  const onSelect = (data: any) => {
    props.setPeriod(data.range1.startDate, data.range1.endDate);
    setRanges(data.range1);
  };

  const isIntelleka = checkIsIntelleka();

  return (
    <>
      <div
        onClick={() => setCalendarIsOpen(!calendarIsOpen)}
        className="mt-[-15px] opacity-50 cursor-pointer transition-all mb-3"
      >
        <div
          className={`${
            calendarIsOpen ? (isIntelleka ? ' bg-active_light_i' : ' bg-active_light_p') : ''
          } mx-2 transition-all rounded-sm flex items-center justify-center ${
            isIntelleka ? ' hover:bg-active_light_i' : ' hover:bg-active_light_p'
          }`}
        >
          {calendarIsOpen ? <FaTimes /> : <FaCalendar />}
          <button className="ml-1">Изменить</button>
        </div>
      </div>
      <RangeWrapper isVisible={calendarIsOpen} isIntelleka={isIntelleka}>
        <DateRange
          editableDateInputs={true}
          onChange={(data) => onSelect(data)}
          moveRangeOnFirstSelection={false}
          ranges={[ranges]}
        />
      </RangeWrapper>
    </>
  );
};

const ChartWrapper: React.FC<{
  dates: { start?: Date; end?: Date };
}> = (props) => {
  const { data: usersHistory, isFetching } = useQuery(['users', props.dates], () =>
    API.admin.online.history({
      min_date: convertTimestampToDate(props.dates.start, 'YYYY-MM-DD'),
      max_date: convertTimestampToDate(props.dates.end, 'YYYY-MM-DD'),
    }),
  );
  return (
    <div className="mr-2 mt-2">
      <Chart history={usersHistory} isFetching={isFetching} />
    </div>
  );
};

const UsersOnline: React.FC = () => {
  let idTimer: number;
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onMouseEnter = () => (idTimer = window.setTimeout(setIsOpen, 250, true));
  const onMouseLeave = () => window.clearTimeout(idTimer);
  const [dates, setDates] = useState<{ start?: Date; end?: Date }>({
    start: new Date(Date.now() - weekAgo),
    end: new Date(),
  });

  useOnClickOutside(ref, () => setIsOpen(false));

  const setPeriod = (start?: Date, end?: Date) => {
    setDates({ start, end });
  };

  const calendar = useMemo(() => <Calendar setPeriod={setPeriod} dates={dates} />, [dates]);

  const { onlineUsers } = useStatistic();

  return (
    <div className="users-online" onMouseOver={onMouseEnter} ref={ref} onMouseLeave={onMouseLeave}>
      <span className="text-white text-[16px] cursor-pointer opacity-80 transition-all absolute top-[-7px] hover:opacity-100">
        ({onlineUsers})
      </span>
      {isOpen && (
        <div className="left-[-150px] top-[-116px] sm:top-[-45px] sm:left-[30px] block absolute bg-white rounded-sm shadow-lg w-auto h-auto max-h-[112px]">
          <ChartWrapper dates={dates} />
          {calendar}
        </div>
      )}
    </div>
  );
};

export default UsersOnline;
