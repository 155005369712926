import { Link } from '@tanstack/react-location';

/**
 * Комонент рендерит заголовок теста с результатом
 * последней сдачи в формате count/passed/percents
 */
const DetailTestResult: React.FC<{
  /** название теста */
  title: string;
  /** общее количество вопросов */
  count: number;
  /** число отвеченных правильно */
  passed: number;
  /** итоговая оценка в процентах */
  percents: string;
  /** доступность результатов */
  hasResults: boolean;
  /** ссылка на таблицу подробных результатов */
  link?: string;
  /** на проверке */
  onCheck: boolean;
  /** успешно сдан */
  isSuccess: boolean;
}> = (props) => {
  return (
    <div className="flex">
      <div className="font-bold">{props.title}</div>

      {props.hasResults && !!props.link && (
        <>
          <div className="mx-1">-</div>
          <Link to={props.link}>
            {props.onCheck ? (
              <div className="text-orange-400"> На проверке</div>
            ) : (
              <div className={`${props.isSuccess ? 'text-green-600' : 'text-red-600'}`}>
                {props.count}/{props.passed}/{props.percents}
              </div>
            )}
          </Link>
        </>
      )}
    </div>
  );
};

export default DetailTestResult;
