import Tooltip from '@mui/material/Tooltip';
import { ReactElement } from 'react';

export interface IContactItemProps {
  /** Ссылка */
  link: string;
  /** Иконка */
  img: string;
  /** Текст */
  text: string;
  /** Текст подсказки */
  tooltip: ReactElement;
  /** Положение подсказки */
  tooltipPlacement?: 'top' | 'bottom';
}

/**
 * Элемент контактной информации
 */
const ContactItem: React.FC<IContactItemProps> = ({ tooltipPlacement = 'bottom', ...props }) => {
  return (
    <Tooltip title={props.tooltip} placement={tooltipPlacement} arrow>
      <a
        href={props.link}
        className="text-md flex items-center text-gray-600 hover:text-gray-600"
        data-testid="contact-link"
      >
        <img src={props.img} alt="info-icon" className="w-[18px] h-[18px] mr-2" />
        <div dangerouslySetInnerHTML={{ __html: props.text }} />
      </a>
    </Tooltip>
  );
};

export default ContactItem;
