import create from 'zustand';
import { IChat, IUnreadMessagesNormalized, IUnreadMessagesResponse } from '../../../typings/common';
import { filterSupportChats } from '../../../utils/chat';

export const useChats = create<{
  unreadClient: number;
  unreadCurator: number;
  unreadTeacher: number;
  normalizedChats: IUnreadMessagesNormalized;
  setNormalizedChats: (
    chats: IUnreadMessagesResponse,
    clientSupportChats: IChat[],
    teacherSupportChats: IChat[],
  ) => void;
}>((set) => ({
  unreadClient: 0,
  unreadCurator: 0,
  unreadTeacher: 0,
  normalizedChats: {},
  setNormalizedChats: (unreadMessages, clientSupportChats, teacherSupportChats) => {
    const normalizedChats = unreadMessages?.chats.reduce(
      (acc, curr) => ({ ...acc, [curr.chat_id]: curr.unread_messages }),
      {} as IUnreadMessagesNormalized,
    );

    const allUnreadChatIds = normalizedChats ? Object.keys(normalizedChats) : [];

    const clientSupportChatIds = filterSupportChats(clientSupportChats)?.map((chat) =>
      String(chat.id),
    );

    const teacherSupportChatIds = filterSupportChats(teacherSupportChats)?.map((chat) =>
      String(chat.id),
    );

    const unreadClientSupportChats = allUnreadChatIds?.filter((chatId) =>
      clientSupportChatIds?.includes(chatId),
    );

    const unreadCuratorSupportChats = allUnreadChatIds?.filter(
      (chatId) =>
        !clientSupportChatIds?.includes(chatId) && !teacherSupportChatIds?.includes(chatId),
    );

    const unreadTeacherSupportChats = allUnreadChatIds?.filter((chatId) =>
      teacherSupportChatIds?.includes(chatId),
    );

    const unreadClient = unreadClientSupportChats.reduce((prev, curentElement: string) => {
      const currentChatsMessagesCount = normalizedChats?.[Number(curentElement)] || 0;
      return prev + currentChatsMessagesCount;
    }, 0);

    const unreadCurator = unreadCuratorSupportChats.reduce((prev, curentElement: string) => {
      const currentChatsMessagesCount = normalizedChats?.[Number(curentElement)] || 0;
      return prev + currentChatsMessagesCount;
    }, 0);

    const unreadTeacher = unreadTeacherSupportChats.reduce((prev, curentElement: string) => {
      const currentChatsMessagesCount = normalizedChats?.[Number(curentElement)] || 0;
      return prev + currentChatsMessagesCount;
    }, 0);
    set({ unreadClient, unreadCurator, normalizedChats, unreadTeacher });
  },
}));
