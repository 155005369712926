import { uniqBy } from 'lodash';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useMatch } from '@tanstack/react-location';

import BaseTemplate from '../../common/containers/BaseTemplate';
import CuratorCourses from '../containers/CuratorCourses';

import { ROOT, USERS, COMPANIES, SUPPORT } from '../routes';
import { IUserCourses } from '../../../typings/curator';
import { useUsersParams } from '../store/useUsersParams';
import SvgList from '../../../react-svg/SvgList';
import SvgBook from '../../../react-svg/SvgBook';
import SvgChat from '../../../react-svg/SvgChat';
import { SidebarLink } from '../../../shared/SidebarLink';
import { useCheckCourses } from '../store/useCheckCourses';
import Pill from '../../../shared/Pill';
import AsideTemplate from '../../common/templates/AsideTemplate';
import SearchForm from '../../../shared/SearchForm';
import { checkIsIntelleka } from '../../../utils/link';
import { API } from '../../../api';
import { useNotificationsStore } from '../../common/store/useNotifications';
import { CURATOR_CACHE, CURATOR_CACHE_OPTIONS } from '../../../cache/curator';

const CuratorTemplate: React.FC = ({ children }) => {
  const { data: user } = useQuery('user', API.common.getCurrentUser, CURATOR_CACHE_OPTIONS);
  const { params: usersParams, updateParam } = useUsersParams();
  const {
    data: pageUsers,
    isLoading,
    refetch,
  } = useQuery(
    [CURATOR_CACHE.USERS, usersParams],
    () => API.curator.getUsers(usersParams),
    CURATOR_CACHE_OPTIONS,
  );

  const { data: allUsers } = useQuery(
    CURATOR_CACHE.USERS,
    () => API.curator.getUsers(),
    CURATOR_CACHE_OPTIONS,
  );

  const { checked, checkAllCourses } = useCheckCourses();

  useEffect(() => {
    refetch();
  }, [usersParams.search, refetch]);

  const match = useMatch();
  const isUsersPage = match.id === ROOT + USERS;

  const curatorUsersData = pageUsers?.results
    .map((user) => user.courses)
    .reduce((previousValue, _, index, self) => {
      return previousValue.concat(self[index]);
    }, []);

  const curatorAllUsersData = allUsers?.results
    .map((user) => user.courses)
    .reduce((previousValue, _, index, self) => {
      return previousValue.concat(self[index]);
    }, []);

  const allCourses: IUserCourses = uniqBy(curatorAllUsersData, 'id');
  const allCoursesList = allCourses.sort((a, b) => a.title.localeCompare(b.title));

  const courses: IUserCourses = uniqBy(curatorUsersData, 'id');

  const coursesList = courses.sort((a, b) => a.title.localeCompare(b.title));

  useEffect(() => {
    const courses = allCoursesList.map((c) => c.id);
    !checked.length && allCoursesList.length && checkAllCourses(courses);
  }, [allCoursesList, checked, checkAllCourses]);

  const { data: unreadMessages, refetch: refetchUnread } = useQuery(
    'unread',
    API.common.getUnreadMessages,
    CURATOR_CACHE_OPTIONS,
  );
  const { notifications } = useNotificationsStore();

  useEffect(() => {
    notifications.length > 0 && refetchUnread();
  }, [notifications.length, refetchUnread]);

  const unreadTotal = unreadMessages?.total_unread_messages;
  const isIntelleka = checkIsIntelleka();

  return (
    <BaseTemplate user={user?.first_name} userStatus={user?.user_status}>
      <AsideTemplate
        className="flex border-t border-gray-default overflow-x-hidden"
        btnClassName="m-2 mb-3"
        sidebar={
          <div className="flex flex-col overflow-hidden border-r border-gray-ultra_light">
            <SidebarLink
              link={ROOT + USERS}
              icon={<SvgList />}
              border={false}
              className="pl-10"
              isIntelleka={isIntelleka}
            >
              Пользователи
            </SidebarLink>
            <SidebarLink
              link={ROOT + COMPANIES}
              icon={<SvgBook />}
              border={false}
              className="pl-10"
              isIntelleka={isIntelleka}
            >
              Доступные компании
            </SidebarLink>
            <SidebarLink
              link={ROOT + SUPPORT}
              icon={<SvgChat />}
              border={false}
              className="pl-10"
              isIntelleka={isIntelleka}
            >
              Служба поддержки {unreadTotal && <Pill>{unreadTotal}</Pill>}
            </SidebarLink>
            {isUsersPage && (
              <>
                <SearchForm
                  className="flex items-center justify-center my-3"
                  submit={({ search }) => updateParam('search', search)}
                  classNameInput="w-56"
                  isIntelleka={isIntelleka}
                  loading={isLoading}
                />
                <CuratorCourses courses={coursesList} />
              </>
            )}
          </div>
        }
      >
        {children}
      </AsideTemplate>
    </BaseTemplate>
  );
};

export default CuratorTemplate;
