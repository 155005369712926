import { useEffect } from 'react';
import { first } from 'lodash';
import { useQuery } from 'react-query';
import { ITask } from '../../../typings/client';
import { useTest } from '../store/useTest';
import { secondsToTime } from '../../../utils/time';
import TaskHint from '../../../shared/TaskHint';
import { useTests } from '../store/useTests';
import { getTimePassingLeft } from '../../../utils/passing';
import { checkIsIntelleka } from '../../../utils/link';
import { API } from '../../../api';
import { ThemeButton } from '../../../shared/Button';
import ClientRepassCounter from './ClientRepassCounter';

const ClientTestButton: React.FC<{
  task: ITask;
  setTask(): void;
  sidebar?: boolean;
  noTitle?: boolean;
}> = ({ task, setTask, sidebar = false, noTitle = false }) => {
  const { ui, task: testIsOpen, setUi } = useTest();
  const { tasksDuration, decrementTaskDuration, setTaskDuration, taskStatuses, setTestStatus } =
    useTests();

  const { data: passings, refetch } = useQuery(['test-passings', task.id], () =>
    API.client.test_passing.getAllTestPassings({ task: task.id }),
  );

  let timeout: number;

  const lastPassing = first(passings);

  const closeAttempt = async () => {
    if (lastPassing) {
      await API.client.test_passing.endTestPassing(lastPassing?.id);
      await refetch();
      clearTimeout(timeout);
      testIsOpen && setUi('results');
    }
  };

  useEffect(() => {
    passings && setTestStatus(task, passings);
  }, [task, passings, setTestStatus]);

  const timeLeft = tasksDuration[task.id];

  const status = taskStatuses && taskStatuses[task.id];

  useEffect(() => {
    if (status === 1) {
      if (timeLeft > 0) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        timeout = window.setTimeout(() => decrementTaskDuration(task.id), 1000);
      }

      if (timeLeft === 0) closeAttempt();
      return () => clearTimeout(timeout);
    }
  }, [timeLeft, status, ui, decrementTaskDuration, task.id]);

  useEffect(() => {
    if (status === 1) {
      const start_time = lastPassing?.start_time;
      const travel_time = task?.travel_time;
      if (start_time && travel_time) {
        setTaskDuration(task.id, getTimePassingLeft(start_time, travel_time));
      }
    }
  }, [lastPassing, task, status, setTaskDuration]);

  const isIntelleka = checkIsIntelleka();

  if (typeof status !== 'number') return <div className="font-bold">{task.title}</div>;

  const newPassing = () => (
    <>
      <ThemeButton isIntelleka={isIntelleka} onClick={setTask}>
        Пройти тестирование
      </ThemeButton>
      <TaskHint prepared_text={task?.prepared_text ?? ''} />
    </>
  );

  if (task.is_hidden && status !== 0 && status !== 9)
    return (
      <>
        <div className="font-bold">{task.title}</div>
        <div className="text-orange-400">Тест на проверке</div>
      </>
    );

  const taskInfo = () => {
    switch (status) {
      case 0:
      case 9:
        return newPassing();
      case 1:
        return (
          <>
            <div className="text-active_p">
              Вы прервали тестирование. Вам необходимо успеть вернуться к тестированию и завершить
              его за оставшееся время: {secondsToTime(timeLeft)}
            </div>
            {sidebar ? (
              <ThemeButton onClick={setTask} isIntelleka={isIntelleka}>
                Пересдать тестирование
              </ThemeButton>
            ) : (
              <>
                <ThemeButton isIntelleka={isIntelleka} onClick={setTask}>
                  Продолжить тестирование
                </ThemeButton>
                <TaskHint prepared_text={task?.prepared_text ?? ''} />
              </>
            )}
          </>
        );
      case 2:
        return (
          <>
            <div className="text-active_p">
              Вы прервали тестирование. Время на прохождение истекло
            </div>
            {sidebar ? (
              <ThemeButton onClick={setTask} isIntelleka={isIntelleka}>
                Пересдать тестирование
              </ThemeButton>
            ) : (
              <ThemeButton isIntelleka={isIntelleka} onClick={setTask}>
                Закончить тест
              </ThemeButton>
            )}
          </>
        );
      case 3:
        return (
          <>
            {sidebar ? (
              <ThemeButton onClick={setTask} isIntelleka={isIntelleka}>
                Пересдать тестирование
              </ThemeButton>
            ) : (
              <ThemeButton isIntelleka={isIntelleka} onClick={setTask}>
                Пересдать тестирование
              </ThemeButton>
            )}
            <div className="text-green-600">
              Результат предыдущей сдачи: {lastPassing?.response_rate}
            </div>
          </>
        );
      case 4:
        return (
          <div className="text-green-600">Успешно сдан: {lastPassing?.response_rate} правильно</div>
        );
      case 5:
        return <div className="text-orange-400">Тест на проверке</div>;
      case 6:
        return (
          <>
            {sidebar ? (
              <ThemeButton onClick={setTask} isIntelleka={isIntelleka}>
                Пересдать тестирование
              </ThemeButton>
            ) : (
              <ThemeButton isIntelleka={isIntelleka} onClick={setTask}>
                Пересдать тестирование
              </ThemeButton>
            )}
            <div className="text-active_p">
              Результат последней сдачи: Тест не зачтён
              <span className="pl-1 pr-1">{lastPassing?.response_rate || 0}</span>
              правильно.
            </div>
            <TaskHint prepared_text={task?.prepared_text ?? ''} />
          </>
        );
      case 7:
        return (
          <>
            {sidebar ? (
              <ThemeButton isIntelleka={isIntelleka} disabled>
                Пересдать тестирование
              </ThemeButton>
            ) : (
              <ThemeButton isIntelleka={isIntelleka} disabled={true}>
                Пересдать тестирование
              </ThemeButton>
            )}
            <div className="text-active_p">
              Тест не зачтён: {lastPassing?.response_rate || 0} правильно. <br />
              <span className="pr-1">Пересдача будет доступна через</span>
              <ClientRepassCounter
                retakeSeconds={Number(task.retake_seconds)}
                lastPassingDate={lastPassing?.finish_time}
              />
            </div>
            <TaskHint prepared_text={task?.prepared_text ?? ''} />
          </>
        );
      case 8:
        return (
          <>
            {sidebar ? (
              <ThemeButton isIntelleka={isIntelleka} disabled>
                Пересдать тестирование
              </ThemeButton>
            ) : (
              <ThemeButton isIntelleka={isIntelleka} disabled={true}>
                Пересдать тестирование
              </ThemeButton>
            )}
            <div className="text-active_p">
              Все попытки исчерпаны {task.attempts} из {task.attempts}
            </div>
          </>
        );
    }
  };

  return (
    <div>
      {!noTitle && <div className="font-bold">{task.title}</div>}
      <div>{taskInfo()}</div>
    </div>
  );
};

export default ClientTestButton;
