/** Компонент заголовка курса */
const CourseHeader: React.FC<{ title?: string; isIntelleka?: boolean; description?: string }> = ({
  title = '...',
  isIntelleka = false,
  description,
}) => {
  return (
    <div
      className={`flex items-center ${
        isIntelleka ? 'gradient_i' : 'gradient_p'
      } justify-center min-h-[185px] pb-[50px]`}
    >
      <div className="py-12 text-white max-w-[900px] text-center leading-[38px]">
        <div className="text-lg lg:text-[35px]">{title}</div>
        <div className="text-[21px] text-yellow-100">{description}</div>
      </div>
    </div>
  );
};

export default CourseHeader;
