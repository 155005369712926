import { useMutation } from 'react-query';
import { API } from '../../../api';
import { TPagination, useCheck, useNotifications } from '../../../ui/admin/store/useUsers';
import { queryClient } from '../../../hooks/queryClient';
import { getKey } from './keys';
import { AdminUsersStore } from './typings';

export function useDeleteAdminUsers(params: TPagination) {
  const key = getKey(params);
  const { setRemovedUser, setFailedRemoveUser, setConfirmVisible } = useNotifications();
  const { setChecked } = useCheck();
  const users = queryClient.getQueryData<AdminUsersStore>(key);

  return useMutation<unknown, unknown, number>((user) => API.admin.user.remove(user), {
    onSuccess: (_, user) => {
      /** обновление кеша пользователей */
      users && setRemovedUser(users.usersById[user].username);
      queryClient.refetchQueries(key);
      /** сброс кеша отмеченных пользователей */
      setChecked([]);
      /** закрытия окна подтверджения */
      setConfirmVisible(undefined);
    },
    onError: (_, user) => users && setFailedRemoveUser(users.usersById[user].username),
  });
}
