import caret from '../../../img/arrow.png';
import ArrowLeft from '../../../shared/ArrowLeft';
import { checkIsIntelleka } from '../../../utils/link';

const ClientCollapseCaret: React.FC<{ isOpen: boolean; sidebar?: boolean }> = ({
  isOpen,
  sidebar,
}) => {
  const isIntelleka = checkIsIntelleka();

  return sidebar ? (
    <div
      className="origin-center absolute transition-all duration-300"
      style={{
        transform: `translateY(3.5px) rotate(${isOpen ? '-90deg' : '-180deg'})`,
        left: 5,
        top: 24,
      }}
    >
      <ArrowLeft isIntelleka={isIntelleka} />
    </div>
  ) : (
    <img
      src={caret}
      alt="arrow-toggle"
      className={`w-[12.3px] h-[7.4px] absolute ${
        sidebar ? 'left-0 top-[31px]' : 'right-0 top-[50%]'
      }  opacity-60 transition-all`}
      style={{ transform: `translateY(-50%) rotate(${isOpen ? '0deg' : '-180deg'})` }}
    />
  );
};

export default ClientCollapseCaret;
