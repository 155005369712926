import { BsArrowBarRight } from 'react-icons/bs';
import { useWindowSize } from 'react-use';
import { useMaterial } from '../store/useMaterial';

const ClientToggleSidebarIcon: React.FC = () => {
  const { width: windowWidth } = useWindowSize(window.innerWidth);
  const { toggleSidebar, isSidebarVisible } = useMaterial();

  return windowWidth < 991 ? (
    !isSidebarVisible ? (
      <div className="fixed top-24 left-0 bg-[rgba(255,255,255,.7)] text-lg">
        <BsArrowBarRight onClick={() => toggleSidebar(!isSidebarVisible)} />
      </div>
    ) : null
  ) : null;
};

export default ClientToggleSidebarIcon;
