import { forwardRef } from 'react';

type TProps = { isIntelleka: boolean; className?: string } & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

type Ref = HTMLTextAreaElement;

const ThemeTextarea = forwardRef<Ref, TProps>(({ isIntelleka, className = '', ...props }, ref) => {
  return (
    <textarea
      {...props}
      className={`${isIntelleka ? 'input-i' : 'input'} ${className}`}
      ref={ref}
    />
  );
});

export default ThemeTextarea;
