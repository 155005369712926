import { useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import { FaCheck, FaSpinner } from 'react-icons/fa';
import styled from 'styled-components';
import ThemeInput from './ThemeInput';

const UserItem = styled.div<{ checked: boolean }>`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => (props.checked ? '#f1f1f1' : '#fff')};
  padding: 5px;
  cursor: pointer;
  transition: ease-in-out 0.3s;
  &:hover {
    background: ${(props) => (props.checked ? '#f1f1f1' : '#f9f9f9')};
  }
`;

const SFaCheck = styled(FaCheck)`
  color: green;
  margin-right: 5px;
`;

const CollapseTitle: React.FC<{ onClick(): void }> = ({ children, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="bg-gray-100 p-2 rounded mb-2 cursor-pointer hover:bg-gray-200 transition-all"
    >
      {children}
    </div>
  );
};

const QuickLoginItem: React.FC<{
  username: string;
  onClick: () => void;
  checked: boolean;
}> = (props) => {
  const { username, checked, onClick } = props;
  return (
    <UserItem onClick={onClick} checked={checked}>
      <div>{username}</div>
      <div>{checked ? <SFaCheck /> : ''}</div>
    </UserItem>
  );
};

/**
 * Компонент с выпадающим списком,
 * возможностью выбрать пользователя
 * и задать пароль для настроек быстрого доступа
 */
const OneClickSetting: React.FC<{
  /** имя выбранного пользователя */
  userName?: string;
  /** индикатор загрузки пользователей */
  loading: boolean;
  /** список пользователей */
  list?: { id: number; username: string; old_password: string }[];
  /** колбек при выборе пользователя */
  onChose(isChecked: boolean, username: string): void;
  /** колбек при изменении текстового поля */
  onChangeInput?(e: string): void;
  /** пароль */
  password?: string;
  /** интерфейс интелеки */
  isIntelleka?: boolean;
  /** заглавие блока (администратор/модератор/куратор/пользователь) */
  label: string;
  /** индикатор обновления */
  updating?: boolean;
}> = ({ onChangeInput, userName = 'не выбран', updating = false, ...props }) => {
  const [opened, toggle] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    props.password && setInputValue(props.password);
  }, [props.password]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (onChangeInput && !!inputValue && inputValue !== props.password) {
        onChangeInput(inputValue);
      }
    }, 500);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <div className={`${updating ? 'opacity-50' : 'opacity-100'}`}>
      <CollapseTitle onClick={() => toggle(!opened)}>
        <div className="flex">
          {props.label}
          {updating ? (
            <FaSpinner className="animate-spin mt-1 ml-1" />
          ) : (
            <div className="ml-1">{userName ? `(${userName})` : '(не выбран)'}</div>
          )}
        </div>
      </CollapseTitle>
      <Collapse isOpened={opened}>
        <div>
          {props.loading
            ? 'Загрузка...'
            : props.list?.map((u) => {
                const isChecked = u.username === userName;
                return (
                  <QuickLoginItem
                    key={u.id}
                    checked={isChecked}
                    username={u.username}
                    onClick={() => props.onChose(isChecked, u.username)}
                  />
                );
              })}
          {onChangeInput && (
            <ThemeInput
              className="w-full my-2"
              placeholder="Пароль"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              isIntelleka={!!props.isIntelleka}
            />
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default OneClickSetting;
