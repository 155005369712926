import axios from 'axios';
import { ITest, ITestParams, TCreateTask, TUpdateTask } from '../../typings/admin';

export async function all(params?: ITestParams) {
  try {
    const { data } = await axios.get<ITest[]>(`/api/v1/courses/task/`, { params });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function byId(id?: string) {
  if (!id) return;
  try {
    const { data } = await axios.get<ITest>(`/api/v1/courses/task/${id}/`);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function create(model: TCreateTask) {
  try {
    const activeTask = { ...model, is_active: true };
    const { data } = await axios.post<ITest>('/api/v1/courses/task/', activeTask);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function update({ id, ...model }: TUpdateTask) {
  try {
    const { data } = await axios.put<ITest>(`/api/v1/courses/task/${id}/`, model);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function change({ id, ...model }: TUpdateTask) {
  try {
    const { data } = await axios.patch<ITest>(`/api/v1/courses/task/${id}/`, model);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function remove(id: number | string) {
  try {
    const { data } = await axios.delete(`/api/v1/courses/task/${id}/`);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
