import translations from './translations';
import { TiInfoOutline } from 'react-icons/ti';
import { Tooltip } from '@mui/material';

const Notice: React.FC = () => {
  return (
    <Tooltip arrow title={<div className="tooltip-summary">{translations.ru}</div>}>
      <div>
        <TiInfoOutline className="ml-1 text-yellow-500" />
      </div>
    </Tooltip>
  );
};

export default Notice;
