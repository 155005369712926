import { useMatch } from '@tanstack/react-location';
import { FaTimes } from 'react-icons/fa';
import ConfirmModal from '../../../shared/ConfirmModal';
import Table from '../../../shared/Table';
import { checkIsIntelleka } from '../../../utils/link';
import AdminAddQuestion from '../containers/AdminAddQuestion';
import AdminEditQuestion from '../containers/AdminEditQuestion';
import AdminCourseTemplate from '../templates/AdminCourseTemplate';
import { maxStringLength } from '../../../utils/string';
import {
  getQuestionsKey,
  useQuestionsByTest,
  useRemoveQuestion,
} from '../../../cache/admin/question';
import { useQuestions } from '../store/useQuestions';
import { Tooltip } from '@mui/material';

const titles = ['№', 'Вопрос', 'Баллы за вопрос (максимальное кол-во)', 'Тип вопроса', 'Действия'];

const AdminTestQuestions: React.FC = () => {
  const match = useMatch();
  const testId = match.params.testId;
  const questionsKey = getQuestionsKey(testId);
  const { confirm, setConfirm } = useQuestions();

  const { data, isFetching } = useQuestionsByTest(testId);

  const { mutate: remove, isLoading: removing } = useRemoveQuestion(testId);

  const isIntelleka = checkIsIntelleka();

  const questions = data?.map((q, i) => [
    i + 1,
    <>
      {q.text.length >= 50 ? (
        <Tooltip title={<div className="text-sm text-center">{q.text}</div>} arrow placement="top">
          <div>{maxStringLength(q.text, 50)}</div>
        </Tooltip>
      ) : (
        <div>{maxStringLength(q.text, 50)}</div>
      )}
    </>,
    q.score,
    <>{q.is_free_answer ? 'С развернутым ответом' : 'С выбором ответа'}</>,
    <div className="flex justify-end lg:justify-center">
      <AdminEditQuestion questionsKey={questionsKey} question={q} />
      <FaTimes
        onClick={() => setConfirm(q.id)}
        className={`ml-[2px] cursor-pointer ${
          isIntelleka ? 'hover:text-active_i' : 'hover:text-active_p'
        } transition-all`}
      />
      <ConfirmModal
        isIntelleka={isIntelleka}
        confirm={() => remove(q.id)}
        isOpen={q.id === confirm}
        hide={() => setConfirm(undefined)}
        loading={removing}
      >
        <div className="text-left">Вы действительно хотите удалить вопрос?</div>
      </ConfirmModal>
    </div>,
  ]);

  return (
    <AdminCourseTemplate>
      <div className="px-4 lg:px-0">
        <div className="mb-1">
          <AdminAddQuestion questionsKey={questionsKey} />
        </div>
        <Table titles={titles} rows={questions} isLoading={!data && isFetching} />
      </div>
    </AdminCourseTemplate>
  );
};

export default AdminTestQuestions;
