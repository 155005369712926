/**
 * @module statistic
 * @category общие утилиты
 * @description утилиты по работе со статистикой пользователя
 */

import { reduce } from 'lodash';
import type { IStatisticUser } from '../typings/client';
import type { IUserDevice } from '../typings/admin';
import moment from 'moment';
import { onlyUnique } from './array';

/**
 * Функция определяет процент прохождения курса пользователем
 * на основе даты начала и окончания обучения,
 * а также сегодняшней даты относительно этого срока
 * @param startDate дата начала обучения в формате YYYY-MM-DD
 * @param endDate дата окончания обучения в формате YYYY-MM-DD
 * @returns Возвращает процент прохождения курса
 */
export const getStuddingPosition = (startDate: string, endDate: string) => {
  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();
  const current = new Date().getTime();
  const fullTerm = end - start;
  const passed = current - start;
  if (end < current) return 100;
  return Math.round(passed / (fullTerm / 100));
};

/**
 * Функция преобразует данные статистики пользователя в читаемый вид
 * @param userActivity данные с сервера
 * @returns строка статистики
 */
export const getUserActivityFormatted = (userActivity?: IStatisticUser) => {
  if (!userActivity) return null;
  const seconds = reduce(userActivity, (sum, currentValue) => sum + currentValue.seconds, 0);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const leftMinutes = minutes % 60;
  const getFormattedNumber = (number: number) =>
    number.toString().length === 1 ? `0${number}` : number;

  return `${getFormattedNumber(hours)}:${getFormattedNumber(leftMinutes)}`;
};

/**
 * Функция сортирует данные пользовательской активности по дате
 * @param activity данные статистики
 * @returns Возвращает массив данных, отсортированный по дате
 */
export const userActivitySortByDate = (activity?: IStatisticUser) =>
  activity
    ?.map((el) => ({ ...el, unixDate: new Date(el.day).getTime() }))
    .sort((a, b) => a.unixDate - b.unixDate);

/**
 * Функция фильтрует массив детальной активности по уникальным дням
 * @param activity массив детальной активности
 * @returns Возвращает отфильтрованный массив детальной активности
 **/
export const filterUniqDaysActivity = (activity?: IUserDevice[]) => {
  const res = activity?.filter((el, i, all) => {
    const currentDay = moment(el.payload.time).format('DD.MM.YYYY');
    const prevDay = all[i - 1] && moment(all[i - 1].payload.time).format('DD.MM.YYYY');
    return prevDay !== currentDay;
  });

  const withDay = res?.map((el) => {
    return { ...el, day: moment(el.payload.time).format('DD.MM.YYYY') };
  });

  const uniqDays = withDay?.map((el) => el.day).filter(onlyUnique);
  return uniqDays?.map((day) => withDay?.find((el) => el.day === day)).filter((el) => !!el) || [];
};

/**
 * Функция выбирает из массива автивности элементы по конкретному дню
 * @param activity массив автивности
 * @param day день
 * @returns элементы активности по дню
 */
export const filterActivityByDay = (activity: IUserDevice[], day: string) => {
  return activity
    ?.filter((el) => el.payload.os)
    .filter((el) => {
      const currentDay = moment(el.payload.time).format('DD.MM.YYYY');
      if (currentDay === day) {
        return el;
      } else return null;
    });
};
