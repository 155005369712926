import { TCreateUser, TUpdateUser } from '../../../typings/admin';
import { useForm, Controller } from 'react-hook-form';
import RadioToggle from '../../../shared/RadioToggle';
import { useEffect } from 'react';
import RSelect from '../../../shared/ReactSelect';
import { useQuery } from 'react-query';
import { getDataForSelect } from '../../../utils/array';
import { checkIsIntelleka } from '../../../utils/link';
import { API } from '../../../api';
import { ThemeButton } from '../../../shared/Button';
import ThemeInput from '../../../shared/ThemeInput';
import { Alert } from '@mui/material';

const AdminMasterForm: React.FC<{
  submit: (data: TCreateUser & TUpdateUser) => void;
  edit?: TUpdateUser;
  loading: boolean;
  onClose(): void;
  open: boolean;
  curator?: boolean;
  error?: string;
  setError?: (e: string) => void;
}> = ({ submit, loading, onClose, open, edit, curator, error, setError }) => {
  const { handleSubmit, setValue, control } = useForm<TCreateUser & TUpdateUser>();

  const { data: companies } = useQuery('companies', () => API.admin.company.all());

  const isIntelleka = checkIsIntelleka();

  useEffect(() => {
    if (!!edit) {
      setValue('first_name', edit.first_name);
      setValue('username', edit.username || '');
      setValue('password', edit.old_password || '');
      setValue('description', edit.description);
      setValue('id', edit.id);
      setValue('is_active', edit.is_active);
      curator && setValue('curator_company', edit.curator_company);
    } else {
      open && setValue('is_active', true);
    }
  }, [open, setValue, edit, curator]);

  const selectCompanies = companies?.map(getDataForSelect);

  return (
    <form onSubmit={handleSubmit(submit)} className="flex flex-col text-left">
      <label className="mb-5 block">
        <div className="mb-1">ФИО</div>
        <Controller
          control={control}
          render={({ field }) => (
            <ThemeInput {...field} className="w-full" isIntelleka={isIntelleka} autoFocus />
          )}
          name="first_name"
        />
      </label>
      <div className="flex justify-between">
        <label className="mb-5 w-[calc(50%-15px)]">
          <div className="mb-1">Логин</div>
          <Controller
            control={control}
            render={({ field }) => (
              <ThemeInput {...field} className="w-full" isIntelleka={isIntelleka} />
            )}
            name="username"
          />
        </label>
        <label className="mb-5 w-[calc(50%-15px)]">
          <div className="mb-1">Пароль</div>
          <Controller
            control={control}
            render={({ field }) => (
              <ThemeInput {...field} className="w-full" isIntelleka={isIntelleka} />
            )}
            name="password"
          />
        </label>
      </div>
      {curator && (
        <label className="mb-3 block">
          <div className="mb-1">Компании</div>
          <Controller
            control={control}
            render={({ field: { name, value } }) => {
              return (
                <RSelect
                  name={name}
                  options={selectCompanies}
                  onChange={(data) =>
                    Array.isArray(data) &&
                    setValue(
                      name,
                      data.map((m) => m.value),
                    )
                  }
                  value={selectCompanies?.filter((t) => value?.includes(t.value))}
                  isMulti
                  isIntelleka={isIntelleka}
                />
              );
            }}
            name="curator_company"
          />
        </label>
      )}
      <label className="mb-5 block">
        <div className="mb-1">Примечание</div>
        <Controller
          control={control}
          render={({ field }) => (
            <ThemeInput
              {...field}
              value={field.value || ''}
              className="w-full"
              isIntelleka={isIntelleka}
            />
          )}
          name="description"
        />
      </label>

      <label className="block">
        <Controller
          control={control}
          render={({ field: { name, value } }) => {
            return (
              <RadioToggle
                label="Активен"
                checked={!!value}
                onChange={() => setValue(name, !value)}
                isIntelleka={isIntelleka}
              />
            );
          }}
          name="is_active"
        />
      </label>
      <div className="mt-5 flex justify-start">
        <ThemeButton
          type="button"
          className="mr-2"
          onClick={onClose}
          isIntelleka={isIntelleka}
          isSecondary
        >
          Отменить
        </ThemeButton>
        <ThemeButton type="submit" isIntelleka={isIntelleka} disabled={loading}>
          {loading ? 'Сохранение...' : 'Сохранить'}
        </ThemeButton>
      </div>
      {!!error && setError && (
        <Alert color="error" className="notification" onClose={() => setError('')}>
          {error}
        </Alert>
      )}
    </form>
  );
};

export default AdminMasterForm;
