import LogOut from './LogOut';

/**
 * Контейнер рендера информации пользователя в хедере
 */
const CurrentUserInfo: React.FC<{
  /** Имя */
  name?: string;
  /** Описание */
  description?: string | React.ReactElement;
  /** Колбек при клике а описание */
  onClickSettings?: () => void;
  /** Колбек при логауте */
  onLogout: () => void;
  /** Интерфейс администратора */
  isAdmin?: boolean;
}> = ({ name = '', onClickSettings = () => null, ...props }) => {
  return (
    <div className="flex flex-col items-start">
      <LogOut userName={name} onClick={props.onLogout} />
      {props.description && (
        <div
          className={`text-[#535658] opacity-50 text-md text-right ${
            props.isAdmin ? 'cursor-pointer hover:text-gray-900' : 'cursor-default'
          }`}
        >
          <span onClick={onClickSettings}>{props.description}</span>
        </div>
      )}
    </div>
  );
};

export default CurrentUserInfo;
