import { useQuery } from 'react-query';
import { getCurrentUser } from '../../../api/common';
import BaseTemplate from '../../common/containers/BaseTemplate';
import AdminMenu from '../containers/AdminMenu';
import { useLocation } from 'react-use';
import {
  ADMIN_COMPANIES,
  ADMIN_USERS,
  ADMIN_MODERATORS,
  ADMIN_CURATORS,
  ADMIN_USERS_CHECKING,
  ADMIN_USERS_EXAM,
  ADMIN_SETTINGS,
} from '../routes';
import AdminUsersMenu from '../containers/AdminUsersMenu';
import { Link } from '@tanstack/react-location';
import { hasLocationMatches } from '../../../utils/link';

const AdminTemplate: React.FC = ({ children }) => {
  const { data: user } = useQuery('user', getCurrentUser);

  const { pathname } = useLocation();

  const isMaterialsPage = hasLocationMatches('admin/courses');
  const isMessagesPage = hasLocationMatches('admin/messages');

  const hasMenu =
    pathname === ADMIN_COMPANIES ||
    pathname === ADMIN_USERS ||
    pathname === ADMIN_MODERATORS ||
    pathname === ADMIN_CURATORS ||
    pathname === ADMIN_USERS_CHECKING ||
    pathname === ADMIN_USERS_EXAM;

  return (
    <BaseTemplate
      user={user?.username}
      userStatus={user?.user_status}
      description={
        <>
          {user?.user_status === 0 ? (
            <Link to={ADMIN_SETTINGS}>Администратор</Link>
          ) : (
            'Преподаватель'
          )}
        </>
      }
    >
      <AdminMenu />
      <div className={`${isMaterialsPage || isMessagesPage ? 'py-4' : 'p-4'} lg:p-8`}>
        {hasMenu && <AdminUsersMenu />}
        <div>{children}</div>
      </div>
    </BaseTemplate>
  );
};

export default AdminTemplate;
