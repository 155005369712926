import create from 'zustand';

export const useQuestions = create<{
  open: boolean;
  toggle: (open: boolean) => void;

  error: string;
  setError(error: string): void;

  confirm?: number;
  setConfirm(data?: number): void;
}>((set) => ({
  open: false,
  toggle: (open) => set({ open }),

  error: '',
  setError: (error) => set({ error }),

  setConfirm: (confirm) => set({ confirm }),
}));
