import { Skeleton } from '@mui/material';
import { times } from 'lodash';

const deviderHeight = 25;

/** Прелоадер прогресбара обучения */
const ProgressbarPreloader: React.FC = () => {
  return (
    <div className="mt-4 flex justify-between items-center">
      <div className="w-[5px]">
        <Skeleton height={deviderHeight} />
      </div>
      {times(7).map((el, i) => {
        return (
          <div key={el} className={`${i % 2 ? 'w-[5px]' : 'w-full'}`}>
            <Skeleton height={i % 2 ? deviderHeight : 10} />
          </div>
        );
      })}
      <div className="w-[5px]">
        <Skeleton height={deviderHeight} />
      </div>
    </div>
  );
};

export default ProgressbarPreloader;
