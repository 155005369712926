import { useQuery } from 'react-query';
import { useCourse } from '../store/useCourse';
import { useMaterial } from '../store/useMaterial';
import ClientMaterialRender from './ClientMaterialRender';
import { useWindowSize } from 'react-use';
import { useEffect } from 'react';
import { API } from '../../../api';
import ClientMaterialSidebar from './ClientMaterialSidebar';
import Modal from '../../../shared/Modal';

const ClientMaterialView: React.FC = () => {
  const { setId, id, isSidebarVisible, toggleSidebar } = useMaterial();
  const { course } = useCourse();
  const { data: materials } = useQuery(
    ['materials', course],
    () => API.client.material.getAllMaterials({ course }),
    {
      enabled: !!course,
    },
  );

  const { width: windowWidth } = useWindowSize(window.innerWidth);

  useEffect(() => {
    windowWidth < 992 ? toggleSidebar(false) : toggleSidebar(true);
  }, [windowWidth, toggleSidebar]);

  useEffect(() => {
    id && windowWidth < 992 && toggleSidebar(false);
  }, [id, toggleSidebar, windowWidth]);

  return (
    <Modal open={!!id} onClose={() => setId()}>
      <div className="flex bg-white" id="material-view">
        <ClientMaterialSidebar materials={materials} windowWidth={windowWidth} />
        {id && (
          <div
            className={`${
              isSidebarVisible ? 'w-0 lg:w-[calc(100%-360px)]' : 'w-full'
            } transition-all duration-500 `}
          >
            <ClientMaterialRender materialId={id} />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ClientMaterialView;
