import SvgClose from '../react-svg/SvgClose';

const FilterIndicator: React.FC<{
  onClick(): void;
  align?: 'start' | 'center' | 'end';
  isIntelleka?: boolean;
}> = ({ onClick, isIntelleka = false, children, align = 'start' }) => {
  return (
    <div
      className="flex relative items-center"
      style={{ justifyContent: align === 'center' ? 'center' : `flex-${align}` }}
    >
      <div
        className={`${
          isIntelleka ? 'bg-active_i' : 'bg-active_p'
        } text-white rounded-l-md pl-2 pb-[2px] pr-[3px]`}
      >
        {children}
      </div>
      <div
        className={`${
          isIntelleka
            ? 'bg-active_i hover:bg-active_i_hover'
            : 'bg-active_p hover:bg-active_p_hover'
        } rounded-r-md cursor-pointer transition-all `}
      >
        <SvgClose onClick={onClick} className="h-full w-[25px] p-[5px] pl-[4px]" />
      </div>
    </div>
  );
};

export default FilterIndicator;
