import { useNavigate } from '@tanstack/react-location';
import Cookie from 'js-cookie';
import Header from '../../../shared/Header';
import Footer from '../../../shared/Footer';
import CurrentUserInfo from '../../../shared/CurrentUserInfo';
import { queryClient } from '../../../hooks/queryClient';
import React from 'react';
import { useLightbox } from '../store/useLightbox';
import LightBox from '../../../shared/LightBox';
import OneClickLogin from '../../admin/containers/AdminOneClick';
import { isCurrentBuildProd } from '../../../constants';
import { checkIsIntelleka } from '../../../utils/link';
import { useNotificationsStore } from '../store/useNotifications';
import NotificationItem from '../../../shared/NotificationItem';
import { generateChat } from '../../admin/routes';
import { ROOT as CLIENT_ROOT, TEACHER, SUPPORT } from '../../client/routes';
import { ROOT as CURATOR_ROOT, SUPPORT as CURATOR_SUPPORT } from '../../curator/routes';

const BaseTemplate: React.FC<{
  user?: string;
  description?: string | React.ReactElement;
  userStatus?: number;
}> = (props) => {
  const navigate = useNavigate();
  const { image, setImage } = useLightbox();

  const logout = () => {
    Cookie.remove('access');
    navigate({ to: '/', replace: true });
    queryClient.clear();
  };

  const isIntelleka = checkIsIntelleka();
  const { notifications, removeNotification } = useNotificationsStore();

  return (
    <>
      <div className="fixed left-3 bottom-3 z-[9999999] ">
        {notifications.map((n, i) => {
          return (
            <NotificationItem
              key={i}
              author={n.author}
              message_text={n.message_text}
              onClick={() =>
                navigate({
                  to:
                    props.userStatus === 3
                      ? CURATOR_ROOT + CURATOR_SUPPORT
                      : props.userStatus === 2
                      ? n.chat_status === 1
                        ? CLIENT_ROOT + TEACHER
                        : CLIENT_ROOT + SUPPORT
                      : generateChat(n.chat_status === 0, n.chat_id),
                })
              }
              onClose={() => removeNotification(n.message_id)}
            />
          );
        })}
      </div>
      <div className={`${props.userStatus === 2 ? 'min-h-[calc(100vh-91px)]' : 'min-h-[100vh]'}`}>
        <Header
          userInfo={
            <CurrentUserInfo
              name={props.user}
              description={props.description}
              onLogout={logout}
              onClickSettings={() => null}
              isAdmin={props.userStatus === 0}
            />
          }
          isIntelleka={isIntelleka}
        />
        <main className={`${props.userStatus === 2 ? '' : 'pb-[135px]'}`}>{props.children}</main>
      </div>

      <Footer isIntelleka={isIntelleka} userStatus={props.userStatus} />

      {image && <LightBox mainSrc={image} onCloseRequest={() => setImage('')} />}
      {!isCurrentBuildProd && <OneClickLogin userStatus={props.userStatus || 0} />}
    </>
  );
};

export default BaseTemplate;
