import create from 'zustand';
import { IMaterial } from '../../../typings/client';

interface ICourseMaterials {
  [x: number]: IMaterial;
}

type TCourseMaterials = {
  materials: ICourseMaterials;
  setCourseMaterials(materials: ICourseMaterials): void;
};

export const useCourseMaterials = create<TCourseMaterials>((set) => ({
  materials: {},
  setCourseMaterials: (materials) => set(() => ({ materials })),
}));
