import Modal from '../../../shared/Modal';
import { checkIsIntelleka } from '../../../utils/link';
import { russianEndings, sliceBefore } from '../../../utils/string';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiErrorCircle } from 'react-icons/bi';
import { BsCheckLg } from 'react-icons/bs';

import { GrDocumentCsv } from 'react-icons/gr';
import { ImSpinner10 } from 'react-icons/im';
import { useMatch } from '@tanstack/react-location';
import { useToasts } from 'react-toast-notifications';
import { queryClient } from '../../../hooks/queryClient';
import { getMaterialsKey } from '../../../cache/admin/material';
import XLSX from 'xlsx';
import { onlyUnique } from '../../../utils/array';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { getError } from '../../../utils/api';
import { AxiosError } from 'axios';
import { useSortMaterials } from '../store/useMaterials';
import BadgeButton from '../../../shared/BadgeButton';
import { API } from '../../../api';
import { getValueByKey } from '../../../utils/object';
import { ThemeButton } from '../../../shared/Button';
import ThemeInput from '../../../shared/ThemeInput';
import { RiUploadCloud2Line } from 'react-icons/ri';

interface IDoc {
  Подзаголовки: string;
  Файлы: string;
}

export type IOrdered = Record<string, string | null>;

const StructureError: React.FC<{ error: unknown; text: React.ReactNode | string }> = (props) => {
  const error = JSON.stringify(getError(props.error as AxiosError<unknown>));
  return (
    <>
      <div className="mb-1">
        <i>{props.text}</i>
        <div className="mb-1 p-2 bg-pink-100 border border-dotted rounded mt-1 font-bold">
          {error.length > 100 ? `${error.slice(0, 100)}...` : error}
        </div>
      </div>
    </>
  );
};

const AdminMaterialMassAdding: React.FC<{ materialsCount: number }> = (props) => {
  const { addToast } = useToasts();
  const { massAddingMaterialOpen: open, setMassAddingMaterialOpen: toggle } = useSortMaterials();
  const isIntelleka = checkIsIntelleka();
  const { handleSubmit } = useForm();
  const match = useMatch();
  const courseId = match.params.id;
  const [fileList, setFileList] = useState<File[]>([]);
  const [fileNames, setFileNames] = useState<string[]>([]);

  const onChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files) return;
    const fileList = [];
    const fileNames = [];
    for (let i = 0; i < files.length; i++) {
      fileList.push(files[i]);
      const fileName = files[i].name.substring(0, files[i].name.length - 4).replace(/_/g, ' ');
      const getFullName = () => {
        return sliceBefore(fileName);
      };
      fileNames.push(getFullName());
    }
    setFileList(fileList);
    setFileNames(fileNames);
  };

  const changeFileTitle = (index: number, value: string) => {
    setFileNames(fileNames.map((name, nameIndex) => (index !== nameIndex ? name : value)));
  };

  function* enumerate(iterable: any[]) {
    let i = 0;

    for (const x of iterable) {
      yield [i, x];
      i++;
    }
  }

  const [loading, setLoading] = useState(false);
  const [successPercents, setSuccessPercents] = useState<number>(0);
  const [successTitle, setSuccessTitle] = useState<string>('');
  const [successTitles, setSuccessTitles] = useState<string[]>([]);

  const [errorTitle, setErrorTitle] = useState<string>('');
  const [errorTitles, setErrorTitles] = useState<string[]>([]);

  useEffect(() => {
    successTitle && setSuccessTitles([...successTitles, successTitle]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successTitle]);

  useEffect(() => {
    errorTitle && setErrorTitles([...errorTitles, errorTitle]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorTitle]);

  const errorMessage = (
    <>
      <b>Не удалось создать материалы:</b>
      <ul className="pl-4 py-1 italic">
        {errorTitles.map((e) => (
          <li className="list-disc">{e}</li>
        ))}
      </ul>
      <span>Проверьте коректность этих файлов и повторите попытку.</span>
    </>
  );

  useEffect(() => {
    if (errorTitles.length + successTitles.length === fileNames.length && fileNames.length !== 0) {
      setTimeout(() => {
        resetForm();
        if (errorTitles.length !== 0) {
          addToast(errorMessage, {
            appearance: 'error',
            autoDismiss: false,
          });
          setErrorTitles([]);
        }
        if (successTitles.length !== 0) {
          addToast(
            <>
              <b>{successTitles.length}</b>
              {` ${russianEndings(successTitles.length, [
                'материал успешно создан',
                'материала успешно созданы',
                'материалов успешно созданы',
              ])}`}
            </>,
            {
              appearance: 'success',
              autoDismiss: true,
            },
          );
          setErrorTitles([]);
        }
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successTitles, errorTitles, fileNames]);

  async function submit() {
    setLoading(true);
    for (const [i, file] of enumerate(fileList)) {
      try {
        const res = await API.admin.material.create({
          course: Number(courseId),
          pdf: file,
          title: fileNames[i],
          rank: props.materialsCount + i,
        });
        setSuccessPercents(Math.round(((i + 1) / fileList.length) * 100));
        setSuccessTitle(res.title);
      } catch (e) {
        fileNames[i] ? setErrorTitle(fileNames[i]) : setErrorTitle(`Pdf-${i + 1}`);
      }
    }
  }

  const orderMaterials = (data: IDoc[]) => {
    const obj = data.map((o) => {
      return {
        Подзаголовки: sliceBefore(o.Подзаголовки).trim(),
        Файлы: sliceBefore(o.Файлы).trim(),
      };
    });
    const parents = obj.map((o) => o['Подзаголовки']);
    const files = obj.map((o) => o['Файлы']);
    const all = parents.concat(files).filter(onlyUnique);
    const orderedArray: IOrdered = {};
    all.forEach((m) => {
      const child = obj.find((el) => el['Файлы'] === m);
      orderedArray[m] = child ? child['Подзаголовки'] : null;
    });
    return orderedArray;
  };

  const fileRef = useRef<HTMLInputElement>(null);
  const [structure, setStructure] = useState<File>();

  const resetForm = () => {
    queryClient.fetchQuery(getMaterialsKey({ course: courseId }));
    toggle(false);
    setLoading(false);
    setFileNames([]);
    setFileList([]);
    setSuccessPercents(0);
    setSuccessTitle('');
    setSuccessTitles([]);
    setErrorTitle('');
    setErrorTitles([]);
    setStatus(0);
    setStructure(undefined);
  };

  /**
   * Статусы состояние структурирования:
   * 0 - структурирование не началось
   * 1 - создание заголовоков и подзаголовоков
   * 2 - создание файлов
   * 3 - структурирование успешно окончено
   */
  const [status, setStatus] = useState<0 | 1 | 2 | 3 | 4>(0);

  /**
   * СТРУКТУРИРОВАНИЕ: создание заголовков и подзаголовков
   */
  const createTitles = async (titles: string[], ordered: IOrdered) => {
    setStatus(1);
    try {
      for (const [rank, title] of enumerate(titles)) {
        const res = await API.admin.material.create({
          title: title.trim(),
          course: Number(courseId),
          rank,
        });
        const isLast = res.title === titles[titles.length - 1];
        if (isLast) createFiles(ordered);
      }
    } catch (e) {
      addToast(
        <StructureError
          text="При создании одного из заголовков обнаружена следующая ошибка:"
          error={e}
        />,
        {
          appearance: 'error',
          autoDismiss: false,
        },
      );
      resetForm();
    }
  };

  /**
   * СТРУКТУРИРОВАНИЕ: создание файлов
   */
  const createFiles = async (ordered: IOrdered) => {
    setStatus(2);
    try {
      for (const [rank, title] of enumerate(fileNames)) {
        const res = await API.admin.material.create({
          course: Number(courseId),
          pdf: fileList[rank],
          title: title.trim(),
          rank,
        });
        const isLast = res.title === fileNames[fileNames.length - 1];
        if (isLast) updateRanks(ordered);
      }
    } catch (e) {
      addToast(
        <StructureError
          text="При создании одного из файлов обнаружена следующая ошибка:"
          error={e}
        />,
        {
          appearance: 'error',
          autoDismiss: false,
        },
      );
      resetForm();
    }
  };

  /**
   * СТРУКТУРИРОВАНИЕ: Обновление вложенности материалов
   */
  const updateRanks = async (ordered: IOrdered) => {
    setStatus(3);

    const materials = await queryClient.fetchQuery(getMaterialsKey({ course: courseId }));
    const materialsByTitle =
      Array.isArray(materials) &&
      materials?.reduce((prev, material) => {
        return {
          ...prev,
          [material.title.trim()]: material.id,
        };
      }, {});
    const children = Object.keys(ordered).filter((title) => !!ordered[title]);

    for (const [rank, t] of enumerate(children)) {
      const title = t.trim();
      const parentTitle = getValueByKey(ordered, title);
      const parent = (materialsByTitle && parentTitle && materialsByTitle[parentTitle]) || null;
      const id = getValueByKey(materialsByTitle, title);

      const data = { id, title: title, parent, rank };
      const isLast = title === children[children.length - 1];
      try {
        /** обновление вложенности материалов */
        await API.admin.material.update(id, data);
        if (isLast) {
          await queryClient.fetchQuery(getMaterialsKey({ course: courseId }));
          setStatus(4);
          setTimeout(() => resetForm(), 1000);
        }
      } catch (e) {
        addToast(
          <StructureError
            text={
              <>
                При структурировании файла <b>{title}</b>, обнаружена следующая ошибка:
              </>
            }
            error={e}
          />,
          {
            appearance: 'error',
            autoDismiss: false,
          },
        );
        resetForm();
      }
    }
  };

  const getPreloaderText = () => {
    switch (status) {
      case 1:
        return 'Создание заголовков...';
      case 2:
        return 'Загрузка файлов...';
      case 3:
        return 'Структурирование...';
      case 4:
        return 'Готово';
      default:
        return 'Сохранить';
    }
  };

  const startStructure = () => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    if (structure) {
      reader.onload = async (e: ProgressEvent<FileReader>) => {
        const bstr = e.target?.result;
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
        const wsName = wb.SheetNames[0];
        const ws = wb.Sheets[wsName];
        const data: IDoc[] = XLSX.utils.sheet_to_json(ws);
        const ordered = orderMaterials(data);
        const titles = Object.keys(ordered).filter(
          (title) => !ordered[title] || data.map((d) => d.Подзаголовки).includes(title),
        );
        createTitles(titles, ordered);
      };

      if (rABS) reader.readAsBinaryString(structure);
    }
  };

  return (
    <>
      <BadgeButton
        onClick={() => toggle(true)}
        className="badge bg-gray-badge w-fit mb-1 focus:outline-none"
      >
        Массовое добавление материалов
      </BadgeButton>
      <Modal
        open={open}
        className="leading-6 rounded max-w-[800px] min-h-[233px] mt-0 sm:mt-[1.75rem] text-black-default cursor-default text-left p-3 text-[17px] block"
        onClose={() => toggle(false)}
      >
        {fileNames.length ? (
          <form onSubmit={handleSubmit(submit)}>
            <div className="max-h-[500px] overflow-auto min-h-[180px]">
              {fileNames.map((_, i) => {
                return (
                  <div className="flex items-center" key={i}>
                    <div className="mr-2 text-lg ">
                      {loading ? (
                        successTitles.includes(fileNames[i]) ? (
                          <BsCheckLg className="text-green-500" />
                        ) : errorTitles.includes(fileNames[i]) ? (
                          <BiErrorCircle className="text-red-500" />
                        ) : (
                          <ImSpinner10 className="animate-spin text-active_p" />
                        )
                      ) : (
                        <AiOutlineFilePdf />
                      )}
                    </div>
                    <ThemeInput
                      name={fileNames[i]}
                      type="text"
                      className="w-full mb-1"
                      isIntelleka={isIntelleka}
                      value={fileNames[i]}
                      onChange={(e) => changeFileTitle(i, e.target.value)}
                    />
                  </div>
                );
              })}
            </div>
            {structure && (
              <div className="flex items-center mt-2">
                <GrDocumentCsv className="mr-2" />
                <div>{structure.name}</div>
              </div>
            )}
            <div className="mt-3">
              <ThemeButton
                onClick={() => toggle(false)}
                className="mr-1"
                isSecondary
                isIntelleka={isIntelleka}
                type="button"
              >
                Закрыть
              </ThemeButton>
              <ThemeButton
                className="mr-1"
                isSecondary
                type="button"
                onClick={() => fileRef.current?.click()}
                isIntelleka={isIntelleka}
              >
                <input
                  type="file"
                  accept={['xlsx', 'csv'].map((x) => '.' + x).join(',')}
                  ref={fileRef}
                  className="hidden"
                  onChange={(e) => setStructure(e.target.files?.[0])}
                />
                {structure ? 'Изменить' : 'Добавить'} структуру
              </ThemeButton>
              {structure ? (
                <ThemeButton
                  onClick={startStructure}
                  className="mr-1"
                  type="button"
                  disabled={status !== 0}
                  isIntelleka={isIntelleka}
                >
                  <div className="flex items-center">
                    {getPreloaderText()}
                    {status !== 0 && status !== 4 && (
                      <ImSpinner10 className="animate-spin text-white ml-1" />
                    )}
                    {status === 4 && <BsCheckLg className="text-white ml-1" />}
                  </div>
                </ThemeButton>
              ) : (
                <ThemeButton
                  className="mr-1"
                  type="submit"
                  disabled={loading}
                  isIntelleka={isIntelleka}
                >
                  {loading ? `Сохранение... ${successPercents}%` : 'Сохранить'}
                </ThemeButton>
              )}
            </div>
          </form>
        ) : (
          <>
            <div className="flex justify-between flex-col min-h-[233px]">
              <label className="flex items-center cursor-pointer border border-gray-400 w-fit max-w-xs justify-between p-2 hover:bg-gray-200 transition-all">
                <RiUploadCloud2Line className="mr-2" />
                <div>Добавить файлы</div>
                <input
                  type="file"
                  accept="application/pdf"
                  multiple
                  hidden
                  onChange={onChangeFiles}
                />
              </label>
              <div className="flex justify-between items-center italic text-gray-400">
                <div>Файлы не выбраны</div>
                <ThemeButton
                  onClick={() => toggle(false)}
                  className="mr-1 mt-3"
                  type="button"
                  isSecondary
                  isIntelleka={isIntelleka}
                >
                  Закрыть
                </ThemeButton>
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default AdminMaterialMassAdding;
