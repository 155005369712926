import { useMutation } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import { API } from '../../../api';
import { queryClient } from '../../../hooks/queryClient';
import { useCourseTests } from '../../../ui/admin/store/useTests';
import { getKey } from './';

export const useRemoveTest = (courseId: string) => {
  const { toggleForm } = useCourseTests();
  const { addToast } = useToasts();

  return useMutation<unknown, unknown, number>((data) => API.admin.test.remove(data), {
    onSuccess: () => {
      toggleForm(false);
      queryClient.refetchQueries(getKey(courseId));
    },
    onError: () => {
      addToast('Не удалось удалить тест', { appearance: 'error' });
    },
  });
};
