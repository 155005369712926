import { useEffect } from 'react';
import create from 'zustand';
import { createWebsocketUrl } from '../../../utils/link';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';

interface Notification {
  message: string;
  message_id: number;
  message_text: string;
  message_for: number;
  chat_id: number;
  author_id: number;
  author: string;
  chat_status: number;
}

export const useNotificationsStore = create<{
  notifications: Notification[];
  setNotifications: (data: Notification) => void;
  clearNotifications: () => void;
  removeNotification: (message_id: number) => void;
  filterNotifications: (chat_id: number) => void;
}>((set, get) => ({
  notifications: [
    // {
    //   chat_id: 1,
    //   message: 'Новое сообщение от agarkov.ua',
    //   message_for: 2,
    //   author_id: 1,
    //   chat_status: 0,
    //   message_text: 'подскажите пожалуйста, как мне наконец-то сдать экзамен и когда?',
    //   author: 'agarkov.ua',
    // },
    // {
    //   chat_id: 1,
    //   message: 'Новое сообщение от agarkov.ua',
    //   message_for: 2,
    //   author_id: 1,
    //   chat_status: 0,
    //   message_text: 'Добрый день',
    //   author: 'agarkov.ua',
    // },
  ],
  setNotifications: (notification) =>
    set({ notifications: [...get().notifications, notification] }),
  clearNotifications: () => set({ notifications: [] }),
  removeNotification: (message_id) =>
    set({ notifications: get().notifications.filter((m) => m.message_id !== message_id) }),
  filterNotifications: (chat_id) =>
    set({ notifications: get().notifications.filter((m) => m.chat_id !== chat_id) }),
}));

export function useChatNotifications(userId: number) {
  const socketUrl = createWebsocketUrl(`notifications/${userId}`);
  const { setNotifications } = useNotificationsStore();

  const { sendJsonMessage } = useWebSocket(socketUrl, {
    shouldReconnect: () => true,
    retryOnError: true,
    onMessage: ({ data }) => {
      setNotifications(JSON.parse(data));
    },
  });

  useEffect(() => {
    sendJsonMessage({});
  }, [sendJsonMessage]);
}
